import { usePage } from "@inertiajs/vue3"
const page = usePage()

/**
 * Check if the user is a super admin
 * @returns {boolean} true if the user is a super admin
 */
export function isSuperAdmin() {
	return page?.props?.isSuperAdmin === true
}

/**
 * Check if the route is a system route
 * @returns {boolean} true if the route is a system route
 */
export function isSystemRoute() {
	return page?.props?.routeList === "system"
}

/**
 * Composable for handling admin route prefixing based on user role
 * @returns {Object} Object containing route utilities
 */
export function useAdminRoutes() {
	const prefix = isSystemRoute() ? "system" : "dashboard"

	return {
		prefix,
		getRoute: (name) => `${prefix}.${name}`,
	}
}
