<template>
	<div class="advanced-search shadow-0 border mb-3 rounded">
		<AsyncSelector
			v-if="showSavedSearch && savedSearchAllowed"
			ref="searchAsyncSelector"
			v-model="activeSavedSearchId"
			:field="asyncSelectorField"
			@selected="handleSavedSearchSelected"
		>
		</AsyncSelector>
		<!-- <template #option-append="{ option, results }">
		<button
			class="btn btn-danger btn-floating btn-sm shadow-0"
			@click.stop="deleteSavedSearchClicked(option, results)"
		>
			<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" role="button" />
		</button>
	</template> -->

		<AdvancedSearchGroup
			v-for="(group, groupIndex) in searchGroups"
			:key="groupIndex"
			:group-number="groupIndex + 1"
			:group="group"
			:fields="fields"
			:options="{ ...options, maxDepth }"
			:depth="0"
			:show-logic="groupIndex > 0"
			@update:group="updateGroup(groupIndex, $event)"
			@remove-group="removeGroup(groupIndex)"
			@remove-condition="removeCondition(groupIndex, $event)"
			@add-condition="addCondition(groupIndex)"
			@add-nested-group="addNestedGroup(groupIndex)"
		/>

		<div class="toolbar d-flex">
			<div class="flex-grow-1">
				<button
					class="btn btn-primary mb-2 btn-rounded btn-sm"
					@click="addGroup"
				>
					Add Group
				</button>
			</div>

			<button
				v-if="savedSearchAllowed && activeSavedSearch"
				class="btn btn-primary mb-2 btn-rounded btn-sm"
				:disabled="updatingSearchInProgress"
				@click="updateSavedSearch"
			>
				Update Saved Search
				<FontAwesomeIcon :icon="lookupIcon('save', 'fas')" role="button" />
			</button>
			<button
				v-if="savedSearchAllowed"
				class="btn btn-primary mb-2 btn-rounded btn-sm"
				:disabled="creatingSavedSearchLoading"
				@click="createModalOpen = true"
			>
				New Saved Search
				<FontAwesomeIcon :icon="lookupIcon('plus', 'fas')" role="button" />
			</button>
			<button
				class="btn btn-primary mb-2 btn-rounded btn-sm"
				size="sm"
				:disabled="creatingSavedSearchLoading"
				@click="$emit('search')"
			>
				Search
				<FontAwesomeIcon
					:icon="lookupIcon('magnifying-glass', 'fas')"
					role="button"
				/>
			</button>
		</div>

		<ConfirmDelete
			v-model="deleteModalOpen"
			:route="deleteRoute"
			title="Delete Saved Search"
			:body-text="`Are you sure you want to delete this saved search?<br><br><strong>${itemToDelete?.name}</strong>`"
			@success="reload()"
		/>

		<MDBModal
			v-model="createModalOpen"
			centered
			size="md"
			backdrop
			keyboard
			scrollable
		>
			<MDBModalHeader>
				<MDBModalTitle>
					<h5 class="modal-title">Create Saved Search</h5>
				</MDBModalTitle>
			</MDBModalHeader>
			<form @submit.prevent="createNewSavedSearch">
				<MDBModalBody>
					<!-- <MDBInput
						v-model="savedSearchForm.name"
						label="Name"
						:is-valid="!formErrors.name"
						:is-validated="!!formErrors.name"
						:invalid-feedback="formErrors.name"
					/> -->
					<FormField
						v-model="savedSearchForm.name"
						:field="{ label: 'Name' }"
						type="text"
						:is-valid="!formErrors.name"
						:is-validated="!!formErrors.name"
						:invalid-feedback="formErrors.name"
					/>

					<div v-if="props.options.model == 'Attendee'" class="mt-3">
						<MDBCheckbox
							v-model="savedSearchForm.generate_dynamic_group"
							label="Create Dynamic Group From Search"
							:is-valid="!formErrors.generate_dynamic_group"
							:is-validated="!!formErrors.generate_dynamic_group"
							:invalid-feedback="formErrors.generate_dynamic_group"
						/>
						<!-- I want to bring this back but don't have the backend for this right now -->
						<!-- <MDBCheckbox
							v-model="savedSearchForm.generate_normal_group"
							label="Create Normal Group From Search Results"
							:is-valid="!formErrors.generate_normal_group"
							:is-validated="!!formErrors.generate_normal_group"
							:invalid-feedback="formErrors.generate_normal_group"
						/> -->
					</div>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn
						color="secondary"
						size="sm"
						class="btn-rounded"
						@click="createModalOpen = false"
					>
						Cancel
					</MDBBtn>
					<MDBBtn type="submit" color="primary" size="sm" class="btn-rounded">
						Save
					</MDBBtn>
				</MDBModalFooter>
			</form>
		</MDBModal>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from "vue"
import { useAdvancedSearch } from "@/Composables/useAdvancedSearch"
import AdvancedSearchGroup from "./AdvancedSearchGroup.vue"
import AsyncSelector from "@/Components/Mod/AsyncSelector.vue"
import { usePage } from "@inertiajs/vue3"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import FormField from "@/Components/Mod/FormField.vue"
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"

import {
	MDBBtn,
	MDBInput,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBCheckbox,
} from "mdb-vue-ui-kit"
import CreateDialog from "@/Components/Mod/CreateDialog.vue"

const props = defineProps({
	modelValue: {
		type: Array,
		required: true,
	},
	fields: {
		type: Array,
		required: true,
	},
	maxDepth: {
		type: Number,
		default: 3,
	},
	options: {
		type: Object,
		default: () => ({}),
	},
	searchableType: {
		type: String,
		default: null,
	},
})

const emit = defineEmits(["update:modelValue", "search"])

const {
	searchGroups,
	activeSavedSearchId,
	activeSavedSearch,
	itemToDelete,
	deleteModalOpen,
	createModalOpen,
	creatingSavedSearchLoading,
	updatingSearchInProgress,
	savedSearchForm,
	formErrors,
	asyncSelectorField,
	addGroup,
	updateGroup,
	removeGroup,
	addCondition,
	removeCondition,
	addNestedGroup,
	handleSavedSearchSelected,
	deleteSavedSearchClicked,
	createNewSavedSearch,
	updateSavedSearch,
	searchAsyncSelector,
	showSavedSearch,
	savedSearchAllowed,
} = useAdvancedSearch(props.fields, props.options)

const deleteRoute = "dashboard.project.saved-searches.delete"

const onSuccess = () => {
	console.log("onSuccess", usePage().props.flash)
}

// Watch for changes in searchGroups and emit updates
watch(
	searchGroups,
	(newValue) => {
		emit("update:modelValue", newValue)
	},
	{ deep: true },
)

// Initialize searchGroups with modelValue
onMounted(() => {
	emit("update:modelValue", [
		{
			conditions: [{ field: null, operator: "=", value: null, logic: "and" }],
			logic: "and",
		},
	])
	nextTick(() => {
		searchGroups.value = props.modelValue
	})
})
</script>

<style scoped>
.advanced-search {
	padding: 20px;
}

.toolbar {
	margin-top: 20px;
}

@media (max-width: 767px) {
	.toolbar {
		flex-direction: column;
		align-items: stretch;
	}

	.toolbar button {
		margin-top: 10px;
	}
}
</style>
