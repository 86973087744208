<template>
	<MDBCarousel
		v-model="carouselIndex"
		:label="props.label"
		:items="props.itemList"
		inner-class="rounded-3 bg-primary-10"
		:controls="false"
		:interval="props.itemList.length > 1 ? 5000 : 0"
		:indicators="props.itemList.length > 1"
	/>
</template>

<!-- Rest of the component remains the same -->

<script setup>
import { ref, reactive, defineProps, defineEmits, computed } from "vue"
import MDBCarousel from "@/Components/Mod/MDBOverride/MDBCarousel.vue"
import { formatTimestamp } from "@/Utils/dateHelpers"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import { format } from "prettier"
import { formatDollar } from "@/Utils/formatDollar"
const props = defineProps({
	itemList: {
		type: Array,
		default: () => [],
	},
	label: {
		type: String,
		default: null,
	},
})
const carouselIndex = ref(0)

const onClick = (index) => {}
</script>
