<template>
	<thead :class="getClass">
		<draggable
			:model-value="modelValue"
			:disabled="!tableOptions.draggableHeaders"
			item-key="key"
			tag="tr"
			ghost-class="ghost"
			handle=".drag-handle"
			@move="onMoveCallback"
			@update:model-value="updateHeaders"
		>
			<template #item="{ element: header, index }">
				<th
					v-if="header.key === '_select'"
					class="fixed-cell text-start table-light"
					:class="header.headerClassList || []"
					:data-key="header.key"
				>
					<div :style="getAlignment(header)">
						<input
							v-if="
								tableOptions.allowSelectAll && !tableOptions.delete.disabled
							"
							id="select-all"
							v-model="toggleAllEnabledProxy"
							class="form-check-input"
							type="checkbox"
							:indeterminate="isIndeterminate"
							test-id="dt-select-all-checkbox"
						/>
						<label for="select-all" class="ms-2 select-all-label"
							>Select All</label
						>
					</div>
				</th>
				<th
					v-else-if="header.key === 'actions'"
					class="text-end table-light fixed-cell-end"
					:class="header.headerClassList || []"
					:data-key="header.key"
				>
					<slot name="item._actions">
						<div :style="getAlignment(header)">
							<span :class="{ 'drag-handle': header.draggable }">
								{{ header.title }}
							</span>

							<FontAwesomeIcon
								v-if="sortBy.key === getSortKey(header)"
								:icon="
									lookupIcon(
										sortBy.direction === 'asc' ? 'arrow-up' : 'arrow-down',
										'fas',
									)
								"
								class="ms-2"
							/>
						</div>
					</slot>
				</th>
				<th
					v-else
					:key="header.key"
					:data-key="header.key"
					:class="header.headerClassList || []"
					@click="updateSort(header)"
				>
					<div :style="getAlignment(header)">
						<span
							:class="{ 'drag-handle': header.draggable }"
							class="column-header-title smaller fw-bold"
						>
							{{ header.title }}
						</span>
						<FontAwesomeIcon
							v-if="sortBy.key === getSortKey(header)"
							:icon="
								lookupIcon(
									sortBy.direction === 'asc' ? 'arrow-up' : 'arrow-down',
									'fas',
								)
							"
							class="ms-2 column-header-sort-icon"
						/>
					</div>
				</th>
			</template>
		</draggable>
	</thead>
</template>

<script setup>
import { defineProps, computed, watch } from "vue"
import draggable from "vuedraggable"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const emit = defineEmits([
	"update:sortBy",
	"update:model-value",
	"update:toggle-all-enabled",
])

// #region ---------  Props ----------
const props = defineProps({
	itemClass: {
		type: String,
		default: "",
	},
	item: {
		type: Object,
		default: () => {
			return {}
		},
	},
	modelValue: {
		type: Array,
		default: () => {
			return []
		},
	},
	tableOptions: {
		type: Object,
		default: () => {
			return {}
		},
	},
	sortBy: {
		type: Object,
		default: () => {
			return {
				key: null,
				direction: null,
			}
		},
	},
	isIndeterminate: {
		type: Boolean,
		default: false,
	},
	toggleAllEnabled: {
		type: Boolean,
		default: false,
	},
})

const toggleAllEnabledProxy = computed({
	get: () => {
		return props.toggleAllEnabled
	},
	set: (value) => {
		emit("update:toggle-all-enabled", value)
	},
})

const getClass = computed(() => {
	return props.itemClass
})

const columnName = (header) => {
	return `item.${header.key}`
}

const getSortKey = (header) => {
	return header.field && header.field.name ? header.field.name : header.key
}

const alignmentOptions = {
	start: "left",
	end: "right",
	center: "center",
}

const getAlignment = (header) => {
	const alignmentStyle = alignmentOptions[header.align]
		? alignmentOptions[header.align]
		: header.align
			? header.align
			: "left"
	return `text-align: ${alignmentStyle}`
}

/**
 * takes in a header containing title and key
 * and finds the data from item.
 * if key uses dot notation, dig into the object
 * @param {object} item
 * @param {object} header
 */
const getNonSlotValue = (item, header) => {
	const key = header.key
	if (key.includes(".")) {
		const keys = key.split(".")
		let value = item
		keys.forEach((k) => {
			value = value[k]
		})
		return value
	} else {
		return item[key]
	}
}

const updateSort = (header) => {
	if (header.sortable === false || header.key === "_select") {
		return
	}
	let sortField = getSortKey(header)
	const tempSort = { ...props.sortBy }
	if (tempSort.key === sortField) {
		// If the clicked header is already the sorted column, invert the sort direction
		tempSort.direction = tempSort.direction === "asc" ? "desc" : "asc"
	} else {
		// If another header is clicked, update the sort column and set the default direction to 'asc'
		tempSort.key = sortField
		tempSort.direction = "asc"
	}
	emit("update:sortBy", tempSort) // emit the updated sortBy object
}

const updateHeaders = (newHeaders) => {
	emit("update:model-value", newHeaders)
}

const onMoveCallback = (evt, originalEvent) => {
	return true
	// const key = evt.related.getAttribute("data-key")
	// if (key == "_select") {
	// 	// todo - figure out how to cancel dragging here.
	// 	return false
	// }
	// return true
}
</script>

<style scoped>
.drag-handle {
	cursor: move;
}
.fixed-cell {
	position: sticky;
	left: 0;
	z-index: 1;
	border-collapse: separate;
	background-clip: padding-box;
}
.fixed-cell-end {
	position: sticky;
	right: 0;
	z-index: 10;
	border-collapse: separate;
	background-clip: padding-box;
}
th:first-of-type:not([data-key="_select"]) {
	padding-left: 0;
}
</style>
