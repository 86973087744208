<template>
	<h5 v-if="props.title.length > 0">
		{{ props.title }}
	</h5>
	<div class="row">
		<p v-for="(field, k) in fieldsListComputed" :key="k">
			<strong>{{ field.label }}: </strong>

			<span :class="field.classList || []">
				<template v-if="field.type === 'timestamp'">
					{{ formatTimestamp(getValue(field)) }}
				</template>
				<template v-else-if="field.type === 'date'">
					{{ formatTimestamp(getValue(field), "MM/dd/yy") }}
				</template>
				<template v-else-if="field.type === 'dollar'">
					{{ formatDollar(getValue(field)) }}
				</template>
				<template v-else>
					{{ getValue(field) }}
				</template>
			</span>
		</p>
	</div>
</template>

<!-- Rest of the component remains the same -->

<script setup>
import { defineProps, computed } from "vue"
import { formatTimestamp } from "@/Utils/dateHelpers"
import { formatDollar } from "@/Utils/formatDollar"
import { storeToRefs } from "pinia"

const props = defineProps({
	fieldsList: {
		type: Object,
		default: () => ({}),
	},
	formatter: {
		type: [Function, undefined],
		default: undefined,
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
	title: {
		type: String,
		default: "",
	},
})
const { dataModel } = storeToRefs(props.dataStore)

const getValue = (field) => {
	// let value = props.dataStore.getNestedValue(dataModel.value, field.name)
	let value = props.dataStore.getModelValue(field)
	return field.formatter ? field.formatter(value, dataModel.value) : value
}

const fieldsListComputed = computed(() => {
	// get all that condition is true, ot doesn't have a condition
	return props.fieldsList.filter((field) => {
		return field.condition ? field.condition(dataModel.value) : true
	})
})
</script>
