import { usePage } from "@inertiajs/vue3"
import { useAdminRoutes, isSuperAdmin } from "./useAdminRoutes"

export const usePermissions = () => {
	// Cache the permissions object
	let permissionsObj = null

	/**
	 * Initialize or return the permissions object for lookups
	 * @returns {Object<string, boolean>} Permissions object
	 */
	function getPermissions() {
		if (permissionsObj !== null) {
			return permissionsObj
		}

		try {
			const page = usePage()
			if (!page?.props?.userPermissions) {
				return {}
			}

			permissionsObj = page.props.userPermissions
			return permissionsObj
		} catch (e) {
			console.error("Error initializing permissions:", e)
			return {}
		}
	}

	/**
	 * Check if user has a specific permission
	 * @param {string} permissionString the name of the permission to check
	 * @returns {boolean} true if the user has the permission
	 */
	function userCan(permissionString) {
		try {
			if (isSuperAdmin() || !permissionString) {
				return true
			}
			return getPermissions()[permissionString] === true
		} catch (e) {
			console.error("Error checking permission:", e)
			return false
		}
	}

	/**
	 * Check if user has any of the specified permissions
	 * @param {string[]} permissions array of permission strings to check
	 * @returns {boolean} true if the user has any of the permissions
	 */
	function userCanAny(permissions) {
		try {
			if (isSuperAdmin() || !permissions || permissions.length === 0) {
				return true
			}
			const perms = getPermissions()
			return permissions.some((permission) => perms[permission] === true)
		} catch (e) {
			console.error("Error checking permissions:", e)
			return false
		}
	}

	/**
	 * Check if user has all of the specified permissions
	 * @param {string[]} permissions array of permission strings to check
	 * @returns {boolean} true if the user has all of the permissions
	 */
	function userCanAll(permissions) {
		try {
			if (isSuperAdmin() || !permissions || permissions.length === 0) {
				return true
			}
			const perms = getPermissions()
			return permissions.every((permission) => perms[permission] === true)
		} catch (e) {
			console.error("Error checking permissions:", e)
			return false
		}
	}

	const { getRoute } = useAdminRoutes()

	const fieldsList = [
		{
			name: "name",
			label: "Name",
			type: "text",
			readonly: true,
		},
	]

	return {
		userCan,
		userCanAny,
		userCanAll,
		isSuperAdmin,
		indexRoute: getRoute("permissions.index"),
		editRoute: getRoute("permissions.show"),
		deleteRoute: getRoute("permissions.destroy"),
		storeRoute: getRoute("permissions.store"),
		itemName: "Permission",
		itemKey: "permission",
		tableTitle: "Permissions List",
		fieldsList,
	}
}
