<template>
	<div v-if="editMode && props.card.toolbar !== false" class="grid-view">
		<div class="row px-2">
			<div class="col-10">
				<div class="form-floating" style="">
					<input
						id="name"
						class="form-control active"
						type="text"
						autocomplete="off"
						error-messages=""
					/>
					<label class="form-label" for="name">Name</label>
				</div>
			</div>
			<div class="col-2 text-end">
				<button
					class="btn btn-success btn-floating btn-lg"
					@click.prevent="editMode = false"
				>
					<FontAwesomeIcon :icon="lookupIcon('save', 'fas')" />
				</button>
				<button class="btn btn-primary btn-floating btn-lg" @click.prevent="">
					<FontAwesomeIcon :icon="lookupIcon('plus', 'far')" />
				</button>
			</div>
		</div>
	</div>
	<div
		v-if="!editMode && props.card.toolbar !== false"
		class="grid-header"
		:class="props.card.toolbarClassList || []"
	>
		<MDBBtn
			v-for="(button, index) in props.card.toolbar"
			:key="index"
			:color="button.color"
			:size="button.size"
			:class="button.classList ? button.classList : []"
			:disabled="props.disabled || button.disabled"
			:href="button.href"
			:tag="button.type == 'external_link' ? 'a' : Link"
		>
			<FontAwesomeIcon
				v-if="button.icon"
				:icon="lookupIcon(button.icon, 'fas')"
			/>
			{{ button.text }}
		</MDBBtn>
		<button
			v-if="!editMode"
			class="btn btn-outline-primary rounded-circle btn-floating"
			@click.prevent="editMode = true"
		>
			<FontAwesomeIcon :icon="lookupIcon('pencil', 'fas')" />
		</button>
	</div>
	<GridLayout
		v-model:layout="layout"
		:responsive-layouts="layouts"
		:cols="{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }"
		:row-height="50"
		:class="['grid-layout', { 'edit-mode': editMode }]"
		:is-draggable="editMode"
		:is-resizable="editMode"
		:responsive="true"
		:auto-size="true"
		:vertical-compact="true"
		:use-css-transforms="true"
		@layout-updated="layoutUpdatedEvent"
		@breakpoint-changed="breakpointChangedEvent"
	>
		<GridItem
			v-for="item in layout"
			:key="item.i"
			:x="item.x"
			:y="item.y"
			:w="item.w"
			:h="item.h"
			:i="item.i"
		>
			<div
				class="grid-item-content"
				:class="gridModel[item.i]['classList'] || []"
			>
				<LiveHtml
					v-if="gridModel[item.i]['body']?.type === 'live-html'"
					:field="gridModel[item.i]['body']"
					:data-store="tileStores[item.i]"
					:form-model="gridModel[item.i]['itemModel']"
				/>
				<FormCard
					v-else
					class="grid-item-content-card"
					:card="gridModel[item.i]['body']"
					:data-store="tileStores[item.i]"
				/>
			</div>
		</GridItem>
	</GridLayout>

	<!-- Set up an MDBModal -->
	<MDBModal v-model="modal" title="Add Tile" size="lg" @close="modal = false">
		<template #body>
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="tileType">Tile Type</label>
						<select id="tileType" v-model="tileType" class="form-control">
							<option value="text">Text</option>
							<option value="image">Image</option>
							<option value="video">Video</option>
						</select>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label for="tileTitle">Tile Title</label>
						<input
							id="tileTitle"
							v-model="tileTitle"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<MDBBtn color="secondary" @click="modal = false"> Close </MDBBtn>
			<MDBBtn color="primary" @click="addTile"> Add Tile </MDBBtn>
		</template>
	</MDBModal>
</template>

<script setup>
import { toArray } from "lodash"
import { computed, ref, nextTick } from "vue"
// import VueGridLayout from "vue-grid-layout"

import GridLayout from "@/Components/Mod/GridLayout/GridLayout.vue"
import GridItem from "@/Components/Mod/GridLayout/GridItem.vue"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { MDBBtn, MDBModal } from "mdb-vue-ui-kit"
import { Link } from "@inertiajs/vue3"

// import Mod/FormModal
import FormModal from "@/Components/Mod/FormModal.vue"
import FormCard from "./FormCard.vue"
import LiveHtml from "@/Components/Mod/FormFields/LiveHtml.vue"

import { useFormModel } from "@/Composables/useFormModel"
import { useDataModelStore } from "@/Store/dataModelStore"
import { storeToRefs } from "pinia"

const { buildModel } = useFormModel()
const itemModel = ref({})
// computed(() => {
// 	return buildModel(props.cardItem, props.card, props)
// })

const props = defineProps({
	tileList: {
		type: Array,
		required: true,
		default: () => [],
	},
	gridData: {
		type: Object,
		required: true,
		default: () => {},
	},
	dataStore: {
		type: Object,
		required: true,
		default: () => {},
	},
	card: {
		type: Object,
		required: true,
		default: () => {},
	},
})

const { dataModel } = storeToRefs(props.dataStore)

const modal = ref(false)
const editTile = ref(0)
const editMode = ref(false)
const asset_list = ref({})
const gridModel = ref({})
const layout = ref([])
const tileStores = ref([])
const layouts = computed(() => {
	let layoutGroup = {
		lg: [],
		xs: [],
	}
	let totalHeight = 0
	props.tileList.map((tile, indexNum) => {
		if (tile.body_card) {
			let index = indexNum.toString()
			if (tile.asset_list) {
				Object.keys(tile.asset_list).forEach((key) => {
					asset_list.value[key] = tile.asset_list[key]
					//find "[["+keyword+"]]" in tile.body_card and replace with asset_list[key]
					tile.body_card = tile.body_card.replace(
						new RegExp("\\[\\[" + key + "\\]\\]", "g"),
						asset_list.value[key],
					)
				})
			}
			if (props.gridData[tile.id]) {
				gridModel.value[index] = props.gridData[tile.id]
			}
			gridModel.value[index]["tileId"] = tile.id

			gridModel.value[index]["classList"] = tile.body_card?.classList || []
			gridModel.value[index]["itemModel"] = { tileId: tile.id }

			tileStores.value[index] = useDataModelStore("tileStore-" + tile.id)
			const tileData = dataModel.value.tileData?.[tile.id]
			const dashboard_assets = tileData.dashboard_assets
			// delete tileData.dashboard_assets
			const parentDataModel = { ...dataModel.value }
			delete parentDataModel.tileData
			delete parentDataModel.dashboard_assets
			delete parentDataModel.tileList

			tileStores.value[index].initializeDataModel(
				{ tileData, dashboard_assets, parentDataModel },
				[],
			)
			// tileStores.value[index].setModelValue()

			let bodyCard = tile.body_card
			if (bodyCard && bodyCard.fieldsList) {
				bodyCard.fieldsList.forEach((field, index) => {
					field.tileId = tile.id
					bodyCard.fieldsList[index] = field
				})
			}
			console.log("body card: ", tile.body_card)
			if (tile.body_card) {
				gridModel.value[index]["body"] = tile.body_card
				gridModel.value[index]["body"]["options"] = tile.options || {}
				gridModel.value[index]["body"]["options"]["tileId"] = tile.id
				gridModel.value[index]["route"] = tile.custom_settings?.route || ""
			}

			layoutGroup.lg.push({
				x: parseInt(tile.coordinates.x),
				y: parseInt(tile.coordinates.y),
				w: parseInt(tile.coordinates.width),
				h: parseInt(tile.coordinates.height),
				i: index,
				static: false,
			})

			layoutGroup.xs.push({
				x: 0,
				y: totalHeight,
				w: 6,
				h: parseInt(tile.coordinates.height),
				i: index,
				static: false,
			})
			totalHeight = totalHeight + parseInt(tile.coordinates.height)
		}
	})
	layoutGroup.xxs = layoutGroup.xs
	layoutGroup.sm = layoutGroup.lg
	layoutGroup.md = layoutGroup.lg
	nextTick(() => {
		layout.value = layoutGroup.lg
	})
	return layoutGroup
})

const formLookup = computed(() => {
	let lookupTable = {}
	props.tileList.map((tile, indexNum) => {
		let index = indexNum.toString()
		lookupTable[index] = {
			settings: tile.settings,
			custom_settings: tile.custom_settings,
			asset_list: tile.asset_list,
			coordinates: tile.coordinates,
			name: tile.name,
		}
	})
	return lookupTable
})
const draggable = computed(() => {
	if (props.gridData.metadata && props.gridData.metadata.draggable)
		return props.gridData.draggable
	return false
})
const resizable = computed(() => {
	if (props.gridData.metadata && props.gridData.metadata.resizable)
		return props.gridData.resizable
	return false
})
const navigate = (route) => {
	// console.log(route)
}
const layoutUpdatedEvent = (layout) => {
	// console.log(layout)
}
const breakpointChangedEvent = (newBreakpoint, newCols) => {
	// console.log(newBreakpoint, newCols)
}
// const index = 0
</script>

<style scoped>
/* .vue-grid-item:not(.vue-grid-placeholder) {
	border: 1px solid black;
} */
.vue-grid-item div {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 100%;
}
.vue-grid-item .resizing {
	opacity: 0.9;
}

.vue-grid-item .text {
	font-size: 24px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
	width: 100%;
}

.vue-grid-item .no-drag {
	height: 100%;
	width: 100%;
}

.vue-grid-item .minMax {
	font-size: 12px;
}

.vue-grid-item .add {
	cursor: pointer;
}

.vue-draggable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	left: 0;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#0076db'/></svg>")
		no-repeat;
	background-position: bottom right;
	padding: 0 8px 8px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: pointer;
}
/*
.layoutJSON {
	background: #ddd;
	border: 1px solid black;
	margin-top: 10px;
	padding: 10px;
} */

.columns {
	-moz-columns: 120px;
	-webkit-columns: 120px;
	columns: 120px;
}
.vue-grid-item.vue-grid-placeholder {
	background: #0076db !important;
}
.edit-mode .card {
}
</style>
