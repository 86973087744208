<template>
	<!-- <MDBBtn
		v-if="props.disableTooltip"
		color="primary"
		:floating="
			!props.formDefinition.create.buttonText &&
			(props.formDefinition.create?.floating ?? true)
		"
		size="sm"
		type="button"
		class="shadow-0 create-dialog-btn m-0"
		:class="{
			'btn-rounded': props.formDefinition.create.buttonText,
			...(props.formDefinition.create.buttonClassList ?? {}),
		}"
		test-id="create-dialog-create-btn"
		@click.prevent="openModal"
	>
		<span
			v-if="props.formDefinition.create?.buttonText"
			class="create-btn-text"
		>
			<FontAwesomeIcon
				v-if="props.formDefinition.create.buttonIconLeft"
				:icon="lookupIcon(props.formDefinition.create.buttonIconLeft, 'far')"
			/>
			{{ props.formDefinition.create.buttonText }}
			<FontAwesomeIcon
				v-if="props.formDefinition.create.buttonIconRight"
				:icon="lookupIcon(props.formDefinition.create.buttonIconRight, 'far')"
			/>
		</span>
		<FontAwesomeIcon v-else :icon="lookupIcon('plus', 'fas')" />
	</MDBBtn> -->
	<MDBTooltip
		v-model="tooltip"
		class="create-dialog-tooltip"
		offset="0,5"
		tabindex="-1"
		:disabled="!props.formDefinition.create?.tooltip || props.disableTooltip"
		direction="left"
	>
		<template #reference>
			<MDBBtn
				color="primary"
				:floating="
					!props.formDefinition.create.buttonText &&
					(props.formDefinition.create?.floating ?? true)
				"
				size="sm"
				type="button"
				class="shadow-0 create-dialog-btn m-0"
				:class="[
					{
						'btn-rounded': props.formDefinition.create.buttonText,
					},
					props.formDefinition.create.classList || [],
				]"
				test-id="create-dialog-create-btn"
				@click.prevent="openModal"
			>
				<span
					v-if="props.formDefinition.create?.buttonText"
					class="create-btn-text"
				>
					<FontAwesomeIcon
						v-if="props.formDefinition.create.buttonIconLeft"
						:icon="
							lookupIcon(props.formDefinition.create.buttonIconLeft, 'far')
						"
					/>
					{{ props.formDefinition.create.buttonText }}
					<FontAwesomeIcon
						v-if="props.formDefinition.create.buttonIconRight"
						:icon="
							lookupIcon(props.formDefinition.create.buttonIconRight, 'far')
						"
					/>
				</span>
				<FontAwesomeIcon v-else :icon="lookupIcon('plus', 'fas')" />
			</MDBBtn>
		</template>
		<template #tip>
			{{ props.formDefinition.create?.tooltip ?? "" }}
		</template>
	</MDBTooltip>

	<MDBModal
		v-model="dialogComputed"
		dialog-classes="modal-dialog-scrollable modal-fullscreen-sm-down"
		:size="currentTab === 'select' ? props.size : 'lg'"
		:remove-backdrop="props.removeBackdrop"
	>
		<MDBModalBody class="px-3">
			<form ref="formRef" @submit.prevent="submit">
				<template v-if="tabsList.length > 0">
					<MDBTabs v-model="currentTab">
						<!-- Tabs Content -->
						<MDBTabContent>
							<MDBTabPane
								v-for="(tab, i) in tabsList"
								:key="tab.tabId ? tab.tabId : 'tab-' + i"
								:tab-id="tab.tabId ? tab.tabId : 'tab-' + i"
							>
								<div class="position-absolute top-0 end-0 p-3">
									<button
										class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary shadow-0"
										type="button"
										@click.prevent="dialogComputed = false"
									>
										<FontAwesomeIcon
											:icon="lookupIcon('xmark', 'fat')"
											size="lg"
										/>
									</button>
								</div>
								<div class="text-center mt-2">
									<FontAwesomeIcon
										v-if="tab.icon"
										:icon="lookupIcon(tab.icon, 'far')"
										size="3x"
										class="text-primary"
									/>
									<h4 v-if="tab.title" class="text-center mt-3 fw-bold">
										{{ $t(tab.title) }}
									</h4>
									<p
										v-if="tab.subtitle"
										class="text-center"
										v-html="$t(tab.subtitle)"
									></p>
									<template v-if="processingJob">
										<div class="p-3">
											<div class="spinner-border" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
										</div>
									</template>
									<template v-else>
										<FormCard
											v-for="(card, j) in tab.cards"
											:key="j"
											:card="card"
											:data-store="createStore"
										>
											<template #title>
												<MDBCardTitle class="d-flex justify-content-between">
													<div class="card-title-text">{{ card.title }}</div>
												</MDBCardTitle>
											</template>
										</FormCard>
									</template>
									<div class="footer-buttons">
										<button
											v-if="tab.closeBtn"
											:class="
												tab.closeBtn.classList ||
												'btn-outline-primary btn-sm rounded-pill btn shadow-0'
											"
											@click.prevent="dialogComputed = false"
										>
											<FontAwesomeIcon
												v-if="tab.closeBtn.icon"
												:icon="lookupIcon(tab.closeBtn.icon, 'fas')"
											/>
											{{ tab.closeBtn.text || "Close" }}
										</button>
										<button
											v-if="tab.prevTab"
											class="btn-outline-primary btn-sm rounded-pill btn shadow-0"
											type="button"
											:disabled="processingJob"
											@click.prevent="currentTab = tab.prevTab.id"
										>
											<FontAwesomeIcon
												:icon="lookupIcon('arrow-left', 'fas')"
											/>
											{{ tab.prevTab.text || "Back" }}
										</button>
										<button
											v-if="tab.nextTab"
											class="btn-primary btn-sm rounded-pill btn btn-filled"
											type="button"
											:disabled="processingJob"
											@click.prevent="currentTab = tab.nextTab.id"
										>
											{{ tab.nextTab.text || "Next" }}
											<FontAwesomeIcon
												:icon="lookupIcon('arrow-right', 'fas')"
											/>
										</button>
										<Link
											v-if="tab.link"
											class="btn-primary btn-sm rounded-pill btn btn-filled"
											:href="tab.link.href"
											:disabled="processingJob"
										>
											{{ tab.link.text || "Go" }}
											<FontAwesomeIcon
												:icon="lookupIcon('arrow-right', 'fas')"
											/>
										</Link>
										<button
											v-if="tab.createButton"
											:disabled="
												processingJob ||
												form.processing ||
												(typeof props.formDefinition?.disabled == 'function' &&
													props.formDefinition.disabled(formObject))
											"
											:loading="form.processing"
											type="submit"
											class="btn-primary btn-sm rounded-pill btn btn-filled"
											style="
												--mdb-focus-ring-color: rgba(
													var(--mdb-primary-rgb),
													0.5
												);
											"
											test-id="create-dialog-submit-btn"
										>
											{{ $t(tab.createButton || "Create") }}
											<FontAwesomeIcon
												:icon="lookupIcon('arrow-right', 'fas')"
											/>
										</button>
									</div>
								</div>
							</MDBTabPane>
						</MDBTabContent>
					</MDBTabs>
				</template>
				<template v-else-if="replyFields.length == 0">
					<button
						class="float-end btn btn-outline-secondary btn-floating btn-sm mt-n3 me-0 border border-secondary shadow-0"
						type="button"
						@click.prevent="dialogComputed = false"
					>
						<FontAwesomeIcon :icon="lookupIcon('times', 'fat')" size="lg" />
					</button>
					<div class="text-center">
						<FontAwesomeIcon
							v-if="props.formDefinition.icon"
							:icon="lookupIcon(props.formDefinition.icon, 'far')"
							class="h1 text-primary"
						/>
						<h4 v-if="props.formDefinition.title" class="text-center mt-3">
							{{ $t(props.formDefinition.title) }}
						</h4>
						<h4 v-else class="text-center mt-3">
							Create New {{ $t(props.formDefinition.itemName ?? "") }}
						</h4>
						<p v-if="props.formDefinition.subtitle" class="text-center">
							{{ $t(props.formDefinition.subtitle) }}
						</p>
						<FormCard
							v-for="(card, j) in props.formDefinition.cards"
							:key="j"
							:card="card"
							:data-store="createStore"
						>
							<template #title>
								<MDBCardTitle class="d-flex justify-content-between">
									<div class="card-title-text">{{ card.title }}</div>
								</MDBCardTitle>
							</template>
						</FormCard>
					</div>
				</template>
				<template v-else>
					<MDBCard class="mx-auto pa-4 shadow-0">
						<MDBCardText>
							<div class="row">
								<FormField
									v-for="(field, k) in replyFields"
									:key="k"
									:data-store="createStore"
									:field="field"
								/>
							</div>
						</MDBCardText>
					</MDBCard>
				</template>
				<div
					v-if="replyFields.length == 0 && !props.formDefinition.multiStage"
					class="modal-footer border-0 px-0"
				>
					<button
						:disabled="
							form.processing ||
							(typeof props.formDefinition?.disabled == 'function' &&
								props.formDefinition.disabled(formObject))
						"
						type="submit"
						class="btn btn-sm rounded-pill bg-primary text-white"
						style="--mdb-focus-ring-color: rgba(var(--mdb-primary-rgb), 0.5)"
						test-id="create-dialog-submit-btn"
					>
						<template v-if="!props.formDefinition.createButtonText"
							>Create</template
						>
						<template v-else>{{
							$t(props.formDefinition.createButtonText)
						}}</template>
					</button>
				</div>
			</form>
		</MDBModalBody>
	</MDBModal>
</template>

<script setup>
import FormField from "@/Components/Mod/FormField.vue"
import FormCard from "@/Components/Mod/FormCard.vue"
import route from "ziggy-js"
import { useToast } from "@/Composables/useToast.js"

import {
	defineProps,
	onMounted,
	onUnmounted,
	ref,
	nextTick,
	watch,
	computed,
} from "vue"
import { useForm, router, Link, usePage } from "@inertiajs/vue3"
import {
	MDBBtn,
	MDBCard,
	MDBCardText,
	MDBCardTitle,
	MDBTabs,
	MDBTabContent,
	MDBTabPane,
	MDBModal,
	MDBModalBody,
	MDBTooltip,
} from "mdb-vue-ui-kit"
import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"
const { injectTeamParam } = useTeamRouteParams()

const emit = defineEmits(["update:modelValue", "cancel", "success", "error"])
// expose openModal to parent component
const props = defineProps({
	formDefinition: {
		type: Object,
		required: true,
	},
	showOnMounted: {
		type: Boolean,
		default: false,
	},
	disableTooltip: {
		type: Boolean,
		default: false,
	},
	removeBackdrop: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String,
		default: "lg",
	},
	buttonOveride: {
		type: Object,
		default: null,
	},
	modelValue: {
		type: Boolean,
		default: null, // if we provide modelValue it will use it, otherwise local state
	},
	parentDataModel: {
		type: Object,
		default: null,
	},
})
let currentTimestamp = Math.floor(Date.now() / 1000)
const createStore = useDataModelStore(
	typeof props.formDefinition.create.route == "string"
		? props.formDefinition.create.route
		: "createStore-" + currentTimestamp.toString(),
)
const { dataModel } = storeToRefs(createStore)

const formRef = ref()
const dialog = ref(false)
const tooltip = ref(false)
let defaultObject = {}
const formObject = ref({})
const replyFields = ref([])
const toast = useToast()
const currentTab = ref()
const tabsList = ref([])
const toggleModal = () => {
	dialog.value = !dialog.value
}
const processingJob = ref(false)
/**
 * Some magic to use local state or modelValue depending
 * on if it's passed in.
 */
const dialogComputed = computed({
	get() {
		return props.modelValue !== null ? props.modelValue : dialog.value
	},
	set(value) {
		dialog.value = value
		if (props.modelValue !== null) {
			emit("update:modelValue", value)
		}
		if (!value) {
			emit("cancel")
		}
	},
})

const openModal = () => {
	dialogComputed.value = true
	if (props.formDefinition.tabsList) {
		tabsList.value = props.formDefinition.tabsList
	} else if (props.formDefinition.cards) {
		tabsList.value = { cards: props.formDefinition.cards }
	}
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const keys = urlParams.keys(),
		values = urlParams.values(),
		entries = urlParams.entries()
	let initValue = props.formDefinition?.initValue || {}
	for (const key of keys) {
		initValue[key] = urlParams.get(key)
	}
	createStore.initializeDataModel(initValue, [tabsList.value], true)

	if (props.formDefinition.watchFields) {
		Object.keys(props.formDefinition.watchFields).forEach((field) => {
			watchFields[field] = null
		})
	}
	// Auto focus on first element
	replyFields.value = []

	nextTick(() => {
		if (formRef.value && formRef.value[0]) {
			formRef.value[0].focus()
		}
		if (props.formDefinition.tabsList) {
			currentTab.value = props.formDefinition.tabsList[0].tabId
		}
	})
}

let form = useForm(() => dataModel.value)
const dialogHeight = ref(document.documentElement.clientHeight - 110)

watch(
	() => tabsList.value,
	() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const keys = urlParams.keys(),
			values = urlParams.values(),
			entries = urlParams.entries()
		let initValue = {}
		for (const key of keys) {
			initValue[key] = urlParams.get(key)
		}
		createStore.initializeDataModel(initValue, [tabsList.value])
	},
)
let watchFields = {}
watch(
	() => dataModel.value,
	() => {
		Object.keys(watchFields).forEach((fieldName) => {
			let newVal = createStore.getModelValue({ name: fieldName })
			if (watchFields[fieldName] !== newVal) {
				performWatchActions(fieldName, newVal)
			}
			watchFields[fieldName] = newVal
		})
	},
	{
		deep: true,
	},
)
watch(
	() => currentTab.value,
	(tabId) => {
		// If reset to first tab, reset watchFields
		if (tabId == tabsList.value[0]["tabId"]) {
			if (props.formDefinition.watchFields) {
				Object.keys(props.formDefinition.watchFields).forEach((field) => {
					watchFields[field] = null
				})
			}
		}
	},
)
const performWatchActions = (fieldName, value) => {
	let watchActions =
		props.formDefinition.watchFields &&
		props.formDefinition.watchFields[fieldName]
			? props.formDefinition.watchFields[fieldName]
			: undefined
	watchActions.forEach((watchAction) => {
		if (watchAction !== undefined && watchAction.action == "append_tabs") {
			tabsList.value = props.formDefinition.tabsList
			if (value) {
				// use axios to perform get against props.formDefinition.watchFields[field.name]
				// then append the result to tabsList
				axios
					.create({
						headers: {
							"X-Requested-With": "XMLHttpRequest",
						},
					})
					.get(watchAction.route + "?" + watchAction.key + "=" + value)
					.then((response) => {
						if (
							response.data.additionalData &&
							response.data.additionalData.createForm
						) {
							tabsList.value = props.formDefinition.tabsList.concat(
								response.data.additionalData.createForm,
							)
							nextTick(() => {
								currentTab.value =
									response.data.additionalData.createForm[0].tabId
							})
						}

						if (response.data.additionalData.dashboard_assets) {
							// add dashboard_assets to create form object
							createStore.setModelValue(
								{
									name: "dashboard_assets",
								},
								response.data.additionalData.dashboard_assets,
							)
						}
					})
					.catch((error) => {
						if (error?.response?.data?.errors?.quota_exceeded) {
							console.log("error: ", error.response.data.errors.quota_exceeded)
							tabsList.value = props.formDefinition.tabsList.concat({
								tabId: "quota_error",
								title: "Quota Exceeded",
								cards: [
									{
										type: "fields_list",
										fieldsList: [
											{
												type: "description",
												content:
													error.response.data.errors.quota_exceeded.join(
														"<br>",
													),
											},
											{
												type: "button",
												text: "Upgrade Plan",
												classList: ["btn-primary", "btn", "rounded-pill"],
												href: route("dashboard.subscriptions.index", {
													team: usePage().props.currentTeam.slug,
												}),
											},
										],
									},
								],
								prevTab: {
									id: props.formDefinition.tabsList[0].tabId,
									text: "Back",
								},
							})
							nextTick(() => {
								currentTab.value = "quota_error"
							})
						}
						console.error("Error fetching options:", error)
					})
			}
		}
	})
}
const submit = () => {
	let targetRoute = ""
	let largeUploads = {}
	if (typeof props.formDefinition.create.route == "function") {
		targetRoute = props.formDefinition.create.route(form, props.parentDataModel)
	} else {
		let params = props.formDefinition.create.params

		params = injectTeamParam(props.formDefinition.create.route, params)
		targetRoute = route(props.formDefinition.create.route, params)
	}
	form
		.transform((data) => {
			data = dataModel.value
			if (props.formDefinition.transform) {
				data = props.formDefinition.transform(data, props.parentDataModel)
			}
			Object.keys(data).forEach((key) => {
				console.log("key", key, data[key])
				if (data[key] instanceof FileList) {
					if (data[key][0].size > 30000000) {
						largeUploads[data[key][0].name] = data[key][0]
						console.log("file too big", data[key][0].size)
						data[key] = {
							filename: data[key][0].name,
							size: data[key][0].size,
							type: data[key][0].type,
							lastModified: data[key][0].lastModified,
							large_upload: true,
						}
					}
				}
			})
			return data
		})
		.post(targetRoute, {
			preserveScroll: true,
			preserveState: true,
			headers: {
				"x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
			},
			onStart: (page) => {
				processingJob.value = true
			},
			onSuccess: (page) => {
				if (page.props.replyObject && page.props.replyObject.waitForChannel) {
					let errorTimeout = setTimeout(() => {
						// Error Timeout, redirect anyway
						processingJob.value = false
						toast.error("Processing Timed Out")
						router.visit(page.props.replyObject.redirectTo, {
							preserveState: true,
						})
					}, 30000)
					window.Echo.private(page.props.replyObject.waitForChannel).listen(
						page.props.replyObject.waitForMessage,
						() => {
							clearTimeout(errorTimeout)
							window.Echo.leave(page.props.replyObject.waitForChannel)
							router.visit(page.props.replyObject.redirectTo, {
								preserveState: true,
							})
						},
					)
				} else if (
					page.props.replyObject &&
					page.props.replyObject.redirectTo
				) {
					setTimeout(() => {
						processingJob.value = false
						// redirect to route
						router.visit(page.props.replyObject.redirectTo, {
							preserveState: true,
						})
					}, 2000)
				} else if (
					page.props.replyObject &&
					page.props.replyObject.uploadUrls &&
					Object.keys(largeUploads).length > 0
				) {
					let activeUploads = {}
					Object.keys(largeUploads).forEach((key) => {
						let upload_url = page.props.replyObject.uploadUrls[key]
						// use fetch to put the file to the url provided by uploadUrls, uploads[key] is a File object
						if (upload_url) {
							// add header Content-Type: application/octet-stream
							activeUploads[key] = fetch(upload_url, {
								method: "PUT",
								headers: {
									"Content-Type": "application/octet-stream",
								},
								body: largeUploads[key],
							})
						}
					})
					if (Object.keys(activeUploads).length > 0) {
						Promise.all(Object.values(activeUploads)).then(() => {
							processingJob.value = false
							form.reset()
							createStore.clearDataModel()
							dialog.value = false
							success()
						})
					} else {
						processingJob.value = false
						form.reset()
						createStore.clearDataModel()
						dialog.value = false
						success()
					}
				} else {
					processingJob.value = false
					// defaultObject = {}
					// setDefaultObject()
					if (page.props.replyObject && page.props.replyObject.length > 0) {
						replyFields.value = page.props.replyObject
					} else {
						form.reset()
						createStore.clearDataModel()
						dialog.value = false
						success()
					}
				}
			},
			onError: (err) => {
				processingJob.value = false
				// emit("error", err)
				const errors = Object.values(err)
				errors.forEach((error) => toast.error(error))
			},
		})
}

const success = () => {
	emit("success")
}

// const inputUpdate = (field) => {
// 	// form[field] = value;

// 	let value = field.value
// 	if (field.column) {
// 		if (!formObject.value[field.column]) {
// 			formObject.value[field.column] = {}
// 		}
// 		formObject.value[field.column][field.name] = value
// 	} else {
// 		formObject.value[field.name] = value
// 	}
// }

onMounted(() => {
	if (props.showOnMounted) {
		openModal()
	}
})
defineExpose({ openModal })

onUnmounted(() => {
	createStore.clearDataModel()
})

// const onKeydown = (event) => {
// 	if (event.key == "c" && event.target.tagName.toUpperCase() == "BODY") {
// 		openModal()
// 	}
// }
</script>
<style scoped>
@media (min-width: 1200px) and (max-width: 1450px) {
	:deep(.modal-dialog.modal-xl) {
		--mdb-modal-width: 85% !important;
	}
}
@media (min-width: 990px) and (max-width: 1199px) {
	:deep(.modal-dialog.modal-xl) {
		--mdb-modal-width: 70% !important;
	}
}
</style>
<style>
.modal .select-dropdown-container {
	position: fixed !important;
}
.footer-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: -20px;
	margin-bottom: 10px;
}
</style>
