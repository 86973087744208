<template>
	<label v-if="field.label ?? label" class="radio-group-label">
		{{ field.label || label }}
	</label>
	<div class="radio-group gap-1" :class="field.wrapperClass ?? []">
		<div
			v-for="option in optionsFiltered"
			:key="option.option"
			:class="[
				'radio-group-option',
				{ 'radio-icon': option.icon, 'form-check': !option.icon },
				...(field.classList ?? []),
			]"
		>
			<label
				class="d-flex form-check-label"
				:class="[
					'rounded-circle',
					'p-2',
					'text-primary',
					'border',
					{
						'bg-white border-primary': localValue === option.option,
						'bg-transparent border-transparent': localValue !== option.option,
					},
					...(option.labelClassList ?? []),
				]"
				:for="option.option + '-radio'"
			>
				<input
					:id="option.option + '-radio'"
					v-model="localValue"
					class="form-check-input"
					type="radio"
					:name="field.name"
					:value="option.option"
					@input="onInput(option.option)"
				/>
				<IconBadge
					v-if="option.icon"
					:icon="option.icon"
					:class="[...(option.iconClassList ?? [])]"
				/>
				<span v-else>
					{{ option.title }}
				</span>
			</label>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"
import IconBadge from "@/Components/IconBadge.vue"

// Props
const props = defineProps({
	field: {
		type: Object,
		required: true,
	},
	label: {
		type: String,
		required: true,
	},
	options: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: null,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const fieldValue = defineModel({
	type: String,
	default: null,
})

const localValue = ref(null)

const optionsFiltered = computed(() => {
	return props.field.conditionalOptions
		? props.field.conditionalOptions(dataModel.value)
		: props.field.options || props.options
})

const initializeLocalValue = () => {
	if (props.dataStore) {
		watch(
			() =>
				props.dataStore.getModelValue({
					column: props.field.column ?? null,
					name: props.field.name ?? null,
				}),
			(newVal) => {
				localValue.value = newVal
			},
			{ immediate: true },
		)
	}
}

const onInput = (value) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(
			props.field,
			value,
			!props.field.preventUpdate ?? true,
		)
	}
}
onMounted(() => {
	if (props.dataStore) {
		initializeLocalValue()
		return
	}
	localValue.value = fieldValue.value ?? props.field.default
	if (fieldValue.value) {
		watch(localValue.value, (newVal) => {
			fieldValue.value = newVal
			emit("update:modelValue", newVal)
		})
	}
})
</script>

<style scoped>
.radio-group-label {
	display: block;
}
.radio-icon input {
	display: none;
}
.radio-icon svg {
	margin: 0 !important;
	height: 18px;
	aspect-ratio: 1;
}
</style>
