<template>
	<div
		ref="videoContainer"
		class=""
		:class="props.field.classList || props.classList || []"
	>
		<video
			ref="videoPlayer"
			class=""
			:class="props.field.videoClassList || props.videoClassList || []"
			:height="props.field.height || props.height || 'auto'"
			:controls="ui ? false : true"
		></video>
	</div>
</template>

<script setup>
import { computed, defineModel, onMounted, shallowRef, ref, watch } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { Link } from "@inertiajs/vue3"

import shaka from "shaka-player/dist/shaka-player.ui.debug.js"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	content: {
		type: String,
		default: "",
	},
	videoClassList: {
		type: Array,
		default: () => [],
	},
	height: {
		type: String,
		default: "auto",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	useManifest: {
		type: Boolean,
		default: false,
	},
})

// const fieldValue = defineModel({
// 	type: String,
// 	default: undefined,
// })

const videoPlayer = ref(null)
const videoContainer = ref(null)
const loadFromManifest = props.useManifest || props.field.useManifest || false
const player = ref(null)
const ui = ref(null)
const uiConfig = {
	time_and_duration: true,
	play_pause: true,
	mute: true,
	volume: true,
	fullscreen: true,
	picture_in_picture: true,
	pip_button: true,
	airplay: false,
	cast: false,
	download: true,
	subtitles: true,
	captions: false,
	quality: true,
	overflow_menu: true,
}
// const ui = new shaka.ui.Overlay(
// 	player,
// 	videoContainer.value,
// 	videoPlayer.value
// );
// ui.getControls();

const localValue = shallowRef(null)
const computeFieldValue = () => {
	let getVal = false
	let urlContent = props.field.content || props.content
	if (urlContent) {
		getVal = props.dataStore.getModelValue({
			column: "dashboard_assets",
			name: urlContent,
			disableDotNotation: true,
		})
	}
	if (getVal) {
		return getVal
	} else {
		console.log("getting value from field")
		if (props.field.name) {
			let retValue = props.dataStore.getModelValue({
				column: props.field.column || null,
				name: props.field.name || null,
				disableDotNotation: true,
			})
			console.log("returning value from field")
			return retValue
		} else {
			console.log("returning value from urlContent")
			return urlContent
		}
	}
}
const onError = (error) => {
	console.error("Error code", error.code, "object", error)
}

onMounted(() => {
	console.log("onMounted")
	console.log("field", props.field)
	console.log("field.value", props.field.value)
	let signature = ""
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}

	if (loadFromManifest) {
		signature = localValue.value.split("?")[1]
		console.log("signature", signature)
		player.value = new shaka.Player(videoPlayer.value)
		ui.value = new shaka.ui.Overlay(
			player.value,
			videoContainer.value,
			videoPlayer.value,
		)
		let uiConfigKeys = Object.keys(uiConfig).filter((key) => uiConfig[key])
		console.log("uiConfigKeys", uiConfigKeys)
		ui.value.configure({ controlPanelElements: uiConfigKeys })
		ui.value.getControls()

		player.value
			.getNetworkingEngine()
			.registerRequestFilter(function (type, request) {
				if (!request.uris[0].includes("?")) {
					request.uris[0] = request.uris[0] + "?" + signature
				}
			})
		player.value
			.load(localValue.value)
			.then(() => {
				// This runs if the asynchronous load is successful.
				console.log("The video has now been loaded!")
			})
			.catch(onError) // onError is executed if the asynchronous load fails.
	} else {
		console.log("loading from src")
		videoPlayer.value.src = localValue.value
	}
})
watch(localValue, (newVal) => {
	console.log("new localValue", newVal)
	if (newVal && loadFromManifest) {
		console.log("loading from manifest")

		player.value.load(newVal).then(() => {
			// This runs if the asynchronous load is successful.
			console.log("The video has now been loaded!")
		})
	} else {
		console.log("loading from src")
		videoPlayer.value.src = newVal
	}
})
</script>
