<template>
	<label v-if="props.field.label" class="form-label">
		{{
			typeof props.field.label == "function"
				? props.field.label(dataModel?.value)
				: props.field.label
		}}
	</label>
	<div :class="{ 'input-group': props.field.editLink }">
		<Link
			v-if="props.field.editLink !== undefined"
			class="input-group-prepend input-group-text"
			:class="props.field.editLink.classList || []"
			:href="editRoute()"
			:as="props.field.editLink.type || 'a'"
			:method="props.field.editLink.method || 'get'"
			:disabled="localValue === null || localValue == 0 || false"
		>
			<FontAwesomeIcon
				v-if="props.field.editLink.icon"
				:icon="
					lookupIcon(
						props.field.editLink.icon,
						props.field.editLink.iconStyle || 'fas',
					)
				"
			/>
		</Link>
		<div class="select-input-wrapper">
			<MDBSelectZ
				ref="selectInput"
				v-model:selected="localValue"
				v-model:options="options"
				v-model:option-appends-loading="activeOptionAppends"
				:required="props.field.required"
				:readonly="props.field.readonly || false"
				class="form-floating"
				:multiple="props.field.multiple ?? false"
				:pills="props.field.pills ?? false"
				:filter="props.field.filter ?? false"
				:preselect="false"
				:class="props.field.classList || []"
				:no-results-text="loading ? 'Loading...' : 'No results found'"
				:option-height="options[0] && options[0].secondaryText ? 60 : 40"
				:option-append="props.field.optionAppend"
				placeholder="Select..."
				@update:selected="onSelect"
				@closed="onInput"
				@option-append-clicked="onOptionAppendClicked"
			>
			</MDBSelectZ>
			<div class="progress" :style="{ opacity: loading ? 1 : 0 }">
				<div
					class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					style="width: 100%"
				></div>
			</div>
		</div>
	</div>

	<ConfirmDelete
		v-model="showConfirmDelete"
		:route="optionAppendRoute"
		:body-text="confirmBodyText"
		@success="deleteSuccess"
	></ConfirmDelete>
</template>

<script setup>
import {
	ref,
	onMounted,
	defineProps,
	defineEmits,
	nextTick,
	computed,
	watch,
} from "vue"
import http from "@/Services/http"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"
import { usePage } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"
import { Link } from "@inertiajs/vue3"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"

const page = usePage()

const props = defineProps({
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	name: {
		type: String,
		required: false,
		default: "",
	},
	label: {
		type: [String, Function],
		required: false,
		default: "",
	},
	classList: {
		type: Array,
		required: false,
		default: () => [],
	},
	default: {
		type: String,
		required: false,
		default: "",
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		required: false,
		default: () => [],
	},
	multiple: {
		type: Boolean,
		required: false,
		default: false,
	},
	pills: {
		type: Boolean,
		required: false,
		default: false,
	},
	filter: {
		type: Boolean,
		required: false,
		default: false,
	},
})

const showConfirmDelete = ref(false)
const emit = defineEmits(["update:modelValue", "selected"])

// expose reload method
const reload = () => {
	fetchOptions()
}
defineExpose({ reload })

const fieldValue = defineModel({
	type: [String, Number],
	default: "",
})
const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const { injectTeamParam } = useTeamRouteParams()

const selectInput = ref(null)
const options = ref([])
const results = ref([])
const selectValue = ref(null)

const loading = ref(false)

const curRoute = computed(() => {
	let curRoute = ""
	const currentTeam = usePage().props.currentTeam
	if (props.field.item) {
		const project_id = page.props.project.slug
		if (props.field.item == "social-stream") {
			curRoute = route("dashboard.project.social-streams.index", {
				project: project_id,
				team: currentTeam.slug,
			})
		}
		if (props.field.item == "location") {
			curRoute = route("dashboard.project.locations.index", {
				project: project_id,
				team: currentTeam.slug,
			})
		}
		if (props.field.item == "project-portal") {
			curRoute = route("dashboard.project.portals.index", {
				project: project_id,
				team: currentTeam.slug,
			})
		}
		if (props.field.item == "ai-interface") {
			curRoute = route("dashboard.project.ai-interfaces.index", {
				project: project_id,
				team: currentTeam.slug,
			})
		}
	}
	if (curRoute === "") {
		if (!props.field.route) {
			throw new Error("No route provided on async selector")
		}

		if (typeof props.field.route == "function") {
			curRoute = props.field.route(props.field, dataModel?.value)
		} else {
			let params = props.field.routeParams || {}

			if (props.field.routeParams?.project) {
				if (props.field.routeParams.project == "{{current_project}}") {
					params.project = page.props.project.slug
				}
			}
			params = injectTeamParam(props.field.route, params)
			curRoute = route(props.field.route, params)
		}
	}
	return curRoute
})
watch(
	() => curRoute.value,
	() => {
		fetchOptions()
	},
)
const lookupData = ref({})
async function fetchOptions() {
	loading.value = true
	try {
		let curPage = 1
		let allOptions = []
		let maxAttempts = 20
		// eslint-disable-next-line no-constant-condition
		while (true) {
			let params = {
				page: curPage,
				per_page: 250,
			}
			if (props.field.searchFilter) params.filter = props.field.searchFilter
			const response = await http.get(curRoute.value, { params })
			let responseData = response.data

			// if there is a nested data property, dig into it
			if (responseData?.data) {
				responseData = responseData.data
			}
			results.value = results.value.concat(responseData)

			if (props.field.transformResponse) {
				allOptions = allOptions.concat(
					props.field.transformResponse(responseData),
				)
			} else {
				const newOptions = responseData.map((item) => ({
					text: item[props.field.labelField || "name"],
					value: item[props.field.valueField || "id"],
					secondaryText:
						item[props.field.secondaryLabelField || "description"] || undefined,
				}))
				allOptions = allOptions.concat(newOptions)
			}

			// Check if we've reached the last page
			if (
				!(response.data.current_page && response.data.last_page) ||
				curPage >= response.data.last_page ||
				curPage >= maxAttempts
			) {
				break
			}

			curPage++
		}

		// allow for more data to be passed to links and functions
		results.value.forEach((option) => {
			lookupData.value[option[props.field.valueField || "id"]] = option
		})

		options.value = allOptions
		nextTick(() => {
			let currentSelection = localValue.value
			if (
				!currentSelection &&
				props.field.preselect &&
				options.value.length > 0
			) {
				onSelect(options.value[0].value)
				onInput()
				selectInput.value.setValue(options.value[0].value)
			} else {
				selectInput.value.setValue(localValue.value)
			}
			nextTick(() => {
				loading.value = false
			})
		})
	} catch (error) {
		console.error("Error fetching options:", error)
		loading.value = false
	}
}

const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

onMounted(() => {
	if (props.dataStore) {
		selectValue.value = computeFieldValue()
	} else {
		selectValue.value = fieldValue.value
	}
	fetchOptions()
})

const editRoute = () => {
	let targetRoute = ""
	if (typeof props.field.editLink.route === "function") {
		targetRoute = props.field.editLink.route(lookupData.value[localValue.value])
	} else {
		targetRoute = route(props.field.editLink.route, {
			[props.field.editLink.key]: localValue.value,
			...injectTeamParam(
				props.field.editLink.route,
				props.field.editLink.params,
			),
		})
	}
	return targetRoute
}
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value || props.field.default
})

const onSelect = async (value) => {
	selectValue.value = value

	nextTick(() => {
		// onInput()
		if (loading.value) {
			return
		}
		const selectedOption = results.value.find(
			(option) => option[props.field.valueField || "id"] == selectValue.value,
		)
		emit("selected", selectedOption || null)
	})
}

const onInput = () => {
	let value = selectValue.value
	let fieldName = props.field.name || props.name || null

	if (props.dataStore && fieldName) {
		if (value !== localValue.value) {
			props.dataStore.setModelValue(
				{
					name: fieldName,
					column: props.field.column || null,
				},
				value,
				true,
			)
		}
	} else {
		localValue.value = value
	}
	fieldValue.value = value
}

const toDeleteOptionAppend = ref(null)
const activeOptionAppends = ref({})

/**
 * The route used for the option append request.
 * This is used for both the delete modal and the option append route
 */
const optionAppendRoute = computed(() => {
	if (!toDeleteOptionAppend.value) {
		return null
	}
	let curRoute = props.field.optionAppend?.route
		? props.field.optionAppend.route
		: null

	if (typeof curRoute == "function") {
		curRoute = curRoute(toDeleteOptionAppend.value)
	} else {
		let routeKey = props.field.optionAppend?.key || "id"
		let params = {}
		if (props.field.optionAppend?.params) {
			params = { ...props.field.optionAppend.params }
		}
		if (params?.project && params.project == "{{current_project}}") {
			params.project = page.props.project.slug
		}
		params = injectTeamParam(curRoute, params)
		// find the item in the results array
		// the user can select a 'valueField' so we need to use that. can't assume ID or slug
		const selectedOption = results.value.find(
			(option) =>
				option[props.field.valueField || "id"] ==
				toDeleteOptionAppend.value.value,
		)

		// construct a delete route based on the value field and the route key defined
		curRoute = route(curRoute, {
			[routeKey]: selectedOption[props.field.valueField || "id"],
			...params,
		})
	}
	return curRoute
})

/**
 * triggered when the user clicks the append
 * You can pass an onClick method, set delete to true, or set a route
 * The route could be used for something like a refresh button or something
 * @param {*} option
 */
const onOptionAppendClicked = async (option) => {
	toDeleteOptionAppend.value = option
	if (
		props.field.optionAppend?.onClick &&
		typeof props.field?.optionAppend?.onClick
	) {
		await props.field.optionAppend.onClick(
			option,
			onOptionAppendClickedCallback,
		)
		activeOptionAppends.value = {
			...activeOptionAppends.value,
			[option.value]: false,
		}
	} else if (props.field.optionAppend?.delete) {
		showConfirmDelete.value = true
		// just clearing this because the modal handles this for us essentially.
		activeOptionAppends.value = {}
	} else if (props.field.optionAppend?.route) {
		await makeOptionAppendRequest(option)
	}
}

/**
 * callback for when delete has completed
 */
const deleteSuccess = () => {
	toDeleteOptionAppend.value = null
	fetchOptions()
}

/**
 * here we can make direct requests for the selected options append
 */
const makeOptionAppendRequest = async (option) => {
	if (!optionAppendRoute.value) {
		return
	}

	try {
		const response = await http.request(optionAppendRoute.value, {
			method: props.field.optionAppend?.method || "post",
		})
	} catch (err) {
		console.error("Error making option append request", err)
	} finally {
		// clear the option append
		activeOptionAppends.value = {
			...activeOptionAppends.value,
			[option.value]: false,
		}
		option = null
	}
}

/**
 * The body text for the confirm delete modal
 */
const confirmBodyText = computed(() => {
	let message =
		props.field.optionAppend?.confirmBodyText ||
		"Are you sure you want to delete this item?"

	return /*html*/ `
	${message}<br/>
	${toDeleteOptionAppend.value?.text}
	`
})
</script>

<style scoped>
@keyframes progressBarAnimation {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.progress-bar {
	animation: progressBarAnimation 2s linear infinite;
	background-size: 200% 100%;
}
.progress {
	height: 1px;
	transition: opacity 0.2s ease-in-out;
	z-index: 100;
	margin-top: -1px;
	width: calc(100% - 10px);
	margin-left: 5px;
	margin-right: 5px;
}
.select-input-wrapper {
	width: 100%;
}
.input-group :deep(input.form-control) {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.input-group-prepend {
	margin-top: auto;
}
</style>
