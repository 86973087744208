<template>
	<MDBTooltip
		v-model="tooltipModel"
		offset="0,5"
		:direction="tooltipDirection"
		:options="{ strategy: 'fixed' }"
		:disabled="!computedTooltipLabel || props.disableTooltip"
	>
		<template #reference>
			<button
				type="button"
				role="button"
				class="shadow-0 btn"
				:class="buttonClassList"
				test-id="create-dialog-create-btn"
				@click.prevent="dialog = !dialog"
			>
				<span
					v-if="computedButtonText"
					class="update-btn-text"
					:class="buttonTextClassList"
				>
					{{ computedButtonText }}
				</span>
				<IconBadge
					v-if="computedButtonIcon"
					:icon="computedButtonIcon"
					:size="computedButtonSize"
					:options="{ classList: ['test'] }"
				/>
			</button>
		</template>
		<template #tip> {{ computedTooltipLabel }} </template>
	</MDBTooltip>
	<Teleport :to="containerEl">
		<MDBModal
			:model-value="dialog"
			class="text-start form-modal text-black"
			tabindex="-1"
			floating
			:order="100"
			:size="currentTab.size || 'lg'"
			:class="currentTab.classList"
			centered
		>
			<form ref="form" @submit.prevent="submit">
				<MDBTabs v-model="currentTabId">
					<!-- Tabs Content -->
					<MDBTabContent>
						<MDBTabPane
							v-for="(item, i) in computedTabsList"
							:key="item.tabId ? item.tabId : 'tab-' + i"
							:tab-id="item.tabId ? item.tabId : 'tab-' + i"
						>
							<div class="modal-body px-3">
								<!-- close button -->

								<div class="row m-0 justify-content-end mb-3">
									<button
										class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary-9 shadow-0"
										type="button"
										@click="dialog = false"
										@close="dialog = false"
									>
										<FontAwesomeIcon
											:icon="lookupIcon('times', 'fal')"
											size="lg"
											class="text-secondary-9"
										/>
									</button>
								</div>
								<div>
									<FontAwesomeIcon
										v-if="props.tabItem?.badge"
										:icon="lookupIcon(props.tabItem?.badge)"
										class="text-black"
									/>
									<h4 v-if="item.title" class="text-center">
										{{ $t(item.title) }}
									</h4>
									<div class="row">
										<FormCard
											v-for="(card, j) in item.cards"
											:key="j"
											:card="card"
											:data-store="modalDataStore"
											@update-value="inputUpdate"
										/>
									</div>
								</div>
							</div>
							<div class="modal-footer">
								<template v-if="currentTabId">
									<button
										v-if="item.prevTab"
										class="btn-outline-primary btn-sm rounded-pill btn shadow-0 me-auto"
										type="button"
										:disabled="processingJob"
										@click.prevent="currentTabId = item.prevTab.id"
									>
										<FontAwesomeIcon :icon="lookupIcon('arrow-left', 'fas')" />
										{{ item.prevTab.text || "Back" }}
									</button>
									<button
										v-if="item.nextTab"
										class="btn-primary btn-sm rounded-pill btn btn-filled ms-auto"
										type="button"
										:disabled="processingJob"
										@click.prevent="currentTabId = item.nextTab.id"
									>
										{{ item.nextTab.text || "Next" }}
										<FontAwesomeIcon
											:icon="lookupIcon('arrow-right', 'fas')"
										/></button
								></template>
								<template v-if="currentTab.footerButtons">
									<FormButtonGroup
										:buttons="currentTab.footerButtons"
										:item="props.tabItem ?? {}"
										@close="dialog = false"
									/>
								</template>
								<template v-else>
									<MDBBtn
										v-if="tab"
										class="rounded-pill bg-primary-3 text-white border-0"
										:disabled="processing"
										test-id="create-dialog-cancel-btn"
										@click="dialog = false"
									>
										Close
									</MDBBtn>
									<MDBBtn
										v-if="
											computedUpdateConfig && computedUpdateConfig.update.route
										"
										:disabled="processing"
										class="rounded-pill shadow-0 btn-outline-primary"
										type="submit"
									>
										Save
									</MDBBtn>
								</template>
							</div>
						</MDBTabPane>
					</MDBTabContent>
				</MDBTabs>
			</form>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import { usePermissionStore } from "@/Store/permissionStore"
import { usePermissions } from "@/Composables/usePermissions"
import { ref, computed, defineProps, defineEmits, watch, onMounted } from "vue"
import { router } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { useTranslator } from "@/Composables/useTranslator"
import FormCard from "@/Components/Mod/FormCard.vue"
import { useFormModel } from "@/Composables/useFormModel"
import CustomDivider from "@/Components/CustomDivider.vue"
import { useDataModelStore } from "@/Store/dataModelStore"
import {
	MDBBtn,
	MDBRow,
	MDBModal,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBTooltip,
	MDBTabs,
	MDBTabContent,
	MDBTabPane,
} from "mdb-vue-ui-kit"
import {
	setNestedProperty,
	getNestedProperty,
} from "@/Utils/dotNotationHelpers"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import IconBadge from "@/Components/IconBadge.vue"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import FormButtonGroup from "@/Components/Mod/FormButtonGroup.vue"
import { storeToRefs } from "pinia"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"
defineExpose({
	openModal: () => {
		dialog.value = true
	},
})

const props = defineProps({
	tab: {
		type: Object,
		default: null,
	},
	tableStyle: {
		type: String,
		default: "table",
	},
	tabsList: {
		type: Array,
		default: null,
	},
	updateConfig: {
		type: Object,
		default: null,
	},
	disableTooltip: {
		type: Boolean,
		default: false,
	},
	tabId: {
		type: String,
		default: "",
	},
	tabItem: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: null,
	},
	size: {
		type: String,
		default: "md",
	},
	options: {
		type: Object,
		default: null,
	},
	tooltipLabel: {
		type: String,
		default: null,
	},
	tooltipDirection: {
		type: String,
		default: "left",
	},
	buttonClass: {
		type: Array,
		default: null,
	},
	buttonTextClass: {
		type: Array,
		default: null,
	},
	buttonText: {
		type: String,
		default: null,
	},
	buttonIcon: {
		type: String,
		default: null,
	},
	buttonIconSize: {
		type: String,
		default: null,
	},
	buttonSize: {
		type: String,
		default: null,
	},
	forceUpdateStore: {
		type: Boolean,
		default: false,
	},
})
const MAX_UID = 1000000
let initializeDataModel = false
// const dataStore = props.dataStore ?? updateStore
let modalDataStore = props.dataStore
if (props.dataStore === null || props.forceUpdateStore) {
	// Called from datatable row
	let updateStoreId =
		props.tab?.tabId ?? props.tabItem.id ?? Math.floor(Math.random() * MAX_UID)
	modalDataStore = useDataModelStore("update_" + updateStoreId)
	initializeDataModel = true
}
const { dataModel, updatedFields } = storeToRefs(modalDataStore)

const tooltipModel = ref(false)
const toast = useToast()
const { buildModel } = useFormModel()
const mutableItemModel = ref({})
const dialog = ref(false)
const processing = ref(false)
const currentTabId = ref(
	props.tabsList?.[0]?.tabId ?? props.tab?.tabId ?? "tab-0",
)
const currentTab = computed(() =>
	props.tabsList
		? props.tabsList.find((tab) => tab.tabId === currentTabId.value)
		: props.tab,
)
const computedTabsList = computed(() => {
	if (props.tabsList) return props.tabsList
	if (props.tab.tabId) return [props.tab]
	return [
		{
			...props.tab,
			tabId: "tab-0",
		},
	]
})
const computedTooltipLabel = computed(() => {
	return (
		props.tooltipLabel ?? computedUpdateConfig.value?.update?.tooltip ?? null
	)
})
const computedUpdateConfig = computed(
	() =>
		props.updateConfig ??
		currentTab.value?.updateConfig ??
		props.options ??
		null,
)
const computedButtonText = computed(
	() =>
		props.buttonText ?? computedUpdateConfig.value?.update?.buttonText ?? null,
)
const containerEl = computed(() => {
	return document.querySelector("body")
})
const buttonClassList = computed(() => {
	let classList = ["btn-" + computedButtonSize.value]
	if (props.buttonClass) classList = classList.concat(props.buttonClass)
	if (computedUpdateConfig.value?.update?.classList) {
		classList = classList.concat(computedUpdateConfig.value.update.classList)
	}
	if (
		props.tableStyle == "table" &&
		computedUpdateConfig.value?.update?.tableClassList
	) {
		classList = classList.concat(
			computedUpdateConfig.value.update.tableClassList,
		)
	}
	if (
		props.tableStyle == "card" &&
		computedUpdateConfig.value?.update?.cardClassList
	) {
		classList = classList.concat(
			computedUpdateConfig.value.update.cardClassList,
		)
	}
	return classList
})

const buttonTextClassList = computed(() => {
	if (props.buttonTextClass) return props.buttonTextClass
	return computedUpdateConfig.value?.update?.buttonTextClassList ?? []
})

const computedButtonIcon = computed(() => {
	return (
		props.buttonIcon ??
		computedUpdateConfig.value?.update?.icon ??
		"far.pen-to-square"
	)
})
const computedButtonSize = computed(
	() =>
		props.buttonSize ?? computedUpdateConfig.value?.update?.iconSIze ?? "md",
)

const { injectTeamParam } = useTeamRouteParams()

watch(
	() => props.tabItem,
	(newTabItem) => {
		mutableItemModel.value = JSON.parse(JSON.stringify(newTabItem))
		currentTab.value?.cards?.forEach((card) => {
			mutableItemModel.value = buildModel(mutableItemModel.value, card, props)
		})
	},
	{ deep: true, immediate: true },
)

const submit = async () => {
	processing.value = true
	//clone object to prevent re-stringify

	//clone object to prevent re-stringify
	let outputData = {}
	// Loop through each updated field
	updatedFields.value.forEach((field) => {
		// If field is deeply nested
		outputData = modalDataStore.setNestedValue(
			field,
			modalDataStore.getNestedValue(dataModel.value, field),
			outputData,
		)
	})

	if (computedUpdateConfig.value === null) return

	outputData["_method"] = "patch"
	let params = computedUpdateConfig.value.update.params
	let targetRoute
	if (typeof computedUpdateConfig.value.update.route === "function") {
		targetRoute = computedUpdateConfig.value.update.route(
			mutableItemModel.value,
		)
	} else {
		if (computedUpdateConfig.value.update.key) {
			params = {
				[computedUpdateConfig.value.update.key]: mutableItemModel.value.id,
				...computedUpdateConfig.value.update.params,
			}
		}
		params = injectTeamParam(computedUpdateConfig.value.update.route, params)
		targetRoute = route(computedUpdateConfig.value.update.route, params)
	}
	router.post(targetRoute, outputData, {
		onSuccess: (event) => {
			toast.success("Successfully updated data")
			dialog.value = false
			processing.value = false
			emit("success")
		},
		onError: (errors) => {
			toast.error(Object.values(errors).join("\n"))
			processing.value = false
		},
	})
}
const navigate = (linkHref) => {
	router.visit(linkHref)
}
const emit = defineEmits(["updateValue", "success"])

const inputUpdate = (fieldName) => {
	let value = fieldName.value
	let updatedColumn = fieldName.name
	if (fieldName.column) {
		updatedColumn = fieldName.column
		if (mutableItemModel.value[fieldName.column]) {
			mutableItemModel.value[fieldName.column][fieldName.name] = value
		} else {
			mutableItemModel.value[fieldName.column] = {}
			mutableItemModel.value[fieldName.column][fieldName.name] = value
		}
	} else {
		setNestedProperty(mutableItemModel.value, fieldName.name, value)
	}
	const newVal = getNestedProperty(mutableItemModel.value, updatedColumn)
	emit("updateValue", updatedColumn, newVal)
	if (!updatedFields.value.includes(updatedColumn)) {
		//add updated fileds to list so that we can patch only those fields
		updatedFields.value.push(updatedColumn)
	}
}

onMounted(() => {
	if (initializeDataModel) {
		console.log("initializeDataModel", props.tabItem)
		modalDataStore.initializeDataModel(
			props.tabItem ?? {},
			props.updateConfig?.tabsList ?? props.tabsList ?? [],
			true,
		)
	}
})
</script>
<style>
.tooltip {
	position: fixed;
	z-index: 999999999;
}
</style>
<style scoped>
.modal {
	padding-right: 0 !important;
}
.modal-body {
	max-height: 85vh;
	overflow-y: auto;
}
.footer-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: -20px;
	margin-bottom: 10px;
}
@media (max-width: 767px) {
	.modal-body {
		max-height: unset;
	}
	.dropdown-menu button {
		width: 32px !important;
		height: 32px !important;
	}
	.dropdown-menu {
		min-width: unset;
		display: flex;
		padding: 5px;
		gap: 5px;
		align-items: center;
	}
	.dropdown-menu li {
		border: none !important;
		cursor: pointer;
		height: 32px;
		flex: 1 0 0;
		aspect-ratio: 1;
	}
	.dropdown-menu svg {
		aspect-ratio: 1;
	}
}
</style>
