<template>
	<label class="form-check-label" :for="inputId">
		<input
			:id="inputId"
			:model-value="localValue"
			class="form-check-input"
			type="checkbox"
			@change="onInput"
		/>
		{{ props.field.label || props.label }}
	</label>
</template>

<script setup>
import { computed, defineModel, defineEmits, onMounted, shallowRef } from "vue"
import { MDBSwitch } from "mdb-vue-ui-kit"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	default: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	align: {
		type: String,
		default: "left",
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const emit = defineEmits(["update:modelValue"])

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: Boolean,
	default: undefined,
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value || props.field.default || props.default
})

const onInput = (value) => {
	let checkValue = value.target.checked
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			checkValue,
			true,
		)
	}
	fieldValue.value = checkValue
}
</script>
