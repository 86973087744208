<template>
	<div
		:class="props.field.classList || props.classList || []"
		v-html="content"
	></div>
</template>

<script setup>
import { ref, computed, watch, defineModel, defineEmits, onMounted } from "vue"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: [Array, String],
		default: () => [],
	},
	title: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})

const fieldValue = defineModel({
	type: String,
	default: "",
})
const content = computed(() => {
	return props.content.length > 0
		? props.content
		: props.field.content
			? props.field.content
			: props.field.name && props.dataStore
				? props.dataStore.getModelValue({
						column: props.field.column || null,
						name: props.field.name || null,
					})
				: fieldValue
})
</script>
