<template>
	<div class="position-relative w-100 h-100">
		<!-- Base GridLayout for top-level sections (Tabs) -->
		<GridLayout
			ref="baseGridLayout"
			v-model:layout="sectionLayout['section_0']"
			:col-num="12"
			:cols="gridCols"
			:row-height="5"
			:use-layout-key="true"
			:is-draggable="true"
			:is-resizable="false"
			:responsive="false"
			:auto-size="true"
			:vertical-compact="true"
			:horizontal-compact="true"
			:use-css-transforms="true"
			class="p-5"
			@layout-change="(newLayout) => handleSectionReorder(0, newLayout)"
		>
			<template #default="{ layoutKey }">
				<!-- Iterate over top-level sections -->
				<GridItem
					v-for="item in sectionLayout['section_0']"
					:key="`${item.i}-griditem`"
					:layout-key="layoutKey"
					:x="item.x"
					:y="item.y"
					:w="item.w"
					:h="item.h"
					:i="item.i"
					:parent-id="'0'"
					:child-depth="3"
					drag-allow-from=".vue-draggable-handle"
					@width-changed="handleSectionWidthChanged"
				>
					<!-- Section Content -->
					<div :key="`div-0-${item.i}`" class="position-relative w-100 h-100">
						<div class="d-block h-100 w-100 shadow-0 border border-1 px-4 py-3">
							<div>
								<h5 class="p-3">
									{{ item.formSection.name || "Form Section" }}
								</h5>
								<!-- Nested GridLayout for child sections (Cards) -->
								<GridLayout
									:ref="(el) => (sectionLayoutRef[`section_${item.i}`] = el)"
									v-model:layout="sectionLayout[`section_${item.i}`]"
									:cols="gridCols"
									:row-height="5"
									:use-layout-key="true"
									:is-draggable="true"
									:is-resizable="true"
									:responsive="false"
									:auto-size="true"
									:vertical-compact="true"
									:use-css-transforms="true"
									@layout-change="
										(newLayout) => handleSectionReorder(item.i, newLayout)
									"
								>
									<template #default="{ layoutKey: nestedLayoutKey }">
										<!-- Iterate over child sections (Cards) -->
										<GridItem
											v-for="child in sectionLayout[`section_${item.i}`]"
											:key="`${child.i}-griditem`"
											:layout-key="nestedLayoutKey"
											:x="child.x"
											:y="child.y"
											:w="child.w"
											:h="child.h"
											:i="child.i"
											:parent-id="item.i"
											:child-depth="3"
											drag-allow-from=".vue-draggable-handle"
											@width-changed="handleSectionWidthChanged"
										>
											<!-- Card Content -->
											<template #default>
												<div
													:key="`div-${item.i}-${child.i}`"
													class="position-relative w-100 h-100 mb-5"
												>
													<div
														class="d-block h-100 w-100"
														:class="cardClasses(child.formSection)"
													>
														<div
															class="p-4"
															:class="{
																'card-body':
																	child.formSection?.custom_settings
																		?.show_card !== false,
															}"
														>
															<!-- Card Header -->
															<div class="col-12">
																<h5>
																	{{ child.formSection?.name || "Form Card" }}
																</h5>
																<h6>Type: {{ child.formSection?.type }}</h6>
															</div>
															<!-- Fields GridLayout -->
															<div class="col-12">
																<GridLayout
																	:ref="
																		(el) =>
																			(sectionLayoutRef[`section_${child.i}`] =
																				el)
																	"
																	v-model:layout="
																		fieldsLayout[`section_${child.i}`]
																	"
																	:cols="gridCols"
																	:row-height="5"
																	:use-layout-key="true"
																	:is-draggable="true"
																	:is-resizable="true"
																	:lock-height="true"
																	:responsive="false"
																	:auto-size="true"
																	:vertical-compact="true"
																	:use-css-transforms="true"
																	@layout-change="
																		(newLayout) =>
																			handleFieldReorder(child.i, newLayout)
																	"
																>
																	<template
																		#default="{ layoutKey: fieldLayoutKey }"
																	>
																		<!-- Iterate over fields -->
																		<GridItem
																			v-for="field in fieldsLayout[
																				`section_${child.i}`
																			]"
																			:key="`${field.i}-griditem`"
																			:layout-key="fieldLayoutKey"
																			:x="field.x"
																			:y="field.y"
																			:w="field.w"
																			:h="field.h"
																			:i="field.i"
																			:parent-id="child.i"
																			:child-depth="3"
																			drag-allow-from=".vue-draggable-handle"
																			@width-changed="handleFieldWidthChanged"
																		>
																			<!-- Field Content -->
																			<template #default>
																				<div
																					class="position-relative w-100 h-100 mb-5"
																				>
																					<div class="d-block h-100 w-100">
																						<div class="">
																							<div class="col-12 py-2 pe-2">
																								<FormField
																									:column-class="'p-0'"
																									:field="field.formField"
																								/>
																							</div>
																							<!-- Field Actions -->
																							<div class="vue-draggable-handle">
																								<FontAwesomeIcon
																									:icon="
																										lookupIcon(
																											'grip-dots-vertical',
																											'fas',
																										)
																									"
																								/>
																							</div>
																						</div>
																						<!-- Edit Field Modal -->
																						<div class="edit-button">
																							<FormModal
																								:ref="
																									(el) =>
																										(formModalRef[
																											`field_${field.i}`
																										] = el)
																								"
																								button-icon="cog"
																								:button-class="[
																									'shadow-0',
																									'btn',
																									'btn-xs',
																									'm-0',
																									'p-0',
																								]"
																								:tabs-list="[
																									{
																										tabId: 'settings',
																										tabTitle: 'Settings',
																										cards: getFieldSettings(
																											field.formField,
																										),
																									},
																								]"
																								:tab-id="`form-field-${field.formField.id}`"
																								:tab-item="field.formField"
																								:update-config="
																									props.card.fieldConfig
																								"
																							/>
																						</div>
																						<!-- Delete Field Button -->
																						<div
																							class="trash-button text-danger"
																							@click.prevent="
																								removeField(child.i, field.i)
																							"
																						>
																							<FontAwesomeIcon
																								:icon="
																									lookupIcon('trash-alt', 'fas')
																								"
																							/>
																						</div>
																					</div>
																				</div>
																			</template>
																		</GridItem>
																	</template>
																</GridLayout>
															</div>
															<!-- Add Field Button -->
															<div class="vue-draggable-handle">
																<FontAwesomeIcon
																	:icon="
																		lookupIcon('grip-dots-vertical', 'fas')
																	"
																/>
															</div>
															<div>
																<div class="float-end">
																	<OptionsDialog
																		v-model="addFieldListOpen[child.i]"
																		:options="props.card.fieldConfig.types"
																		@selected="addField(child.i, $event)"
																	/>
																	<button
																		class="btn btn-primary btn-floating"
																		@click="addFieldListOpen[child.i] = true"
																	>
																		<FontAwesomeIcon
																			:icon="lookupIcon('plus', 'fas')"
																		/>
																	</button>
																</div>
															</div>
															<!-- Edit Card Modal -->
															<div class="edit-button">
																<FormModal
																	button-icon="cog"
																	:button-class="[
																		'shadow-0',
																		'btn',
																		'btn-xs',
																		'm-0',
																		'p-0',
																	]"
																	:tabs-list="[
																		{
																			tabId: 'settings',
																			tabTitle: 'Settings',
																			cards: getSectionSettings(
																				child.formSection,
																			),
																		},
																	]"
																	:tab-id="`form-section-${child.formSection.id}`"
																	:tab-item="child.formSection"
																	:update-config="props.card.sectionConfig"
																/>
															</div>
															<!-- Delete Card Button -->
															<div
																v-if="canRemoveSection(child.formSection)"
																class="trash-button text-danger"
																@click.prevent="removeSection(item.i, child.i)"
															>
																<FontAwesomeIcon
																	:icon="lookupIcon('trash-alt', 'fas')"
																/>
															</div>
														</div>
													</div>
												</div>
											</template>
										</GridItem>
									</template>
								</GridLayout>
								<!-- Add Card Button -->
								<div class="text-end">
									<button
										v-if="canAddCard(item.formSection)"
										class="btn btn-primary rounded-pill"
										@click.prevent="addSection(item.i)"
									>
										Add Card
									</button>
								</div>
								<!-- Section Actions -->
								<div class="vue-draggable-handle">
									<FontAwesomeIcon
										:icon="lookupIcon('grip-dots-vertical', 'fas')"
									/>
								</div>
								<!-- Edit Section Modal -->
								<div class="edit-button">
									<FormModal
										button-icon="cog"
										:button-class="['shadow-0', 'btn', 'btn-xs', 'm-0', 'p-0']"
										:tabs-list="[
											{
												tabId: 'settings',
												tabTitle: 'Settings',
												cards: getSectionSettings(item.formSection),
											},
										]"
										:tab-id="`form-section-${item.formSection.id}`"
										:tab-item="item.formSection"
										:update-config="props.card.sectionConfig"
									/>
								</div>
								<!-- Delete Section Button -->
								<div
									class="trash-button text-danger"
									@click.prevent="removeSection(0, item.i)"
								>
									<FontAwesomeIcon :icon="lookupIcon('trash-alt', 'fas')" />
								</div>
							</div>
						</div>
					</div>
				</GridItem>
			</template>
		</GridLayout>
		<!-- Add Tab Button -->
		<div class="text-end pt-3">
			<button
				class="btn btn-primary rounded-pill"
				@click.prevent="addSection(0)"
			>
				Add Tab
			</button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, nextTick, watch, onMounted } from "vue"
import { debounce, toArray } from "lodash"
import { storeToRefs } from "pinia"
import GridLayout from "@/Components/Mod/GridLayout/GridLayout.vue"
import GridItem from "@/Components/Mod/GridLayout/GridItem.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import OptionsDialog from "@/Components/Mod/OptionsDialog.vue"
import FormField from "@/Components/Mod/FormField.vue"
import FormModal from "@/Components/Mod/FormModal.vue"
import { useFormModel } from "@/Composables/useFormModel"
import { useDataModelStore } from "@/Store/dataModelStore"
import { useToast } from "@/Composables/useToast"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"
const props = defineProps({
	dataStore: {
		type: Object,
		required: false,
		default: null,
	},
	class: {
		type: String,
		required: false,
		default: "",
	},
	card: {
		type: Object,
		required: true,
		default: () => ({}),
	},
})

const { injectTeamParam } = useTeamRouteParams()

const emit = defineEmits(["updateValue"])

// Setup References and Reactive Variables
const sectionLayoutRef = ref({})
const formModalRef = ref({})
const addFieldListOpen = ref({})
const baseGridLayout = ref(null)
const sectionLayout = ref({ section_0: [] })
const fieldsLayout = ref({ section_0: [] })
const colNum = ref(12)
const toast = useToast()
const gridCols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

// Data Model References
const pageStore = useDataModelStore()
const { dataModel } = props.dataStore
	? storeToRefs(props.dataStore)
	: storeToRefs(pageStore)

// Watchers for Auto Resizing
const debouncedAutoSize = debounce(() => {
	nextTick(() => {
		Object.values(sectionLayoutRef.value).forEach((section) => {
			section.triggerAutoSize()
		})
		if (baseGridLayout.value) {
			baseGridLayout.value.triggerAutoSize()
		}
	})
}, 200)

watch(
	sectionLayout,
	() => {
		debouncedAutoSize()
	},
	{ deep: true },
)

watch(
	fieldsLayout,
	() => {
		debouncedAutoSize()
	},
	{ deep: true },
)

// Lifecycle Hooks
onMounted(() => {
	sectionLayout.value = computeSectionLayout()
	debouncedAutoSize()
})

// Methods

/**
 * Computes the initial layout of sections and fields based on the data model.
 */
function computeSectionLayout() {
	const layout = {
		section_0: [],
	}

	if (dataModel.value.sections) {
		dataModel.value.sections.forEach((section) => {
			const sectionId = section.id.toString()
			const parentSectionId = section.parent_section_id.toString()

			if (!layout[`section_${parentSectionId}`]) {
				layout[`section_${parentSectionId}`] = []
			}
			if (!layout[`section_${sectionId}`]) {
				layout[`section_${sectionId}`] = []
			}

			const formSection = {
				...section,
				columns: section.columns || gridCols,
			}

			layout[`section_${parentSectionId}`].push({
				parentId: parentSectionId,
				order: section.order,
				formSection,
				x: 0,
				y: 0,
				w: formSection.columns.lg,
				h: 2,
				i: sectionId,
			})

			// Initialize child sections
			if (section.child_sections) {
				section.child_sections.forEach((childSection) => {
					if (!fieldsLayout.value[`section_${childSection.id}`]) {
						fieldsLayout.value[`section_${childSection.id}`] = []
					}
					if (!addFieldListOpen.value[childSection.id.toString()]) {
						addFieldListOpen.value[childSection.id.toString()] = false
					}

					layout[`section_${sectionId}`].push({
						parentId: sectionId,
						order: childSection.order,
						formSection: {
							...childSection,
							columns: childSection.columns || gridCols,
						},
						x: 0,
						y: 0,
						w: childSection.columns?.lg || 12,
						h: 2,
						i: childSection.id.toString(),
					})
				})
			}
		})
	}

	if (dataModel.value.fields) {
		dataModel.value.fields.forEach((field) => {
			const formField = {
				...field,
				id: field.id.toString(),
				columns: field.columns || gridCols,
			}
			const parentSectionKey = `section_${field.form_section_id}`

			if (!fieldsLayout.value[parentSectionKey]) {
				fieldsLayout.value[parentSectionKey] = []
			}

			fieldsLayout.value[parentSectionKey].push({
				parentId: field.form_section_id,
				order: field.order,
				formField,
				x: 0,
				y: 0,
				w: formField.columns,
				h: 6,
				i: field.id.toString(),
			})
		})
	}

	return layout
}

/**
 * Adds a new section under the specified parent.
 * @param {number|string} parentId - The ID of the parent section.
 */
async function addSection(parentId) {
	if (!props.card.sectionConfig.create) {
		return
	}

	let targetRoute = ""
	if (typeof props.card.sectionConfig.create.route === "function") {
		targetRoute = props.card.sectionConfig.create.route(form)
	} else {
		let targetParams = injectTeamParam(
			props.card.sectionConfig.create.route,
			props.card.sectionConfig.create.params,
		)
		targetRoute = route(props.card.sectionConfig.create.route, targetParams)
	}

	const payload = { parent_section_id: parentId, type: "cards-list" }

	try {
		const response = await http.post(targetRoute, payload)
		const data = response.data
		const parentSectionKey = `section_${data.parent_section_id}`
		const sectionKey = `section_${data.id}`

		sectionLayout.value[parentSectionKey] =
			sectionLayout.value[parentSectionKey] || []
		sectionLayout.value[sectionKey] = sectionLayout.value[sectionKey] || []
		fieldsLayout.value[parentSectionKey] =
			fieldsLayout.value[parentSectionKey] || []
		fieldsLayout.value[sectionKey] = fieldsLayout.value[sectionKey] || []

		const formSection = {
			...data,
			columns: data.columns || gridCols,
		}

		const lastSection = sectionLayout.value[parentSectionKey].length
			? sectionLayout.value[parentSectionKey][
					sectionLayout.value[parentSectionKey].length - 1
				]
			: { y: 0, h: 0 }

		sectionLayout.value[parentSectionKey].push({
			parentId,
			order: data.order,
			formSection,
			x: 0,
			y: lastSection.y + lastSection.h,
			w: formSection.columns.lg || 12,
			h: 6,
			i: data.id.toString(),
		})

		if (parseInt(parentId, 10) === 0) {
			// Automatically add a sub-section card if adding to the top-level section
			await addSection(data.id)
		} else {
			toast.success("Section created")
		}
	} catch (error) {
		console.error("Error adding section:", error)
		const errorMessage =
			error.response?.data?.error ||
			"An error occurred while adding the section."
		toast.error(errorMessage)
	}
}

/**
 * Adds a new field to the specified section.
 * @param {number|string} parentId - The ID of the parent section.
 * @param {string} fieldType - The type of the field to add.
 */
async function addField(parentId, fieldType) {
	if (!props.card.fieldConfig.create) {
		return
	}

	let targetRoute = ""
	let targetParams = { ...props.card.fieldConfig.create.params }
	if (typeof props.card.fieldConfig.create.route === "function") {
		targetRoute = props.card.fieldConfig.create.route(form)
	} else {
		targetParams = injectTeamParam(
			props.card.fieldConfig.create.route,
			targetParams,
		)
		targetRoute = route(props.card.fieldConfig.create.route, targetParams)
	}

	const payload = { section: parentId, type: fieldType, name: "New Field" }

	try {
		const response = await http.post(targetRoute, payload)
		const data = response.data
		toast.success("Field created")

		const parentSectionKey = `section_${parentId}`
		fieldsLayout.value[parentSectionKey] =
			fieldsLayout.value[parentSectionKey] || []

		const lastField = fieldsLayout.value[parentSectionKey].length
			? fieldsLayout.value[parentSectionKey][
					fieldsLayout.value[parentSectionKey].length - 1
				]
			: { y: 0, h: 0 }

		const formField = {
			...data,
			columns: data.columns || gridCols,
			id: data.id.toString(),
		}

		fieldsLayout.value[parentSectionKey].push({
			parentId: parseInt(parentId, 10),
			order: data.order,
			formField,
			x: 0,
			y: lastField.y + lastField.h,
			w: formField.columns.lg,
			h: 6,
			i: data.id.toString(),
		})

		nextTick(() => {
			if (formModalRef.value[`field_${data.id}`]) {
				formModalRef.value[`field_${data.id}`].openModal()
			}
		})
	} catch (error) {
		console.error("Error adding field:", error)
		const errorMessage =
			error.response?.data?.error || "An error occurred while adding the field."
		toast.error(errorMessage)
	}
}

/**
 * Removes a field from a section.
 * @param {number|string} parentId - The ID of the parent section.
 * @param {number|string} fieldId - The ID of the field to remove.
 */
async function removeField(parentId, fieldId) {
	if (!props.card.fieldConfig.destroy) {
		return
	}

	let targetRoute = ""
	let targetParams = {
		...props.card.fieldConfig.destroy.params,
		field: fieldId,
	}
	if (typeof props.card.fieldConfig.destroy.route === "function") {
		targetRoute = props.card.fieldConfig.destroy.route(form)
	} else {
		targetParams = injectTeamParam(
			props.card.fieldConfig.destroy.route,
			targetParams,
		)
		targetRoute = route(props.card.fieldConfig.destroy.route, targetParams)
	}

	try {
		await http.delete(targetRoute)
		toast.success("Field deleted")

		const parentSectionKey = `section_${parentId}`
		fieldsLayout.value[parentSectionKey] = fieldsLayout.value[
			parentSectionKey
		].filter((field) => field.i !== fieldId.toString())
	} catch (error) {
		console.error("Error removing field:", error)
		const errorMessage =
			error.response?.data?.error ||
			"An error occurred while removing the field."
		toast.error(errorMessage)
	}
}

/**
 * Removes a section.
 * @param {number|string} parentId - The ID of the parent section.
 * @param {number|string} sectionId - The ID of the section to remove.
 */
async function removeSection(parentId, sectionId) {
	if (!props.card.sectionConfig.destroy) {
		return
	}

	let targetRoute = ""
	let targetParams = {
		...props.card.sectionConfig.destroy.params,
		section: sectionId,
	}
	if (typeof props.card.sectionConfig.destroy.route === "function") {
		targetRoute = props.card.sectionConfig.destroy.route(form)
	} else {
		targetParams = injectTeamParam(
			props.card.sectionConfig.destroy.route,
			targetParams,
		)
		targetRoute = route(props.card.sectionConfig.destroy.route, targetParams)
	}

	try {
		await http.delete(targetRoute)
		toast.success("Section deleted")

		const parentSectionKey = `section_${parentId}`
		sectionLayout.value[parentSectionKey] = sectionLayout.value[
			parentSectionKey
		].filter((section) => section.i !== sectionId.toString())
	} catch (error) {
		console.error("Error removing section:", error)
		const errorMessage =
			error.response?.data?.error ||
			"An error occurred while removing the section."
		toast.error(errorMessage)
	}
}

/**
 * Updates the width of a field.
 * @param {number|string} fieldId - The ID of the field.
 * @param {number} width - The new width.
 * @param {number|string} parentId - The ID of the parent section.
 */
async function updateFieldWidth(fieldId, width, parentId) {
	const sectionKey = `section_${parentId}`
	const fieldToUpdate = fieldsLayout.value[sectionKey]?.find(
		(field) => field.i === fieldId.toString(),
	)

	if (fieldToUpdate) {
		fieldToUpdate.formField.columns = width

		if (props.card.fieldConfig.update) {
			let targetRoute = ""
			let targetParams = {
				...props.card.fieldConfig.update.params,
				field: fieldToUpdate.formField.id,
			}
			if (typeof props.card.fieldConfig.update.route === "function") {
				targetRoute = props.card.fieldConfig.update.route(form)
			} else {
				targetParams = injectTeamParam(
					props.card.fieldConfig.update.route,
					targetParams,
				)
				targetRoute = route(props.card.fieldConfig.update.route, targetParams)
			}

			try {
				const payload = {
					columns: fieldToUpdate.formField.columns,
				}
				await http.put(targetRoute, payload)
				toast.success("Field updated")
			} catch (error) {
				console.error("Error updating field width:", error)
				const errorMessage =
					error.response?.data?.error ||
					"An error occurred while updating the field."
				toast.error(errorMessage)
			}
		}
	}
}

/**
 * Updates the width of a section.
 * @param {number|string} sectionId - The ID of the section.
 * @param {number} width - The new width.
 * @param {number|string} parentId - The ID of the parent section.
 */
async function updateSectionWidth(sectionId, width, parentId) {
	const sectionKey = `section_${parentId}`
	const sectionToUpdate = sectionLayout.value[sectionKey]?.find(
		(section) => section.i === sectionId.toString(),
	)

	if (sectionToUpdate) {
		sectionToUpdate.w = width

		if (props.card.sectionConfig.update) {
			let targetRoute = ""
			let targetParams = {
				...props.card.sectionConfig.update.params,
				section: sectionId,
			}
			if (typeof props.card.sectionConfig.update.route === "function") {
				targetRoute = props.card.sectionConfig.update.route(form)
			} else {
				targetParams = injectTeamParam(
					props.card.sectionConfig.update.route,
					targetParams,
				)
				targetRoute = route(props.card.sectionConfig.update.route, targetParams)
			}

			try {
				const payload = {
					columns: { lg: width, md: width, sm: width, xs: width, xxs: width },
				}
				await http.put(targetRoute, payload)
				toast.success("Section updated")
			} catch (error) {
				console.error("Error updating section width:", error)
				const errorMessage =
					error.response?.data?.error ||
					"An error occurred while updating the section."
				toast.error(errorMessage)
			}
		}
	}
}

/**
 * Handler for section width changes.
 */
function handleSectionWidthChanged(i, w, parentId) {
	updateSectionWidth(i, w, parentId)
}

/**
 * Handler for field width changes.
 */
function handleFieldWidthChanged(i, w, parentId) {
	updateFieldWidth(i, w, parentId)
}

/**
 * Checks if a section can be removed.
 */
function canRemoveSection(formSection) {
	const sectionTypes = props.card.sectionConfig?.types || {}
	return !(
		sectionTypes.default?.includes("disable-remove") ||
		sectionTypes[formSection.type]?.includes("disable-remove")
	)
}

/**
 * Checks if a card can be added to a section.
 */
function canAddCard(formSection) {
	const sectionTypes = props.card.sectionConfig?.types || {}
	return !(
		sectionTypes.default?.includes("disable-additional-cards") ||
		sectionTypes[formSection.type]?.includes("disable-additional-cards")
	)
}

/**
 * Generates classes for a card based on its settings.
 */
function cardClasses(formSection) {
	return {
		card: formSection?.custom_settings?.show_card !== false,
		border: formSection?.custom_settings?.show_border !== false,
		"border-1": formSection?.custom_settings?.show_border !== false,
		"hover-shadow":
			formSection?.custom_settings?.show_border === false ||
			formSection?.custom_settings?.show_card === false,
	}
}

/**
 * Retrieves the section settings.
 */
function getSectionSettings(section) {
	const sectionType = section?.type || "card"
	return [
		...(props.card.sectionConfig?.additionalSettings?.default || []),
		...(props.card.sectionConfig?.additionalSettings?.[sectionType] || []),
		...(props.card.sectionConfig.settings || []),
	]
}

/**
 * Retrieves the field settings.
 */
function getFieldSettings(field) {
	const fieldType = field?.type || "text"
	return [
		...(props.card.fieldConfig?.additionalSettings?.default || []),
		...(props.card.fieldConfig?.additionalSettings?.[fieldType] || []),
		...(props.card.fieldConfig?.settings || []),
	]
}

/**
 * Handles the reordering of sections within a parent section.
 * @param {string} parentSectionId - The ID of the parent section.
 * @param {Array} newOrder - The new order of sections.
 */
async function handleSectionReorder(parentSectionId, newOrder) {
	const sectionKey = `section_${parentSectionId}`
	const updatedSections = newOrder.map((item, index) => ({
		...item,
		order: item.x + item.y,
	}))

	sectionLayout.value[sectionKey] = updatedSections

	try {
		await updateSectionOrderOnServer(parentSectionId, updatedSections)
		toast.success("Section order updated")
	} catch (error) {
		console.error("Error updating section order:", error)
		toast.error("Failed to update section order")
	}
}

/**
 * Handles the reordering of fields within a section.
 * @param {string} sectionId - The ID of the section.
 * @param {Array} newOrder - The new order of fields.
 */
async function handleFieldReorder(sectionId, newOrder) {
	const sectionKey = `section_${sectionId}`
	const updatedFields = newOrder.map((item, index) => ({
		...item,
		order: item.x + item.y,
	}))

	fieldsLayout.value[sectionKey] = updatedFields

	try {
		await updateFieldOrderOnServer(sectionId, updatedFields)
		toast.success("Field order updated")
	} catch (error) {
		console.error("Error updating field order:", error)
		toast.error("Failed to update field order")
	}
}

/**
 * Updates the section order on the server.
 * @param {string} parentSectionId - The ID of the parent section.
 * @param {Array} updatedSections - The updated sections with new order.
 */
async function updateSectionOrderOnServer(parentSectionId, updatedSections) {
	if (!props.card.sectionConfig.updateOrder) {
		return
	}

	let targetRoute = ""
	if (typeof props.card.sectionConfig.updateOrder.route === "function") {
		targetRoute = props.card.sectionConfig.updateOrder.route(form)
	} else {
		let params = props.card.sectionConfig.updateOrder.params
		params = injectTeamParam(props.card.sectionConfig.updateOrder.route, params)
		targetRoute = route(props.card.sectionConfig.updateOrder.route, params)
	}

	const payload = {
		parent_section_id: parentSectionId,
		sections: updatedSections.map((section) => ({
			id: section.i,
			order: section.order,
		})),
	}

	// Use PUT for updating the order
	await http.put(targetRoute, payload)
}

/**
 * Updates the field order on the server.
 * @param {string} sectionId - The ID of the section.
 * @param {Array} updatedFields - The updated fields with new order.
 */
async function updateFieldOrderOnServer(sectionId, updatedFields) {
	if (!props.card.fieldConfig.updateOrder) {
		return
	}

	let targetRoute = ""
	if (typeof props.card.fieldConfig.updateOrder.route === "function") {
		targetRoute = props.card.fieldConfig.updateOrder.route(form)
	} else {
		let params = props.card.fieldConfig.updateOrder.params
		params = injectTeamParam(props.card.fieldConfig.updateOrder.route, params)
		targetRoute = route(props.card.fieldConfig.updateOrder.route, params)
	}

	const payload = {
		section_id: sectionId,
		fields: updatedFields.map((field) => ({
			id: field.i,
			order: field.order,
		})),
	}

	// Use PUT for updating the order
	await http.put(targetRoute, payload)
}
</script>

<style>
.vue-grid-item .vue-draggable-handle {
	cursor: move;
	position: absolute;
	top: 0;
	left: -16px;
}

.vue-grid-item .edit-button span button {
	position: absolute;
	top: 25px;
	left: -20px;
}

.vue-grid-item .trash-button {
	position: absolute;
	top: 50px;
	left: -20px;
}

.vue-grid-item .resizing {
	opacity: 0.9;
}

.vue-grid-item .text {
	font-size: 24px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
	width: 100%;
}

.vue-grid-item .no-drag {
	height: 100%;
	width: 100%;
}

.vue-grid-item .minMax {
	font-size: 12px;
}

.vue-grid-item .add {
	cursor: pointer;
}

.vue-draggable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	left: 0;
	padding: 0 8px 8px 0;
	box-sizing: border-box;
	cursor: pointer;
}

.columns {
	-moz-columns: 120px;
	-webkit-columns: 120px;
	columns: 120px;
}

.vue-grid-item.vue-grid-placeholder {
	background: #0076db !important;
}

.edit-mode .card {
}
</style>
