import { computed, ref } from "vue"
import { router, usePage } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { constructObjectFromDotNotation } from "@/Utils/dotNotationHelpers"
import { storeToRefs } from "pinia"
import http from "@/Services/http"

/**
 * Composable for handling autosave confirmation functionality
 * @param {Object} props - Component props containing dataStore and field configuration
 * @returns {Object} Autosave confirmation state and methods
 */
export const useAutosaveConfirmation = (props) => {
	const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

	const toast = useToast()
	const confirmDialogOpen = ref(false)
	const updateInProgress = ref(false)
	const previousValue = ref(null)

	const localValue = computed(() => {
		let fieldName = props.field.name || props.name || null
		return props.dataStore && fieldName
			? props.dataStore.getModelValue({
					column: props.field.column || null,
					name: fieldName,
				})
			: props.modelValue || props.field.default || props.default
	})

	const dialogConfig = computed(() => {
		const newValue = localValue.value
		const falseState = props.field.inlineUpdate?.falseState
		const falseStateValue = props.field.inlineUpdate?.falseStateValue ?? false
		// If switching to falseStateValue and falseState config exists, use it
		if (newValue === falseStateValue && falseState) {
			return {
				title:
					falseState.confirmTitle ||
					props.field.inlineUpdate?.confirmTitle ||
					"Save This Change?",
				bodyText:
					falseState.confirmBodyText ||
					props.field.inlineUpdate?.confirmBodyText ||
					"Are you sure you want to save this change?",
				confirmButtonText:
					falseState.confirmButtonText ||
					props.field.inlineUpdate?.confirmButtonText ||
					"Save",
				cancelButtonText:
					falseState.cancelButtonText ||
					props.field.inlineUpdate?.cancelButtonText ||
					"Cancel",
				confirmButtonColor:
					falseState.confirmButtonColor ||
					props.field.inlineUpdate?.confirmButtonColor ||
					"outline-primary",
			}
		}

		// Default/true state configuration
		return {
			title: props.field.inlineUpdate?.confirmTitle || "Save This Change?",
			bodyText:
				props.field.inlineUpdate?.confirmBodyText ||
				"Are you sure you want to save this change?",
			confirmButtonText: props.field.inlineUpdate?.confirmButtonText || "Save",
			cancelButtonText: props.field.inlineUpdate?.cancelButtonText || "Cancel",
			confirmButtonColor:
				props.field.inlineUpdate?.confirmButtonColor || "outline-primary",
		}
	})

	/**
	 * Handles the update process for the field
	 * Validates route existence and processes the update request
	 * @async
	 */
	const handleUpdate = async () => {
		if (!props.field?.inlineUpdate?.route) {
			console.warn("No route provided in field object:", props.field)
			return
		}

		updateInProgress.value = true
		previousValue.value = localValue.value
		const data = constructUpdatePayload()

		try {
			await submitUpdate(data)
		} catch (err) {
			handleError(err)
		}
	}

	/**
	 * Constructs the update payload based on field configuration
	 * @returns {Object} The formatted data payload for the update request
	 */
	const constructUpdatePayload = () => {
		if (props.field.column) {
			const data = {}
			data[props.field.column] = {}
			data[props.field.column][props.field.name] = localValue.value
			return data
		}

		if (props.field.name && props.field.name.includes(".")) {
			return constructObjectFromDotNotation({
				[props.field.name]: localValue.value,
			})
		}

		return { [props.field.name]: localValue.value }
	}

	/**
	 * Submits the update request to the server
	 * @async
	 * @param {Object} data - The data payload to send
	 */
	const submitUpdate = async (data) => {
		const method = (props.field.inlineUpdate?.method || "patch").toLowerCase()
		const curRoute =
			typeof props.field.inlineUpdate.route == "function"
				? props.field.inlineUpdate.route(data, dataModel)
				: route(props.field.inlineUpdate.route, {
						...props.field.inlineUpdate.params,
						team: usePage().props.currentTeam.slug,
					})

		try {
			const response = await http[method](curRoute, data)
			handleSuccess()
		} catch (error) {
			handleError(error)
		} finally {
			updateInProgress.value = false
		}
	}

	/**
	 * Handles successful update response
	 * Shows success toast and cleans up the updated fields
	 */
	const handleSuccess = () => {
		toast.success(
			props.field.inlineUpdate?.confirmSuccessMessage || "Successfully updated",
		)
		confirmDialogOpen.value = false

		// remove field from updatedFields array
		removeFieldFromUpdatedFields()
	}

	/**
	 * Handles error during update
	 * Shows error toast and reverts to previous value
	 * @param {Error} err - The error object
	 */
	const handleError = (err) => {
		console.error("Update failed:", err)
		toast.error(
			props.field.inlineUpdate?.confirmErrorMessage || "Error updating",
		)
		// Reset to previous value
		if (props.dataStore && props.field.name) {
			props.dataStore.setModelValue(
				{
					name: props.field.name,
					column: props.field.column,
				},
				previousValue.value,
			)
		}
		updateInProgress.value = false
	}

	/**
	 * Handles cancellation of the update
	 * Reverts the field to its previous value
	 */
	const handleCancelUpdate = () => {
		if (props.dataStore && props.field.name) {
			props.dataStore.setModelValue(
				{
					name: props.field.name,
				},
				previousValue.value,
			)
			removeFieldFromUpdatedFields()
		}
	}

	/**
	 * Removes the current field from the updatedFields array in the data store
	 */
	const removeFieldFromUpdatedFields = () => {
		const foundInUpdated = props.dataStore.updatedFields.findIndex(
			(field) => field === props.field.name,
		)
		if (foundInUpdated > -1) {
			props.dataStore.updatedFields.splice(foundInUpdated, 1)
		}
	}

	const openConfirmationDialog = () => {
		confirmDialogOpen.value = true
	}

	return {
		confirmDialogOpen,
		updateInProgress,
		dialogConfig,
		handleUpdate,
		previousValue,
		handleCancelUpdate,
		openConfirmationDialog,
	}
}
