<template>
	<div
		id="dropzone-container"
		:class="{ dragging: isDragging }"
		@dragover.prevent="dragover"
		@dragleave="dragleave"
		@drop="drop"
	>
		<input
			id="fileInput"
			ref="fileInput"
			type="file"
			multiple
			name="file"
			class="hidden-input"
			:accept="fileTypes?.join(',')"
			@change="onChange"
		/>
		<label
			for="fileInput"
			class="file-label text-center position-relative"
			@dragleave.stop.prevent
		>
			<div class="lh-1 d-flex flex-column gap-2 py-3">
				<img
					src="/images/asset_upload.png"
					alt="Upload Image"
					class="mx-auto"
					style="max-width: 60px"
				/>
				<h5 class="fw-bold mb-0" style="font-size: 1.125rem">Drag File Here</h5>
				<div style="font-size: 0.75rem">Or</div>
				<div
					style="font-size: 0.875rem"
					class="btn-outline-primary rounded-pill align-self-center"
				>
					<IconBadge icon="far.cloud-arrow-up" />
					<span class="fw-bold">Upload from Computer</span>
				</div>
			</div>
			<div
				v-if="isDragging"
				class="position-absolute top-0 d-flex h-100 w-100 justify-content-center align-items-center bg-white"
			>
				Release to drop files here.
			</div>
		</label>
		<div v-if="files.length" class="mt-2">
			<MDBAccordion :key="files.length">
				<MDBAccordionItem>
					<div
						v-for="(file, index) in files"
						:key="file.file.name"
						:title="file.file.name"
						class="row align-items-center"
					>
						<div class="col-1">
							<MDBBtn
								class="btn-danger btn-sm ml-2"
								type="button"
								title="Remove file"
								@click="remove(index)"
							>
								&times;
							</MDBBtn>
						</div>
						<div class="col-3 d-flex justify-content-center">
							<img
								v-if="isImage(file)"
								class="preview-img"
								:src="generateThumbnail(file.file)"
							/>
							<img
								v-else
								class="preview-img"
								:src="getGenericFileImage(file.name)"
								alt="icon"
							/>
						</div>
						<div class="col-8">
							<TextField v-model="file.name" label="Rename File" />
							<div><b>Size:</b> {{ file.file.size / 1000 }} KB</div>
						</div>
					</div>
				</MDBAccordionItem>
			</MDBAccordion>
		</div>
	</div>
	<MDBBtn v-if="files.length" color="primary" class="m-auto" @click="onSubmit">
		Upload
	</MDBBtn>
</template>

<script setup>
import { ref, computed } from "vue"
import { useProjectStore } from "@/Store/projectStore"
import { useAssetStore } from "@/Store/assetStore"
import { useToast } from "@/Composables/useToast"
import axios from "axios"
import { MDBAccordion, MDBAccordionItem, MDBBtn } from "mdb-vue-ui-kit"
import IconBadge from "@/Components/IconBadge.vue"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import { usePage } from "@inertiajs/vue3"

const props = defineProps({
	options: {
		type: Object,
		default: () => ({}),
	},
	fileTypes: {
		type: Array,
		required: true,
	},
})

const isDragging = ref(false)
const files = ref([])
const fileInput = ref(null)

const dragover = () => {
	isDragging.value = true
}

const dragleave = (e) => {
	if (e.target.id !== "dropzone-container") return
	isDragging.value = false
}

const drop = (e) => {
	e.preventDefault()
	fileInput.value.files = e.dataTransfer.files
	onChange()
	isDragging.value = false
}

const onChange = () => {
	Array.from(fileInput.value.files).forEach((file) => {
		files.value.push({ file: file, name: file.name })
	})
}

const isImage = (file) => {
	const extension = file.name.split("?")[0].split(".").at(-1)
	const imageExtensions = [
		".jpg",
		".jpeg",
		".png",
		".gif",
		".bmp",
		".tiff",
		".svg",
		".webp",
		".ico",
	]
	return imageExtensions.includes(`.${extension.toLowerCase()}`)
}

const getGenericFileImage = (url) => {
	const extension = url.split("?")[0].split(".").at(-1)
	const fileTypeExtensions = {
		font: [".ttf", ".otf", ".woff", ".woff2", ".eot", ".svg"],
		video: [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm", ".m4v"],
		audio: [".mp3", ".wav", ".flac", ".aac", ".m4a", ".ogg", ".wma", ".alac"],
		table: [".json", ".csv", ".xlsx", ".xls"],
	}
	for (const [key, value] of Object.entries(fileTypeExtensions)) {
		if (value.includes(`.${extension.toLowerCase()}`)) {
			if (key === "video" || key === "audio")
				return "/images/playable_asset.png"
			if (key === "font") return "/images/font_asset.png"
			if (key === "table") return "/images/table_asset.png"
		}
	}
}

const generateThumbnail = (file) => {
	const fileSrc = URL.createObjectURL(file)
	setTimeout(() => URL.revokeObjectURL(fileSrc), 1000)
	return fileSrc
}

const remove = (index) => {
	files.value.splice(index, 1)
}

const onSubmit = () => {
	const formData = new FormData()
	const projectStore = useProjectStore()
	const project_id = projectStore.getActiveProject.slug
	const assetStore = useAssetStore()
	const toast = useToast()

	files.value.forEach((file) => {
		formData.append("asset[]", file.file)
		formData.append("asset_name[]", file.name)
	})

	const uploadRoute = route("dashboard.project.assets.store", {
		project: project_id,
		team: usePage().props.currentTeam.slug,
	})

	axios
		.post(uploadRoute, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				"X-Requested-With": "XMLHttpRequest",
			},
		})
		.then((response) => {
			response.data.forEach((asset) => assetStore.addAsset(asset))
			toast.success("Successfully uploaded assets")
		})
		.catch((errors) => {
			console.log(errors)
			toast.error(Object.values(errors).join("\n"))
		})

	files.value = []
}
</script>

<style scoped>
.main {
	display: flex;
	flex-grow: 1;
	align-items: center;
	height: 100vh;
	justify-content: center;
	text-align: center;
}
#dropzone-container {
	border: 1px dashed #222222;
	max-width: 650px;
	margin: auto;
	width: 100%;
}
#dropzone-container.dragging * {
	pointer-events: none;
}
.hidden-input {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
}
.file-label {
	font-size: 20px;
	display: block;
	cursor: pointer;
}
.preview-container {
	display: flex;
	margin-top: 2rem;
}
.preview-card {
	display: flex;
	border: 1px solid #a2a2a2;
	padding: 5px;
	margin-left: 5px;
}
.preview-img {
	width: 100%;
	border-radius: 5px;
	border: 1px solid #a2a2a2;
	background-color: #a2a2a2;
}
</style>
<style>
#dropzone-container .accordion-body {
	display: flex;
	flex-direction: column;
	gap: 16px;
	min-height: 70px;
}
#dropzone-container .accordion-header + .show {
	overflow-y: auto;
	min-height: 200px;
}
</style>
