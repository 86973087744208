<template>
	<component :is="tag" :class="wrapperClassName" @click.prevent="handleChange">
		<IconBadge
			:icon="localValue ? iconTrue : iconFalse"
			:class="inputClassName"
			:size="props.field.size ? props.field.size : 'sm'"
			:options="{ classList: [] }"
		/>
		<span v-if="props.field.label" :class="labelClassName" :for="uid">{{
			props.field.label
		}}</span>
	</component>
</template>

<script setup>
import IconBadge from "@/Components/IconBadge.vue"
import { computed, shallowRef } from "vue"

const MAX_UID = 1000000

const getUID = (prefix) => {
	do {
		prefix += Math.floor(Math.random() * MAX_UID)
	} while (document.getElementById(prefix))

	return prefix
}

const props = defineProps({
	id: {
		type: String,
		default: "",
	},
	inputClass: {
		type: String,
		default: "",
	},
	field: {
		type: Object,
		default: () => ({}),
	},
	labelClass: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	tag: {
		type: String,
		default: "button",
	},
	wrapperClass: {
		type: String,
		default: "btn-floating",
	},
})

const emit = defineEmits(["update:modelValue"])

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = computed(() => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
})
const inputId = computed(() => {
	return props.field.id || props.field.name
})

const uid = inputId.value || getUID("MDBSwitch-")
const iconTrue = computed(() => {
	if (props.field.iconTrue) {
		return props.field.iconTrue
	} else {
		return "circle-check"
	}
})
const iconFalse = computed(() => {
	if (props.field.iconFalse) {
		return props.field.iconFalse
	} else {
		return "circle-xmark"
	}
})
const wrapperClassName = computed(() => {
	let size = props.field.size ? "btn-" + props.field.size : "btn-sm"
	let valueClassList = localValue.value
		? props.field.classListTrue
			? props.field.classListTrue
			: ["btn-outline-success"]
		: props.field.classListFalse
			? props.field.classListFalse
			: ["btn-infor"]
	return ["btn", "shadow-0", size, props.wrapperClass, ...valueClassList]
})
const inputClassName = computed(() => {
	return [props.inputClass]
})
const labelClassName = computed(() => {
	return ["form-check-label", props.labelClass]
})

function handleChange() {
	const newValue = !localValue.value
	fieldValue.value = newValue
	if (props.dataStore) {
		props.dataStore.setModelValue(
			props.field,
			newValue,
			!props.field.inlineUpdate ?? true,
		)
	} else {
		emit("update:modelValue", value)
	}
}
</script>
