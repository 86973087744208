<template>
	<component
		:is="tag"
		ref="selectWrapperRef"
		v-mdb-click-outside="close"
		:class="className"
		v-bind="$attrs"
		@click="toggle"
		@keydown.enter.prevent="handleEnterAndSpace"
		@keydown.esc="close"
		@keydown.down.prevent.exact="handleArrowDown"
		@keydown.up.prevent.exact="handleArrowUp"
		@keydown.tab="handleTab"
		@keydown.alt.down="open"
		@keydown.alt.up="close"
		@keydown.space.prevent="handleEnterAndSpace"
		@keydown="onKeyDown"
	>
		<MDBInput
			:id="inputId"
			v-model="inputValue"
			label-class="select-label"
			class="select-input form-control form-select"
			:class="inputClassName"
			:label="label"
			:placeholder="placeholder"
			:disabled="disabled"
			:size="size"
			:aria-disabled="disabled"
			:aria-expanded="isDropdownActive"
			:aria-required="isValidated && required"
			:role="filter ? 'combobox' : 'listbox'"
			:tabindex="tabindex"
			aria-haspopup="true"
			readonly
			:white="white"
			:form-outline="false"
			wrapper-class="form-floating"
			:autocomplete="autocomplete"
			:style="!multiple ? getInputStyle(selectedOptions[0]) : {}"
		>
			<div class="valid-feedback">
				{{ validFeedback }}
			</div>
			<div class="invalid-feedback">
				{{ invalidFeedback }}
			</div>
			<span
				v-if="inputValue && clearButton"
				class="select-clear-btn"
				tabindex="0"
				@click.stop="clear"
				@keydown.enter.stop="clear"
				>✕</span
			>
		</MDBInput>
	</component>
	<div
		v-if="isDropdownActive"
		:id="dropdownId"
		ref="dropdownRef"
		class="select-dropdown-container text-start"
		:style="selectDropdownContainerStyle"
	>
		<div
			class="select-dropdown"
			:class="isPopperActive && 'open'"
			:tabindex="dropdownTabindex"
		>
			<div v-if="filter" ref="searchWrapperRef" class="input-group" @click.stop>
				<input
					ref="searchRef"
					class="form-control select-filter-input"
					:placeholder="searchPlaceholder"
					role="searchbox"
					type="text"
					@input="handleFilter"
					@keydown.enter.prevent="handleEnterAndSpace"
					@keydown.esc="close"
					@keydown.down.prevent="handleArrowDown"
					@keydown.up.prevent="handleArrowUp"
					@keydown.tab="handleTab"
					@touchstart.stop
				/>
			</div>
			<div
				ref="selectOptionsWrapperRef"
				class="select-options-wrapper"
				:style="selectOptionsWrapperStyle"
				@touchstart.stop
			>
				<div
					v-if="filter && filteredOptions.length === 0"
					class="select-no-results"
					:style="{ height: `${optionHeight}px` }"
				>
					{{ noResultsText }}
				</div>
				<div class="select-options-list">
					<div
						v-if="multiple && selectAll && search === ''"
						class="select-option"
						:style="{ height: `${optionHeight}px` }"
						:class="[
							activeOptionKey === -1 && 'active',
							areAllOptionsChecked && 'selected',
							isOptgroup ? 'select-option-group' : null,
						]"
						@click.stop="toggleSelectAll"
					>
						<span class="select-option-text">
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									:checked="areAllOptionsChecked"
									tabindex="-1"
								/>
								{{ selectAllLabel }}
							</div>
						</span>
					</div>
					<template v-for="option in filteredOptions" :key="option.value">
						<hr v-if="option.dividerTop" class="text-secondary-5" />
						<div
							class="select-option"
							:class="[
								option.disabled && 'disabled',
								activeOptionKey === option.value && 'active',
								option.selected && 'selected',
								isOptgroup && !option.optgroup ? 'select-option-group' : null,
								{ 'bg-primary-9': option.selected },
								...(option.classList ?? []),
							]"
							:style="getOptionStyle(option)"
							role="option"
							:aria-selected="option.selected"
							:aria-disabled="option.disabled || false"
							:hidden="option.hidden"
							@click.stop="handleOptionClick(option)"
						>
							<span v-if="multiple" class="select-option-text">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										:checked="option.selected"
										tabindex="-1"
										:disabled="option.disabled || false"
									/>
									{{ option.text }}
									<span
										v-if="option.secondaryText"
										class="select-option-secondary-text"
										>{{ option.secondaryText }}</span
									>
								</div>
							</span>
							<span v-else class="select-option-text">
								{{ option.text }}
								<span
									v-if="option.secondaryText"
									class="select-option-secondary-text"
									>{{ option.secondaryText }}</span
								>
							</span>
							<span
								v-if="option.icon"
								class="select-option-icon-container"
								:class="option.iconClassList ?? []"
							>
								<IconBadge :icon="option.icon" :options="{ classList: [] }" />
							</span>

							<button
								v-if="props.optionAppend"
								class="btn btn-floating btn-sm"
								:class="props.optionAppend.classList || []"
								@click.stop="onOptionAppendClick(option, key, $event)"
							>
								<MDBSpinner
									v-if="
										props.optionAppendsLoading &&
										props.optionAppendsLoading[option.value]
									"
									size="sm"
								></MDBSpinner>
								<IconBadge
									:icon="props.optionAppend.icon"
									:options="{ classList: [] }"
								/>
								<!-- spinner when it's loading -->
							</button>
						</div>
						<hr v-if="option.dividerBottom" class="text-secondary-5" />
					</template>
				</div>
			</div>
			<div
				v-if="$slots.default"
				class="select-custom-content"
				@click.stop
				@touchstart.stop
			>
				<slot></slot>
			</div>
		</div>
	</div>
	<div v-if="pills && multiple" class="d-flex flex-wrap gap-3 mt-2">
		<div
			v-for="(item, index) in modelSelected"
			:key="index"
			class="bg-secondary-3 rounded-pill py-1 px-3"
		>
			<span class="item-name fw-bold me-2">{{ renderPillText(item) }}</span>
			<IconBadge
				class="text-danger"
				icon="far.trash"
				:options="{ classList: [] }"
				@click.stop="handlePill(item)"
			/>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: "MDBSelectZ",
	inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, ref, watch, onBeforeMount, nextTick, PropType } from "vue"
import {
	MDBInput,
	MDBSpinner,
	mdbClickOutside as vMdbClickOutside,
} from "mdb-vue-ui-kit"
import MDBPopper from "./MDBPopper"
import IconBadge from "@/Components/IconBadge.vue"

export interface Option {
	text: string | number
	value?: string | number
	mdbKey?: number
	selected?: boolean
	optgroup?: boolean
	disabled?: boolean
	secondaryText?: string
	icon?: string
	hidden?: boolean
	sortOrder?: number
}

const props = defineProps({
	options: {
		type: Array as PropType<Option[]>,
		required: true,
	},
	selected: {
		type: [String, Array, Number] as PropType<
			string | number | { [props: string]: string | number | boolean }[]
		>,
		default: null,
	},
	preselect: {
		type: Boolean,
		default: true,
	},
	label: {
		type: String,
		default: "",
	},
	placeholder: {
		type: String,
		default: "",
	},
	disabled: Boolean,
	optionHeight: {
		type: Number,
		default: 38,
	},
	visibleOptions: {
		type: Number,
		default: 5,
	},
	optionsSelectedLabel: {
		type: String,
		default: "options selected",
	},
	displayedLabels: {
		type: Number,
		default: 5,
	},
	selectAll: {
		type: Boolean,
		default: true,
	},
	selectAllLabel: {
		type: String,
		default: "Select all",
	},
	required: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String,
		default: "md",
	},
	clearButton: {
		type: Boolean,
		default: false,
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	isValidated: {
		type: Boolean,
		default: false,
	},
	isValid: {
		type: Boolean,
		default: true,
	},
	validFeedback: {
		type: String,
		default: "",
	},
	invalidFeedback: {
		type: String,
		default: "",
	},
	filter: {
		type: Boolean,
		default: false,
	},
	searchPlaceholder: {
		type: String,
		default: "Search...",
	},
	noResultsText: {
		type: String,
		default: "No results",
	},
	filterDebounce: {
		type: Number,
		default: 300,
	},
	tag: {
		type: String,
		default: "div",
	},
	arrow: {
		type: Boolean,
		default: true,
	},
	autoSelect: {
		type: Boolean,
		default: false,
	},
	tabindex: {
		type: Number,
		default: 0,
	},
	white: {
		type: Boolean,
		default: false,
	},
	autocomplete: {
		type: String,
		default: "off",
	},
	filterFn: {
		type: Function as PropType<
			(data: Option[], searchValue: string) => Option[]
		>,
		default: null,
	},
	inputId: {
		type: String,
		default: "",
	},
	pills: {
		type: Boolean,
		default: false,
	},
	optionAppend: {
		type: [Object, undefined],
		default: undefined,
	},
	optionAppendsLoading: {
		type: [Object, undefined],
		default: undefined,
	},
})

const MAX_UID = 1000000

const getUID = (prefix: string) => {
	do {
		prefix += Math.floor(Math.random() * MAX_UID)
	} while (document.getElementById(prefix))

	return prefix
}

const renderPillText = (item) => {
	return (
		filteredOptions.value.find((option) => option.value == item)?.text ?? item
	)
}

const emit = defineEmits([
	"update:options",
	"update:selected",
	"update:modelValue",
	"change",
	"open",
	"opened",
	"close",
	"closed",
	"clear",
	"update:optionAppendsLoading",
	"optionAppendClicked",
])

// Class & styles ------------------------
const className = computed(() => {
	return [
		"select-wrapper",
		isSelectValidated.value && isSelectValid.value ? "is-valid" : "",
		isSelectValidated.value && !isSelectValid.value ? "is-invalid" : "",
	]
})
const inputClassName = computed(() => {
	return [
		isPopperActive.value && "focused",
		(modelSelected.value && !inputValue.value) || isPopperActive.value
			? "active"
			: null,
	]
})
const selectDropdownContainerStyle = computed(() => {
	return {
		width: dropdownWidth.value,
	}
})
const selectOptionsWrapperStyle = computed(() => {
	return {
		maxHeight: `${props.visibleOptions * props.optionHeight}px`,
	}
})

// Config ------------------------
const selectWrapperRef: any = ref(null)
const dropdownRef: any = ref(null)
const selectOptionsWrapperRef: any = ref(null)
const dropdownId = getUID("MDBSelectDropdown-")
const dropdownWidth = ref("200px")
const isDropdownActive: any = ref(false)
const activeOptionKey: any = ref(null)
let wasInitiated = false
const popperConfig = {
	placement: "bottom-start",
	eventsEnabled: true,
	modifiers: [
		{
			name: "offset",
			options: {
				offset: [0, 5],
			},
		},
	],
}

// Data ------------------------
const modelOptions = ref(props.options as Option[])
const filteredOptions: any = ref(modelOptions.value)
const modelSelected: any = ref(props.selected || []) // Initialize with props.selected
const isOptgroup = ref(modelOptions.value.some((option) => option.optgroup))

modelOptions.value.map((option: Option, key) => (option.mdbKey = key))

let lookupLastTime = 0
let lookupPrefix = ""
const dropdownTabindex = ref(0)

const getOptionStyle = (option: any) => {
	let style = {
		height: `${props.optionHeight}px`,
	}
	if (option.fontFamily) style.fontFamily = option.fontFamily
	return style
}
const getInputStyle = (option: any) => {
	let style = {}
	if (option?.fontFamily) style.fontFamily = option.fontFamily
	return style
}

// Popper ------------------------
const { setPopper, isPopperActive, closePopper, openPopper } = MDBPopper()

// Public methods ------------------------
const handlePill = (item) => {
	handleOptionClick(
		filteredOptions.value.find((option) => option.value == item),
	)
	emit("update:selected", modelSelected.value)
}
const toggle = () => {
	if (isPopperActive.value) {
		close()
	} else {
		open()
	}
}

const close = () => {
	if (!isPopperActive.value) {
		return
	}

	dropdownTabindex.value = -1
	closePopper()
	emit("close")
	setActiveOptionKey()
	setTimeout(() => {
		isDropdownActive.value = false
		search.value = ""
		filteredOptions.value = modelOptions.value
		emit("closed")
	}, 300)
}

const open = () => {
	if (props.disabled || isPopperActive.value) {
		return
	}

	dropdownTabindex.value = 0
	isDropdownActive.value = true
	setActiveOptionKey()
	nextTick(() => {
		openDropdown()
		emit("opened")
	})

	if (props.filter) {
		setTimeout(() => {
			initSearch()
			scrollToInput()
		}, 100)
	}
}

const selectedItemsArray = ref<(string | number | undefined)[]>([])
const setOption = (key: number) => {
	if (props.multiple) {
		if (key === -1) {
			return toggleSelectAll()
		}
		if (!modelOptions.value[key].disabled) {
			const option = modelOptions.value[key]
			const val = option.value

			if (option.selected) {
				// Deselect the option
				option.selected = false
				const index = selectedItemsArray.value.indexOf(val)
				if (index > -1) {
					selectedItemsArray.value.splice(index, 1)
					// Adjust the sortOrder for remaining items
					for (let i = index; i < selectedItemsArray.value.length; i++) {
						const opt = modelOptions.value.find(
							(opt) => opt.value === selectedItemsArray.value[i],
						)
						if (opt) {
							opt.sortOrder = i
						}
					}
				}
			} else {
				// Select the option
				option.selected = true
				option.sortOrder = selectedItemsArray.value.length
				selectedItemsArray.value.push(val)
			}
		}
	} else {
		modelOptions.value.forEach((option) => {
			option.selected = false
		})
		modelOptions.value[key].selected = true
	}
}

const clear = () => {
	modelOptions.value.forEach((option: Option) => {
		option.selected = false
	})
	activeOptionKey.value = null
	emit("clear")
}

const setValue = (request: number[] | number) => {
	clear()

	if (props.multiple && Array.isArray(request)) {
		request.forEach((val) => {
			const selectedKey = modelOptions.value.findIndex(
				(option) => option.value === val,
			)
			if (selectedKey >= 0) {
				setOption(selectedKey)
			}
		})
	} else {
		const selectedKey = modelOptions.value.findIndex(
			(option: Option) => option.value === request,
		)

		if (selectedKey >= 0) {
			setOption(selectedKey)
			close()
		}
	}
}

const toggleSelectAll = () => {
	const areAllOptionsSelected = areAllOptionsChecked.value
	modelOptions.value.forEach((option) => {
		!option.disabled && (option.selected = !areAllOptionsSelected)
	})
}

// Private methods ------------------------
const emitChangeEvents = () => {
	emit("update:selected", modelSelected.value)
	if (wasInitiated) {
		emit("change")
	} else {
		wasInitiated = true
	}
}

const setActiveOptionKey = () => {
	if (selectedOptions.value[0]) {
		activeOptionKey.value = filteredOptions.value.findIndex(
			(option) => option === selectedOptions.value[0],
		)

		if (props.multiple && props.selectAll && areAllOptionsChecked.value) {
			activeOptionKey.value = -1
		}
	}
}

const openDropdown = () => {
	if (!dropdownRef.value) {
		return
	}

	setPopper(selectWrapperRef.value, dropdownRef.value, popperConfig)
	openPopper()
	dropdownWidth.value = `${selectWrapperRef.value.offsetWidth}px`

	nextTick(() => scrollBottomToOption())

	emit("open")
}

const initSearch = () => {
	if (searchRef.value) {
		searchRef.value?.focus()
		searchHeight = searchWrapperRef?.value.offsetHeight
	}
}

const getScrollParent = (node: HTMLElement) => {
	if (node == null) {
		return null
	}

	if (node.scrollHeight > node.clientHeight) {
		return node
	} else {
		return getScrollParent(node.parentNode as HTMLElement)
	}
}

const scrollToInput = () => {
	if (!window) {
		return
	}

	const scrollableParent = getScrollParent(selectWrapperRef.value)

	if (window.innerWidth < 992 && scrollableParent) {
		const selectOffsetTop = selectWrapperRef.value.offsetTop
		scrollableParent.scrollTo({
			top: selectOffsetTop - 20,
			behavior: "smooth",
		})
	}
}

const setFirstNotDisabledOption = () => {
	if (
		!props.multiple &&
		props.preselect &&
		modelOptions.value.filter((option) => option.selected === true).length === 0
	) {
		const firstNotDisabledOption = modelOptions.value.findIndex(
			(option) => option.disabled !== true,
		)
		setOption(firstNotDisabledOption)
	}

	if (!props.preselect) {
		wasInitiated = true
	}
}

const handleOptionClick = async (option: any) => {
	if (option.disabled) {
		return
	}

	setOption(option?.mdbKey)
	if (!props.multiple) {
		close()
	} else {
		await nextTick()
		emit("closed")
	}
}

const setDefaults = () => {
	setFirstNotDisabledOption()
	setActiveOptionKey()
}

/**
 * sortOptions
 */
const sortOptions = (a, b) => {
	if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
		return a.sortOrder - b.sortOrder
	} else if (a.sortOrder !== undefined) {
		return -1
	} else if (b.sortOrder !== undefined) {
		return 1
	} else {
		return 0 // Default sorting behavior when sortOrder is undefined
	}
}

// Getters
const selectedOptions = computed(() => {
	/* eslint-disable */
	if (props.multiple) {
		modelSelected.value = modelOptions.value
			.filter((option) => option.selected === true)
			.sort(sortOptions)
			.map((option) => option.value)
	} else if (
		modelOptions.value.filter((value) => value.selected === true).length > 0
	) {
		modelSelected.value = modelOptions.value.filter(
			(value) => value.selected === true,
		)[0].value
	} else {
		modelSelected.value = ""
		return ""
	}

	return modelOptions.value.filter((value) => value.selected === true)
	/* eslint-enable */
})

const inputValue = computed(() => {
	if (!selectedOptions.value || selectedOptions.value.length === 0) {
		return ""
	} else if (selectedOptions.value.length === 1) {
		return selectedOptions.value[0].text
	} else if (props.multiple) {
		if (
			props.displayedLabels !== -1 &&
			selectedOptions.value.length > props.displayedLabels
		) {
			return `${selectedOptions.value.length} ${props.optionsSelectedLabel}`
		} else {
			const temp = [...selectedOptions.value]
			return temp
				.sort(sortOptions)
				.map((selectedOption) => selectedOption.text)
				.join(", ")
		}
	}
	return ""
})

const optionsNotDisabled = (options: Option[]) => {
	return options.filter((option) => option.disabled !== true)
}

const areAllOptionsChecked = computed(() => {
	if (
		props.multiple &&
		props.selectAll &&
		optionsNotDisabled(selectedOptions.value as Option[]).length ===
			optionsNotDisabled(modelOptions.value).length
	) {
		return true
	}
	return false
})

const activeEl = computed(() => {
	return dropdownRef.value?.querySelectorAll(".select-option")[
		activeOptionKey.value
	]
})

const isSelectAllVisible = computed(() => {
	if (props.multiple && props.selectAll && search.value === "") {
		return true
	}

	return false
})

const selectAllOptionHeight = computed(() => {
	if (isSelectAllVisible.value) {
		return props.optionHeight
	}

	return 0
})

const activeOptionOffsetTop = computed(() => {
	let offsetTop = 0

	if (activeEl.value) {
		offsetTop =
			activeEl.value.offsetTop + selectAllOptionHeight.value - searchHeight
	}

	return offsetTop
})

const optionListHeight = computed(() => {
	return props.visibleOptions * props.optionHeight
})

const isActiveOptionVisible = computed(() => {
	if (
		selectOptionsWrapperRef.value &&
		selectOptionsWrapperRef.value.scrollTop < activeOptionOffsetTop.value &&
		selectOptionsWrapperRef.value.scrollTop + optionListHeight.value >
			activeOptionOffsetTop.value
	) {
		return true
	}

	return false
})

// Keyboard accessibility ------------------------
const handleEnterAndSpace = () => {
	if (props.multiple) {
		if (isPopperActive.value && activeOptionKey.value === null) {
			close()
			focusInput()
		} else if (isPopperActive.value && activeOptionKey.value === -1) {
			setOption(-1)
		} else if (isPopperActive.value && activeOptionKey.value !== null) {
			setOption(filteredOptions.value[activeOptionKey.value].mdbKey)
		} else {
			open()
		}
	} else {
		if (isPopperActive.value) {
			if (activeOptionKey.value !== null) {
				setOption(filteredOptions.value[activeOptionKey.value].mdbKey)
			}
			close()
			focusInput()
		} else {
			open()
		}
	}
}

const handleTab = () => {
	if (
		props.autoSelect &&
		!props.multiple &&
		isPopperActive.value &&
		activeOptionKey.value !== null
	) {
		setOption(filteredOptions.value[activeOptionKey.value].mdbKey)
	}
	close()
}

const onKeyDown = (event: KeyboardEvent) => {
	if (props.disabled && !props.filter) {
		return
	}

	const lookupThreshold = 1000 // milliseconds
	const now = performance.now()
	if (now - lookupLastTime > lookupThreshold) {
		lookupPrefix = ""
	}
	lookupPrefix += event.key.toLowerCase()
	lookupLastTime = now

	const index = modelOptions.value.findIndex((item) => {
		const text = item.text.toString()

		return (
			text.toLowerCase().startsWith(lookupPrefix) &&
			!item.disabled &&
			!item.hidden
		)
	})

	if (index !== -1) {
		activeOptionKey.value = index
		scrollBottomToOption()
	}
}

const focusInput = () => {
	if (props.filter) {
		selectWrapperRef.value.querySelector(".select-input").focus()
	}
}

const handleArrowDown = () => {
	setNextActiveOptionKey()

	if (isDropdownActive.value) {
		scrollBottomToOption()
	}

	if (!props.multiple && !isDropdownActive.value) {
		setOption(activeOptionKey.value)
	} else if (props.multiple && !isDropdownActive.value) {
		open()
	}
}

const handleArrowUp = () => {
	if (activeOptionKey.value === null) {
		return
	}

	setPrevActiveOptionKey()

	if (isDropdownActive.value) {
		scrollTopToOption()
	}

	if (!props.multiple && !isDropdownActive.value) {
		setOption(activeOptionKey.value)
	} else if (props.multiple && !isDropdownActive.value) {
		open()
	}
}

const setNextActiveOptionKey = () => {
	let nextOptionKey = activeOptionKey.value

	if (activeOptionKey.value === null && isSelectAllVisible.value) {
		nextOptionKey = -1
	} else {
		nextOptionKey = filteredOptions.value.findIndex(
			(option, key) =>
				(key > nextOptionKey || nextOptionKey === null) &&
				!option.disabled &&
				!option.hidden,
		)

		if (nextOptionKey === -1) {
			return
		}
	}

	activeOptionKey.value = nextOptionKey
}

const setPrevActiveOptionKey = () => {
	let prevOptionKey = activeOptionKey.value

	if (activeOptionKey.value === 0 && isSelectAllVisible.value) {
		prevOptionKey = -1
	} else {
		prevOptionKey = filteredOptions.value.indexOf(
			filteredOptions.value
				.filter(
					(option, key) =>
						key < prevOptionKey && !option.disabled && !option.hidden,
				)
				.pop(),
		)

		if (prevOptionKey === -1) {
			return
		}
	}

	activeOptionKey.value = prevOptionKey
}

const scrollBottomToOption = () => {
	if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
		const offsetBottom =
			activeOptionOffsetTop.value - optionListHeight.value + props.optionHeight
		selectOptionsWrapperRef.value.scrollTo(0, offsetBottom)
	}
}

const scrollTopToOption = () => {
	if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
		selectOptionsWrapperRef.value.scrollTo(0, activeOptionOffsetTop.value)
	}
}

// Validation ------------------------
const isSelectValidated = ref(props.isValidated)
const isSelectValid = ref(props.isValid)

// Filtering ------------------------
const searchWrapperRef: any = ref(null)
const searchRef: any = ref(null)
const search = ref("")
let filterTimeout: any
let searchHeight = 0

const defaultFilterFn = (data: Option[], searchValue: string) =>
	data.filter((option) =>
		(option.text as string).toLowerCase().includes(searchValue.toLowerCase()),
	)

const handleFilter: any = (event: InputEvent) => {
	clearTimeout(filterTimeout)
	filterTimeout = setTimeout(() => {
		activeOptionKey.value = null
		const target = event.target as HTMLInputElement
		search.value = target.value

		const filterFn = props.filterFn ? props.filterFn : defaultFilterFn

		filteredOptions.value = filterFn(modelOptions.value, search.value)

		closePopper()
		openPopper()
	}, props.filterDebounce)
}

const onOptionAppendClick = async (option, key, event) => {
	event.stopPropagation()

	if (props.optionAppendsLoading && props.optionAppendsLoading[option.value]) {
		return
	}

	emit("update:optionAppendsLoading", {
		...props.optionAppendsLoading,
		[option.value]: true,
	})
	emit("optionAppendClicked", option)
}

// Hooks ------------------------
onBeforeMount(() => {
	if (modelOptions.value.length > 0) {
		setDefaults()
	}
})
// Watchers ----------------------
watch(
	() => props.options,
	(options: Option[]) => {
		modelOptions.value = options
		modelOptions.value.map((option, key) => (option.mdbKey = key))
		filteredOptions.value = modelOptions.value
	},
)

watch(
	() => modelSelected.value,
	() => emitChangeEvents(),
)

watch(
	() => props.isValidated,
	(value) => (isSelectValidated.value = value),
)

watch(
	() => props.isValid,
	(value) => (isSelectValid.value = value),
)

defineExpose({
	clear,
	close,
	open,
	setValue,
	setOption,
	toggle,
	toggleSelectAll,
})
</script>

<style scoped>
.select-options-wrapper {
	padding: 10px;
}
.select-options-list > *:nth-child(n + 2) {
	margin-top: 7px;
	margin-bottom: 0;
}
.select-option {
	padding: 10px;
	border-radius: 5px;
}
.select-option * {
	font-family: inherit;
}
</style>
