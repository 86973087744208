import { now } from "lodash"
import { DateTime } from "luxon"

export function formatTimestamp(timestamp, format = "F") {
	if (!timestamp) {
		return null
	}
	let dt

	if (format === null) {
		try {
			dt = DateTime.fromISO(timestamp, { zone: "utc" })
			if (dt.isValid) {
				return dt.toLocal().toLocaleString(DateTime.DATE_SHORT)
			}
		} catch (e) {}
	}

	try {
		dt = DateTime.fromISO(timestamp, { zone: "utc" })
		if (dt.isValid) {
			return dt.toLocal().toFormat(format)
		}
	} catch (e) {}

	// Fallback to epoch timestamp
	try {
		dt = DateTime.fromMillis(parseInt(timestamp, 10) * 1000, { zone: "utc" })
		if (dt.isValid) {
			return dt.toLocal().toFormat(format)
		}
	} catch (e) {}

	// List of possible timestamp formats to try
	const formats = [
		"yyyy-MM-dd'T'HH:mm:ss.SSSZZ",
		"yyyy-LL-dd HH:mm",
		"yyyy-MM-dd HH:mm:ss",
	]

	// Attempt to parse the timestamp using the known formats
	for (let i = 0; i < formats.length; i++) {
		try {
			dt = DateTime.fromFormat(timestamp, formats[i], { zone: "utc" })
			if (dt.isValid) {
				return dt.toLocal().toFormat(format)
			}
		} catch (e) {}
	}

	return null
}

export function formatLaravelTimestamp(timestamp, format = "F") {
	const dt = DateTime.fromFormat(timestamp, "yyyy-MM-dd HH:mm:ss", {
		zone: "utc",
	}).toLocal()
	return dt.toFormat(format)
}

export function timeSince(timestamp, format = "yyyy-MM-dd HH:mm:ss") {
	if (!timestamp) {
		return null
	}
	let endTime = DateTime.fromFormat(timestamp, format, { zone: "utc" })
	let nowTime = DateTime.now()
	let minutesBetweenDates = Math.floor(
		nowTime.diff(endTime, "minutes").toObject().minutes,
	)
	if (minutesBetweenDates < 1) {
		return "Just now"
	} else if (minutesBetweenDates < 60) {
		return `${minutesBetweenDates} minute${minutesBetweenDates > 1 ? "s" : ""} ago`
	} else if (minutesBetweenDates < 1440) {
		let hours = Math.floor(minutesBetweenDates / 60)
		return `${hours} hour${hours > 1 ? "s" : ""} ago`
	} else {
		let days = Math.floor(minutesBetweenDates / 1440)
		return `${days} day${days > 1 ? "s" : ""} ago`
	}
}

export function minutesUntil(timestamp, format = "yyyy-MM-dd HH:mm:ss") {
	let endTime = DateTime.fromFormat(timestamp, format, { zone: "utc" })
	let nowTime = DateTime.now()
	let minutesBetweenDates = endTime.diff(nowTime, "minutes").toObject().minutes
	return Math.ceil(minutesBetweenDates)
}

export function formatSecondsToDuration(seconds) {
	const parsedSeconds = parseFloat(seconds)
	if (isNaN(parsedSeconds) || parsedSeconds < 0) {
		throw new Error("Input must be a non-negative number")
	}

	const ms = Math.floor((parsedSeconds % 1) * 1000)
	const totalSeconds = Math.floor(parsedSeconds)

	return {
		h: Math.floor(totalSeconds / 3600),
		m: Math.floor((totalSeconds % 3600) / 60),
		s: totalSeconds % 60,
		ms: ms,
	}
}

export function formatSecondsToDurationString(seconds) {
	if (!seconds) {
		return ""
	}
	const { h, m, s } = formatSecondsToDuration(seconds)
	const parts = []

	if (h > 0) parts.push(`${h}h`)
	if (m > 0) parts.push(`${m}m`)
	if (s > 0 || parts.length === 0) parts.push(`${s}s`)

	return parts.join(" ")
}
