import { defineStore } from "pinia"
import { ref, watch } from "vue"

export const useTableStyle = (
	styleKey = "table_style_store",
	defaultValue = "table_view",
) => {
	let store = defineStore(styleKey, () => {
		const tableStyle = ref(getPersitedValue())

		function toggleStyle() {
			tableStyle.value =
				tableStyle.value === "table_view" ? "card_view" : "table_view"
		}
		watch(tableStyle, (value) => {
			localStorage.setItem("table_style" + styleKey, JSON.stringify(value))
		})
		function getPersitedValue() {
			let value = localStorage.getItem("table_style" + styleKey)
			if (value) {
				return JSON.parse(value)
			} else {
				return defaultValue
			}
		}
		return { toggleStyle, tableStyle }
	})
	return new store()
}
