// Utilities
import { init } from "@sentry/vue"
import { data } from "autoprefixer"
import { initial } from "lodash"
import { defineStore } from "pinia"
import { ref, watch } from "vue"
import { useAssetStore } from "@/Store/assetStore"

export const useDataModelStore = (storeName = "data_model_store") => {
	// let store = defineStore(uniqueStoreName, myStore)
	// return new store()
	// export const useDataModelStore =
	let store = defineStore(storeName, () => {
		const assetStore = useAssetStore()
		const dataModel = ref({})
		const storedState = ref({})
		const updatedFields = ref([])
		const fileToggle = ref(false)
		watch(
			() => assetStore.assetList,
			() => {
				const assetPreview = assetStore.updateAssetPreview(dataModel.value)
				setModelValue({ name: "asset_preview" }, assetPreview)
			},
		)
		let throttle = {}

		function initializeDataModel(initialState, form, setInitialState = false) {
			if (throttle[storeName]) return
			throttle[storeName] = true
			setTimeout(() => {
				delete throttle[storeName]
			}, 500)

			storedState.value = initialState
			if (setInitialState) {
				dataModel.value = initialState
			}
			if (initialState.tileData) {
				dataModel.value.tileData = initialState.tileData
			}
			if (initialState.parentDataModel) {
				dataModel.value.parentDataModel = initialState.parentDataModel
			}
			if (initialState.dashboard_assets) {
				dataModel.value.dashboard_assets = initialState.dashboard_assets
			}
			if (initialState.global_theme) {
				dataModel.value.global_theme = initialState.global_theme
			}
			if (initialState.global_settings) {
				dataModel.value.global_settings = initialState.global_settings
			}
			// To initialize the data model, we need to loop through the form, the form consists of of an array of objects,
			// each object has key 'cards', 'cards' is an array of objects, if object in array contains key "fieldsList",
			// loop through fieldslist and set each object that has key name to the datamodel with key as the datamodel key
			// if fieldslist object also has a key "column", the datamodel should store the value as an array with the column as the key
			// if fieldslist object also has a key "default", the datamodel should store the value as the default value unless storedState has a value for the given key
			// if a card has a key "tabsList", loop through the tabsList and do the same as the fieldsList
			form.forEach((tab) => {
				initializeTab(tab)
			})
		}
		function clearDataModel() {
			dataModel.value = {}
			storedState.value = {}
			updatedFields.value = []
			fileToggle.value = !fileToggle.value
		}

		function clearUpdated() {
			updatedFields.value = []
			fileToggle.value = !fileToggle.value
		}

		function initializeTab(tab) {
			tab.cards?.forEach((card) => {
				if (card.fieldsList) {
					card.fieldsList.forEach((field) => {
						initializeField(field)
					})
				} else if (card.tabsList) {
					card.tabsList.forEach((tab) => {
						initializeTab(tab)
					})
				} else if (card.cards) {
					initializeTab(card)
				} else if (card.listKey) {
					setNestedValue(
						card.listKey,
						getNestedValue(storedState.value, card.listKey) || [],
						dataModel.value,
					)
				}
			})
			if (tab.field) {
				let field = tab.field
				initializeField(field)
			}
		}
		function initializeField(field) {
			if (field.name) {
				let field_name = field.name
				if (field.column) {
					field_name = field.column + "." + field.name
				}
				let defaultValue =
					field.default ||
					(field.type === "boolean" || field.data_type == "boolean"
						? false
						: "")
				let retrieve_value = getNestedValue(storedState.value, field_name)
				let field_value
				if (
					typeof retrieve_value === "boolean" ||
					typeof retrieve_value === "number"
				) {
					field_value = retrieve_value
				} else {
					field_value = retrieve_value ?? defaultValue
				}

				const macroMap = {
					"{{user_timezone}}": () =>
						Intl.DateTimeFormat().resolvedOptions().timeZone,
				}
				if (field_value in macroMap) {
					field_value = macroMap[field_value]()
				}

				setNestedValue(field_name, field_value, dataModel.value)
			}
		}

		function getModelValue(field) {
			let useShortNotation = field.disableDotNotation ? false : true
			let field_name = field.name || false
			if (field.column) {
				field_name = field.column + "." + field_name
			}
			if (field_name) {
				return getNestedValue(dataModel.value, field_name)
			} else {
				return ""
			}
		}
		function setModelValue(field, value, addToUpdated = false) {
			try {
				if (field.name) {
					let field_name = field.name
					if (field.column) {
						field_name = field.column + "." + field.name
					}
					setNestedValue(field_name, value, dataModel.value)
					if (addToUpdated && !updatedFields.value.includes(field_name)) {
						updatedFields.value.push(field_name)
					}
				}
			} catch (e) {
				if (!field.hasOwnProperty("name"))
					console.error(
						'The following field does not have a "name" property: ',
						field,
					)
				else console.error(e)
			}
		}

		function getNestedValue(obj, path) {
			if (path.includes(".")) {
				let keys = path.split(".")
				let returnObj = obj
				let fileKey = keys[keys.length - 2]
				let fileExtension = keys[keys.length - 1]
				if (typeof returnObj === "object") {
					keys.forEach((key) => {
						if (!returnObj) return ""
						if (typeof returnObj === "object") {
							if (returnObj[key] === undefined) {
								returnObj = returnObj[fileKey + "." + fileExtension] ?? null
							} else {
								returnObj = returnObj[key]
							}
						}
					})
					return returnObj
				} else {
					return null
				}
			} else {
				return obj[path]
			}
		}
		function setNestedValue(path, value, objModel = undefined) {
			if (objModel == undefined) {
				objModel = dataModel.value
			}
			if (path.includes(".")) {
				let keys = path.split(".")
				let key = keys[0]
				keys.shift()
				if (!objModel[key]) {
					objModel[key] = {}
				}
				setNestedValue(keys.join("."), value, objModel[key])
			} else {
				objModel[path] = value
			}
			return objModel
		}
		// const conditionParamCheck = (conditionParams) => {
		// 	if (conditionParams != undefined) {
		// 		let conditionMet = false
		// 		conditionParams.every((element) => {
		// 			let compareValue = getModelValue({
		// 				column: element.column || null,
		// 				name: element.name || null,
		// 			})
		// 			let comparisonValue = element.value

		// 			switch (element.logic) {
		// 				case "==":
		// 					conditionMet = compareValue == comparisonValue
		// 					break
		// 				case "!=":
		// 					conditionMet = compareValue != comparisonValue
		// 					break
		// 				case ">":
		// 					conditionMet = compareValue > comparisonValue
		// 					break
		// 				case "<":
		// 					conditionMet = compareValue < comparisonValue
		// 					break
		// 				case ">=":
		// 					conditionMet = compareValue >= comparisonValue
		// 					break
		// 				case "<=":
		// 					conditionMet = compareValue <= comparisonValue
		// 					break
		// 				case "g0length":
		// 					conditionMet = compareValue.length > 0
		// 					break
		// 				case "n0length":
		// 					conditionMet = compareValue.length == 0
		// 					break
		// 			}
		// 			return conditionMet
		// 		})
		// 		return conditionMet
		// 	} else {
		// 		return true
		// 	}
		// }

		return {
			dataModel,
			storedState,
			fileToggle,
			initializeDataModel,
			initializeTab,
			getModelValue,
			setModelValue,
			clearDataModel,
			clearUpdated,
			updatedFields,
			getNestedValue,
			setNestedValue,
		}
	})
	return new store()
}
