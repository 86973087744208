<template>
	<div class="position-relative input-group">
		<MDBInput
			:id="inputId"
			:model-value="localValue"
			:label="label"
			:required="props.field.required || props.required"
			:type="props.type"
			autocomplete="off"
			:maxlength="
				props.field.maxLength
					? props.field.maxLength
					: props.maxLength
						? props.maxLength
						: 0
			"
			:readonly="props.field.readonly || props.readonly || false"
			:disabled="props.field.readonly || props.readonly || false"
			:class="props.field.classList || props.classList || []"
			:error-messages="errorMessages"
			:form-outline="false"
			wrapper-class="form-floating"
			@update:model-value="onTypeInput"
		/>
		<button
			class="input-group-prepend input-group-text"
			:class="props.field.buttonclassList || []"
			type="button"
			:disabled="props.field.disabled || false"
			@click.prevent="showScanner = true"
		>
			<FontAwesomeIcon :icon="lookupIcon('barcode-read', 'fas')" />
		</button>
		<MDBModal
			v-model="showScanner"
			dialog-classes="modal-dialog-scrollable modal-fullscreen-sm-down"
		>
			<div class="modal-body px-3">
				<QrcodeStream
					:formats="['qr_code', 'code_128']"
					@detect="onInput"
				></QrcodeStream>
			</div>
			<div class="modal-footer">
				<button
					type="button"
					class="btn btn-secondary rounded-pill"
					@click="showScanner = false"
				>
					Close
				</button>
			</div>
		</MDBModal>
	</div>
</template>

<script setup>
import { computed, defineModel, ref } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import IconBadge from "@/Components/IconBadge.vue"
import { Link } from "@inertiajs/vue3"
import { MDBInput, MDBModal } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: [String, Function],
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})
const showScanner = ref(false)
const inputId = computed(() => {
	return props.field.id || props.field.name
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})
let label = computed(() => {
	if (typeof props.field.label === "function") {
		return props.field.label(props.field, dataModel.value)
	} else if (typeof props.label === "function") {
		return props.label(props.field, dataModel.value)
	} else {
		return props.field.label || props.label
	}
})
const onInput = (detectedValue) => {
	let value = detectedValue[0].rawValue || "Invalid"

	if (props.field.transform && typeof props.field.transform === "function") {
		value = props.field.transform(value)
	}
	let fieldName = props.field.name || props.name || null
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
	showScanner.value = false
}
const onTypeInput = (textValue) => {
	let value = textValue

	let fieldName = props.field.name || props.name || null
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
	showScanner.value = false
}
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 100)
}
</script>

<style>
.arrows + div input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

.arrows + div input[type="number"]::-webkit-inner-spin-button,
.arrows + div input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
</style>
<style scoped>
.arrows {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 20px;
	height: 50%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.arrows > * {
	justify-self: center;
	align-self: center;
}
</style>
