<template>
	<div>
		<MDBDropdown
			v-model="dropdownVisible"
			v-mdb-click-outside="handleClickOutside"
			:popper-config="{ strategy: 'fixed' }"
			dropstart
			@update:model-value="handleToggle"
		>
			<MDBTooltip
				v-if="props.icon"
				v-model="iconTooltip"
				:options="{ strategy: 'fixed' }"
			>
				<template #reference>
					<MDBDropdownToggle
						color="secondaryz"
						size="sm"
						tag="button"
						class="btn-info btn-floating"
						@click.stop="toggleDropdown"
					>
						<FontAwesomeIcon :icon="lookupIcon(props.icon, 'fas')" />
					</MDBDropdownToggle>
				</template>
				<template #tip>
					{{ dropdownLabel }}
				</template>
			</MDBTooltip>
			<MDBDropdownToggle v-else @click.stop="toggleDropdown">
				<MDBBtn
					color="secondaryz"
					size="small"
					:floating="props.icon ? true : false"
					@click.stop="toggleDropdown"
				>
					<span
						>{{ dropdownLabel }}
						<FontAwesomeIcon :icon="lookupIcon('caret-down', 'fas')"
					/></span>
				</MDBBtn>
			</MDBDropdownToggle>
			<!-- <MDBDropdownToggle @click.stop="toggleDropdown">
				{{ dropdownLabel }}
			</MDBDropdownToggle> -->
			<MDBDropdownMenu aria-labelledby="dropdownMenuButton" class="col-6">
				<div class="dropdown-search p-2 fixed-top border-bottom bg-white">
					<input
						v-model="searchTerm"
						type="text"
						placeholder="Search..."
						class="form-control"
					/>
				</div>
				<div
					class="select-menu-items-wrapper overflow-auto mt-5 pt-2"
					:style="dropdownStyle"
				>
					<div
						v-for="item in filteredItems"
						:key="item[valueField]"
						href="#"
						class="dropdown-item d-flex px-3"
						@click.prevent="toggleSelection(item)"
					>
						<MDBCheckbox
							:model-value="isSelected(item)"
							:label="item[labelField]"
							@click.stop
							@change="toggleSelection(item)"
						/>
					</div>
				</div>
				<!-- no results text -->
				<div v-if="!filteredItems.length" class="no-data p-2">
					No results found...
				</div>
			</MDBDropdownMenu>
		</MDBDropdown>
	</div>
</template>

<script setup>
import {
	MDBCheckbox,
	MDBDropdown,
	MDBDropdownItem,
	MDBDropdownMenu,
	MDBDropdownToggle,
	MDBBtn,
	MDBTooltip,
	mdbClickOutside as vMdbClickOutside,
} from "mdb-vue-ui-kit"
import { ref, watch, defineProps, defineEmits, computed } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const emit = defineEmits(["update:modelValue"])

// Props
const props = defineProps({
	modelValue: {
		type: Array,
		default: () => [],
	},
	items: {
		type: Array,
		required: true,
	},
	valueField: {
		type: String,
		required: true,
	},
	labelField: {
		type: String,
		required: true,
	},
	label: {
		type: String,
		default: "",
	},
	icon: {
		type: String,
		default: "",
	},
	returnObject: {
		type: Boolean,
		default: false,
	},
	height: {
		type: String,
		default: "200px",
	},
})

// Data
const dropdownVisible = ref(false)
const selectedItems = ref([...props.modelValue])

// Watch for external changes to v-model and update our component accordingly
watch(
	() => props.modelValue,
	(newVal) => {
		selectedItems.value = [...newVal]
	},
	{ deep: true },
)

const searchTerm = ref("")
const iconTooltip = ref(false)

const filteredItems = computed(() => {
	if (!searchTerm.value) {
		return props.items
	}
	return props.items.filter((item) => {
		return (
			item[props.labelField]
				.toLowerCase()
				.includes(searchTerm.value.toLowerCase()) ||
			item[props.valueField]
				.toLowerCase()
				.includes(searchTerm.value.toLowerCase())
		)
	})
})

// Computed
const dropdownLabel = computed(() => {
	const selectedLabels = selectedItems.value.map(
		(item) => item[props.labelField],
	)
	let label = "Visible Columns"
	if (props.label) {
		label = props.label
	}

	return selectedLabels.length ? `${label} (${selectedLabels.length})` : label
})

// Methods
const toggleDropdown = () => {
	dropdownVisible.value = !dropdownVisible.value
}

const toggleSelection = (item) => {
	const itemIndex = selectedItems.value.findIndex(
		(selectedItem) =>
			(props.returnObject ? selectedItem[props.valueField] : selectedItem) ===
			item[props.valueField],
	)
	if (itemIndex > -1) {
		selectedItems.value.splice(itemIndex, 1)
	} else {
		selectedItems.value.push(props.returnObject ? item : item[props.valueField])
	}
	emit("update:modelValue", selectedItems.value)
}

const isSelected = (item) => {
	if (props.returnObject) {
		const itemValue = item[props.valueField]
		return selectedItems.value.some(
			(selectedItem) => selectedItem[props.valueField] === itemValue,
		)
	} else {
		return selectedItems.value.includes(item[props.valueField])
	}
}

const handleToggle = () => {
	// if it's false, set search to empty
	if (!dropdownVisible.value) {
		setTimeout(() => {
			searchTerm.value = ""
		}, 1000)
	}
}
const handleClickOutside = () => {
	// if it's false, set search to empty
	if (dropdownVisible.value) {
		dropdownVisible.value = false
	}
}
const dropdownStyle = ref({
	maxHeight: props.height,
})
</script>

<style scoped>
/* You can add your custom styles here */
.dropdown-toggle::before,
.dropdown-toggle::after {
	display: none;
}
.dropdown-menu {
	min-width: 250px;
}
</style>
