<template>
	<div>
		<p>
			Export has has successfully started. You will receive an email and
			notification when it is ready to download.
		</p>
		<!-- <p>
			It will be available
			<strong
				><Link class="text-secondary font-weight-bold" :href="getDownloadLink"
					>here</Link
				></strong
			>
		</p> -->
	</div>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3"
import { computed } from "vue"
import route from "ziggy-js"
const getDownloadLink = computed(() => {
	const project = usePage().props.project
	if (project && !Array.isArray(project) && project.slug) {
		return route("dashboard.project.exports.index", {
			project: project.slug,
			team: usePage().props.currentTeam.slug,
		})
	} else {
		return (
			route("dashboard.teams.account", {
				team: usePage().props.currentTeam.slug,
			}) + "#exports"
		)
	}
})
</script>

<style scoped></style>
