// Utilities
import { defineStore } from "pinia"
import { ref } from "vue"

export const useNavStore = defineStore("dashboard_navigation", () => {
	const pageTitle = ref("")
	const projectName = ref("")
	const pageSubNavigation = ref([])
	const activeComponent = ref("")
	const breadCrumbs = ref([])
	const bookmark = ref({})
	const showTabs = ref([])
	const currentTab = ref("")
	const pageTitleAppend = ref("")
	const topBreadcrumb = ref({})

	function setPageTitle(value, appendData = null) {
		this.pageTitle = value
		this.pageTitleAppend = appendData
	}
	function setProjectName(value) {
		this.projectName = value
	}
	function setBookmark(value) {
		this.bookmark = value
	}
	function setActiveComponent(value) {
		this.activeComponent = value
	}
	function setTopBreadcrumb(value) {
		this.topBreadcrumb = value
	}
	function setShowTabs(value) {
		this.showTabs = value
	}

	function setBreadCrumbs(value) {
		if (value instanceof Array && value.length > 0) {
			this.breadCrumbs = value
		} else {
			this.breadCrumbs = []
		}
	}
	function setPageSubNavigation(value) {
		if (value instanceof Array && value.length > 0) {
			this.pageSubNavigation = value
		} else {
			this.pageSubNavigation = []
		}
	}

	return {
		pageTitle,
		projectName,
		pageSubNavigation,
		breadCrumbs,
		bookmark,
		showTabs,
		currentTab,
		topBreadcrumb,
		setShowTabs,
		setPageTitle,
		setProjectName,
		setBookmark,
		setPageSubNavigation,
		activeComponent,
		setActiveComponent,
		setBreadCrumbs,
		setTopBreadcrumb,
		pageTitleAppend,
	}
})
