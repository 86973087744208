<template>
	<h6 v-if="props.field.label || props.label">
		{{ props.field.label || props.label }}
	</h6>
	<froala v-model:value="localValue" :config="config" :tag="'textarea'" />
	<div id="preview"></div>
</template>

<script setup>
import {
	computed,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
	watch,
} from "vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	height: {
		type: String,
		default: "80vh",
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const config = {
	events: {
		contentChanged: function () {
			$("#preview").html(this.html.get())
		},
	},
}

const emit = defineEmits(["update:modelValue"])

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = shallowRef(null)
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

// Watch for html model updates, everything else is handled by event listeners
watch(fieldValue, async (newContent, oldContent) => {
	onInput(newContent)
})

const onInput = (value) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, value, true)
	}
	localValue.value = value
	if (fieldValue.value !== undefined) {
		fieldValue.value = localValue.value
	}
	emit("update:modelValue", value)
}
onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>
