<template>
	<MDBProgress :height="20">
		<MDBProgressBar :value="localValue"> {{ localValue }}% </MDBProgressBar>
	</MDBProgress>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
} from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { Link } from "@inertiajs/vue3"
import { MDBProgress, MDBProgressBar, MDBRange } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})
</script>
