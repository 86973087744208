<template>
	<div :class="props.field.wrapperClassList || []">
		<AsyncSelector
			:model-value="localValue"
			:field="props.field"
			:filter="true"
			:class-list="props.field.selectorClassList"
			@update:model-value="onInput"
			@selected="selectionChanged"
		></AsyncSelector>
		<div
			v-if="!loading && fetchedItem && cardsList"
			:key="fetchedItem.id"
			class="mt-2"
		>
			<FormCard
				v-for="(card, index) in cardsList"
				:key="card.id"
				:class="props.field.classList || props.classList"
				:data-store="props.dataStore"
				:card="card"
			>
			</FormCard>
		</div>
		<!-- loading screen -->
		<div v-else-if="loading" class="text-center my-4">
			<MDBSpinner size="lg" class="" />
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	onMounted,
	defineProps,
	defineEmits,
	nextTick,
	computed,
	watch,
} from "vue"
import FormCard from "@/Components/Mod/FormCard.vue"
import { storeToRefs } from "pinia"
import AsyncSelector from "@/Components/Mod/AsyncSelector.vue"
import { MDBSpinner } from "mdb-vue-ui-kit"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})
const emit = defineEmits(["update:modelValue"])
const loading = ref(false)

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

const fieldValue = defineModel({
	type: String,
	default: "",
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value
})

const fetchedItem = ref(null)
const selectedItem = ref(null)
onMounted(() => {
	if (localValue.value) {
		fetchCards()
	}
})

const selectionChanged = (item) => {
	selectedItem.value = item
}

const cardsList = computed(() => {
	if (!fetchedItem.value) {
		return []
	}
	return fetchedItem.value[props.field.formColumn || "settings"]
})

watch(cardsList, (newVal) => {
	props.dataStore.initializeTab({ cards: newVal })
})

function fetchCards() {
	fetchedItem.value = null
	const routeKey = props.field.formRouteKey
	const targetRoute =
		typeof props.field.formRoute == "function"
			? props.field.formRoute(props.field, selectedItem.value)
			: route(props.field.formRoute, {
					[routeKey]: localValue.value,
					...props.field.formRouteParams,
				})

	if (!targetRoute) return
	loading.value = true

	http
		.get(targetRoute)
		.then((response) => {
			fetchedItem.value = response.data

			nextTick(() => {
				loading.value = false
			})
		})
		.catch((error) => {
			console.error("Error fetching options:", error)
			loading.value = false
		})
}

const onInput = (option) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, option, true)
	}
	emit("update:modelValue", option)

	fetchCards()
}
</script>

<style scoped>
.collapsible {
	overflow: hidden;
}
.collapsed {
	max-height: 0 !important;
}
.collapse-smooth {
	max-height: 100%;
	transition: 0.5s ease-in-out max-height;
}
</style>
