<template>
	<MDBRangeZ
		:id="inputId"
		:model-value="localValue"
		:label="props.field.label || props.label"
		:min="props.field.min ?? 1"
		:max="props.field.max ?? 0"
		:step="props.field.step ?? 0.01"
		wrapper-class="form-floating"
		@update:model-value="onInput"
	/>
	<div class="bottomLabelContainer text-secondary-10">
		<div class="min">{{ field.minLabel ?? field.min }}</div>
		<div class="max">{{ field.maxLabel ?? field.max }}</div>
	</div>
</template>

<script setup>
import { computed, defineModel } from "vue"
import MDBRangeZ from "@/Components/Mod/MDBOverride/MDBRangeZ.vue"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: [String, Number],
	default: 0,
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				0
})

const onInput = (value) => {
	let fieldName = props.field.name || props.name || null
	if (props.field.precision) {
		value = value.toFixed(props.field.precision)
	}
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
}
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 250)
}
</script>

<style scoped>
.bottomLabelContainer {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 1.5rem);
	left: 0.75rem;
}
.min {
	transform: translateX(-50%);
}
.max {
	transform: translateX(50%);
}
</style>
