<template>
	<div class="chart-label" :class="props.field.labelClassList">
		{{ props.field.label }}
	</div>
	<div ref="chart" :class="['custom-chart', 'bar-chart', ...classList]"></div>
</template>

<script setup>
import { bb, bar } from "billboard.js"
import "billboard.js/dist/billboard.css"
import "billboard.js/dist/theme/graph.css"
import { storeToRefs } from "pinia"
import { useNavStore } from "@/Store/navStore"

const navStore = useNavStore()

import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
	onBeforeUnmount,
	nextTick,
} from "vue"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const chart = ref(null)
const bbChart = ref(null)

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})

const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})

const generateChart = () => {
	if (!localValue.value) return
	bbChart.value = bb.generate({
		data: {
			columns: localValue.value || [],

			type: bar(),
			// onclick: props.field.onClick,
			// onover: props.field.onOver,
			// onout: props.field.onOut,
		},
		bar: {
			title: props.field.title,
		},

		bindto: chart.value,
	})
}

watch(
	() => navStore.currentTab,
	(newTab) => {
		nextTick(() => {
			generateChart()
		})
	},
)

onMounted(() => {
	generateChart()
	watch(
		() => localValue.value,
		(newData) => {
			if (newData) {
				bbChart.value.load({
					columns: newData,
				})
			}
		},
		{ deep: true },
	)
})

onBeforeUnmount(() => {
	if (bbChart.value) {
		bbChart.value.destroy()
	}
})
</script>

<style scoped></style>
