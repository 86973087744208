<template>
	<div class="input-group" :class="props.field.classList || []">
		<div class="select-input-wrapper">
			<MDBSelectZ
				ref="selectInput"
				v-model:selected="selectValue"
				v-model:options="options"
				:label="props.field.label"
				:required="props.field.required"
				:readonly="props.field.readonly || false"
				:preselect="false"
				:no-results-text="noResultsText"
				:placeholder="props.field.placeholder || 'Select an option'"
				class="form-floating"
				:option-height="options[0] && options[0].secondaryText ? 60 : 40"
				@update:selected="onSelect"
				@closed="onInput"
			>
			</MDBSelectZ>
			<div class="progress" :style="{ opacity: loading ? 1 : 0 }">
				<div
					class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					style="width: 100%"
				></div>
			</div>
		</div>

		<CreateDialog
			v-if="modalBasedProviders.includes(provider)"
			ref="createDialog"
			v-model="showDialog"
			:disable-tooltip="true"
			size="lg"
			:remove-backdrop="true"
			:form-definition="dialogConfig"
			@success="handleSuccessfulConnection"
		/>
		<button
			v-else
			class="input-group-append input-group-text btn-primary"
			type="button"
			@click="connect"
		>
			New Connection
		</button>
		<Link
			:href="
				route('dashboard.integration-tokens.index', {
					team: usePage().props.currentTeam?.slug,
				})
			"
			class="btn btn-secondary btn-sm mx-0"
			as="button"
		>
			<FontAwesomeIcon :icon="lookupIcon('cog', 'fas')" size="lg" />
		</Link>
	</div>
</template>

<script setup>
import {
	ref,
	onMounted,
	defineProps,
	defineEmits,
	computed,
	nextTick,
	inject,
	watch,
} from "vue"
import http from "@/Services/http"
import { useTranslator } from "@/Composables/useTranslator"
import { useProjectStore } from "@/Store/projectStore"
import { MDBProgress, MDBProgressBar } from "mdb-vue-ui-kit"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import { Link, usePage } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { useLegacyEFConnection } from "@/Composables/Models/legacyEFConnection"
import { useSwoogoConnection } from "@/Composables/Models/swoogoConnection"
import CreateDialog from "@/Components/Mod/CreateDialog.vue"
import route from "ziggy-js"
import { storeToRefs } from "pinia"

const page = usePage()

const props = defineProps({
	modelValue: {
		type: [String, Number, undefined, null],
		required: false,
		default: null,
	},
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: null,
	},
	errorMessages: {
		type: Array,
		required: false,
		default: () => [],
	},
})
const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})
const selectValue = ref(null)
const selectInput = ref(null)
const options = ref([])
const results = ref([])
const loading = ref(false)

const modalBasedProviders = ref(["eventfinity", "swoogo"])

async function fetchOptions() {
	loading.value = true
	try {
		let curRoute = route("dashboard.integration-tokens.index", {
			team: usePage().props.currentTeam?.slug,
		})

		const response = await http.get(curRoute, {
			params: {
				per_page: 500,
				provider: provider.value,
			},
		})
		const responseData = response.data.data
		options.value = responseData.map((item) => ({
			text: item.name,
			value: item.id,
			secondaryText: "Provider: " + item.provider_name,
		}))

		nextTick(() => {
			let currentSelection = localValue.value
			if (!currentSelection && options.value.length > 0) {
				onSelect(options.value[0].value)
				onInput()
				selectInput.value.setValue(options.value[0].value)
			} else {
				selectInput.value.setValue(localValue.value)
			}
			nextTick(() => {
				loading.value = false
			})
		})
	} catch (error) {
		console.error("Error fetching options:", error)
		loading.value = false
	}
}
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: props.field.content || fieldValue.value || props.field.default
})

onMounted(() => {
	selectValue.value = localValue.value
	fetchOptions()
	window.Echo.private(channelName).listen(
		"IntegrationStatusUpdate",
		handleMessageReceived,
	)
})

const provider = computed(() => {
	return props.field.provider(dataModel.value)
})

const toast = useToast()

const emit = defineEmits(["selected", "update:modelValue"])

const noResultsText = computed(() => {
	if (loading.value) {
		return "Loading..."
	}
	return "No connections available.. Connect a new one by clicking the 'New Connection' button"
})

const createDialog = ref(null)
const { fieldsList } = useLegacyEFConnection(props)
const { fieldsList: swoogoFieldsList } = useSwoogoConnection(props)
const showDialog = ref(false)

const connectionFormFactory = (provider) => {
	switch (provider) {
		case "eventfinity":
			return {
				type: "fields_list",
				fieldsList: fieldsList,
			}
		case "swoogo":
			return {
				type: "fields_list",
				fieldsList: swoogoFieldsList,
			}
		default:
			return {
				type: "fields_list",
				fieldsList: fieldsList,
			}
	}
}

const dialogConfig = computed(() => {
	const storeRoute = "dashboard.integration-tokens.store"

	return {
		tabsList: [
			{
				title: "Integration",
				tabId: "payment_methods",
				cards: [connectionFormFactory(provider.value)],
			},
		],
		create: {
			route: storeRoute,
			params: {
				team: usePage().props.currentTeam?.slug,
			},
			buttonText: "New Connection",
			buttonClassList: ["input-group-append", "input-group-text"],
		},
		transform: (data) => {
			return {
				...data,
				provider_name: provider.value,
				token_type: "bearer",
			}
		},
	}
})

let popupWindow = null
const connect = (evt) => {
	evt.preventDefault()
	const modalProviders = ["eventfinity", "swoogo"]

	if (provider.value == "cvent") {
		popupWindow = window.open(
			route("dashboard.auth.cvent.redirect"),
			"Cvent Oath",
			"width=600,height=600",
		)
	} else if (modalProviders.includes(provider.value)) {
		createDialog.value.openModal()
	}
}

// function emitValue() {
// 	if (loading.value) {
// 		return
// 	}
// 	emit("selected", selectValue.value)
// 	console.log("emitting: ", selectValue.value)
// 	emit("update:modelValue", selectValue.value)

// 	if (props.dataStore && props.field.name) {
// 		props.dataStore.setModelValue(
// 			{
// 				name: props.field.name,
// 				column: props.field.column || null,
// 			},
// 			selectValue.value,
// 			true,
// 		)
// 	}
// }
const onSelect = async (value) => {
	selectValue.value = value

	nextTick(() => {
		// onInput()
		if (loading.value) {
			return
		}
		const selectedOption = options.value.find(
			(option) => option[props.field.valueField || "id"] == selectValue.value,
		)
		emit("selected", selectedOption || null)
		emit("update:modelValue", selectedOption || null)
	})
}

const onInput = () => {
	let value = selectValue.value
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
			true,
		)
	} else {
		localValue.value = value
	}
	fieldValue.value = value
}

const channelName = `dashboard.integration_updated.admin.${
	usePage().props.auth?.user?.id
}`

const handleMessageReceived = async (e) => {
	if (e.metadata?.status == "success") {
		if (popupWindow) {
			popupWindow.close()
		}
		onSelect(e.integrationToken?.id)
		onInput()
		// localValue.value = e.integrationToken?.id || null
		await fetchOptions()
	} else if (e.metadata?.status == "error") {
		toast.error(e.metadata?.message || "Error connecting integration")
	} else {
		toast.info("Integration connection in progress")
	}
}

const handleSuccessfulConnection = async (e) => {
	showDialog.value = false
}
</script>

<style scoped>
@keyframes progressBarAnimation {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.progress-bar {
	animation: progressBarAnimation 2s linear infinite;
	background-size: 200% 100%;
}
.progress {
	height: 1px;
	transition: opacity 0.2s ease-in-out;
	z-index: 100;
	margin-top: -1px;
	width: calc(100% - 10px);
	margin-left: 5px;
	margin-right: 5px;
}

.select-input-wrapper {
	width: 100%;
}
.input-group :deep(input.form-control) {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
</style>
