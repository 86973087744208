<template>
	<MDBFile
		:id="inputId"
		:key="fileToggle"
		:label="props.field.label || props.label"
		:accept="props.field.accept || props.accept || []"
		:model-value="localValue"
		:readonly="props.field.readonly || props.readonly || false"
		:disabled="props.field.readonly || props.readonly || false"
		variant="solo-filled"
		:error-messages="errorMessages"
		@update:model-value="onInput"
	/>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
} from "vue"
import { MDBFile } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	accept: {
		type: Array,
		default: () => [],
	},
	base64: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const emit = defineEmits(["update:modelValue"])

const { fileToggle } = props.dataStore ? storeToRefs(props.dataStore) : false

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: [FileList, Array],
	default: undefined,
})
const localValue = ref(null)

const onInput = (value) => {
	if (props.base64) {
		const reader = new FileReader()
		let fileName = value[0].name
		reader.onload = (e) => {
			let result = e.target.result
			let mime = result.match(/:(.*?);/)[1]
			let base64 = result.replace(/^data:image\/\w+;base64,/, "")
			setValue({
				fileName: fileName,
				base64: base64,
				mime: mime,
			})
		}
		reader.readAsDataURL(value[0])
	} else {
		setValue(value)
	}
}
const setValue = (value) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(
			{
				column: props.field.column || null,
				name: props.field.name,
			},
			value,
			true,
		)
	}
	fieldValue.value = value
}
</script>
