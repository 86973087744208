<template>
	<label v-if="label" :for="id" class="form-label">
		{{ label }}
	</label>
	<VueDatePicker
		v-model="localValue"
		class="date-time-picker"
		time-picker-inline
		:minutes-increment="1"
		:minutes-grid-increment="1"
		:format="formatDate"
		text-input
		:ui="{
			input: 'form-control',
		}"
		utc
		:time-picker="props.type === 'time'"
		:enable-time-picker="props.type == 'datetime' || props.type == 'time'"
		:teleport="true"
		@update:model-value="handleUpdate"
	>
		<template #action-row="{ internalModelValue, selectDate }">
			<div
				class="action-row d-flex justify-content-between w-100 align-items-end"
			>
				<p class="current-selection small mb-0">
					{{ formatDate(internalModelValue) }}
				</p>
				<button
					class="btn btn-primary btn-sm"
					type="button"
					@click="selectDate"
				>
					Select
				</button>
			</div>
		</template>

		<template
			#dp-input="{
				value,
				onInput,
				onEnter,
				onTab,
				onClear,
				onBlur,
				onKeypress,
				onPaste,
				isMenuOpen,
			}"
		>
			<MDBInput
				:model-value="value"
				:class="props.field.classList || props.classList || []"
				:error-messages="errorMessages"
				:readonly="props.field.readonly || props.readonly || false"
				:required="props.field.required || props.required"
				:form-outline="false"
				wrapper-class="form-floating"
				@input="onInput"
				@blur="onBlur"
				@keypress="onKeypress"
				@keydown.enter="onEnter"
				@keydown.tab="onTab"
				@keydown.esc="onClear"
				@paste="onPaste"
			>
			</MDBInput>
		</template>
	</VueDatePicker>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
} from "vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import { DateTime } from "luxon"
import { MDBInput } from "mdb-vue-ui-kit"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "date",
	},
	name: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	default: {
		type: [String, Array, Object],
		default: "",
	},
	dateFormat: {
		type: String,
		default: "M/d/yy hh:mm a ZZZZ",
	},
	hoursFormat: {
		type: Number,
		default: 24,
	},
	disablePast: {
		type: Boolean,
		default: false,
	},
	increment: {
		type: Number,
		default: null,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
	inputFormat: {
		type: String,
		default: "yyyy-MM-dd HH:mm:ss",
	},
})
const label = computed(() => props.field.label ?? props.label)

const fieldValue = defineModel({
	type: String,
	default: "",
})

const localValue = ref(null)

const dataModelValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	let returnValue =
		props.dataStore && fieldName
			? props.dataStore.getModelValue({
					column: props.field.column || null,
					name: fieldName,
				})
			: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""

	if (!returnValue) {
		return null
	}

	let dt = DateTime.fromISO(returnValue, {
		zone: "utc",
	})

	if (!dt.isValid) {
		dt = DateTime.fromFormat(returnValue, props.field.inputFormat, {
			zone: "utc",
		})
	}

	switch (props.type) {
		case "time":
			return {
				hours: dt.hour,
				minutes: dt.minute,
				seconds: dt.second,
			}
		case "datetime":
			return dt.toFormat("F ZZZZ")
		case "date":
		default:
			return dt.toFormat("F ZZZZ")
	}
})

watch(
	dataModelValue,
	(newValue) => {
		if (newValue !== localValue.value) {
			console.log("newValue in date time picker", newValue)
			localValue.value = newValue
		}
	},
	{ immediate: true },
)

const handleUpdate = (newValue) => {
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			newValue,
			true,
		)
	}
}

/**
 * uses luxon to format the date
 * @param {Date} dateObj
 */
const formatDate = (dateObj) => {
	console.log("format Date: ", dateObj)
	if (dateObj) {
		let format
		switch (props.type) {
			case "time":
				format = "HH:mm"
				break
			case "datetime":
				format = "MM/dd/yy h:mm a ZZZZ"
				break
			case "date":
			default:
				format = "MM/dd/yy"
		}
		return DateTime.fromJSDate(dateObj).toFormat(format)
	}
	return ""
}
</script>

<style scoped>
.search-field > :deep(div) {
	height: 10px !important;
}
</style>
