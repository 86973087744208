// useAdvancedSearch.js
import { ref, reactive, computed, nextTick } from "vue"
import { useToast } from "@/Composables/useToast"
import { usePage } from "@inertiajs/vue3"
import http from "@/Services/http"
import DependencyTrackerErrorToast from "@/Components/DependencyTrackerErrorToast.vue"
export function useAdvancedSearch(initialFields, initialOptions) {
	const toast = useToast()
	const page = usePage()

	const fields = ref(initialFields)
	const options = ref(initialOptions)
	const searchGroups = ref([])
	const activeSavedSearchId = ref(null)
	const activeSavedSearch = ref(null)
	const itemToDelete = ref(null)
	const deleteModalOpen = ref(false)
	const createModalOpen = ref(false)
	const creatingSavedSearchLoading = ref(false)
	const updatingSearchInProgress = ref(false)
	const savedSearchKey = ref(0)
	const searchAsyncSelector = ref(null)
	const showSavedSearch = ref(true)
	const savedSearchAllowed = ref(
		initialOptions.disableSavedSearch ? false : true,
	)
	const savedSearchForm = reactive({
		name: "",
	})

	const formErrors = ref({})

	const asyncSelectorField = computed(() => ({
		route: "dashboard.project.saved-searches.index",
		routeParams: {
			project: page.props.project?.slug,
			searchable_type: options.value.model || null,
		},
		optionAppend: {
			icon: "trash",
			classList: "btn-danger btn-floating btn-sm shadow-0",
			delete: true,
			route: "dashboard.project.saved-searches.destroy",
			params: {
				project: "{{current_project}}",
			},
			key: "saved_search",
			confirmBodyText: "Are you sure you want to delete this saved search?",
		},
		filter: true,
		label: "Saved Search",
	}))

	const addGroup = () => {
		searchGroups.value.push({
			conditions: [{ field: null, operator: "=", value: null, logic: "and" }],
			logic: "and",
		})
	}

	const updateGroup = (groupIndex, updatedGroup) => {
		searchGroups.value[groupIndex] = updatedGroup
	}

	const removeGroup = (groupIndex) => {
		searchGroups.value.splice(groupIndex, 1)
	}

	const addCondition = (groupIndex) => {
		searchGroups.value[groupIndex].conditions.push({
			field: null,
			operator: "=",
			value: null,
			logic: "and",
		})
	}

	const removeCondition = (groupIndex, conditionIndex) => {
		searchGroups.value[groupIndex].conditions.splice(conditionIndex, 1)
	}

	const addNestedGroup = (groupIndex) => {
		searchGroups.value[groupIndex].conditions.push({
			conditions: [{ field: null, operator: "=", value: null, logic: "and" }],
			logic: "and",
		})
	}

	const handleSavedSearchSelected = (selected) => {
		activeSavedSearch.value = selected
		searchGroups.value = selected?.search_parameters
			? JSON.parse(JSON.stringify(selected.search_parameters))
			: []
	}

	const deleteSavedSearchClicked = (search, allResults) => {
		const fullItem = allResults.find((item) => item.id === search.value)
		itemToDelete.value = fullItem
		deleteModalOpen.value = true
	}

	const createNewSavedSearch = async () => {
		creatingSavedSearchLoading.value = true
		try {
			const projectSlug = page.props.project?.slug

			const endpoint = getSavedSearchRoute("store")

			const transformedData = {
				searchable_type: options.value.model,
				name: savedSearchForm.name,
				search_parameters: searchGroups.value,
				project_id: projectSlug,
				generate_normal_group: savedSearchForm.generate_normal_group,
				generate_dynamic_group: savedSearchForm.generate_dynamic_group,
			}

			const response = await http.post(endpoint, transformedData)

			if (response.status === 200 || response.status === 201) {
				toast.success("Saved Search Created")
				createModalOpen.value = false
				savedSearchForm.name = ""
				activeSavedSearch.value = response.data.savedSearch || null
				activeSavedSearchId.value = response.data.savedSearch?.id || null
				showSavedSearch.value = false
				// force re-render of async selector to update the options

				nextTick(() => {
					showSavedSearch.value = true
				})
			} else {
				toast.error("Error creating saved search")
			}
		} catch (err) {
			toast.error(err.message)
			if (err.response?.data?.errors) {
				formErrors.value = err.response.data.errors
			}
		} finally {
			creatingSavedSearchLoading.value = false
		}
	}

	const updateSavedSearch = async () => {
		updatingSearchInProgress.value = true

		try {
			const projectSlug = page.props.project?.slug

			const endpoint = getSavedSearchRoute("update", {
				saved_search: activeSavedSearch.value.id,
			})

			const transformedData = {
				searchable_type: options.value.searchable_type,
				name: activeSavedSearch.value.name,
				search_parameters: searchGroups.value,
				project_id: projectSlug,
			}

			const response = await http.put(endpoint, transformedData)

			if (response.status === 200 || response.status === 201) {
				toast.success("Saved Search Updated")
				showSavedSearch.value = false
				// force re-render of async selector to update the options

				nextTick(() => {
					showSavedSearch.value = true
				})
			} else {
				toast.error("Error updating saved search")
			}
		} catch (err) {
			if (err.response?.data?.errors?.locked_by_dependant) {
				const {
					type,
					name,
					edit_route,
					secondary_dependency_name,
					secondary_dependency_type,
				} = err.response.data.errors.locked_by_dependant
				toast.error(
					{
						component: DependencyTrackerErrorToast,
						props: {
							type,
							name,
							editRoute: edit_route,
							secondaryDependencyName: secondary_dependency_name,
							secondaryDependencyType: secondary_dependency_type,
						},
					},
					{
						timeout: 30000,
					},
				)
			} else {
				toast.error(
					err.response?.data?.message || "Error updating saved search",
				)
				if (err.response?.data?.errors) {
					formErrors.value = err.response.data.errors
				}
			}
		} finally {
			updatingSearchInProgress.value = false
		}
	}

	const getSavedSearchRoute = (actionName, params) => {
		const slug = usePage().props.project?.slug
		const teamSlug = usePage().props.currentTeam?.slug

		if (slug) {
			return route(`dashboard.project.saved-searches.${actionName}`, {
				project: slug,
				team: teamSlug,
				...params,
			})
		} else {
			return route(`dashboard.saved-searches.${actionName}`, {
				team: teamSlug,
				...params,
			})
		}
	}

	return {
		fields,
		options,
		searchGroups,
		activeSavedSearchId,
		activeSavedSearch,
		itemToDelete,
		deleteModalOpen,
		createModalOpen,
		creatingSavedSearchLoading,
		updatingSearchInProgress,
		savedSearchForm,
		formErrors,
		asyncSelectorField,
		addGroup,
		updateGroup,
		removeGroup,
		addCondition,
		removeCondition,
		addNestedGroup,
		handleSavedSearchSelected,
		deleteSavedSearchClicked,
		createNewSavedSearch,
		updateSavedSearch,
		searchAsyncSelector,
		showSavedSearch,
		savedSearchAllowed,
	}
}
