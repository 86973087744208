<template>
	<MDBTooltip
		v-model="tooltip"
		class="filter-dialog-tooltip"
		offset="0,5"
		tabindex="-1"
		:disabled="!props.options.disableTooltip"
		direction="left"
	>
		<template #reference>
			<MDBBtn
				color="outline-primary"
				:floating="
					!filterButtonText && (props.filterDefinition?.floating ?? true)
				"
				size="sm"
				type="button"
				class="shadow-0 filter-dialog-btn m-0"
				:class="[
					{
						'btn-rounded': filterButtonText,
					},
					props.filterDefinition?.classList || [],
				]"
				test-id="filter-dialog-btn"
				@click.prevent="openModal"
			>
				<span v-if="filterButtonText" class="filter-btn-text">
					<FontAwesomeIcon
						v-if="props.filterDefinition.buttonIconLeft"
						:icon="lookupIcon(props.filterDefinition.buttonIconLeft, 'far')"
					/>
					{{ filterButtonText }}
					<FontAwesomeIcon
						v-if="props.filterDefinition.buttonIconRight"
						:icon="lookupIcon(props.filterDefinition.buttonIconRight, 'far')"
					/>

					<!-- carrot up when open, down when closed -->
					<FontAwesomeIcon
						v-if="dialogComputed"
						:icon="lookupIcon('caret-up', 'fas')"
					/>
					<FontAwesomeIcon v-else :icon="lookupIcon('caret-down', 'fas')" />
				</span>
				<FontAwesomeIcon v-else :icon="defaultIcon" />
				<MDBBadge v-if="filterCount > 0" notification color="primary" pill>{{
					filterCount
				}}</MDBBadge>
			</MDBBtn>
		</template>
		<template #tip>
			{{ props.filterDefinition?.tooltip ?? "" }}
		</template>
	</MDBTooltip>

	<Teleport :to="containerEl">
		<MDBModal
			v-model="dialogComputed"
			dialog-classes="modal-dialog-scrollable modal-fullscreen-sm-down"
			:size="props.options.size || 'lg'"
			:remove-backdrop="!props.options.removeBackdrop"
		>
			<MDBModalTitle v-if="props.filterDefinition?.title">
				{{ props.filterDefinition?.title }}
			</MDBModalTitle>
			<MDBModalBody class="p-4">
				<form ref="formRef" @submit.prevent="applyFilters">
					<div class="position-absolute top-0 end-0 p-3">
						<button
							class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary shadow-0"
							type="button"
							@click.prevent="dialogComputed = false"
						>
							<FontAwesomeIcon :icon="lookupIcon('xmark', 'fat')" size="lg" />
						</button>
					</div>

					<div class="text-center">
						<FontAwesomeIcon
							v-if="props.filterDefinition?.icon"
							:icon="lookupIcon(props.filterDefinition.icon, 'fas')"
							class="h1 text-primary"
						/>
						<h4 v-if="props.filterDefinition?.title" class="text-center mt-3">
							{{ props.filterDefinition.title }}
						</h4>
						<div class="row">
							<FormCard
								v-for="(card, j) in props.filterDefinition?.cards"
								:key="j"
								:card="card"
								:data-store="filterStore"
							>
								<template #title>
									<MDBCardTitle class="d-flex justify-content-between">
										<div class="card-title-text">{{ card.title }}</div>
									</MDBCardTitle>
								</template>
							</FormCard>
						</div>
					</div>

					<div class="modal-footer border-0 px-0 d-flex justify-content-end">
						<button
							type="submit"
							class="btn btn-primary btn-sm rounded-pill"
							:disabled="processing"
						>
							Apply
						</button>
					</div>
				</form>
			</MDBModalBody>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import {
	ref,
	computed,
	onMounted,
	watch,
	nextTick,
	inject,
	onUnmounted,
} from "vue"
import { useForm, router } from "@inertiajs/vue3"
import debounce from "lodash/debounce"
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBTooltip,
	MDBCardTitle,
	MDBModalTitle,
	MDBBadge,
} from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import FormCard from "@/Components/Mod/FormCard.vue"
import { useDataModelStore } from "@/Store/dataModelStore"
import { storeToRefs } from "pinia"
import mitt from "mitt"

const props = defineProps({
	filterDefinition: {
		type: Object,
		required: true,
	},
	modelValue: {
		type: Object,
		default: () => ({}),
	},
	standalone: {
		type: Boolean,
		default: true,
	},
	options: {
		type: Object,
		default: () => ({
			size: "lg",
			disableTooltip: false,
			removeBackdrop: false,
		}),
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})

const emit = defineEmits(["update:modelValue", "apply", "clear", "cancel"])

// Initialize data store with unique identifier
const currentTimestamp = Math.floor(Date.now() / 1000)
const filterStore = useDataModelStore(`filterStore-${currentTimestamp}`, {
	parentDataModel: props.dataStore?.dataModel?.value,
})

const { dataModel, updatedFields } = storeToRefs(filterStore)

const dialog = ref(false)
const tooltip = ref(false)
const formRef = ref(null)
const processing = ref(false)

const containerEl = computed(() => {
	return document.querySelector("body")
})
// Computed for v-model dialog state
const dialogComputed = computed({
	get: () => dialog.value,
	set: (value) => {
		dialog.value = value
		if (!value) {
			emit("cancel")
		}
	},
})

// Watch for external filter changes
// watch(
// 	() => props.modelValue,
// 	(newValue) => {
// 		if (newValue && Object.keys(newValue).length) {
// 			filterStore.initializeDataModel(newValue)
// 		}
// 	},
// 	{ deep: true },
// )

// Initialize filters
const initializeFilters = () => {
	if (props.filterDefinition.tabsList) {
		tabsList.value = props.filterDefinition.tabsList
	} else if (props.filterDefinition.cards) {
		tabsList.value = [{ cards: props.filterDefinition.cards }]
	}

	if (props.standalone) {
		const urlParams = new URLSearchParams(window.location.search)
		const filtersParam = urlParams.get("filters")

		let initValue = {}
		if (filtersParam) {
			initValue = JSON.parse(filtersParam)
			emit("update:modelValue", initValue)
		}

		const buttonText = urlParams.get("buttonText")
		if (buttonText) {
			finalButtonText.value = buttonText
		}

		filterStore.initializeDataModel(
			{ ...filters.value, parentDataModel: props.dataStore.dataModel },
			tabsList.value,
		)
	} else {
		filterStore.initializeDataModel(
			{ ...filters.value, parentDataModel: props.dataStore.dataModel },
			tabsList.value,
		)
	}
}
const tabsList = ref(null)
// Open modal handler
const openModal = () => {
	console.log(props.filterDefinition)
	initializeFilters()
	nextTick(() => {
		dialog.value = true
	})
}

// Clear filters
const clearFilters = () => {
	filterStore.clearDataModel()
	emit("update:modelValue", {})
	emit("clear")
	dialog.value = false
	if (props.standalone) {
		const url = new URL(window.location)
		url.search = ""
		router.visit(url.toString(), { preserveState: true })
	}
}

const filterCount = computed(() => {
	if (props.modelValue == null) return 0
	return props.modelValue.length
})

const defaultIcon = computed(() => {
	const weight = filterCount.value > 0 ? "fas" : "far"
	return lookupIcon("filter", weight)
})

// Apply filters with debounce
const debouncedApply = debounce(() => {
	processing.value = true
	if (filterButtonTextCandidate.value) {
		finalButtonText.value = filterButtonTextCandidate.value
	}
	if (props.standalone) {
		const url = new URL(window.location)
		const params = new URLSearchParams(url.search) // Use url.search to get existing params
		const hash = window.location.hash

		// Clear existing filters to prevent duplication
		params.delete("filters") // Clear existing filters

		// Set the new filters
		params.set("filters", JSON.stringify(props.modelValue))
		params.set("buttonText", finalButtonText.value)

		// Update the URL with new parameters
		url.search = params.toString() // Set the search parameters directly
		const newUrl = url.toString() + hash // Construct the new URL

		try {
			router.visit(newUrl, {
				preserveState: true,
				preserveScroll: true,
				onSuccess: () => {
					processing.value = false
				},
				onError: (errors) => {
					toast.error(Object.values(errors).join("\n"))
					processing.value = false
				},
			})
		} catch (err) {
			processing.value = false
			toast.error(err.message)
		}
	}
	processing.value = false
}, 500)

// Add this computed property
const filters = computed({
	get: () => {
		// Convert array of filter objects to flat object
		if (!props.modelValue || !Array.isArray(props.modelValue)) return {}
		return props.modelValue.reduce((acc, filter) => {
			acc[filter.field] = filter.value
			return acc
		}, {})
	},
	set: (newValue) => {
		// Convert flat object to array of filter objects
		const outputData = Object.entries(newValue).map(([field, value]) => ({
			field,
			operator: "=",
			value,
		}))
		emit("update:modelValue", outputData)
		emit("apply", outputData)
	},
})
// Update applyFilters to use the computed setter
const applyFilters = () => {
	processing.value = true

	const updatedData = {}
	// Use all dataModel fields if there are no updatedFields
	if (updatedFields.value.length === 0) {
		Object.keys(dataModel.value).forEach((field) => {
			if (
				dataModel.value[field] !== undefined &&
				dataModel.value[field] !== null &&
				dataModel.value[field] !== "" &&
				field !== "parentDataModel" &&
				field !== "dataModel"
			) {
				updatedData[field] = dataModel.value[field]
			}
		})
	} else {
		// Otherwise use only updated fields
		updatedFields.value.forEach((field) => {
			updatedData[field] = dataModel.value[field]
		})
	}
	filters.value = updatedData

	if (props.standalone) {
		debouncedApply()
	}

	dialog.value = false
	processing.value = false

	if (globalEventBus) {
		globalEventBus.emit("filters-changed")
		globalEventBus.emit("filter-applied", filters.value)
	}
}

const filterButtonText = computed(() => {
	return finalButtonText.value || props.filterDefinition.buttonText
})

const globalEventBus = inject("globalEventBus")

const filterButtonTextCandidate = ref(null)
const finalButtonText = ref(props.filterDefinition.buttonText || "")

const presetGroupId = computed(() => {
	return props.filterDefinition.presetGroupId
})

onMounted(() => {
	if (globalEventBus) {
		globalEventBus.on("presetSelected", (text) => {
			console.log("presetSelected", text)
			filterButtonTextCandidate.value = text
		})
	}

	if (presetGroupId.value && props.filterDefinition.defaultPresetSlug) {
		const slug = props.filterDefinition.defaultPresetSlug
		localStorage.setItem(`${presetGroupId.value}_preset_selection`, slug)
	}
	initializeFilters()

	// Only apply filters if we're in standalone mode and there's no filters parameter
	if (props.standalone) {
		const urlParams = new URLSearchParams(window.location.search)
		const filtersParam = urlParams.get("filters")

		if (!filtersParam) {
			nextTick(() => {
				applyFilters()
			})
		}
	}
})

onUnmounted(() => {
	if (globalEventBus) {
		globalEventBus.off("presetSelected")
	}
})

// Expose openModal method to parent
defineExpose({ openModal })
</script>

<style scoped>
.modal-footer {
	margin-top: 1rem;
}

.badge {
	position: absolute;
	left: calc(100% - 3px);
	top: calc(100% - 8px);
}
</style>
