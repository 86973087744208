<template>
	<Link
		:class="props.field.classList || props.classList || []"
		type="button"
		:disabled="props.field.disabled || props.disabled"
		:href="linkHref"
		:data="props.field.data || {}"
		:as="props.type == 'external_link' ? 'a' : 'button'"
		:method="props.field.method || props.method || 'get'"
	>
		<FontAwesomeIcon
			v-if="props.field.icon || props.icon"
			:icon="lookupIcon(props.field.icon || props.icon, 'fas')"
		/>
		<FontAwesomeIcon
			v-if="props.field.iconLeft || props.iconLeft"
			:icon="lookupIcon(props.field.iconLeft || props.iconLeft, 'fas')"
		/>
		{{ props.field.text || props.text }}
		<FontAwesomeIcon
			v-if="props.field.iconRight || props.iconRight"
			:icon="lookupIcon(props.field.iconRight || props.iconRight, 'fas')"
		/>
	</Link>
</template>

<script setup>
import { computed, defineModel, onMounted, shallowRef } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { Link } from "@inertiajs/vue3"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { storeToRefs } from "pinia"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	type: {
		type: String,
		default: "button",
	},
	text: {
		type: String,
		default: "",
	},
	href: {
		type: [Function, String],
		default: undefined,
	},
	method: {
		type: String,
		default: "get",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})
const { dataModel } = storeToRefs(props.dataStore)

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = shallowRef(null)
const linkHref = computed(() => {
	return props.field.href
		? typeof props.field.href === "function"
			? props.field.href(props.field, dataModel.value)
			: props.field.href
		: props.href
			? typeof props.href === "function"
				? props.href(props.field, dataModel.value)
				: props.href
			: null
})
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>
