// Helper function to get an object property by dot notation
export function getNestedProperty(obj, dotNotation) {
	if (!dotNotation) return null
	return dotNotation.split(".").reduce((o, i) => (o ? o[i] : undefined), obj)
}

// Helper function to set an object property by dot notation
export function setNestedProperty(obj, dotNotation, value) {
	const keys = dotNotation.split(".")
	keys.slice(0, -1).reduce((o, key, i, arr) => {
		if (!o[key] || o[key] === null) o[key] = {} // Added null check
		return o[key]
	}, obj)[keys.pop()] = value
}

export function constructObjectFromDotNotation(obj) {
	let result = {}

	for (let key in obj) {
		if (key.includes(".")) {
			setNestedProperty(result, key, obj[key])
		} else {
			result[key] = obj[key]
		}
	}

	return result
}
