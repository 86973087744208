<template>
	<div>
		<p>
			This item is locked and cannot be edited because it depends on the
			{{ props.type }}: <strong>{{ props.name }}</strong
			>. Please manage the {{ props.type }} before making changes here.
		</p>
		<p v-if="props.secondaryDependencyName">
			The {{ props.type }} is also locked by an underlying
			{{ props.secondaryDependencyType }}:
			<strong>{{ props.secondaryDependencyName }}</strong
			>.
		</p>
		<p>
			<strong>
				<Link class="font-weight-bold text-white" :href="props.editRoute">
					Manage {{ props.type }} Here
				</Link>
			</strong>
		</p>
	</div>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3"

const props = defineProps({
	editRoute: {
		type: String,
		required: true,
	},
	type: {
		type: String,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	secondaryDependencyName: {
		type: String,
		default: null,
	},
	secondaryDependencyType: {
		type: String,
		default: null,
	},
})
</script>

<style scoped></style>
