import { ref } from "vue"

/**
 * @param {*} props
 * @returns
 */
export const useDataTableSort = ({ props, emit }) => {
	const currentSort = ref({
		key: null,
		direction: null,
	})

	return {
		currentSort,
	}
}
