// useSearch.js
import { ref, watch, inject } from "vue"

import { debounce } from "lodash"
import { router } from "@inertiajs/vue3"
import { mergeUrlParams, getParam } from "@/Utils/urlParams"

/**
 * useSearch composable function.
 *
 * This composable function provides a reactive search functionality
 * that can be used across multiple components. It takes in a `props` object
 * and an `emit` function as parameters.
 *
 * It allows for 2 way binding of the search string, for example:
 *         <DataTable
 *           v-model:search="search"
 *       />
 *
 * @param {Object} props - The props object from the component.
 * @param {Function} emit - The emit function from the component.
 *
 * @returns {Object} - An object containing the reactive `searchProxy` ref.
 *
 * @example
 * import useSearch from './useSearch.js';
 * const { searchProxy } = useSearch(props, emit);
 */

export default function useSearch(props, emit) {
	const existingSearch = getParam("search")
	const searchProxy = ref(props.search || existingSearch)
	const previousSearch = ref(searchProxy.value)
	const searchBoxClosed = ref(true)

	const performSearch = (value) => {
		const queryParams = mergeUrlParams({ search: value })
		if (!props.searchRoute) {
			console.error("missing search route in datatable options prop")
			return
		}
		router.get(props.searchRoute, queryParams, {
			preserveState: true,
			replace: true,
		})
	}

	const debouncedSearch = debounce((value, callback) => {
		emit("update:search", value)
		if (!props.options.searchRoute) {
			console.error("missing search route in datatable options prop")
			return
		}
		callback(value)
		previousSearch.value = value
	}, 250)

	watch(
		() => props.search,
		(newSearchValue) => {
			searchProxy.value = newSearchValue
		},
	)
	return {
		searchProxy,
		searchBoxClosed,
		debouncedSearch,
		previousSearch,
	}
}
