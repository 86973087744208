<template>
	<MDBAlert
		v-model="newDataAvailable"
		class="data-table-refresh-alert"
		position="top-right"
		dismiss
		:autohide="false"
		color="primary"
	>
		{{ dialogText }}
		<MDBBtn
			color="text"
			class="btn-rounded ms-2"
			:disabled="fetchingData"
			size="sm"
			@click="onClick"
		>
			<template v-if="!fetchingData && !waitingForReload">Refresh</template>
			<template v-else>
				<FontAwesomeIcon size="sm" :icon="lookupIcon('sync', 'fas')" spin />
			</template>
		</MDBBtn>
	</MDBAlert>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { MDBAlert, MDBBtn } from "mdb-vue-ui-kit"
import { computed, onBeforeUnmount, watch } from "vue"
import { defineProps, onMounted, ref } from "vue"
import { debounce } from "lodash"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const emit = defineEmits(["refresh"])
const props = defineProps({
	options: {
		type: Object,
		default: () => ({}),
	},
	fetchingData: {
		type: Boolean,
		default: false,
	},
})
const showAlert = ref(false)
const newDataAvailable = ref(false)
const waitingForReload = ref(false)
const testVal = ref(false)

const channelConfig = computed(() => {
	return props.options.refreshConfig?.channelConfig || []
})

const dialogText = computed(() => {
	return props.options.refreshConfig?.dialogText || "New data available"
})

watch(
	() => props.fetchingData,
	(newVal, oldVal) => {
		if (newVal) {
			waitingForReload.value = true
		} else {
			setTimeout(() => {
				waitingForReload.value = false
			}, 500)
			newDataAvailable.value = false
		}
	},
)

const onClick = () => {
	waitingForReload.value = true
	emit("refresh")
}

const debouncedListener = debounce(() => {
	console.log("New data available")
	newDataAvailable.value = true
}, 5000) // Adjust the debounce delay as needed

onMounted(() => {
	testVal.value = true
	if (!channelConfig.value.length) {
		return
	}

	channelConfig.value.forEach((config) => {
		const channel = window.Echo.private(config.channelName)

		config.eventNames.forEach((eventName) => {
			console.log(
				`Listening for event ${eventName} on channel ${config.channelName}`,
			)
			channel.listen(eventName, debouncedListener)
		})
	})
})

onBeforeUnmount(() => {
	if (!channelConfig.value.length) {
		return
	}

	channelConfig.value.forEach((config) => {
		const channel = window.Echo.private(config.channelName)

		config.eventNames.forEach((eventName) => {
			channel.stopListening(eventName)
		})
	})

	newDataAvailable.value = false
})
</script>

<style lang="scss" scoped>
.data-table-refresh-alert {
	z-index: 10000;
}
</style>
