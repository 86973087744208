<template>
	<MDBSelect
		ref="selectInput"
		v-model:selected="selectValue"
		:label="props.field.label"
		:options="options"
		:required="props.field.required"
		variant="solo-filled"
		:readonly="props.field.readonly || false"
		:class="props.field.classList || []"
		@change="changeValue"
	>
	</MDBSelect>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, nextTick, watch } from "vue"
import axios from "axios"
import { MDBSelect } from "mdb-vue-ui-kit"
import { usePage } from "@inertiajs/vue3"

const props = defineProps({
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	dataStore: {
		type: Object,
		default: null,
	},
})

const fieldValue = defineModel({
	type: [String, Number, null],
	default: null,
})

const selectInput = ref(null)
const options = ref([])
const selectValue = ref(null)

const emit = defineEmits(["update:modelValue"])

const loading = ref(false)
watch(
	() => selectValue,
	(newVal) => {
		console.log("selectValue", newVal)
	},
)
const changeValue = (val) => {}
onMounted(() => {
	if (props.dataStore) {
		selectValue.value = computeFieldValue()
	} else {
		selectValue.value = fieldValue.value
	}
	fetchOptions()
})
const computeFieldValue = () => {
	if (props.field.name && props.dataStore) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content || props.field.default || ""
	}
}

async function fetchOptions() {
	console.log("fetchOptions for pvs")
	loading.value = true
	const curRoute = route("dashboard.plugins.pages.versions.index", {
		page: props.field.plugin_page_id,
		team: usePage().props.currentTeam.slug,
	})
	const response = await http.get(curRoute, {
		params: {
			tags: props.field.tags,
		},
	})

	options.value = response.data.map((item) => ({
		text: item["version"] + (!item["is_published"] ? " (Development)" : ""),
		value: item["id"],
		secondaryText: item["notes"],
	}))
	options.value.unshift({
		text: "Use Published Default",
		value: 0,
		secondaryText:
			"This plugin will always load the current published default set",
	})

	loading.value = false
	let currentSelection = selectValue.value
	nextTick(() => {
		loading.value = false
		selectInput.value.setValue(currentSelection)
	})
}

function emitValue() {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, selectValue.value, true)
	} else {
		emit("update:modelValue", selectValue.value)
	}
}
</script>
