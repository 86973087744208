import { useFeatureCheck } from "@/Composables/useFeatureCheck"
import { usePermissions } from "@/Composables/usePermissions"
const { isSuperAdmin, userCanAll } = usePermissions()
const { userHasAllFeatures } = useFeatureCheck()
export const checkFeaturesAndPermissions = (optionObject) => {
	if (
		isSuperAdmin() ||
		!optionObject ||
		(typeof optionObject == "object" &&
			optionObject.features == undefined &&
			optionObject.permissions == undefined)
	) {
		return true
	}

	try {
		const featureCheck = userHasAllFeatures(optionObject.features)
		const permissionCheck = userCanAll(optionObject.permissions)

		return featureCheck && permissionCheck
	} catch (error) {
		console.error("Error checking permissions and features: ", error)
		// this is only frontend validation, so we'll just
		// default to visible if there's an error
		return true
	}
}
