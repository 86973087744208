<template>
	<label v-if="props.field.label" :for="inputId" class="form-label">
		{{ props.field.label }}
	</label>
	<MDBTooltipZ
		v-if="props.field.tooltip?.length"
		v-model="tooltip"
		:options="{ strategy: 'fixed', placement: 'right-end' }"
		class="form-label cursor-pointer"
		:max-width="props.field.tooltipOptions?.maxWidth"
		direction="right"
	>
		<template #reference>
			<IconBadge icon="info-circle" :options="{ classList: ['ms-1'] }" />
		</template>
		<template #tip>{{ props.field.tooltip }}</template>
	</MDBTooltipZ>
	<MDBSelectZ
		ref="selectInput"
		v-model:selected="selectValue"
		v-model:options="options"
		:input-id="inputId"
		:filter="props.field.filter || false"
		:preselect="false"
		:multiple="props.multiple"
		:required="props.field.required"
		:pills="props.field.pills ?? false"
		:disabled="
			typeof props.field.readonly == 'function'
				? props.field.readonly(props.field, dataModel.value)
				: props.readonly || props.readonly || false
		"
		class="form-floating"
		:class="props.field.classList || []"
		:option-height="options[0] && options[0].secondaryText ? 60 : 40"
		@update:selected="onSelect"
		@closed="onInput"
	/>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	nextTick,
} from "vue"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"
import { storeToRefs } from "pinia"
import MDBTooltipZ from "../MDBOverride/MDBTooltipZ.vue"
import IconBadge from "@/Components/IconBadge.vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	dataStore: {
		type: Object,
		default: null,
	},
})
const tooltip = ref(false)
const { dataModel } = storeToRefs(props.dataStore)

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: [String, Number, Array, Object],
	default: "",
})

const selectInput = ref(null)

const options = ref([])

const selectValue = ref(null)
const initialOptions = computed(() => {
	if (typeof props.field.options === "function") {
		return props.field.options(dataModel.value ?? {})
	} else {
		return props.field.options || []
	}
})

const computeFieldValue = () => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: props.field.content || fieldValue.value || props.field.default
}

watch(
	computeFieldValue,
	(newValue) => {
		if (newValue !== selectValue.value) {
			selectValue.value = newValue
			nextTick(() => {
				selectInput.value?.setValue(newValue)
			})
		}
	},
	{ immediate: true },
)

const onSelect = (value) => {
	selectValue.value = value
}
const onInput = () => {
	let value = selectValue.value
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
			true,
		)
	}
	fieldValue.value = value
}

watch(
	initialOptions,
	(newValue, oldValue) => {
		// selectValue.value = computeFieldValue()
		// selectInput.value?.setValue(selectValue.value)
		if (Array.isArray(newValue)) {
			let oldKeys = oldValue ? oldValue.map((v) => v.value) : []
			let newKeys = newValue ? newValue.map((v) => v.value) : []
			let optsChanged = JSON.stringify(oldKeys) !== JSON.stringify(newKeys)
			if (optsChanged) {
				options.value = newValue
			}
		}
	},
	{ immediate: true },
)
</script>
