import {
	getNestedProperty,
	setNestedProperty,
	constructObjectFromDotNotation,
} from "@/Utils/dotNotationHelpers"

export const passConditionParams = (
	paramList,
	dataObject,
	groupLogic = "AND",
) => {
	let conditionMet = false
	if (groupLogic == "AND") {
		conditionMet = paramList.every((element) => {
			return testElement(element, dataObject)
		})
	} else {
		conditionMet = paramList.some((element) => {
			return testElement(element, dataObject)
		})
	}
	return conditionMet
}

export const testElement = (element, dataModel) => {
	let modelPathArray = []
	if (element.column) modelPathArray.push(...element.column.split("."))
	if (element.name) modelPathArray.push(...element.name.split("."))
	const modelPathDotNotation = modelPathArray.join(".")

	let modelValue
	if (dataModel && typeof dataModel === "object") {
		modelValue = getNestedProperty(dataModel, modelPathDotNotation)
	} else {
		modelValue = ""
	}

	let conditionPathArray = []
	if (element.column) conditionPathArray.push(...element.column.split("."))
	if (element.name) conditionPathArray.push(...element.name.split("."))
	const conditionPathDotNotation = conditionPathArray.join(".")

	let conditionValue
	if (element.value && typeof element.value === "object") {
		if (dataModel && typeof dataModel === "object") {
			conditionValue = getNestedProperty(dataModel, conditionPathDotNotation)
		} else {
			conditionValue = ""
		}
	} else {
		conditionValue = element.value ?? ""
	}

	switch (element.logic) {
		case "==":
			return modelValue == conditionValue
		case "!=":
			return modelValue != conditionValue
		case ">":
			return modelValue > conditionValue
		case "<":
			return modelValue < conditionValue
		case ">=":
			return modelValue >= conditionValue
		case "<=":
			return modelValue <= conditionValue
		case "in-array":
			return conditionValue.includes(modelValue)
		case "not-in-array":
			return !conditionValue.includes(modelValue)
		case "g0length":
			return modelValue?.length > 0
		case "n0length":
			return modelValue?.length == 0
		case "starts-with":
			return modelValue && modelValue?.startsWith(conditionValue)
		case "ends-with":
			return modelValue && modelValue?.endsWith(conditionValue)
	}
}

export const buildSubModel = (paramList, dataModel) => {
	const subModel = paramList.reduce((subModel, param) => {
		let pathArray = []
		if (param.column) pathArray.push(...param.column.split("."))
		if (param.name) pathArray.push(...param.name.split("."))
		const pathDotNotation = pathArray.join(".")
		setNestedProperty(
			subModel,
			pathDotNotation,
			getNestedProperty(dataModel, pathDotNotation),
		)
		return subModel
	}, {})
	return subModel
}

export const checkConditions = (modElement, dataModel) => {
	let subModel
	if (modElement.conditionParams?.length)
		subModel = buildSubModel(modElement.conditionParams, dataModel)
	else subModel = dataModel

	if (modElement.condition) {
		return modElement.condition(subModel)
	} else
		return passConditionParams(
			modElement.conditionParams,
			subModel,
			modElement.conditionGroupLogic,
		)
}
