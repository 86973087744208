<template>
	<component
		:is="props.grouped ? MDBBtnGroup : 'div'"
		class="form-button-group"
		:class="[
			{ formButtonGroupGrouped: props.grouped },
			...(props.classList ? props.classList : []),
		]"
	>
		<MDBBtn
			v-for="(button, index) in visibleButtons"
			:key="index"
			type="button"
			:color="button.color"
			:size="button.size"
			:disabled="props.disabled || button.disabled"
			:href="
				typeof button.href === 'function'
					? button.href(button, props.item)
					: button.href
			"
			:tag="button.type == 'external_link' ? 'a' : 'button'"
			:class="button.classList ? button.classList : []"
			@click="handleClicked(button, $event, index)"
		>
			<FontAwesomeIcon
				v-if="button.icon"
				:icon="lookupIcon(button.icon, 'fas')"
			/>
			{{ button.text }}
		</MDBBtn>

		<ConfirmDelete
			v-model="deleteModalOpen"
			v-model:confirm-input="confirmInput"
			:route="
				typeof confirmationData.route == 'function'
					? confirmationData.route(props.item)
					: confirmationData.route || null
			"
			:title="confirmationData.confirmTitle"
			:body-text="confirmationData.confirmBodyText"
			:button-text="confirmationData.confirmButtonText"
			:success-message="confirmationData.confirmSuccessMessage"
			:error-message="confirmationData.confirmErrorMessage"
			:confirm-text="confirmationData.confirmText"
			:requires-input-confirmation="confirmationData.requiresInputConfirmation"
			:confirm-deletion-text="confirmationData.confirmDeletionText"
			:delete-request-method="confirmationData.deleteRequestMethod"
			@success="onSuccess"
		/>
	</component>
</template>

<script setup>
import { MDBBtn, MDBBtnGroup } from "mdb-vue-ui-kit"
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"
import { ref } from "vue"
import route from "ziggy-js"
import { text } from "@fortawesome/fontawesome-svg-core"
import { router } from "@inertiajs/vue3"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { computed } from "vue"

const emit = defineEmits(["success"])

const props = defineProps({
	buttons: {
		type: Array,
		required: true,
		default: () => [],
	},
	grouped: {
		type: Boolean,
		required: false,
		default: false,
	},
	disabled: {
		type: Boolean,
		required: false,
		default: false,
	},
	classList: {
		type: Array,
		required: false,
		default: () => [],
	},
	item: {
		type: Object,
		required: false,
		default: () => null,
	},
})

const deleteModalOpen = ref(false)
const confirmationData = ref({})
const confirmInput = ref("")
const processingButtons = ref({})

const handleClicked = (button, event, index) => {
	processingButtons.value[button.text] = true
	if (button.type == "confirm_delete") {
		confirmDeleteClicked(button)
		return
	} else if (button.type == "event_emitter") {
		emit(button.eventName || "click", event.data, event)
	} else if (button.type != "external_link" && button.href) {
		let href =
			typeof button.href === "function"
				? button.href(button, props.item)
				: button.href

		router.visit(href, {
			method: button.method || "get",
			onSuccess: button.onSuccess || (() => {}),
			onError: button.onError || (() => {}),
		})
	}
}

const confirmDeleteClicked = (button) => {
	confirmationData.value = Object.assign({}, button)
	deleteModalOpen.value = true
}

const onSuccess = (evt) => {
	if (typeof confirmationData.value.onSuccess === "function") {
		confirmationData.value.onSuccess(evt)
	}
}

const visibleButtons = computed(() => {
	return props.buttons.filter((button) => {
		if (button.condition) {
			return button.condition(props.item)
		}
		return true
	})
})
</script>

<style scoped>
.btn-floating {
	padding-bottom: 6px;
	padding-top: 9px;
	text-align: center;
}
</style>
