<template>
	<label v-if="label" :for="id" class="form-label">
		{{ label }}
	</label>
	<MDBTooltipZ
		v-if="fieldOptions.tooltip?.length"
		v-model="tooltip"
		:options="{ strategy: 'fixed', placement: 'right-end' }"
		class="form-label cursor-pointer"
		:max-width="fieldOptions.tooltipOptions?.maxWidth"
		direction="right"
	>
		<template #reference>
			<IconBadge icon="info-circle" :options="{ classList: ['ms-1'] }" />
		</template>
		<template #tip>{{ fieldOptions.tooltip }}</template>
	</MDBTooltipZ>
	<div class="position-relative">
		<!-- Phone Number Input -->
		<MDBInputZ
			:id="inputId"
			:model-value="formattedPhoneNumber"
			:required="computedRequired"
			type="tel"
			autocomplete="tel"
			:readonly="
				typeof props.field.readonly == 'function'
					? props.field.readonly(props.field, dataModel.value)
					: props.field.readonly || props.readonly || false
			"
			:disabled="
				typeof props.field.readonly == 'function'
					? props.field.readonly(props.field, dataModel.value)
					: props.field.readonly || props.readonly || false
			"
			:class="props.field.classList || props.classList || []"
			:form-outline="false"
			wrapper-class="form-floating"
			:options="fieldOptions"
			:is-validated="props.isValidated"
			:is-valid="props.isValid"
			:invalid-feedback="props.invalidFeedback"
			placeholder="(555) 555-5555"
			@update:model-value="onPhoneInput"
		/>
	</div>
</template>

<script setup>
import { ref, computed, defineModel } from "vue"
import IconBadge from "@/Components/IconBadge.vue"
import MDBInputZ from "@/Components/Mod/MDBOverride/MDBInputZ.vue"
import { storeToRefs } from "pinia"
import MDBTooltipZ from "../MDBOverride/MDBTooltipZ.vue"
import { parsePhoneNumber, AsYouType } from "libphonenumber-js"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: [String, Function],
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Object,
		default: undefined,
	},
	isValidated: { type: Boolean },
	isValid: { type: Boolean },
	invalidFeedback: { type: String, default: undefined },
})

const fieldOptions = computed(() => props.options ?? props.field.options ?? {})
const tooltip = ref(null)

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})

const computedRequired = computed(() => {
	return typeof props.field.required === "function"
		? props.field.required(props.field, dataModel.value)
		: typeof props.required === "function"
			? props.required(props.field, dataModel.value)
			: props.field.required || props.required || false
})

const formattedPhoneNumber = computed({
	get() {
		let fieldName = props.field.name || props.name || null
		let value =
			props.dataStore && fieldName
				? props.dataStore.getModelValue({
						column: props.field.column || null,
						name: fieldName,
					})
				: fieldValue.value || ""

		if (!value) return ""

		try {
			// If no "+" prefix, assume US number
			const formatter = new AsYouType(value.startsWith("+") ? undefined : "US")
			return formatter.input(value)
		} catch {
			return value
		}
	},
	set(value) {
		try {
			// If no "+" prefix, assume US number
			const parsedNumber = parsePhoneNumber(
				value,
				value.startsWith("+") ? undefined : "US",
			)
			const saveValue = parsedNumber.format("E.164")

			let fieldName = props.field.name || props.name || null
			fieldValue.value = saveValue

			if (props.dataStore && fieldName) {
				setDataModel(
					{
						name: fieldName,
						column: props.field.column || null,
					},
					saveValue,
				)
			}
		} catch (error) {
			console.error("Error processing phone number:", error)
			// If parsing fails, store the raw value
			let fieldName = props.field.name || props.name || null
			fieldValue.value = value

			if (props.dataStore && fieldName) {
				setDataModel(
					{
						name: fieldName,
						column: props.field.column || null,
					},
					value,
				)
			}
		}
	},
})

const onPhoneInput = (value) => {
	try {
		formattedPhoneNumber.value = value
		return formattedPhoneNumber.value
	} catch (error) {
		console.error("Error processing phone number:", error)
		return value
	}
}

let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 100)
}
</script>

<style scoped>
/* Remove all styles as they were related to the select field */
</style>
