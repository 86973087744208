<template>
	<label v-if="props.field.label || props.label" :for="id" class="form-label">
		{{ props.field.label || props.label }}
	</label>
	<div
		:class="{
			'input-group': true,
		}"
	>
		<MDBInput
			:model-value="localValue"
			type="text"
			:readonly="true"
			:class="props.field.classList || props.classList || []"
			:form-outline="false"
			wrapper-class="form-floating"
		/>
		<MDBBtn
			class="btn btn-outline-primary"
			:ripple="{ color: 'primary' }"
			@click="copyToClipboard"
		>
			<FontAwesomeIcon :icon="lookupIcon('copy', 'fas')" />
		</MDBBtn>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
} from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { Link } from "@inertiajs/vue3"
import { MDBInput, MDBBtn, mdbClipboard as vMdbClipboard } from "mdb-vue-ui-kit"
import { useToast } from "@/Composables/useToast"
import { storeToRefs } from "pinia"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const toast = useToast()
const localValue = shallowRef(null)
const computeFieldValue = () => {
	if (props.field.value) {
		return props.field.value
	} else if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

const copyToClipboard = async () => {
	try {
		await navigator.clipboard.writeText(localValue.value)
		toast.success("Copied to clipboard")
	} catch (err) {
		console.error("Failed to copy text: ", err)
	}
}

onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>

<style scoped>
.btn.btn-outline-primary {
	margin-top: auto;
}
</style>
