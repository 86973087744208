<template>
	<form ref="form" class="col-12 form-panel" @submit.prevent="submit">
		<div class="row" :style="customGutters">
			<template v-if="!props.tab.draggable">
				<FormCard
					v-for="(card, j) in cardsList"
					:key="j"
					:card="card"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				>
					<template #title>
						<MDBCardTitle class="d-flex justify-content-between">
							<div class="card-title-text">{{ $t(card.title) }}</div>
							<MDBBtn
								v-if="props.tab.delete"
								floating
								class="float-right"
								@click.stop="deleteClicked(card)"
							>
								<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" />
							</MDBBtn>
						</MDBCardTitle>
					</template>
				</FormCard>
				<div v-if="props.tab.updateConfig !== undefined">
					<CustomDivider class="mb-4"></CustomDivider>
					<div class="text-end">
						<MDBBtn color="success" type="submit"> Save </MDBBtn>
					</div>
				</div>
			</template>
			<template v-else>
				<!-- draggable setup -->
				<draggable
					v-model="cardsList"
					:disabled="reorderInProgress"
					item-key="dragId"
					group="cards"
					ghost-class="ghost"
					handle=".drag-handle"
					:data-drag-target-data="props.tab.dragTargetData || ''"
					@end="reorderUpdate"
				>
					<template #item="{ element: row }">
						<FormCard
							:key="row.dragId"
							:card="row"
							:data-store="props.dataStore"
							drag-handle-class="drag-handle"
							:data-item="JSON.stringify(row.dragItemData)"
							@update-value="inputUpdate"
						>
							<template #title>
								<MDBCardTitle class="d-flex justify-content-between">
									<div class="card-title-text">{{ $t(row.title) }}</div>
									<MDBBtn
										v-if="props.tab.delete"
										floating
										class="float-right"
										@click.stop="deleteClicked(row)"
									>
										<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" />
									</MDBBtn>
								</MDBCardTitle>
							</template>
						</FormCard>
					</template>
				</draggable>
			</template>
			<div
				v-if="props.tab.createConfig !== undefined"
				class="form-panel-create"
			>
				<CustomDivider class="mb-4"></CustomDivider>
				<div class="text-end">
					<CreateDialog
						:form-definition="props.tab.createConfig"
						@success="handleCreateSuccess"
					></CreateDialog>
				</div>
			</div>
			<ConfirmDelete
				v-model="confirmDeleteModalOpen"
				:route="deleteRoute"
				:body-text="props.tab.delete?.modalBody"
				:title="props.tab.delete?.modalTitle"
				@success="handleDeleteSuccess"
			/>
		</div>
	</form>
</template>

<script setup>
import { usePermissionStore } from "@/Store/permissionStore"
import { usePermissions } from "@/Composables/usePermissions"
import { ref, computed, defineProps, defineEmits, watch, onMounted } from "vue"
import { router } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { useTranslator } from "@/Composables/useTranslator"
import FormCard from "@/Components/Mod/FormCard.vue"
import { useFormModel } from "@/Composables/useFormModel"
import CustomDivider from "@/Components/CustomDivider.vue"
import { MDBBtn, MDBCardTitle, MDBRow } from "mdb-vue-ui-kit"
import draggable from "vuedraggable"
import CreateDialog from "@/Components/Mod/CreateDialog.vue"
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import {
	setNestedProperty,
	getNestedProperty,
} from "@/Utils/dotNotationHelpers"

import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"

const props = defineProps({
	tab: {
		type: Object,
		default: () => ({}),
	},
	tabId: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})

const { dataModel, updatedFields } = storeToRefs(props.dataStore)

const toast = useToast()
const cardsList = computed(() => {
	if (!props.tab.cards && props.tab.name) {
		return dataModel.value[props.tab.name]
	}

	let showCards = props.tab.cards.filter(
		(item) => !(item && item.type === "carousel" && item.itemList.length === 0),
	)
	return showCards.map((card, i) => {
		card.cardId = i
		return card
	})
})
const submit = async () => {
	//clone object to prevent re-stringify
	if (!props.tab.updateConfig) {
		return
	}
	const data = Object.assign({}, dataModel.value)
	Object.keys(data).forEach((key, value) => {
		if (!updatedFields.value.includes(key)) {
			//remove all fields that are not updated
			delete data[key]
		} else if (key.includes(".")) {
			//save keys to array
			const split = key.split(".")
			data[split[0]] = {}
			data[split[0]][split[1]] = value
			delete data[key]
		}
	})
	//Maybe only do this when uploading files? patch doesnt work for uploading so need to spoof
	data["_method"] = "patch"
	let params = props.tab.updateConfig.update.params
	if (props.tab.updateConfig.update.key) {
		params = {
			[props.tab.updateConfig.update.key]: dataModel.value.id,
			...props.tab.updateConfig.update.params,
		}
	}
	router.post(route(props.tab.updateConfig.update.route, params), data, {
		onSuccess: (event) => {
			toast.success("Successfully updated data")
		},
		onError: (errors) => {
			toast.error(Object.values(errors).join("\n"))
		},
	})
}

const emit = defineEmits(["updateValue"])

const reorderInProgress = ref(false)
/**
 * Called when a user drags a data table row
 * @param {*} updates
 */
const reorderUpdate = (event) => {
	if (
		reorderInProgress.value ||
		(event.to == event.from && event.oldIndex == event.newIndex)
	) {
		return
	}
	reorderInProgress.value = true

	console.log("reorderUpdate", reorderInProgress.value)

	const itemJson = event.item.getAttribute("data-item")

	const item = JSON.parse(itemJson)
	console.log("drag event: ", event)

	const dragTargetPanelData = event.to.getAttribute("data-drag-target-data")
	const dragSourcePanelData = event.from.getAttribute("data-drag-target-data")
	console.log("target:", dragTargetPanelData, "source:", dragSourcePanelData)

	const data = {
		originalOrder: item[props.tab.reorder.key],
		oldIndex: event.oldIndex,
		newIndex: event.newIndex,
		targetPanelData: dragTargetPanelData,
		sourcePanelData: dragSourcePanelData,
	}
	try {
		let targetRoute
		if (typeof props.tab.reorder.route === "function") {
			targetRoute = props.tab.reorder.route(item, data)
		} else {
			targetRoute = route(props.tab.reorder.route, {
				[props.tab.reorder.key]: item.id,
				...props.tab.reorder.params,
			})
		}
		const transformedData = props.tab.reorder.transform
			? props.tab.reorder.transform(item, data)
			: data
		router.patch(targetRoute, transformedData, {
			preserveScroll: true,
			preserveState: true,
			onSuccess: (event) => {
				reorderInProgress.value = false
				toast.success("Successfully reordered data")
			},
			onError: (errors) => {
				reorderInProgress.value = false
				toast.error(Object.values(errors).join("\n"))
			},
		})
	} catch (err) {
		reorderInProgress.value = false
		console.error(err)
	}
}

const handleCreateSuccess = (newItem) => {
	// triggerCardUpdate(props.tabItem)
}

const confirmDeleteModalOpen = ref(false)
const toDeleteId = ref(null)
const deleteRoute = computed(() => {
	if (toDeleteId.value && props.tab.delete.route) {
		if (typeof props.tab.delete.route === "function") {
			const foundObject = props.tab.cards.find(
				(item) => item.deleteId === toDeleteId.value,
			)
			return props.tab.delete.route(foundObject)
		}

		return route(props.tab.delete.route, {
			[props.tab.delete.key]: toDeleteId.value,
			...props.tab.delete.params,
		})
	}
	return null
})

const deleteClicked = (row) => {
	toDeleteId.value = row.deleteId
	confirmDeleteModalOpen.value = true
}
const handleDeleteSuccess = () => {
	toDeleteId.value = null
	// triggerCardUpdate(props.tabItem)
}

const customGutters = computed(() => {
	let style = {}
	if (props.tab?.gutterY) style["--mdb-gutter-y"] = props.tab.gutterY
	if (props.tab?.gutterX) style["--mdb-gutter-x"] = props.tab.gutterX
	return style
})

const inputUpdate = (field) => {
	emit("updateValue", field)
}

{
	/* <ModObjectField
v-if="card.module" : field="field"
            : model="state.currentObjValues[card.module]" /> */
}
</script>

<style scoped></style>
