<template>
	<div
		class="group-container border rounded p-3 mt-3"
		:style="{ marginLeft: `${depth * 20}px` }"
	>
		<div class="group-header my-3">
			<select
				v-if="showLogic"
				v-model="localGroup.logic"
				class="form-control search-field"
				@change="emitGroupUpdate"
			>
				<option value="and">AND</option>
				<option value="or">OR</option>
			</select>
		</div>

		<div
			v-for="(condition, conditionIndex) in localGroup.conditions"
			:key="conditionIndex"
			class="condition-container"
		>
			<div
				v-if="!condition.conditions"
				class="condition-content row g-1 py-1 align-items-center"
			>
				<div class="col-md-3">
					<select
						v-model="condition.field"
						class="form-control search-field"
						@change="updateCondition(conditionIndex, condition)"
					>
						<option key="-1" disabled :value="null">Select a field...</option>
						<option
							v-for="field in fields"
							:key="field.slug"
							:value="field.slug"
						>
							{{ field.label }}
						</option>
					</select>
				</div>

				<div class="col-md-2">
					<select
						v-model="condition.operator"
						class="form-control search-field"
						@change="updateCondition(conditionIndex, condition)"
					>
						<option key="-1" disabled :value="null">
							Select an operator...
						</option>
						<option
							v-for="op in availableOperators(condition.field)"
							:key="op"
							:value="op"
						>
							{{ operatorDisplayNames[op] }}
						</option>
					</select>
				</div>

				<div class="col-md-5">
					<component
						:is="getFieldComponent(condition.field)"
						v-if="shouldShowValueField(condition.operator)"
						v-model="condition.value"
						class="search-field"
						:options="getOptions(condition)"
						v-bind="getFieldProps(condition.field)"
						@update:model-value="updateCondition(conditionIndex, condition)"
					></component>
				</div>

				<div class="col-md-2 d-flex justify-content-end">
					<select
						v-if="conditionIndex > 0 && localGroup.conditions.length > 1"
						v-model="condition.logic"
						class="form-control search-field flex-grow-1"
						@change="updateCondition(conditionIndex, condition)"
					>
						<option value="and">AND</option>
						<option value="or">OR</option>
					</select>
					<button
						:disabled="conditionIndex == 0 && localGroup.conditions.length == 1"
						class="btn btn-danger btn-sm btn-floating rounded-circle"
						@click="$emit('remove-condition', conditionIndex)"
					>
						<!-- trash -->
						<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" />
					</button>
				</div>
			</div>

			<AdvancedSearchGroup
				v-else
				:group="condition"
				:fields="fields"
				:options="options"
				:depth="depth + 1"
				:show-logic="conditionIndex > 1"
				@update:group="updateNestedGroup(conditionIndex, $event)"
				@remove-group="removeNestedGroup(conditionIndex)"
				@remove-condition="removeNestedCondition(conditionIndex, $event)"
				@add-condition="addNestedCondition(conditionIndex)"
				@add-nested-group="addNestedNestedGroup(conditionIndex)"
			/>
		</div>

		<div class="group-footer mt-2">
			<button
				class="btn btn-danger btn-sm btn-rounded"
				:disabled="groupNumber == 1 && isRootGroup"
				@click="$emit('remove-group')"
			>
				Remove Group
				<!-- trash -->
				<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" />
			</button>
			<button
				class="btn btn-primary btn-sm btn-rounded"
				@click="$emit('add-condition')"
			>
				Add Condition

				<!-- plus -->
				<FontAwesomeIcon :icon="lookupIcon('plus', 'fas')" />
			</button>
			<button
				class="btn btn-secondary btn-sm btn-rounded"
				:disabled="depth >= options.maxDepth - 1"
				@click="$emit('add-nested-group')"
			>
				Add Nested Group
				<!-- plus -->
				<FontAwesomeIcon :icon="lookupIcon('plus', 'fas')" />
			</button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import DateTimePickerField from "@/Components/Mod/FormFields/DateTimePickerField.vue"
import SelectField from "@/Components/Mod/FormFields/SelectField.vue"
import SwitchField from "@/Components/Mod/FormFields/SwitchField.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
const props = defineProps({
	group: {
		type: Object,
		required: true,
	},
	fields: {
		type: Array,
		required: true,
	},
	options: {
		type: Object,
		required: true,
	},
	depth: {
		type: Number,
		default: 0,
	},
	showLogic: {
		type: Boolean,
		default: false,
	},
	groupNumber: {
		type: Number,
		default: 1,
	},
})

const emit = defineEmits([
	"update:group",
	"remove-group",
	"remove-condition",
	"add-condition",
	"add-nested-group",
])

const localGroup = ref({ ...props.group })

watch(
	() => props.group,
	(newGroup) => {
		localGroup.value = { ...newGroup }
	},
	{ deep: true },
)

const isRootGroup = computed(() => props.depth === 0)

const defaultFieldProps = {
	text: {
		class: "",
	},
	number: {
		class: "",
	},
	time: {
		type: "time",
	},
	date: {
		type: "date",
	},
	datetime: {
		type: "datetime",
		hoursFormat: 12,
	},
	multi_select: { multiple: true },
	boolean: {},
}

const equalsOps = ["=", "!=", "is empty", "is not empty"]
const gtlsOps = [">", ">=", "<", "<="]
const textOps = ["starts with", "contains", "does not contain"]
const arrayOps = ["all in", "not all in", "any in", "not any in"]

const operatorDisplayNames = {
	"=": "is",
	"!=": "is not",
	">": "is greater than",
	">=": "is greater than or equal to",
	"<": "is less than",
	"<=": "is less than or equal to",
	in: "is in",
	"not in": "is not in",
	"all in": "contains all",
	"not all in": "doesn't contain all",
	"any in": "contains any",
	"not any in": "doesn't contain any",
	"starts with": "starts with",
	contains: "contains",
	"does not contain": "does not contain",
	"is empty": "is empty",
	"is not empty": "is not empty",
}

const operators = {
	default: [...equalsOps],
	multiSelect: [...equalsOps, ...arrayOps],
	text: [...equalsOps, ...textOps],
	email: [...equalsOps, ...textOps],
	number: [...equalsOps, ...gtlsOps],
	date: [...equalsOps, ...gtlsOps],
	datetime: [...equalsOps, ...gtlsOps],
	time: [...equalsOps, ...gtlsOps],
}

const opsThatDoNotRequestVal = {
	"is empty": true,
	"is not empty": true,
}

const opsThatHaveOptions = ["multi_select", "radio", "inline_radio", "select"]

const shouldShowValueField = (operator) => {
	return !opsThatDoNotRequestVal[operator]
}

const getFieldComponent = (fieldName) => {
	const field = props.fields.find((f) => f.slug === fieldName)
	if (!field) return TextField

	switch (field.type) {
		case "time":
		case "date":
		case "datetime":
			return DateTimePickerField
		case "select":
		case "multi_select":
		case "radio":
		case "inline_radio":
			return SelectField
		case "boolean":
		case "checkbox":
			return SwitchField
		case "number":
		case "textarea":
		case "text":
		case "email":
		case "link":
		default:
			return TextField
	}
}

const getFieldProps = (fieldName) => {
	const field = props.fields.find((f) => f.slug === fieldName)
	if (!field) return defaultFieldProps["text"] || {}
	return defaultFieldProps[field.type] || {}
}

const availableOperators = (fieldName) => {
	const field = props.fields.find((f) => f.slug === fieldName)
	if (!field) return operators.default

	// if type is set in operators, use it, otherwise default
	if (operators[field.type]) return operators[field.type]

	if (field.type === "multi_select") return operators.multiSelect

	return operators.default
}

const emitGroupUpdate = () => {
	emit("update:group", localGroup.value)
}

const updateCondition = (index, condition) => {
	localGroup.value.conditions[index] = { ...condition }
	emitGroupUpdate()
}

const updateNestedGroup = (index, updatedGroup) => {
	localGroup.value.conditions[index] = updatedGroup
	emitGroupUpdate()
}

const removeNestedGroup = (index) => {
	localGroup.value.conditions.splice(index, 1)
	emitGroupUpdate()
}

const removeNestedCondition = (groupIndex, conditionIndex) => {
	localGroup.value.conditions[groupIndex].conditions.splice(conditionIndex, 1)
	emitGroupUpdate()
}

const addNestedCondition = (groupIndex) => {
	localGroup.value.conditions[groupIndex].conditions.push({
		field: null,
		operator: "=",
		value: null,
		logic: "and",
	})
	emitGroupUpdate()
}

const addNestedNestedGroup = (groupIndex) => {
	localGroup.value.conditions[groupIndex].conditions.push({
		conditions: [{ field: null, operator: "", value: null }],
		logic: "and",
	})
	emitGroupUpdate()
}

const getOptions = (condition) => {
	const field = props.fields.find((f) => f.slug === condition.field)
	if (!field) return []

	if (opsThatHaveOptions.includes(field.type) && field.options) {
		return field.options
	}

	return null
}
</script>

<style scoped>
.search-field {
	height: 35px;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

::v-deep .search-field .form-control {
	height: 35px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

/* .condition-content {
	display: flex;
	align-items: center;
	gap: 10px;
}

.form-control,
.btn {
	margin-bottom: 5px;
}

@media (max-width: 767px) {
	.condition-content {
		flex-direction: column;
		align-items: flex-start;
	}
} */
</style>
