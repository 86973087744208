<template>
	<div class="tag-list-input">
		<form @submit.prevent="addItem">
			<MDBInput
				ref="textField"
				v-model="newItemField"
				:label="props.field.label || props.label"
				outlined
				dense
				:error-messages="errors"
				:placeholder="placeholder"
				class="tag-list-input"
				:hint="localHint"
				:type="type"
				validate-on="blur"
				input-group
				@update:model-value="onInput"
			>
				<MDBBtn v-if="buttonText" @click="addItem">{{ buttonText }}</MDBBtn>
				<MDBBtn v-else size="sm" class="shadow-0" @click="addItem">
					<FontAwesomeIcon :icon="lookupIcon('plus', 'fas')" />
				</MDBBtn>
			</MDBInput>
		</form>
		<MDBListGroup v-if="list" class="item-list-wrapper">
			<MDBListGroupItem v-for="item in localValue" :key="item">
				<div class="d-flex justify-space-between align-center w-100">
					<span>{{ item }}</span>
					<slot name="removeButton">
						<MDBBtn icon @click="removeItem(item)">
							<FontAwesomeIcon :icon="lookupIcon(removeIcon, 'fas')" />
						</MDBBtn>
					</slot>
				</div>
			</MDBListGroupItem>
		</MDBListGroup>
		<div
			v-else-if="localValue.length > 1 || hasSubmitted"
			class="chip-list-wrapper my-1"
		>
			<span
				v-for="(item, index) in localValue"
				:key="index"
				:size="size"
				class="badge badge-secondary mx-2"
			>
				{{ item }}

				<FontAwesomeIcon
					:icon="lookupIcon(removeIcon, 'fas')"
					role="button"
					tabindex="0"
					@click="removeItem(item)"
				/>
			</span>
		</div>
	</div>
</template>

<script setup>
import {
	MDBBtn,
	MDBChip,
	MDBInput,
	MDBListGroup,
	MDBListGroupItem,
} from "mdb-vue-ui-kit"
import {
	ref,
	watchEffect,
	watch,
	defineExpose,
	computed,
	shallowRef,
	onMounted,
} from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	/** used to v-model the selected items */
	input: {
		type: String,
		default: "",
	},
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	default: {
		type: Array,
		default: () => [],
	},
	/** shows errors from form validation */
	errors: {
		type: Array,
		default: () => [],
	},

	/** the text to display on the add button */
	buttonText: {
		type: [String],
		default: "",
	},
	label: {
		type: String,
		default: "Item",
	},

	/** the icon to show on the remove button */
	removeIcon: {
		type: String,
		default: "times",
	},
	placeholder: {
		type: String,
		default: "Add item",
	},
	list: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String,
		default: "default",
	},
	hint: {
		type: String,
		default:
			"Press enter on your keyboard or click the plus button to add another item. To add multiple items, separate them with a comma.",
	},
	type: {
		type: String,
		default: "text",
	},
	rules: {
		type: Array,
		default: () => [],
	},
	allowDuplicates: {
		type: Boolean,
		default: false,
	},
	allowListAdd: {
		type: Boolean,
		default: true,
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const fieldValue = defineModel({
	type: Array,
	default: () => [],
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value || props.default || []
})

const emit = defineEmits([
	/** the v-model update event */
	"update:modelValue",
	"update:input",
])

const localHint = ref(props.hint)

const textField = ref(null)

const newItemField = ref("")

const hasSubmitted = ref(false)

const addItem = async (event) => {
	// stop event propagation
	event.stopPropagation()
	event.preventDefault()
	const errors = await validate()
	if (errors.length > 0) {
		return
	}
	// textField.value.resetValidation()
	let itemList = localValue.value
	if (!itemList) {
		itemList = []
	}

	if (props.allowListAdd) {
		const newItems = newItemField.value.split(",")
		for (let i = 0; i < newItems.length; i++) {
			itemList.push(newItems[i].trim())
		}
	} else {
		itemList.push(newItemField.value)
	}
	if (!props.allowDuplicates) {
		itemList = [...new Set(itemList)]
	}

	newItemField.value = ""
	localHint.value = ""
	hasSubmitted.value = true
	setFieldValue(itemList)
}

const removeItem = (item) => {
	const copyOfItems = [...fieldValue.value]
	const index = copyOfItems.indexOf(item)
	if (index > -1) {
		copyOfItems.splice(index, 1)
	}
	setFieldValue(copyOfItems)
}

const validate = async () => {
	// const errors = await textField.value.validate()
	// return errors
	return []
}

defineExpose({
	/** validates the text input */
	validate,
})

const setFieldValue = (value) => {
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
			true,
		)
	}
	fieldValue.value = value
}
</script>
