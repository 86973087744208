<template>
	<div class="label-wrapper d-flex justify-content-between align-items-center">
		<label v-if="computedLabel" :for="inputId" class="form-label">
			{{ computedLabel }}
		</label>
		<MDBTooltip
			v-if="props.field.aiBuilder"
			v-model="aiBuilderTooltip"
			:options="{ strategy: 'fixed' }"
		>
			<template #reference>
				<IconBadge
					icon="fas.sparkles"
					class="text-primary ms-auto cursor-pointer flex-shrink-0"
					@click="showAiBuilderModal = true"
				/>
			</template>
			<template #tip>
				{{ props.field?.aiBuilder?.tooltipText || "Edit with AI" }}
			</template>
		</MDBTooltip>
	</div>
	<div>
		<MDBTextarea
			:id="inputId"
			class="form-control-textarea"
			:model-value="localValue"
			type="textarea"
			:required="props.field.required || props.required"
			variant="solo-filled"
			:readonly="props.field.readonly || props.readonly || false"
			:disabled="props.field.readonly || props.readonly || false"
			:class="props.field.classList || props.classList || []"
			:rows="props.field.rows || props.rows || 5"
			:error-messages="errorMessages"
			:form-outline="false"
			wrapper-class="form-floating"
			:max-length="fieldOptions.maxLength"
			:counter="fieldOptions.maxLength"
			@update:model-value="onInput"
		/>

		<AiFormFieldBuilder
			v-if="props.field?.aiBuilder"
			v-model:is-open="showAiBuilderModal"
			:system-prompt="props.field.aiBuilder?.systemPrompt"
			:field="props.field"
			:current-value="{ value: localValue }"
			:output-format="{
				value: `{value for this option}${fieldOptions?.maxLength ? `: (max length ${fieldOptions?.maxLength} characters)` : ''}`,
			}"
			@save="handleAiBuilderSave"
		/>
	</div>

	<div
		v-if="fieldOptions.displayCharacterCount"
		class="smaller text-end text-secondary-10"
	>
		<span class="text-black">{{ localValue.length }}</span>
		<span v-if="fieldOptions.maxLength ?? props.field.maxLength">
			/ {{ fieldOptions.maxLength ?? props.field.maxLength }}
		</span>
		Characters
	</div>
</template>

<script setup>
import { computed, defineModel, ref } from "vue"
import { MDBTextarea, MDBTooltip } from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import AiFormFieldBuilder from "./AiFormFieldBuilder.vue"
import IconBadge from "@/Components/IconBadge.vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Object,
		default: undefined,
	},
})

const computedLabel = computed(() => props.field.label ?? props.label)

const fieldOptions = computed(() => props.options ?? props.field.options ?? {})

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})

const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})

const onInput = (value) => {
	let fieldName = props.field.name || props.name || null
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
}
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 100)
}

const showAiBuilderModal = ref(false)
const aiBuilderTooltip = ref(false)
const handleAiBuilderSave = (payload) => {
	try {
		let receivedJson = JSON.parse(payload)

		if (receivedJson.value) {
			const maxLength = fieldOptions.value?.maxLength
			let tempVal = receivedJson.value
			if (tempVal && maxLength && tempVal.length > maxLength) {
				tempVal = tempVal.slice(0, maxLength)
			}
			onInput(tempVal)
		}
	} catch (error) {
		console.error("Error parsing AI response:", error)
	}
	showAiBuilderModal.value = false
}
</script>
