<template>
	<div :class="props.field.classList || props.classlist || []">
		<FontAwesomeIcon
			:class="props.field.iconClassList || props.iconClassList || []"
			:icon="
				lookupIcon(
					props.field.content || props.icon,
					props.field.iconStyle || props.iconStyle,
				)
			"
		/>
	</div>
</template>

<script setup>
import { defineModel, onMounted, shallowRef } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	icon: {
		type: String,
		default: "",
	},
	iconStyle: {
		type: String,
		default: "fas",
	},
	iconClassList: {
		type: Array,
		default: () => [],
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const fieldValue = defineModel({
	type: String,
	default: "",
})
const localValue = shallowRef(null)
const computeFieldValue = () => {
	let urlContent = props.field.content || props.content
	let getVal = props.dataStore.getModelValue({
		column: "dashboard_assets",
		name: urlContent,
		disableDotNotation: true,
	})
	if (getVal) {
		return getVal
	} else {
		if (props.field.name) {
			let retValue = props.dataStore.getModelValue({
				column: props.field.column || null,
				name: props.field.name || null,
				disableDotNotation: true,
			})
			return retValue
		} else {
			return urlContent
		}
	}
}

onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>
