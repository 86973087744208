<template>
	<div :id="props.options.id" class="data-table-main position-relative">
		<!-- Export Dropdown -->
		<div
			v-if="props.options.hideToolbar !== true"
			class="data-table-toolbar d-flex flex-wrap justify-content-between align-items-center gap-2 flex-wrap"
		>
			<!-- Table title -->
			<div class="data-table-title me-auto">
				<h5 v-if="tableTitle" class="m-0 larger fw-bold">
					{{ $t(tableTitle) }}
				</h5>
			</div>

			<div
				v-if="
					!props.options.searchRoute?.hide &&
					props.options.searchRoute &&
					(!props.options.advancedSearch || !advancedSearchEnabled)
				"
				ref="searchInputRef"
				class="expanding-search ms-auto"
				:class="{ closed: searchBoxClosed && !searchProxy }"
				@mouseenter="handleHover"
				@mouseleave="handleLeave"
			>
				<MDBInput
					v-model.trim="searchProxy"
					:form-outline="false"
					size="sm"
					class="form-icon-trailing rounded-pill border-primary"
					:placeholder="!searchBoxClosed ? 'Search' : ''"
					@keyup.enter.prevent="fetchData"
					@focus="searchBoxClosed = false"
					@blur="
						searchBoxClosed =
							searchProxy == undefined || searchProxy.length == 0
					"
					@click="focusSearchInput"
				>
					<FontAwesomeIcon
						class="trailing text-primary"
						size="sm"
						:icon="lookupIcon('search', 'fas')"
					/>
				</MDBInput>
			</div>
			<div v-if="options.tableStyleKey && !options.tableStyleDisabled">
				<MDBTooltip
					v-model="tooltipStyle"
					class=""
					offset="0,5"
					direction="left"
				>
					<template #reference>
						<MDBBtn
							size="sm"
							type="button"
							:class="['shadow-0', 'rounded-circle', 'btn-outline-primary']"
							:floating="true"
							@click.stop="toggleStyle"
						>
							<FontAwesomeIcon
								:icon="
									lookupIcon(
										tableStyle == 'card_view' ? 'table' : 'grid-2',
										'fas',
									)
								"
							/>
						</MDBBtn>
					</template>
					<template #tip> Change View Style </template>
				</MDBTooltip>
			</div>
			<!-- Advanced Search Toggle -->
			<div v-if="props.options.advancedSearch?.allowToggleBasicSearch">
				<MDBTooltip v-model="tooltip" class="" offset="0,5" direction="left">
					<template #reference>
						<MDBBtn
							type="button"
							size="sm"
							:class="[
								'shadow-0',
								'rounded-circle',
								{
									'btn-secondary': advancedSearchEnabled,
									'btn-outline-primary': !advancedSearchEnabled,
								},
							]"
							:floating="true"
							@click.stop="advancedSearchToggleChanged"
						>
							<FontAwesomeIcon
								:icon="lookupIcon('magnifying-glass-plus', 'fas')"
							/>
						</MDBBtn>
					</template>
					<template #tip> Advanced Search </template>
				</MDBTooltip>
			</div>
			<MDBTooltip
				v-if="
					props.options.exports &&
					checkFeaturesAndPermissions(props.options.exports)
				"
				v-model="tooltipExports"
				class="d-block"
				offset="0,5"
				direction="left"
			>
				<template #reference>
					<MDBDropdown
						id="export-dropdown"
						v-model="exportDropdown"
						class="d-flex justify-content-center py-2"
						:disabled="exportProcessing"
					>
						<MDBDropdownToggle
							id="exportButton"
							class="btn btn-primary btn-sm shadow-0 btn-floating no-caret m-0"
							@click="exportDropdown = !exportDropdown"
						>
							<FontAwesomeIcon
								class=""
								size="sm"
								:icon="lookupIcon('file-export', 'far')"
							/>
						</MDBDropdownToggle>
						<MDBDropdownMenu aria-labelledby="exportButton">
							<MDBDropdownItem
								v-for="(exportOption, exportIndex) in props.options.exports"
								:key="exportIndex"
								href="#"
								@click="exportClicked(exportOption)"
							>
								{{ exportOption.label ?? "Missing Export Button Label" }}
							</MDBDropdownItem>
						</MDBDropdownMenu>
					</MDBDropdown>
					<div class="dropdown-actions">
						<MDBDropdown
							v-for="(dropdown, dropdownIndex) in tableCreate?.dropdowns"
							:key="dropdownIndex"
							v-model="showDropdowns[dropdownIndex]"
							btn-group
							class="d-flex justify-content-center py-2 shadow-0"
						>
							<MDBDropdownToggle
								:id="`dropdown-${dropdownIndex}`"
								class="btn btn-primary btn-sm shadow-0 btn-floating no-caret"
								@click="
									showDropdowns[dropdownIndex] = !showDropdowns[dropdownIndex]
								"
							>
								<FontAwesomeIcon
									class=""
									size="sm"
									:icon="lookupIcon(dropdown.icon, 'far')"
								/>
								{{ dropdown.label }}
							</MDBDropdownToggle>
							<MDBDropdownMenu :aria-labelledby="`dropdown-${dropdownIndex}`">
								<MDBDropdownItem
									v-for="(link, linkIndex) in dropdown.items"
									:key="linkIndex"
									:disabled="
										dropdownsProcessing[dropdownIndex] || link.disabled || false
									"
									class="dropdown-item"
									href="#"
									:title="link.label"
									@click="handleDropdownItemClicked(link, dropdownIndex)"
								>
									<span v-if="link.icon">
										<FontAwesomeIcon
											:icon="lookupIcon(link.icon, 'fas')"
											class="me-1"
										/>
									</span>
									{{ link.label }}
								</MDBDropdownItem>
							</MDBDropdownMenu>
						</MDBDropdown>
					</div>
				</template>
				<template #tip> Exports </template>
			</MDBTooltip>
			<div>
				<FilterDialog
					v-if="props.options.filter"
					v-model="customFilters"
					:filter-definition="props.options.filter"
					:standalone="false"
					@apply="handleFilterApply"
					@clear="handleFilterClear"
				/>
			</div>
			<div
				class="d-flex gap-2 flex-wrap justify-content-end col-12 col-sm-auto"
			>
				<!-- Table Links -->
				<template v-if="tableCreate?.links?.length > 0">
					<!-- Existing Button behavior -->

					<MDBBtn
						v-for="(link, index) in tableCreate.links"
						:key="index"
						type="button"
						:color="link.color || 'primary'"
						:outline="link.outline || ''"
						:floating="link.floating || false"
						:disabled="link.disabled || false"
						:tag="link.tag || 'a'"
						:rel="link.rel || ''"
						:size="link.size || 'lg'"
						:class="[
							link.classList,
							'rounded-pill',
							'd-flex-inline',
							'align-items-center',
							'justify-content-center',
							'm-0',
							'shadow-0',
						]"
						@click="handleTableLinkClicked(link)"
					>
						<span v-if="link.iconAndLabel">
							<FontAwesomeIcon
								:icon="lookupIcon(link.icon, 'fas')"
								class="me-1"
							/>
							{{ link.label }}
						</span>
						<span v-else-if="link.icon">
							<FontAwesomeIcon :icon="lookupIcon(link.icon, 'fas')" />
						</span>
						<span v-else>
							{{ link.label }}
						</span>
					</MDBBtn>
				</template>

				<!-- Create Dialog -->
				<component
					:is="activeCreateDialog"
					v-if="
						activeCreateDialog &&
						checkFeaturesAndPermissions(props.createConfig) &&
						createDialogPosition == CREATE_DIALOG_POSITION_TOP_RIGHT
					"
					:form-definition="tableCreate"
					:size="props.createConfig?.modalSize || 'lg'"
					:show-on-mounted="
						(paginatedItems?.total == 0 && tableCreate?.showWhenEmpty) ||
						openCreateDialogParam
					"
					:parent-data-model="dataModel"
					@success="handleCreateSuccess"
				></component>
			</div>
		</div>
		<template v-if="props.options.infoBanner">
			<div class="banner">
				<div class="banner-content rounded-3 bg-primary-10 p-3 mb-3">
					<p class="m-0">{{ props.options.infoBanner }}</p>
				</div>
			</div>
		</template>

		<AdvancedSearch
			v-if="
				advancedSearchEnabled &&
				props.options.advancedSearch &&
				props.options.advancedSearch.showBuilder !== false
			"
			v-model="advancedSearchFilters"
			:model-name="'attendee'"
			:options="props.options.advancedSearch"
			:max-depth="3"
			:fields="props.options.advancedSearch.fields"
			:initial-filters="[]"
			@update:filters="handleFilterUpdate"
			@search="fetchData"
		></AdvancedSearch>

		<!-- Delete All Button -->
		<div class="d-flex justify-content-between">
			<div
				v-if="
					selectedItems?.length > 0 &&
					options.bulkDelete &&
					checkFeaturesAndPermissions(options.bulkDelete)
				"
			>
				<MDBTooltip v-model="bulkDeleteTooltip" offset="-7,0" direction="right">
					<template #reference>
						<MDBBtn
							type="button"
							class="mb-3"
							color="danger"
							:disabled="selectedItems.length == 0"
							test-id="dt-bulk-delete-btn"
							@click="handleDeleteAllClicked"
						>
							<FontAwesomeIcon
								size="lg"
								:icon="lookupIcon('trash-list', 'fas')"
							/>
						</MDBBtn>
					</template>
					<template #tip> Delete Selected </template>
				</MDBTooltip>
			</div>
			<div
				v-else-if="
					selectedItems?.length > 0 &&
					options.bulkAction &&
					checkFeaturesAndPermissions(options.bulkAction)
				"
			>
				<MDBBtn
					type="button"
					class="mb-3"
					color="primary"
					:disabled="bulkActionInProgress"
					test-id="dt-bulk-update-btn"
					@click="handleBulkActionClicked"
				>
					{{ options.bulkAction.label }}
				</MDBBtn>
			</div>
		</div>

		<!-- Multi Selection Actions -->
		<div
			v-if="
				filteredSelectionActions.length > 0 ||
				(tableStyle &&
					tableStyle == 'card_view' &&
					props.options.allowSelectAll &&
					!props.options.delete?.disabled)
			"
			class="multi-selection-actions d-flex align-items-center justify-content-between mb-3 mt-4 flex-wrap"
		>
			<!-- Select All Toggle For Card View -->
			<div
				v-if="tableStyle && tableStyle == 'card_view'"
				class="select-all-toggle"
			>
				<input
					v-if="props.options.allowSelectAll && !props.options.delete?.disabled"
					id="select-all"
					v-model="toggleAllEnabled"
					class="form-check-input"
					type="checkbox"
					:indeterminate="isIndeterminate"
					test-id="dt-select-all-checkbox"
				/>
				<label for="select-all" class="ms-3 select-all-label">Select All</label>
			</div>

			<!-- Selection Actions -->
			<div v-if="filteredSelectionActions.length > 0" class="ms-auto">
				<button
					v-for="(action, index) in filteredSelectionActions"
					:key="index"
					class="btn shadow-0 rounded-pill"
					type="button"
					:class="action.classList"
					@click="handleSelectionActionClicked(action.slug)"
				>
					<span v-if="action.prependIcon">
						<FontAwesomeIcon
							:icon="lookupIcon(action.prependIcon, action.iconStyle || 'fas')"
							class="me-1"
						/>
					</span>
					{{ action.label }}
					<span v-if="action.appendIcon">
						<FontAwesomeIcon
							:icon="lookupIcon(action.appendIcon, action.iconStyle || 'fas')"
							class="me-1"
						/>
					</span>
				</button>
			</div>
		</div>

		<div
			v-if="tableStyle && tableStyle == 'card_view' && options.cardLayout"
			:class="{ 'py-5': fetchingData && tableRows.length === 0 }"
		>
			<div
				class="row position-relative"
				:class="{ 'py-5': fetchingData && tableRows.length === 0 }"
			>
				<InfoCard
					v-for="(row, index) in tableRows"
					:key="row.id || index"
					:card="options.cardLayout"
					:card-item="row"
					:options="options"
					@delete-clicked="handleDeleteClicked"
				>
					<template v-if="props.options.selectable" #card-select>
						<input
							:id="'checkbox-' + row.id"
							v-model="selectedItems"
							class="form-check-input float-end"
							type="checkbox"
							:value="row.id"
							test-id="dt-row-checkbox"
						/>
					</template>
				</InfoCard>
				<div
					v-if="fetchingData || updateInProgress || reorderInProgress"
					class="spinner-wrapper position-absolute m-0 p-0 w-auto"
				>
					<MDBSpinner class="dt-spinner" />
				</div>
				<p v-else-if="tableRows.length == 0" class="text-center">
					{{ options.emptyText || "No data available" }}
				</p>
			</div>
		</div>
		<div
			v-else
			class="data-table-wrapper table-responsive text-nowrap rounded mb-2 post-item-relative"
		>
			<MDBTable class="position-relative mb-0">
				<DataTableColumnHandler
					v-model="columnsComputed"
					v-model:sort-by="currentSort"
					v-model:toggle-all-enabled="toggleAllEnabled"
					:is-indeterminate="isIndeterminate"
					:table-options="props.options"
					@update:sort-by="handleSortByChange"
				>
					<template #[`item._actions`]>
						<div
							v-if="props.options.visibleColumns"
							class="select-visible-columns"
						>
							<CustomSelectMenu
								v-model="visibleColumns"
								:items="visibleColumnOptions"
								value-field="key"
								label-field="title"
								icon="table-columns"
							/>
						</div>
					</template>
				</DataTableColumnHandler>
				<draggable
					v-if="props.options.draggable"
					handle=".cursor-grab"
					:list="tableRows"
					:disabled="reorderInProgress"
					item-key="id"
					tag="tbody"
					ghost-class="ghost"
					group="table-rows"
					:data-drag-target-data="props.options.dragTargetData || ''"
					@end="reorderUpdate"
				>
					<template #item="{ element, index }">
						<DataTableRowHandler
							:key="element.id || index"
							:item="element"
							:headers="columnsComputed"
							:item-class="props.options.itemClass"
							:table-options="props.options"
							:navigation-route="navigationRoute"
							@export-clicked="exportClicked"
							@inline-update="inlineUpdate"
							@expandable-success="handleExpandableSuccess"
							@navigate="navigate"
							@delete-clicked="handleDeleteClicked"
							@additional-action-clicked="handleAdditionalActionClicked"
						>
							<template #[`item._select`]>
								<input
									:id="'checkbox-' + element.id"
									v-model="selectedItems"
									class="form-check-input"
									type="checkbox"
									:value="element.id"
									test-id="dt-row-checkbox"
								/>
							</template>
						</DataTableRowHandler>
					</template>
				</draggable>
				<tbody v-else>
					<DataTableRowHandler
						v-for="(element, index) in tableRows"
						:key="element.id ?? index"
						:item="element"
						:headers="columnsComputed"
						:item-class="props.options.itemClass"
						:table-options="props.options"
						:navigation-route="navigationRoute"
						@export-clicked="exportClicked"
						@inline-update="inlineUpdate"
						@expandable-success="handleExpandableSuccess"
						@navigate="navigate"
						@delete-clicked="handleDeleteClicked"
						@additional-action-clicked="handleAdditionalActionClicked"
					>
						<template #[`item._select`]>
							<input
								:id="'checkbox-' + element.id"
								v-model="selectedItems"
								class="form-check-input"
								type="checkbox"
								:value="element.id"
								test-id="dt-row-checkbox"
							/> </template
					></DataTableRowHandler>
					<tr v-if="tableRows.length === 0">
						<td :colspan="columnsComputed.length" class="text-center">
							{{ options.emptyText || "No data available" }}
						</td>
					</tr>
				</tbody>
				<div class="spinner-wrapper position-absolute">
					<MDBSpinner
						v-if="fetchingData || updateInProgress || reorderInProgress"
						class="dt-spinner"
					/>
				</div>
			</MDBTable>
		</div>

		<div
			class="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-2"
		>
			<BasePaginationDisplayAmount
				:class="{
					'd-none': props.options.hidePagination || !props.paginatedItems,
				}"
				:total="
					props.listData?.length ??
					localPaginatedData?.total ??
					props.paginatedItems?.total ??
					0
				"
				:current="
					localPaginatedData?.data?.length ??
					props.paginatedItems?.data?.length ??
					currentListPageData?.length ??
					0
				"
			/>
			<BasePagination
				v-model="page"
				:class="[
					'mx-auto',
					{
						'd-none': props.options.hidePagination || options.internalData,
					},
				]"
				:paginated-response="paginatedResponseComputed"
				:current-page="page"
				:total-pages="pageCount"
				@update:model-value="navigatePage"
			/>
			<BasePaginationPageSelector
				v-model="itemsPerPage"
				:class="{
					'd-none': props.options.hidePagination || !props.paginatedItems,
				}"
				:per-page-options="perPageOptions"
				@update:model-value="handlePerPageChange"
			/>
		</div>

		<ConfirmDelete
			v-if="deleteRoute !== undefined"
			v-model="confirmDeleteModalOpen"
			v-model:confirm-input="userConfirmTextInput"
			v-model:delete-all-or-selected="deleteAllOrSelected"
			:confirm-text="confirmText"
			:route="deleteRoute"
			:bulk-delete-route="bulkDeleteRoute"
			:requires-input-confirmation="props.options.requireInputConfirmation"
			:delete-all-count="deleteAllCount"
			:selected-count="selectedItems.length"
			:selected-items="selectedItems"
			:allow-delete-all="props.options.deleteAll"
			:title="props.options.delete?.title"
			:body-text="props.options.delete?.bodyText"
			:button-text="props.options.delete?.buttonText"
			:success-message="props.options.delete?.successMessage"
			:error-message="props.options.delete?.errorMessage"
			@success="handleDeleteSuccess"
		/>
		<ConfirmDelete
			v-model="activeSelectionActionModalOpen"
			v-model:confirm-input="userConfirmTextInput"
			v-model:delete-all-or-selected="deleteAllOrSelected"
			:confirm-text="activeSelectionAction?.confirmText"
			:route="activeSelectionAction?.fullRoute"
			:bulk-delete-route="activeSelectionAction?.fullRoute"
			:requires-input-confirmation="
				activeSelectionAction?.requiresInputConfirmation
			"
			:delete-request-method="activeSelectionAction?.requestMethod"
			:delete-all-count="deleteAllCount"
			:selected-count="selectedItems.length"
			:selected-items="selectedItems"
			:allow-delete-all="activeSelectionAction?.allowDeleteAll"
			:title="activeSelectionAction?.title"
			:body-text="activeSelectionAction?.bodyText"
			:button-text="activeSelectionAction?.buttonText"
			:success-message="activeSelectionAction?.successMessage"
			:bulk-delete-success="activeSelectionAction?.onSuccess"
			:error-message="activeSelectionAction?.errorMessage"
			:confirm-button-color="activeSelectionAction?.confirmButtonColor"
			:transform-body="activeSelectionAction?.transformBody"
			:request-body="activeSelectionAction?.requestBody"
			@success="handleDeleteSuccess"
		/>

		<DataTableRefreshButton
			:options="props.options"
			:fetching-data="fetchingData"
			container=".data-table-main"
			@refresh="fetchData"
		/>

		<!-- Create Dialog Bottom -->
		<div
			class="create-dialog-bottom-wrapper"
			:class="tableCreate?.wrapperClassList || []"
		>
			<component
				:is="activeCreateDialog"
				v-if="
					activeCreateDialog &&
					createDialogPosition == CREATE_DIALOG_POSITION_BOTTOM
				"
				:form-definition="tableCreate"
				:size="props.createConfig?.modalSize || 'lg'"
				:show-on-mounted="
					(paginatedItems?.total == 0 && tableCreate?.showWhenEmpty) ||
					openCreateDialogParam
				"
				:parent-data-model="dataModel"
				@success="handleCreateSuccess"
			></component>
		</div>
	</div>
</template>

<script setup>
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"
import DataTableRowHandler from "@/Components/DataTableRowHandler.vue"
import DataTableColumnHandler from "@/Components/DataTableColumnHandler.vue"
import { useCheckboxGroup } from "@/Composables/useCheckboxGroup"
import { useDataTableSort } from "@/Composables/useDataTableSort"
import { getParam } from "@/Utils/urlParams"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import draggable from "vuedraggable"
import AdvancedSearch from "@/Components/AdvancedSearch.vue"
import CustomSelectMenu from "@/Components/CustomSelectMenu.vue"
import ExportSuccessToastContent from "@/Components/ExportSuccessToastContent.vue"
import {
	reactive,
	computed,
	defineProps,
	ref,
	onMounted,
	watch,
	onUnmounted,
	inject,
} from "vue"
import { router, Link, usePage } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { useTranslator } from "@/Composables/useTranslator"
import FormPanel from "@/Components/Mod/FormPanel.vue"
import InfoCard from "@/Components/Mod/InfoCard.vue"
import route from "ziggy-js"
import useSearch from "@/Composables/useSearch"
import { useAdvancedSearch } from "@/Composables/useAdvancedSearch"
import {
	MDBBtn,
	MDBBtnGroup,
	MDBTooltip,
	MDBInput,
	MDBSelect,
	MDBSpinner,
	MDBSwitch,
	MDBTable,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
} from "mdb-vue-ui-kit"
import BasePagination from "@/Components/BasePagination.vue"
import { useDataTableActions } from "@/Composables/useDataTableActions"
import { useDataTableCreateModal } from "@/Composables/useDataTableCreateModal"
import { useDataTablePagination } from "@/Composables/useDataTablePagination"
import BasePaginationPageSelector from "@/Components/BasePaginationPageSelector.vue"
import BasePaginationDisplayAmount from "@/Components/BasePaginationDisplayAmount.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { useTableStyle } from "@/Store/tableStyleStore"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"

import { useProjectStore } from "@/Store/projectStore"
const projectStore = useProjectStore()
const { injectTeamParam } = useTeamRouteParams()

const emit = defineEmits(["update:search", "update:selected"])
const props = defineProps({
	tableTitle: {
		type: String,
		required: false,
		default: () => "",
	},
	headers: {
		type: Array,
		required: true,
		default: () => [],
	},
	options: {
		type: Object,
		required: true,
		default: () => ({}),
	},
	paginatedItems: {
		type: Object,
		required: false,
		default: null,
	},
	listData: {
		type: [Array, null],
		required: false,
		default: null,
	},
	createConfig: {
		type: Object,
		required: true,
		default: () => ({}),
	},
	itemModel: {
		type: Object,
		required: false,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
	selected: {
		type: Array,
		required: false,
		default: () => [],
	},
})
const globalEventBus = inject("globalEventBus") // Inject `emitter`
import { storeToRefs } from "pinia"
import DataTableRefreshButton from "@/Components/DataTableRefreshButton.vue"
const { dataModel } = storeToRefs(props.dataStore)

const toast = useToast()
const translator = useTranslator()
const tableStyleStore = useTableStyle(
	props.options?.tableStyleKey,
	props.options?.tableDefaultStyle || props.options?.style,
)
const { tableStyle } = storeToRefs(tableStyleStore)

const { searchProxy, previousSearch, searchBoxClosed, debouncedSearch } =
	useSearch(props, emit)

watch(searchProxy, (newValue) => {
	debouncedSearch(newValue, fetchData)
})

const localPaginatedData = ref({})
const tooltip = ref(false)
const tooltipStyle = ref(false)
const tooltipExports = ref(false)
const bulkDeleteTooltip = ref(false)
const advancedSearchEnabled = ref(false)
const advancedSearchFilters = ref([])
const shallowCopyOfHeaders = ref([...props.headers])
watch(
	() => props.headers,
	(newHeaders) => {
		shallowCopyOfHeaders.value = [...newHeaders]
	},
)
const colsToOmitFromVisibleSelector = ["actions"]

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const openCreateDialogParam = urlParams.get("create")

/**
 * Remapping the headers into MDB format.
 * This way if we ever change again we don't have to change
 * all our pages config.
 */
const columnsComputed = computed({
	get() {
		let allCols = [...shallowCopyOfHeaders.value]

		// filter based on visibleColumns array
		allCols = allCols.filter((col) => {
			if (visibleColumns.value.length === 0) {
				return true
			} else if (colsToOmitFromVisibleSelector.includes(col.key)) {
				return true
			}
			return visibleColumns.value.includes(col.key)
		})

		if (props.options.selectable && !props.options.delete?.disabled) {
			allCols.unshift({
				key: "_select",
				title: "",
				align: "left",
				width: "50px",
			})
		}
		if (
			props.options.reorder &&
			checkFeaturesAndPermissions(props.options.reorder)
		) {
			allCols.unshift({
				key: "_drag",
				title: "",
				align: "center",
				width: "50px",
			})
		}
		return allCols
	},
	set(newHeaders) {
		// remove column with key _select
		const selectIndex = newHeaders.findIndex(
			(header) => header.key === "_select",
		)
		if (selectIndex > -1) {
			newHeaders.splice(selectIndex, 1)
		}

		// remove column with key _drag
		const dragIndex = newHeaders.findIndex((header) => header.key === "_drag")
		if (dragIndex > -1) {
			newHeaders.splice(dragIndex, 1)
		}

		shallowCopyOfHeaders.value = [...newHeaders]
	},
})
const tableRows = ref([])

const visibleColumnOptions = computed(() => {
	return props.headers.filter((item) => {
		return !colsToOmitFromVisibleSelector.includes(item.key)
	})
})
const visibleColumns = ref(props.options.visibleColumns?.defaultHeaders ?? [])
// Define a method to update the tableRows based on current data and conditions
const updateTableRows = () => {
	let rows = []

	if (props.listData && props.listData.length > 0) {
		rows = currentListPageData.value
	} else if (makeInternalRequest.value) {
		rows = localPaginatedData.value?.data ?? []
	} else {
		rows = props.paginatedItems?.data ?? []
	}
	if (props.options.excludeRows) {
		const set = new Set(
			dataModel.value[props.options.excludeRows.name].map((item) => item.id),
		)
		rows = rows.filter((item) => !set.has(item.id))
	}
	tableRows.value = rows
}

const paginatedResponseComputed = computed(() => {
	if (props.listData) {
		return null
	} else if (
		props.paginatedItems &&
		Object.keys(props.paginatedItems).length > 0
	) {
		return props.paginatedItems
	} else if (
		localPaginatedData.value &&
		Object.keys(localPaginatedData.value).length > 0
	) {
		return localPaginatedData.value
	} else {
		return null
	}
})

const showPaginationUIButtons = computed(() => {
	if (props.options.hidePagination) {
		return false
	}

	// if we are making internal requests, show true
	if (
		makeInternalRequest.value &&
		localPaginatedData.value?.total &&
		localPaginatedData.value?.per_page &&
		localPaginatedData.value?.total < localPaginatedData.value?.per_page
	) {
		return false
	}

	// if we have paginatedItems, return true
	// this is whne we are pulling data on page load
	if (
		props.paginatedItems?.total &&
		props.paginatedItems?.per_page &&
		props.paginatedItems?.total <= props.paginatedItems?.per_page
	) {
		return false
	}

	// if we have list data, it wouldn't have pagination, so no
	if (props.listData) {
		if (props.listData?.length <= props.options.defaultItemsPerPage) {
			return false
		}
	}

	// default to true if we haven't considered other options...
	return true
})

const { selectedItems, toggleAllEnabled, isIndeterminate } = useCheckboxGroup(
	tableRows,
	props,
	emit,
)

/**
 * The create config is passed in from the parent component
 */
const tableCreate = computed(() => props.createConfig)

const { activeCreateDialog } = useDataTableCreateModal(props, emit)

const { currentSort } = useDataTableSort({
	props,
	emit,
})
/**
 * we make internal requests if props.searchRoute is set as an object
 * with a route and a requestType of post. This is in case we need a payload
 * if searchRoute is a string, set to false
 */
const makeInternalRequest = computed(() => {
	if (
		(props.listData == null || props.listData.length == 0) &&
		props.options.searchRoute
	) {
		if (typeof props.options.searchRoute === "string") {
			return false
		} else if (
			props.options.searchRoute.requestType === "post" ||
			props.options.searchRoute.requestType === "get"
		) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
})

const updateInProgress = ref(false)

/**
 * Called when a user makes an update within the table
 * @param {*} item
 * @param {*} updated
 */
const inlineUpdate = (item, updated) => {
	if (!props.options.update) {
		return
	}
	updateInProgress.value = true
	const data = {}
	if (updated.column) {
		data[updated.column] = []
		data[updated.column][updated.name] = updated.value
	} else {
		data[updated.name] = updated.value
	}
	//Maybe only do this when uploading files? patch doesnt work for uploading so need to spoof
	let params = props.options.update.params
	if (props.options.update.key) {
		params = {
			[props.options.update.key]: item.id,
			...props.options.update.params,
		}
	}
	params = injectTeamParam(props.options.update.route, params)
	try {
		router.patch(route(props.options.update.route, params), data, {
			preserveScroll: true,
			preserveState: true,
			onSuccess: (event) => {
				updateInProgress.value = false
				toast.success("Successfully updated data")
			},
			onError: (errors) => {
				updateInProgress.value = false
				toast.error(Object.values(errors).join("\n"))
			},
		})
	} catch (err) {
		updateInProgress.value = false

		console.error(err)
	}
}

const reorderInProgress = ref(false)
/**
 * Called when a user drags a data table row
 * @param {*} updates
 */
const reorderUpdate = (event) => {
	if (
		reorderInProgress.value ||
		(event.to == event.from && event.oldIndex == event.newIndex)
	) {
		return
	}
	reorderInProgress.value = true

	const itemJson = event.item.getAttribute("data-item")

	const item = JSON.parse(itemJson)

	const dragTargetTableData = event.to.getAttribute("data-drag-target-data")
	const dragSourceTableData = event.from.getAttribute("data-drag-target-data")

	const data = {
		originalOrder: item[props.options.reorder.key],
		oldIndex: event.oldIndex,
		newIndex: event.newIndex,
		page: page.value,
		per_page: itemsPerPage.value,
		targetTableData: dragTargetTableData,
		sourceTableData: dragSourceTableData,
	}
	try {
		let targetRoute
		if (typeof props.options.reorder.route === "function") {
			targetRoute = props.options.reorder.route(item, data, dataModel.value)
		} else {
			let params = props.options.reorder.params
			params = injectTeamParam(props.options.reorder.route, params)
			targetRoute = route(props.options.reorder.route, {
				[props.options.reorder.key]: item.id,
				...params,
			})
		}
		const transformedData = props.options.reorder.transform
			? props.options.reorder.transform(item, data)
			: data
		router.patch(targetRoute, transformedData, {
			preserveScroll: true,
			preserveState: true,
			onSuccess: (event) => {
				reorderInProgress.value = false
				toast.success("Successfully reordered data")
			},
			onError: (errors) => {
				reorderInProgress.value = false
				toast.error(Object.values(errors).join("\n"))
			},
		})
	} catch (err) {
		reorderInProgress.value = false
		console.error(err)
	}
}

const customFilters = ref({})

/**
 * when we are in internal mode, we need to build the
 * payload manually. This function returns the payload
 */
const getInternalRequestPayload = () => {
	const basePayload = {
		per_page: itemsPerPage.value,
		page: searchProxy.value != previousSearch.value ? 1 : page.value,
		sort_by: currentSort.value.key,
		sort_direction: currentSort.value.direction,
		search: searchProxy.value,
		visible_columns: visibleColumns.value,
	}
	const filteredPayload = Object.entries(basePayload).reduce(
		(acc, [key, value]) => {
			if (value !== "" && value != null) {
				acc[key] = value
			}
			return acc
		},
		{},
	)
	if (advancedSearchEnabled.value) {
		return {
			...filteredPayload,
			search_mode: "advanced",
			condition_groups: advancedSearchFilters.value,
		}
	}

	if (props.options.applyFiltersFromUrl) {
		const urlParams = new URLSearchParams(window.location.search)
		const filtersParam = urlParams.get("filters")
		if (filtersParam) {
			basePayload.filters = JSON.parse(filtersParam)
		}
	} else if (
		typeof customFilters.value === "object" &&
		customFilters.value !== null
	) {
		basePayload.filters = customFilters.value
	}

	return basePayload
}

/**
 * When working with post requests or other internal requests,
 * we'll handle all errors here.
 * @param {*} err
 */
const handleInternalRequestError = (err) => {
	fetchingData.value = false

	const errors = err.response?.data?.errors
	if (errors) {
		toast.error(errors.join("\n"))
	} else {
		console.error(err)
		toast.error(err.response?.error || "An error occurred")
	}
}

const fetchingData = ref(false)
/**
 * Fetches data when we are in internal mode
 * This is usually only used when making post requests
 * instead of get requests.
 */
const fetchData = async () => {
	if (!props.options.searchRoute) {
		console.error("no search route provided")
	}

	fetchingData.value = true
	try {
		const searchRouteValue = props.options.searchRoute
		let searchRoute = ""
		if (
			typeof searchRouteValue === "object" &&
			searchRouteValue.projectPlugin
		) {
			let pluginParams = {
				project: projectStore.getActiveProject.slug,
			}
			Object.keys(searchRouteValue.params).forEach((key) => {
				let paramValue = getNestedProperty(
					dataModel.value,
					searchRouteValue.params[key],
				)
				pluginParams[key] = paramValue
			})
			pluginParams = injectTeamParam(searchRouteValue.route, pluginParams)
			searchRoute = route(searchRouteValue.route, pluginParams)
		} else {
			if (typeof searchRouteValue === "string") {
				searchRoute = searchRouteValue
			} else if (
				typeof searchRouteValue === "object" &&
				typeof searchRouteValue.route == "function"
			) {
				searchRoute = searchRouteValue.route()
			} else if (Array.isArray(searchRouteValue.params)) {
				let pluginParams = {
					project: projectStore.getActiveProject.slug,
				}
				searchRouteValue.params.forEach((param) => {
					if (param.key) {
						let paramValue = getNestedProperty(dataModel.value, param.key)
						pluginParams[param.name] = paramValue
					} else if (param.value) {
						pluginParams[param.name] = param.value
					}
				})

				pluginParams = injectTeamParam(searchRouteValue.route, pluginParams)
				searchRoute = route(searchRouteValue.route, pluginParams)
			} else {
				const params = injectTeamParam(
					searchRouteValue.route,
					searchRouteValue.params,
				)
				searchRoute = route(searchRouteValue.route, params)
			}
		}
		const payload = getInternalRequestPayload()

		if (makeInternalRequest.value) {
			const requestMethod = props.options.searchRoute.requestType || "get"
			const res = await http.request(searchRoute, {
				method: props.options.searchRoute.requestType || "get",
				data: requestMethod == "post" ? payload : null,
				params: requestMethod == "get" ? payload : null,
			})

			if (props.options.listKey) {
				localPaginatedData.value = res.data[props.options.listKey]
			} else {
				localPaginatedData.value = res.data
			}
			fetchingData.value = false
		} else {
			router.visit(searchRoute, {
				data: payload,
				preserveScroll: true,
				preserveState: true,
				onSuccess: (event) => {
					fetchingData.value = false
				},
				onError: (errors) => {
					fetchingData.value = false
					toast.error(Object.values(errors).join("\n"))
				},
			})
		}
	} catch (err) {
		handleInternalRequestError(err)
	}
}

const listDataFiltered = computed(() => {
	if (props.listData && props.listData.length) {
		const payload = getInternalRequestPayload()

		// Sort the data
		const sortedData = [...props.listData].sort((a, b) => {
			if (!payload.sort_by) {
				return 0
			}
			if (payload.sort_direction === "asc") {
				if (a[payload.sort_by] < b[payload.sort_by]) return -1
				if (a[payload.sort_by] > b[payload.sort_by]) return 1
				return 0
			} else {
				if (a[payload.sort_by] > b[payload.sort_by]) return -1
				if (a[payload.sort_by] < b[payload.sort_by]) return 1
				return 0
			}
		})

		const finalData = sortedData

		return finalData
	}
	return []
})

const currentListPageData = computed(() => {
	if (listDataFiltered.value && listDataFiltered.value.length > 0) {
		const payload = getInternalRequestPayload()

		const start = (payload.page - 1) * payload.per_page
		const end = start + payload.per_page

		return listDataFiltered.value.slice(start, end)
	}
	return []
})

const {
	confirmDeleteModalOpen,
	toDeleteId,
	deleteRoute,
	navigate,
	navigationRoute,
	handleDeleteClicked,
	confirmText,
	handleDeleteAllClicked,
	deleteAllOrSelected,
	userConfirmTextInput,
	deleteAllCount,
	bulkDeleteRoute,
	handleBulkActionClicked,
	bulkActionInProgress,
	handleTableLinkClicked,
	handleDropdownItemClicked,
	handleAdditionalActionClicked,
	showDropdowns,
	dropdownsProcessing,
} = useDataTableActions({
	props,
	emit,
	tableRows,
	toggleAllEnabled,
	listDataFiltered,
	paginatedResponseComputed,
	selectedItems,
	fetchData,
	dataModel,
})

const handleDeleteSuccess = () => {
	toDeleteId.value = null
	if (selectedItems.value.length > 0 && props.options.bulkDelete?.onSuccess) {
		props.options.bulkDelete.onSuccess({
			fetchData,
			selectedItems: selectedItems.value,
		})
	} else if (props.options.delete?.onSuccess) {
		props.options.delete.onSuccess({
			fetchData,
			selectedItems: selectedItems.value,
		})
	} else {
		if (makeInternalRequest.value) {
			fetchData()
		}
	}
	if (selectedItems.value.length > 0) {
		selectedItems.value = []
		toggleAllEnabled.value = false
	}
}

const {
	page,
	pageCount,
	itemsPerPage,
	perPageOptions,
	navigatePage,
	navigationInProgress,
} = useDataTablePagination({
	props,
	emit,
	makeInternalRequest,
	fetchData,
	paginatedResponseComputed,
	tableStyle,
})

const toggleStyle = () => {
	tableStyleStore.toggleStyle()
	itemsPerPage.value = perPageOptions.value[0].value
	handlePerPageChange()
}

const handleLocalPaginatedDataChanged = (newData, oldData) => {
	updateTableRows()

	if (newData.per_page !== oldData.per_page) {
		itemsPerPage.value = newData.per_page
	}
	if (newData.per_page !== oldData.per_page) {
		itemsPerPage.value = newPerPage
	}
}

/*
 * Watch for changes in props data and update itemsPerPage
 */
watch(
	() => props.paginatedItems?.per_page,
	(newPerPage) => {
		itemsPerPage.value = newPerPage
	},
)

watch(currentListPageData, updateTableRows)
watch(() => localPaginatedData, handleLocalPaginatedDataChanged, {
	deep: true,
})
watch(() => props.paginatedItems, updateTableRows)
// watch(() => tableRows.vlaue,
// () => {
// 	console.log("tableRows changed")
// 	console.log(tableRows.value)
// },
// {
// 	deep: true,
// })
const handlePerPageChange = () => {
	if (props.listData) {
		return
	} else if (makeInternalRequest.value && props.options.searchRoute) {
		fetchData()
	} else {
		navigatePage(1, itemsPerPage.value)
	}
}

const handleSortByChange = () => {
	if (props.listData) {
		return
	}
	fetchData()
}

const handleHover = () => {
	searchBoxClosed.value = false
}
const handleLeave = () => {
	const searchInputEl = getSearchInputEl()
	if (!searchProxy.value && document.activeElement !== searchInputEl) {
		searchBoxClosed.value = true
	}
}

const searchInputRef = ref(null)

const getSearchInputEl = () => {
	const inputElement = searchInputRef.value.querySelector("input")
	return inputElement
}

const focusSearchInput = () => {
	const inputElement = getSearchInputEl()

	if (inputElement) {
		inputElement.focus()
	}
}

const advancedSearchToggleChanged = () => {
	advancedSearchEnabled.value = !advancedSearchEnabled.value
	if (!advancedSearchEnabled.value) {
		fetchData()
	}
}

const handleCreateSuccess = () => {
	if (makeInternalRequest.value) {
		fetchData()
	}
}
const handleExpandableSuccess = () => {
	if (makeInternalRequest.value) {
		fetchData()
	}
}

import { checkFeaturesAndPermissions } from "@/Composables/useModVisibilityChecker"
import FilterDialog from "./FilterDialog.vue"
const CREATE_DIALOG_POSITION_BOTTOM = "bottom"
const CREATE_DIALOG_POSITION_TOP_RIGHT = "top"

const createDialogPosition = computed(() => {
	return props.createConfig?.position || CREATE_DIALOG_POSITION_TOP_RIGHT
})

// emitter.on(props.id + ":fetch", () => {
// 	console.log("fetching")
// 	fetchData()
// })

// Exporter section

const exportDropdown = ref(false)
const exportProcessing = ref(false)

const exportClicked = async (exportOption) => {
	console.log("export clicked top")
	exportProcessing.value = true

	const payload = {}

	let targetRoute
	if (typeof exportOption.route === "function") {
		targetRoute = exportOption.route(exportOption)
	} else {
		let params = exportOption.params
		params = injectTeamParam(exportOption.route, params)
		targetRoute = route(exportOption.route, {
			...params,
		})
	}
	if (exportOption.selected) {
		payload.selected = selectedItems.value
	}

	try {
		const res = await http.post(targetRoute, payload)
		const data = res.data
		toast(ExportSuccessToastContent, {
			closeOnClick: false,
		})
	} catch (err) {
		console.error(err)
		toast.error(
			err.response?.error || err.response?.data?.message || "An error occurred",
		)
	}
}

const reloadFilters = () => {
	if (props.listData) return null
	if (makeInternalRequest.value) {
		fetchData()
		return
	}
	navigationInProgress.value = true

	// get current url params and hash
	const currentUrl = window.location.href
	const params = new URLSearchParams(currentUrl)
	const hash = window.location.hash

	// if perPage, add perPage to the url
	params.set("filters", JSON.stringify(customFilters.value))

	const newUrl = currentUrl + "?" + params.toString() + hash

	try {
		router.visit(newUrl, {
			preserveState: true,
			preserveScroll: true,
			onSuccess: () => {
				navigationInProgress.value = false
			},
			onError: (errors) => {
				toast.error(Object.values(errors).join("\n"))

				navigationInProgress.value = false
			},
		})
	} catch (err) {
		navigationInProgress.value = false
		toast.error(err.message)
	}
}

const handleFilterApply = () => {
	reloadFilters()
}

const handleFilterClear = () => {
	console.log("filter cleared")
	console.log(customFilters.value)
	reloadFilters()
}

const activeSelectionActionSlug = ref(null)
const activeSelectionActionModalOpen = ref(false)

const activeSelectionAction = computed(() => {
	if (!props.options.selectionActions) {
		return null
	}
	const action = props.options.selectionActions.find(
		(action) => action.slug === activeSelectionActionSlug.value,
	)

	if (action) {
		action.fullRoute = route(action.route, {
			...action.params,
			team: usePage().props?.currentTeam?.slug,
		})
	}
	return action
})

const handleSelectionActionClicked = (slug) => {
	activeSelectionActionSlug.value = slug
	activeSelectionActionModalOpen.value = true
}

const filteredSelectionActions = computed(() => {
	if (!props.options.selectionActions) {
		return []
	}
	// Create an array to hold the filtered actions
	return props.options.selectionActions.filter((action) => {
		// Always show actions with showWithNoSelection
		if (action.showWithNoSelection) {
			return true
		}
		// Show actions only if there are selected items
		return selectedItems.value.length > 0
	})
})

onMounted(() => {
	const advancedSearchOpenParam = getParam("search_mode")
	if (!props.options.advancedSearch?.allowToggleBasicSearch) {
		advancedSearchEnabled.value = false
	} else if (props.options.advancedSearch?.defaultEnabled) {
		advancedSearchEnabled.value = true
	} else if (advancedSearchOpenParam === "advanced") {
		advancedSearchEnabled.value = true
	}
	updateTableRows()

	const urlParamSortBy =
		getParam("sort_by") || props.options.defaultSortBy || null
	const urlParamSortDirection =
		getParam("sort_direction") || props.options.defaultSortDirection || null

	currentSort.value = {
		key: urlParamSortBy,
		direction: urlParamSortDirection,
	}
	if (makeInternalRequest.value) {
		fetchData()
	}

	if (props.options.onMounted) {
		props.options.onMounted({
			fetchData,
			updateTableRows,
		})
	}

	if (globalEventBus && props.options.applyFiltersFromUrl) {
		globalEventBus.on("filters-changed", () => {
			fetchData()
		})
	}
})

onUnmounted(() => {
	if (props.options.onUnmounted) {
		props.options.onUnmounted()
	}

	if (globalEventBus) {
		globalEventBus.off("filters-changed")
	}
})

// https://codepen.io/crwilson311/pen/Bajbdwd
</script>

<style scoped>
.data-table-toolbar {
	min-height: 30px;
}
.expanding-search {
	position: relative;
	flex-grow: 1;
	transition: all 0.3s;
	padding-left: 0px;
	flex-basis: 30px;
	max-width: 300px;
}

.expanding-search.closed {
	flex-grow: 0;
	overflow: hidden;
	cursor: pointer;
}

.input-group-text.search-prepend {
	padding: 0;
	border: none;
	background: transparent;
}

.search-btn {
	width: 50px;
	height: 50px;
	padding: 0;
	margin: 0;
	border: none;
	background: transparent;
}

.form-check-input:indeterminate {
	background-color: #007bff;
	border-color: #007bff;
	box-shadow: none;
	position: relative;
}

.form-check-input:indeterminate::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 2px;
	background-color: #fff;
	transform: translate(-50%, -50%);
}

.spinner-wrapper {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.dt-spinner {
	width: 5em;
	height: 5em;
	opacity: 0.3;
}
.data-table-wrapper {
	overflow: auto;
}
.trailing {
	position: absolute;
	right: 9px;
	left: auto;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.no-caret::after {
	display: none;
}
</style>
