<template>
	<!-- Previous Button -->
	<nav class="base-pagination">
		<MDBBtn
			:disabled="!hasPrevious"
			type="button"
			size="sm"
			class="shadow-0 page-item px-2 text-primary"
			test-id="pagination-prev-btn"
			@click.prevent="navigatePage(1)"
		>
			<FontAwesomeIcon :icon="lookupIcon('chevrons-left', 'fas')" />
		</MDBBtn>
		<MDBBtn
			:disabled="!hasPrevious"
			type="button"
			size="sm"
			class="shadow-0 page-item px-2 text-primary"
			test-id="pagination-prev-btn"
			@click.prevent="navigatePage(currentPage - 1)"
		>
			<FontAwesomeIcon :icon="lookupIcon('chevron-left', 'fas')" />
		</MDBBtn>
		<!-- Page Items -->
		<MDBBtn
			v-for="(link, index) in allLinks"
			:key="index"
			:class="{
				active: link.active,
				'btn-primary': link.active,
				'btn-primary-info': !link.active,
			}"
			class="shadow-0 page-item px-2"
			type="button"
			size="sm"
			test-id="pagination-page-btn"
			:disabled="link.disabled || link.url === null"
			@click.prevent="navigatePage(parseInt(link.label))"
		>
			{{ link.label }}
		</MDBBtn>
		<!-- Next Button -->
		<MDBBtn
			:disabled="!hasNext"
			next
			class="shadow-0 page-item px-2 text-primary"
			type="button"
			size="sm"
			test-id="pagination-next-btn"
			@click.prevent="navigatePage(parseInt(currentPage) + 1)"
		>
			<FontAwesomeIcon :icon="lookupIcon('chevron-right', 'fas')" />
		</MDBBtn>
		<MDBBtn
			:disabled="!hasNext"
			next
			class="shadow-0 page-item px-2 text-primary"
			type="button"
			size="sm"
			test-id="pagination-next-btn"
			@click.prevent="navigatePage(parseInt(totalPages))"
		>
			<FontAwesomeIcon :icon="lookupIcon('chevrons-right', 'fas')" />
		</MDBBtn>
	</nav>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit"
import { computed } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const emit = defineEmits(["navigate", "update:modelValue"])

const props = defineProps({
	modelValue: {
		type: [String, Number],
		required: true,
	},
	paginatedResponse: {
		type: [Object, null],
		required: false,
		default: null,
	},
	totalPages: {
		type: [String, Number],
		required: false,
		default: 0,
	},
	currentPage: {
		type: [String, Number],
		required: false,
		default: 1,
	},
})

const allLinks = computed(() => {
	if (props.paginatedResponse && props.paginatedResponse.links) {
		return props.paginatedResponse.links.filter(
			(link) =>
				link.label !== "&laquo; Previous" && link.label !== "Next &raquo;",
		)
	}

	// Number of links to show before and after the current page
	const surroundingCount = 2

	// Calculate the start and end index for the links
	let startPage = Math.max(props.currentPage - surroundingCount, 1)
	let endPage = Math.min(props.currentPage + surroundingCount, props.totalPages)

	// Adjust if close to the beginning or end
	if (props.currentPage <= surroundingCount) {
		endPage = Math.min(startPage + surroundingCount * 2, props.totalPages)
	}

	if (props.totalPages - props.currentPage < surroundingCount) {
		startPage = Math.max(endPage - surroundingCount * 2, 1)
	}

	// Generate the limited set of page links
	let links = Array.from({ length: endPage - startPage + 1 }, (_, index) => {
		const pageIndex = startPage + index
		return {
			label: pageIndex,
			active: props.currentPage === pageIndex,
		}
	})

	// Add the extra buttons to indicate that there are more results
	if (startPage > 1) {
		links.unshift({ label: "...", active: false, disabled: true })
	}

	if (endPage < props.totalPages) {
		links.push({ label: "...", active: false, disabled: true })
	}

	return links
})

const hasPrevious = computed(() => {
	if (props.paginatedResponse) {
		return !!props.paginatedResponse.prev_page_url
	}
	return props.currentPage > 1
})

const hasNext = computed(() => {
	if (props.paginatedResponse) {
		return !!props.paginatedResponse.next_page_url
	}
	return props.currentPage < props.totalPages
})

const navigatePage = (page) => {
	emit("update:modelValue", page)
}
</script>

<style scoped>
.base-pagination {
	position: sticky;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	padding: 10px 0;
	z-index: 1;
}
.active {
	min-width: 30px;
	font-weight: 300;
}
@media (max-width: 767px) {
	.base-pagination {
		order: 1;
		width: 100%;
	}
}
</style>
