import { usePage } from "@inertiajs/vue3"
import { usePermissions } from "@/Composables/usePermissions"

export const useFeatureCheck = () => {
	// Using Set for O(1) lookups instead of array.includes()
	let featureSet = null

	/**
	 * Initialize or return the feature Set for efficient lookups
	 * @returns {Set<string>} Set of features
	 */
	function getFeatureSet() {
		if (featureSet !== null) {
			return featureSet
		}

		try {
			const page = usePage()
			if (!page?.props?.dashboardMaskFeatures) {
				return new Set()
			}

			featureSet = new Set(page.props.dashboardMaskFeatures)
			return featureSet
		} catch (e) {
			console.error("Error initializing feature set:", e)
			return new Set()
		}
	}

	/**
	 * Check if user has a specific feature
	 * @param {string} featureName the name of the feature to check
	 * @returns {boolean} true if the user has the feature
	 */
	function userHasFeature(featureName) {
		try {
			return getFeatureSet().has(featureName)
		} catch (e) {
			console.error("Error checking feature:", e)
			return true
		}
	}

	/**
	 * Check if user has any of the specified features
	 * @param {string[]} featureNames array of feature names to check
	 * @returns {boolean} true if the user has any of the features
	 */
	function userHasAnyFeature(featureNames) {
		if (!featureNames) return true

		try {
			const features = getFeatureSet()
			return featureNames.some((feature) => features.has(feature))
		} catch (e) {
			console.error("Error checking features:", e)
			return true
		}
	}

	/**
	 * Check if user has all of the specified features
	 * @param {string[]} featureNames array of feature names to check
	 * @returns {boolean} true if the user has all of the features
	 */
	function userHasAllFeatures(featureNames) {
		if (!featureNames) return true
		try {
			const features = getFeatureSet()
			return featureNames.every((feature) => features.has(feature))
		} catch (e) {
			console.error("Error checking features:", e)
			return true
		}
	}

	return {
		userHasFeature,
		userHasAnyFeature,
		userHasAllFeatures,
	}
}
