<template>
	<MDBSelectZ
		ref="selectInput"
		v-model:selected="selectValue"
		v-model:options="options"
		:label="props.field.label"
		:required="props.field.required"
		:readonly="props.field.readonly || false"
		class="form-floating"
		:preselect="false"
		:class="props.field.classList || []"
		:no-results-text="loading ? 'Loading...' : 'No results found'"
		:option-height="50"
		@change="emitValue"
	>
	</MDBSelectZ>
	<div class="progress" :style="{ opacity: loading ? 1 : 0 }">
		<div
			class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
			role="progressbar"
			aria-valuenow="75"
			aria-valuemin="0"
			aria-valuemax="100"
			style="width: 100%"
		></div>
	</div>
</template>

<script setup>
import {
	ref,
	onMounted,
	defineProps,
	defineEmits,
	computed,
	nextTick,
} from "vue"
import http from "@/Services/http"

import { useTranslator } from "@/Composables/useTranslator"
import { useProjectStore } from "@/Store/projectStore"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"

const props = defineProps({
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	dataStore: {
		type: Object,
		default: null,
	},
})

const fieldValue = defineModel({
	type: [String, Number, null],
	default: null,
})

const selectInput = ref(null)
const options = ref([])
const selectValue = ref(null)

const emit = defineEmits(["update:modelValue"])

const loading = ref(false)

onMounted(() => {
	if (props.dataStore) {
		selectValue.value = computeFieldValue()
	} else {
		selectValue.value = fieldValue.value
	}
	fetchOptions()
})

async function fetchOptions() {
	loading.value = true
	const curRoute = route("dashboard.plugins.pages.index")
	const response = await http.get(curRoute, {
		params: {
			tags: props.field.tags,
		},
	})
	if (props.field.transformResponse) {
		options.value = props.field.transformResponse(response)
	} else {
		options.value = response.data.map((item) => ({
			text: item[props.field.labelField || "name"],
			value: item[props.field.valueField || "id"],
			secondaryText: item[props.field.secondaryField || "description"],
		}))
	}

	loading.value = false
	let currentSelection = selectValue.value
	nextTick(() => {
		selectInput.value.setValue(currentSelection)
	})
}

const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

function emitValue() {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, selectValue.value, true)
	} else {
		emit("update:modelValue", selectValue.value)
	}
}
</script>
