<template>
	<label v-if="props.field.label || props.label" class="form-label">{{
		props.field.label || props.label
	}}</label>
	<div
		class="timestamp-field-type"
		:class="props.field.classList || props.classList || []"
	>
		{{ formatTimestamp(localValue, props.field.format || props.format || "F") }}
	</div>
</template>

<script setup>
import { defineModel, computed } from "vue"
import { formatTimestamp } from "@/Utils/dateHelpers"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	format: {
		type: String,
		default: "F",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = computed(() => {
	if (props.field.name && props.dataStore) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
			disableDotNotation: true,
		})
		return retValue
	} else {
		return fieldValue
	}
})
</script>
