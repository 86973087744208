<template>
	<component :is="headingSize" :class="getFieldClasses()">
		{{ renderContent(content) }}
		<footer
			v-if="
				headingSize === 'blockquote' &&
				(props.field.label || props.label.length > 0)
			"
			class="blockquote-footer"
		>
			{{ props.field.label || props.label }}
		</footer>
		<MDBTooltipZ
			v-if="fieldOptions.tooltip?.length"
			v-model="tooltip"
			:options="{ strategy: 'fixed', placement: 'right-end' }"
			class="form-label cursor-pointer"
			:max-width="fieldOptions.tooltipOptions?.maxWidth"
			direction="right"
		>
			<template #reference>
				<IconBadge icon="info-circle" :options="{ classList: [] }" />
			</template>
			<template #tip>{{ fieldOptions.tooltip }}</template>
		</MDBTooltipZ>
	</component>
</template>

<script setup>
import { ref, computed } from "vue"
import MDBTooltipZ from "../MDBOverride/MDBTooltipZ.vue"
import IconBadge from "@/Components/IconBadge.vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: null,
	},
	type: {
		type: String,
		default: "sm",
	},
	classList: {
		type: Array,
		default: () => [],
	},
})

const fieldOptions = computed(() => props.field.options ?? {})
const tooltip = ref(null)

const fieldValue = defineModel({
	type: String,
	default: "",
})
const content = computed(() => {
	return props.content.length > 0
		? props.content
		: props.field.content
			? props.field.content
			: props.field.name && props.dataStore
				? props.dataStore.getModelValue({
						column: props.field.column || null,
						name: props.field.name || null,
					})
				: props.field.label
					? props.field.label
					: props.field.default_value
						? props.field.default_value
						: ""
})
const renderContent = (content) => {
	if (props.field.transform && typeof props.field.transform === "function") {
		return props.field.transform(content)
	} else {
		return content
	}
}
const headingSize = computed(() => {
	switch (props.type) {
		case "xs":
		case "small-heading":
		case "heading-small":
			return "h6"
		case "sm":
		case "heading":
			return "h5"
		case "md":
			return "h4"
		case "lg":
		case "heading-large":
			return "h3"
		case "xl":
			return "h2"
		case "xxl":
			return "h1"
		case "quote":
		case "blockquote":
			return "blockquote"
		case "description":
			return "p"
		default:
			return "h5"
	}
})
const getFieldClasses = () => {
	let fieldClasses = props.field.classList || []
	if (props.field.type === "blockquote") {
		fieldClasses.push("blockquote")
	}
	return fieldClasses
}
// const computeFieldValue = () => {
// 	if (props.field.name) {
// 		let retValue = props.dataStore.getModelValue({
// 			column: props.field.column || null,
// 			name: props.field.name || null,
// 		})
// 		if (props.field.stringify) {
// 			try {
// 				return JSON.stringify(retValue, null, 4)
// 			} catch (err) {
// 				return retValue
// 			}
// 		} else {
// 			return retValue
// 		}
// 	} else {
// 		return props.field.content
// 	}
// }

// onMounted(() => {
// 	nextTick(() => {
// 		if (props.dataStore) {
// 			localValue.value = computeFieldValue()
// 		} else {
// 			localValue.value = fieldValue.value
// 		}
// 	})
// })
</script>
