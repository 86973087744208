<template>
	<div v-html="localValue"></div>
</template>

<script setup>
import markdownit from "markdown-it"
import DOMPurify from "dompurify"
import { ref } from "vue"
const props = defineProps({
	field: {
		type: Object,
		required: true,
	},
})

const localValue = ref(
	DOMPurify.sanitize(markdownit().render(props.field.content)),
)
</script>

<style scoped></style>
