<template>
	<div :class="props.field.classList || props.classList">
		<div v-if="loading" class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
		<div
			v-else
			class="row row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3 g-4 justify-content-center"
		>
			<div v-for="option in options" :key="option.id" class="col">
				<InfoCard
					:card-item="option"
					:card="{
						headerImage: 'header_image',
						headerOnly: true,
						headerRatio: '14 / 5',
						buttonPosition: 'bottom-right',
						bodyClassList: ['h-100', 'd-flex', 'flex-column'],
					}"
					class="h-100"
					button-text="Create"
					button-icon="arrow-right"
					@selected="onSelect"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, nextTick } from "vue"
import axios from "axios"
import InfoCard from "@/Components/Mod/InfoCard.vue"
import { usePage } from "@inertiajs/vue3"
const props = defineProps({
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	name: {
		type: String,
		required: true,
	},
	label: {
		type: String,
		required: true,
	},
	default: {
		type: [String, Number],
		default: null,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})
const selectValue = ref(null)
const selectInput = ref(null)
const options = ref([])
const emit = defineEmits(["update:modelValue"])
const loading = ref(true)
onMounted(() => {
	fetchOptions()
})

function fetchOptions() {
	axios
		.create({
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			},
		})
		.get(
			route("dashboard.projects.available-plans", {
				team: usePage().props.currentTeam.slug,
			}),
		)
		.then((response) => {
			const pageList = response.data.pluginPlans.map((plan) => ({
				id: plan.id,
				value: plan.id,
				icon: plan.icon,
				header_image: plan.header_image_url,
				title: plan.name,
				description: plan.description,
				features: plan.feature_descriptions,
				titleBadge: plan.preview_mode ? "Limited Preview" : false,
				titleBadgeClassList: ["badge-preview"],
			}))
			options.value = pageList
			nextTick(() => {
				loading.value = false
			})
		})
		.catch((error) => {
			console.error("Error fetching options:", error)
		})
}
// const selectOption = (option) => {
// 	if (selectValue.value === option) {
// 		// Reset the value to 0 to trigger datamodel change
// 		props.dataStore.setModelValue(
// 			{ column: props.field.column, name: props.field.name },
// 			0,
// 			true,
// 		)
// 	}
// 	selectValue.value = option

// 	props.dataStore.setModelValue(
// 		{ column: props.field.column, name: props.field.name },
// 		option,
// 		true,
// 	)
// 	emitValue()
// }

// function emitValue() {
// 	emit("update:modelValue", selectValue.value)
// }
const onSelect = (option) => {
	if (selectValue.value === option) {
		// Reset the value to 0 to trigger datamodel change
		props.dataStore.setModelValue(
			{ column: props.field.column, name: props.field.name },
			0,
			true,
		)
	}
	selectValue.value = option
	nextTick(() => {
		onInput(option)
	})
}
const onInput = (option) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, option, true)
	}
	emit("update:modelValue", option)
}
</script>
