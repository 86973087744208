<template>
	<div class="preset-system mx-3">
		<!-- Default Presets -->
		<div v-for="preset in defaultPresets" :key="preset.slug" class="mb-2">
			<button
				type="button"
				:class="`btn ${selectedPreset === preset.slug ? 'active bg-primary-9' : 'btn-outline-secondary'} ${preset.buttonClassList || ''} preset-btn-color`"
				class="w-100 text-start shadow-0"
				@click="selectPreset(preset)"
			>
				{{ preset.name }}
			</button>
		</div>

		<hr class="mb-2 mt-0 preset-hr" />

		<!-- Custom Option -->
		<div class="mb-2">
			<button
				type="button"
				:class="`btn ${selectedPreset === 'custom' ? 'active bg-primary-9' : 'btn-outline-secondary'} preset-btn-color`"
				class="w-100 text-start shadow-0"
				@click="selectPreset({ slug: 'custom', name: 'Custom' })"
			>
				Custom
			</button>
		</div>

		<!-- Saved Presets -->
		<div class="" style="max-height: 300px; overflow-y: auto">
			<div v-for="preset in existingPresets" :key="preset.slug" class="mb-2">
				<div class="w-100 d-flex flex-nowrap position-relative">
					<button
						type="button"
						:class="[
							selectedPreset === preset.slug
								? ' active bg-primary-9'
								: 'btn-outline-secondary',
							'preset-btn-color',
						]"
						class="text-start shadow-0 btn flex-grow-1 d-flex justify-content-between py-2"
						@click="selectPreset(preset)"
					>
						<span class="preset-btn-text">{{ preset.name }}</span>
						<div>
							<span class="me-2 text-muted" @click.stop="renamePreset(preset)">
								<FontAwesomeIcon :icon="lookupIcon('edit', 'fas')" />
							</span>
							<span class="text-muted" @click.stop="deletePreset(preset)">
								<FontAwesomeIcon
									v-if="loadingStates.delete === preset.slug"
									:icon="lookupIcon('spinner', 'fas')"
									spin
								/>
								<FontAwesomeIcon v-else :icon="lookupIcon('times', 'fas')" />
							</span>
						</div>
					</button>
				</div>
			</div>
		</div>

		<!-- Action Buttons -->
		<div v-if="props.field.presetSystem?.allowSavedViews !== false" class="">
			<button
				class="shadow-0 btn btn-sm rounded-pill btn-outline-primary"
				type="button"
				:disabled="
					loadingStates.save || loadingStates.update || loadingStates.delete
				"
				@click="createPreset"
			>
				<FontAwesomeIcon
					v-if="loadingStates.save"
					:icon="lookupIcon('spinner', 'fas')"
					spin
					class="me-1"
				/>
				Save View
			</button>
			<button
				v-if="showUpdateButton"
				class="shadow-0 btn btn-sm rounded-pill btn-outline-primary"
				type="button"
				:disabled="
					loadingStates.save || loadingStates.update || loadingStates.delete
				"
				@click="updatePreset"
			>
				<FontAwesomeIcon
					v-if="loadingStates.update"
					:icon="lookupIcon('spinner', 'fas')"
					spin
					class="me-1"
				/>
				Update View
			</button>

			<!-- validation error messages -->
			<div v-if="errorMessages" class="text-danger">
				{{ errorMessages }}
			</div>
		</div>
		<!-- save and rename form -->
		<Teleport to="body">
			<MDBModal
				v-model="showSaveModal"
				centered
				:title="isUpdatingExisting ? 'Rename Preset' : 'Save Preset'"
			>
				<form @submit.prevent="savePreset">
					<MDBModalBody>
						<div class="position-absolute top-0 end-0 p-3">
							<button
								class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary shadow-0"
								type="button"
								@click.prevent="handleCancel"
							>
								<FontAwesomeIcon :icon="lookupIcon('xmark', 'fat')" size="lg" />
							</button>
						</div>
						<div class="text-center mt-2">
							<h4 class="modal-title text-center">
								{{ isUpdatingExisting ? "Rename Preset" : "Save Preset" }}
							</h4>
							<p class="text-center" v-html="bodyText || defaultBodyText"></p>
						</div>
						<FormField
							v-model="newPresetName"
							:field="{
								type: 'text',
								name: 'Preset Name',
								placeholder: 'Enter preset name',
								required: true,
							}"
							:error-messages="errorMessages ? [errorMessages] : []"
						/>
						<div v-if="errorMessages" class="invalid-feedback">
							{{ errorMessages }}
						</div>
					</MDBModalBody>
					<MDBModalFooter class="d-flex justify-content-center my-4">
						<button
							class="btn-outline-primary bg-transparent rounded-pill btn"
							type="button"
							@click="handleCancel"
						>
							Cancel
						</button>
						<button
							type="submit"
							class="btn rounded-pill btn-primary"
							:disabled="loadingStates.save || loadingStates.update"
						>
							<span class="d-inline-flex align-items-center">
								<MDBSpinner
									v-if="loadingStates.save || loadingStates.update"
									size="sm"
									class="spinner-button"
								/>
								<span v-else>{{ isUpdatingExisting ? "Rename" : "Save" }}</span>
							</span>
						</button>
					</MDBModalFooter>
				</form>
			</MDBModal>
		</Teleport>
	</div>
</template>

<script setup>
import { usePage, router } from "@inertiajs/vue3"
import http from "@/Services/http"
import { useToast } from "@/Composables/useToast"
import {
	setNestedProperty,
	getNestedProperty,
} from "@/Utils/dotNotationHelpers"
import {
	computed,
	inject,
	nextTick,
	ref,
	Teleport,
	watch,
	onMounted,
	onUnmounted,
} from "vue"
import route from "ziggy-js"
import RadioGroupField from "@/Components/Mod/FormFields/RadioGroupField.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { debounce } from "lodash"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import {
	MDBInput,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBSpinner,
} from "mdb-vue-ui-kit"
import FormField from "../FormField.vue"
import mitt from "mitt"

const globalEventBus = inject("globalEventBus")

const toast = useToast()

const props = defineProps({
	field: {
		type: Object,
		required: true,
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const presetColumn = computed(() => {
	return props.field.presetSystem?.column
})

const presetFieldName = computed(() => {
	return props.field.presetSystem?.name
})

const dataModelPrefix = computed(() => {
	return props.field.presetSystem?.dataModelPrefix
})

const newPresetNameField = computed(() => {
	return props.field.presetSystem?.newPresetNameField
})

const localValue = computed(() => {
	return props.dataStore && presetFieldName.value
		? props.dataStore.getModelValue({
				column: dataModelPrefix.value + "." + presetColumn.value,
				name: presetFieldName.value,
			})
		: null
})

const presetOptions = computed(() => {
	if (!localValue.value) {
		return []
	}
	return localValue.value.map((option) => {
		return {
			name: option.name,
			value: option.value,
		}
	})
})

const defaultPresets = computed(() => {
	return props.field.presetSystem?.defaultPresets || []
})

const existingPresets = computed(() => {
	return localValue.value || []
})

const isDefaultPreset = computed(() => {
	return defaultPresets.value.some((p) => p.slug === selectedPreset.value)
})

const errorMessages = ref(null)

const selectedPreset = ref("custom")
const showUpdateButton = computed(() => {
	return (
		selectedPreset.value !== "custom" &&
		!isDefaultPreset.value &&
		!isCurrentSettingsMatchingSelectedPreset.value
	)
})

const selectPreset = (preset) => {
	console.log("selectPreset", preset)
	if (preset == "custom") {
		preset = {
			slug: "custom",
			name: "Custom",
		}
	}
	selectedPreset.value = preset.slug
	if (globalEventBus) {
		globalEventBus.emit("presetSelected", preset.name)
	}

	// Save to localStorage
	localStorage.setItem(`${props.field.id}_preset_selection`, preset.slug)

	if (preset.settings) {
		Object.entries(preset.settings).forEach(([key, value]) => {
			props.dataStore.setModelValue({ name: key }, value, true)
		})
	}
}

const handleCancel = () => {
	showSaveModal.value = false
	newPresetName.value = ""
	errorMessages.value = null
	isUpdatingExisting.value = false
}

const loadingStates = ref({
	save: false,
	update: false,
	delete: null, // Will store preset slug when deleting
})

const showSaveModal = ref(false)
const newPresetName = ref("")
const isUpdatingExisting = ref(false)

const createPreset = async () => {
	isUpdatingExisting.value = false
	newPresetName.value = ""
	showSaveModal.value = true
}

const renamePreset = async (preset) => {
	isUpdatingExisting.value = true
	newPresetName.value = preset.name
	showSaveModal.value = true
	renameTargetPreset.value = preset
}

const renameTargetPreset = ref(null)

const savePreset = async (event) => {
	event?.preventDefault()

	console.log("newPresetName at start:", newPresetName.value)

	if (!newPresetName.value.trim()) {
		errorMessages.value = "Please enter a preset name"
		return
	}

	try {
		if (isUpdatingExisting.value) {
			if (loadingStates.value.update) return
			loadingStates.value.update = true
			// Handle renaming existing preset
			const existingPresets = [...localValue.value]
			const index = existingPresets.findIndex(
				(p) => p.slug === renameTargetPreset.value?.slug,
			)
			if (index > -1) {
				existingPresets[index].name = newPresetName.value
				existingPresets[index].updated_at = new Date()
				await submit(existingPresets)

				toast.success("Preset renamed successfully")
			}
		} else {
			// Handle creating new preset
			if (loadingStates.value.save) return
			loadingStates.value.save = true
			const newPreset = await submit()
			selectPreset(newPreset)

			toast.success("Preset saved successfully")
		}
		showSaveModal.value = false
	} catch (error) {
		errorMessages.value = error.message
	} finally {
		loadingStates.value.save = false
		loadingStates.value.update = false
		errorMessages.value = null
	}
}

const deletePreset = async (preset) => {
	if (loadingStates.value.delete) return
	loadingStates.value.delete = preset.slug

	try {
		const existingPresets = [...localValue.value]
		const index = existingPresets.findIndex((p) => p.slug === preset.slug)
		if (index > -1) {
			existingPresets.splice(index, 1)
			if (selectedPreset.value === preset.slug) {
				selectedPreset.value = "custom"
			}

			await submit(existingPresets)

			if (selectedPreset.value === preset.slug) {
				console.log("selecting custom")
				nextTick(() => {
					selectPreset("custom")
				})
			}
			toast.success("Preset deleted successfully")
		}
	} finally {
		console.log("finally")
		loadingStates.value.delete = null
	}
}

const updatePreset = async () => {
	if (loadingStates.value.update) return
	loadingStates.value.update = true

	try {
		const existingPresets = [...localValue.value]
		const index = existingPresets.findIndex(
			(p) => p.slug === selectedPreset.value,
		)
		if (index > -1) {
			const currentSettings = getCurrentSettings()
			existingPresets[index].settings = currentSettings
			existingPresets[index].updated_at = new Date()

			await submit(existingPresets)
			toast.success("Preset updated successfully")
		}
	} finally {
		loadingStates.value.update = false
	}
}

// Helper function to get current settings from the form
const getCurrentSettings = () => {
	const presetData = JSON.parse(JSON.stringify(props.dataStore.dataModel))
	const fieldsToKeep = props.field.presetSystem?.fields || []

	return Object.keys(presetData)
		.filter((key) => fieldsToKeep.includes(key))
		.reduce((obj, key) => {
			obj[key] = presetData[key]
			return obj
		}, {})
}

const submit = (presets = null) => {
	return new Promise((resolve, reject) => {
		try {
			const settings = getCurrentSettings()
			const existingPresets = presets || localValue.value || []
			const newName = newPresetName.value
			let newPreset = null

			if (!presets) {
				newPreset = {
					slug: generateSlug(newName),
					name: newName,
					settings: settings,
					created_at: new Date(),
					updated_at: new Date(),
					created_by: usePage().props.auth.user.id,
				}
				existingPresets.push(newPreset)
				selectedPreset.value = newPreset.slug
			}

			const presetData = JSON.parse(JSON.stringify(props.dataStore.dataModel))
			const fieldsToKeep = props.field.presetSystem?.fields || []

			const filteredPresetData = Object.keys(presetData)
				.filter((key) => fieldsToKeep.includes(key))
				.reduce((obj, key) => {
					obj[key] = presetData[key]
					return obj
				}, {})

			const updateConfig = props.field.presetSystem?.update || {}
			let targetRoute = updateConfig?.route

			if (!targetRoute) {
				reject(new Error("No route provided on preset system"))
				return
			}

			if (typeof targetRoute === "function") {
				targetRoute = targetRoute(filteredPresetData)
			} else if (!targetRoute.includes("https://")) {
				let params = updateConfig.params || {}

				if (Array.isArray(params)) {
					const paramValues = {}
					params.forEach((param) => {
						if (param.key && param.value != undefined) {
							let value = null
							if (param.value === "{{current_project}}") {
								value = usePage().props.project.slug
							} else {
								value = getNestedProperty(filteredPresetData, param.value)
							}
							paramValues[param.key] = value
						} else if (param.key && param.name) {
							const val = props.dataStore.getModelValue({
								column: param.column || null,
								name: param.name,
							})
							paramValues[param.key] = val
						}
					})
					params = paramValues
				} else {
					params.team = usePage().props.currentTeam.slug
				}

				if (updateConfig.itemRouteParamField) {
					params[updateConfig.key] = props.dataStore.getModelValue({
						name: updateConfig.itemRouteParamField,
					})
				} else {
					params[updateConfig.key] = props.dataStore.getModelValue({
						name:
							props.dataStore.dataModel.slug || props.dataStore.dataModel.id,
					})
				}

				targetRoute = route(targetRoute, params)
			}

			if (presetColumn.value) {
				delete filteredPresetData[presetColumn.value]
			} else {
				delete filteredPresetData[presetFieldName.value]
			}

			try {
				router.visit(targetRoute, {
					method: props.field.presetSystem?.method || "PATCH",
					data: {
						[presetColumn.value]: {
							[presetFieldName.value]: existingPresets,
						},
					},
					preserveState: true,
					preserveScroll: true,
					onSuccess: () => {
						resolve(newPreset || true)
					},
					onError: (errors) => {
						const errorMessage = Object.values(errors).join("\n")
						toast.error(errorMessage)
						reject(new Error(errorMessage))
					},
				})
			} catch (error) {
				console.error("Error submitting preset system:", error)
				reject(error)
			}
		} catch (error) {
			reject(error)
		}
	})
}

// Create a debounced version of the watch handler
const debouncedWatchHandler = debounce((newValue) => {
	if (
		selectedPreset.value !== "custom" &&
		isDefaultPreset.value &&
		!isCurrentSettingsMatchingSelectedPreset.value
	) {
		selectedPreset.value = "custom"
	}
}, 500)

// Update the watch to use the debounced handler
watch(
	() => props.dataStore.dataModel,
	(newValue) => {
		debouncedWatchHandler(newValue)
	},
	{ deep: true },
)

// Existing method to check if current settings match a given preset
const isCurrentSettingsMatchingPreset = (presetSlug) => {
	console.log("preset: ", presetSlug)
	const preset = [...defaultPresets.value, ...existingPresets.value].find(
		(p) => p.slug === presetSlug,
	)
	if (!preset?.settings) return false

	return Object.entries(preset.settings).every(([key, value]) => {
		const curVal = props.dataStore.getModelValue({ name: key })
		console.log(curVal, value)
		return curVal === value
	})
}

// New computed property that uses the existing method
const isCurrentSettingsMatchingSelectedPreset = computed(() => {
	return isCurrentSettingsMatchingPreset(selectedPreset.value)
})

// Helper function to generate a slug (you can adjust this as needed)
const generateSlug = (name) => {
	return (
		name
			.toLowerCase()
			.replace(/[^a-z0-9]+/g, "-")
			.replace(/(^-|-$)/g, "") +
		"-" +
		Date.now().toString(36)
	)
}

onMounted(() => {
	const savedPresetSlug = localStorage.getItem(
		`${props.field.id}_preset_selection`,
	)
	if (savedPresetSlug) {
		const allPresets = [...defaultPresets.value, ...existingPresets.value]
		const savedPreset = allPresets.find(
			(preset) => preset.slug === savedPresetSlug,
		)
		if (savedPreset) {
			selectPreset(savedPreset)
		}
	}

	if (globalEventBus) {
		globalEventBus.on("filter-applied", (filters) => {
			console.log("filter-applied", filters)

			if (!isCurrentSettingsMatchingSelectedPreset.value) {
				selectPreset("custom")
			}
		})
	}
})

onUnmounted(() => {
	if (globalEventBus) {
		globalEventBus.off("filter-applied")
	}
})
</script>

<style lang="scss" scoped>
.preset-btn-color {
	color: var(--mdb-card-color);
	transition: background-color 0.3s ease;
	border: none;
}

.preset-hr {
	opacity: 0.1;
}

.spinner-button {
	width: 1em !important;
	height: 1em !important;
}
</style>
