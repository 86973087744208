export const downloadFile = async (downloadUrl, filename = "download") => {
	if (!downloadUrl) {
		console.error("Download URL is not defined")
		return
	}

	// Get the signed URL and Content-Disposition from your server
	const response = await http.get(downloadUrl)

	if (!response.data || !response.data.url) {
		throw new Error("Invalid response from server")
	}

	const signedUrl = response.data.url

	// Extract filename from Content-Disposition header
	const contentDisposition = response.headers["content-disposition"]
	if (contentDisposition) {
		const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/i)
		if (filenameMatch) {
			filename = filenameMatch[1]
		}
	}

	// Create a hidden anchor element to trigger the download
	const link = document.createElement("a")
	link.href = signedUrl
	link.setAttribute("download", filename)
	link.setAttribute("target", "_blank") // Open in a new tab
	document.body.appendChild(link)
	link.click()
	link.remove()
}
