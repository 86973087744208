<template>
	<div>Showing {{ displayCurrent }} of {{ total }}</div>
</template>

<script setup>
import { MDBSelect } from "mdb-vue-ui-kit"
import { computed } from "vue"

const props = defineProps({
	total: {
		type: Number,
		default: 0,
	},
	current: {
		type: Number,
		default: 0,
	},
})

const displayCurrent = computed(() => {
	return isNaN(props.current) ? 0 : props.current
})
</script>

<style scoped>
div {
	font-size: 12px;
}
</style>
