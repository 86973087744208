<template>
	<div
		class="time-series-chart d-flex flex-column flex-grow-1 my-0 p-4 card"
		:class="props.field.classList"
	>
		<div class="chart-label flex-shrink-0 my-0 py-0">
			<h5 class="chart-label-text py-0 my-0">{{ chartTitle }}</h5>
		</div>

		<!-- Bootstrap Tabs Navigation -->
		<div
			v-if="localValue?.data && localValue?.data?.length > 0"
			class="d-flex flex-column min-h-0 my-0 py-0 flex-grow-1"
		>
			<nav class="nav-tabs-header my-2 d-flex align-items-center">
				<button
					:disabled="isLoading"
					class="shadow-0 rounded-circle btn btn-outline-primary btn-floating btn-sm me-2"
					@click="scrollTabs('left')"
				>
					<FontAwesomeIcon :icon="lookupIcon('angle-left', 'fas')" />
				</button>

				<ul
					ref="tabsContainer"
					class="nav nav-tabs nav-tabs-header flex-grow-1 justify-content-start flex-nowrap"
				>
					<li
						v-for="(series, index) in localValue?.data"
						:key="index"
						class="nav-item"
					>
						<button
							class="nav-link mx-2 px-4 border py-2 fw-bold nav-link nav-item my-1 my-md-0 border text-black bg-white"
							:class="{ active: currentIndex === index }"
							@click="setActiveTab(index)"
						>
							{{
								formatTabTimestamp(series.timestamp) || `Series ${index + 1}`
							}}
						</button>
					</li>
				</ul>

				<button
					:disabled="isLoading"
					class="shadow-0 rounded-circle btn btn-outline-primary btn-floating btn-sm ms-2"
					@click="scrollTabs('right')"
				>
					<FontAwesomeIcon :icon="lookupIcon('angle-right', 'fas')" />
				</button>
			</nav>

			<!-- Tab Content -->
			<div class="tab-content flex-grow-1 min-h-0">
				<div class="tab-pane fade h-100" :class="{ 'show active': true }">
					<div class="pane-wrapper h-100 d-flex flex-column">
						<div class="legend-container flex-shrink-0">
							<div
								:id="`legend-${tileId}`"
								class="d-flex justify-content-end align-items-center"
							></div>
						</div>

						<div
							v-if="!isLoading"
							class="chart-container flex-grow-1 overflow-auto"
						>
							<div
								:id="chartId"
								class="custom-chart line-chart"
								:style="{ height: '100%' }"
							></div>
						</div>

						<div class="d-flex justify-content-center gap-4 flex-wrap">
							<div
								v-for="(totalDetails, key) in currentTotals"
								:key="key"
								class="total-value bg-secondary-2 rounded-6 text-start px-3 py-2"
								:class="{
									'flex-grow-1': Object.keys(currentTotals).length === 3,
									'w-45': Object.keys(currentTotals).length === 4,
								}"
							>
								<div class="text-muted small total-label mb-0">
									{{ getChartLabel(key) }}
								</div>
								<div class="total-amount mt-0">
									<span class="total-amount-value-text fw-medium">{{
										totalDetails.value
									}}</span>
									<span class="total-text-append small ms-2">{{
										totalDetails.appendText
									}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { bb, line, spline, zoom } from "billboard.js"
import "billboard.js/dist/billboard.css"
import "billboard.js/dist/theme/graph.css"
import { storeToRefs } from "pinia"
import { useNavStore } from "@/Store/navStore"
import { formatTimestamp } from "@/Utils/dateHelpers"
import { formatLabel } from "@/Utils/labelHelpers"
import {
	ref,
	computed,
	watch,
	defineModel,
	onMounted,
	onBeforeUnmount,
	nextTick,
	inject,
} from "vue"
import { formatSecondsToDurationString } from "@/Utils/dateHelpers"
import { DateTime } from "luxon"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { formatNumber } from "@/Utils/formatNumber"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const globalEventBus = inject("globalEventBus")

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})

const currentIndex = ref(0)
const bbChart = ref(null)
const isLoading = ref(false)

const defaultsFromVarius = {
	total_social_stream_posts: "Submissions",
	unique_users: "Unique Users",
	total_generations: "Generations",
}

const chartGroupId = ref(props.field.chartGroupId)

// Computed properties
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})

const chartId = computed(() => `chart-${dataModel.value?.tileData?.tileId}`)
const tileId = computed(() => dataModel.value?.tileData?.tileId)

const chartRequest = computed(() => {
	return (
		JSON.parse(
			dataModel.value?.tileData?.custom_settings?.chart_requests || "[]",
		) || []
	)
})

const currentTotals = computed(() => {
	if (!localValue.value?.data || !localValue.value.data[currentIndex.value])
		return {}

	let totals = localValue.value.data[currentIndex.value].totals

	// get the chart request
	const curRequestInfo = chartRequest.value[0]

	// get the metrics

	// get the output definition
	const outputDefinition = curRequestInfo.outputDefinition

	// apply formats to each based on output definition
	const finalTotals = {}
	Object.entries(totals).forEach(([key, value]) => {
		if (!outputDefinition) {
			finalTotals[key] = {
				value: value,
			}
			return
		}

		if (outputDefinition[key]?.format === "duration") {
			value = formatSecondsToDurationString(value)
		} else {
			try {
				value = formatNumber(value)
			} catch (error) {}
		}

		finalTotals[key] = {
			appendText: outputDefinition[key]?.appendText || "",
			value: value,
		}
	})
	return finalTotals
})

const chartTitle = computed(() => {
	return chartRequest.value.length > 0
		? chartRequest.value[0]?.title || props.field.title || ""
		: props.field.title || ""
})

const outputDefinition = computed(() => {
	return chartRequest.value.length > 0
		? chartRequest.value[0]?.outputDefinition || []
		: []
})

const chartOptions = computed(() => {
	return chartRequest.value.length > 0
		? chartRequest.value[0]?.chartOptions || {}
		: {}
})

const primaryInterval = computed(() => {
	return localValue.value.config.primary_interval
})

const timezone = computed(() => {
	return localValue.value.config.timezone
})

// Add ref for tabs container
const tabsContainer = ref(null)

// Methods
const scrollTabs = (direction) => {
	if (!tabsContainer.value) return

	const scrollAmount = 200 // Adjust this value as needed
	const currentScroll = tabsContainer.value.scrollLeft
	const newScroll =
		direction === "left"
			? currentScroll - scrollAmount
			: currentScroll + scrollAmount

	tabsContainer.value.scrollTo({
		left: newScroll,
		behavior: "smooth",
	})

	// Also update the selected tab
	// if (direction === "left" && currentIndex.value > 0) {
	// 	setActiveTab(currentIndex.value - 1)
	// } else if (
	// 	direction === "right" &&
	// 	currentIndex.value < localValue.value?.data?.length - 1
	// ) {
	// 	setActiveTab(currentIndex.value + 1)
	// }
}

// Modify the method to accept a smooth parameter
const scrollToActiveTab = (smooth = true) => {
	nextTick(() => {
		if (!tabsContainer.value) return

		const activeTab = tabsContainer.value.querySelector(".nav-link.active")
		if (!activeTab) return

		const containerRect = tabsContainer.value.getBoundingClientRect()
		const tabRect = activeTab.getBoundingClientRect()
		const containerWidth = containerRect.width
		const tabWidth = tabRect.width
		const maxScroll = tabsContainer.value.scrollWidth - containerWidth

		const tabLeft =
			tabRect.left - containerRect.left + tabsContainer.value.scrollLeft
		let scrollPosition = tabLeft - (containerWidth - tabWidth) / 2
		scrollPosition = Math.max(0, Math.min(scrollPosition, maxScroll))

		setTimeout(() => {
			tabsContainer.value.scrollTo({
				left: scrollPosition,
				behavior: smooth ? "smooth" : "auto",
			})
		}, 100)
	})
}

// Modify setActiveTab to include scrolling
const setActiveTab = (index) => {
	currentIndex.value = index
	if (chartGroupId.value) {
		globalEventBus.emit("chart-tab-change", {
			groupId: chartGroupId.value,
			index: index,
		})
	}
	nextTick(() => {
		generateChart()
		scrollToActiveTab(true) // Smooth scroll for user interactions
	})
}

const formatData = (data) => {
	const headerRow = ["x"]
	data.details.forEach((detail) => {
		headerRow.push(detail.timestamp)
	})

	const seriesData = {}
	const metricsOrder = chartRequest.value[0]?.metrics || []

	const filteredMetricsOrder = metricsOrder.filter(
		(series) => outputDefinition.value[series]?.time_series !== false,
	)

	console.log("filteredMetricsOrder", outputDefinition.value)

	filteredMetricsOrder.forEach((series) => {
		const label = getChartLabel(series)

		seriesData[series] = [label]
	})

	console.log("seriesData", seriesData)

	data.details.forEach((detail) => {
		for (const series of filteredMetricsOrder) {
			seriesData[series].push(detail.totals[series] || 0)
		}
	})

	return [headerRow, ...Object.values(seriesData)]
}

const getChartLabel = (key) => {
	if (dataModel?.tileData?.custom_settings?.chart_labels?.[key]) {
		return dataModel.tileData.custom_settings.chart_labels[key]
	}
	if (localValue.value?.labels?.[key]) {
		return localValue.value.labels[key]
	}
	if (defaultsFromVarius[key]) {
		return defaultsFromVarius[key]
	}
	return formatLabel(key)
}

const formatMap = {
	day: "%Y-%m-%d",
	hour: "%H:%M",
}

const isProgrammaticZoom = ref(false)
const isProgrammaticUnzoom = ref(false)
const generateChart = () => {
	if (!localValue.value?.data) return
	isLoading.value = true

	bbChart.value?.destroy()

	const data = localValue.value.data[currentIndex.value]
	if (!data) {
		console.error("No data found for index:", currentIndex.value)
		isLoading.value = false
		return
	}

	const chartElement = document.getElementById(chartId.value)
	if (!chartElement) {
		console.error("Chart element not found:", chartId.value)
		isLoading.value = false
		return
	}

	const parsedData = formatData(data)
	const legendBindTo = `#legend-${tileId.value}`

	try {
		bbChart.value = bb.generate({
			data: {
				x: "x",
				columns: parsedData.map((series) => series),
				type: spline(),
			},
			spline: {
				interpolation: {
					type: "basis",
				},
				connectNull: true,
				width: 3, // Adjust the stroke width here
			},

			line: {
				width: 3,
			},
			point: { show: false },
			axis: {
				x: {
					type: "timeseries",
					tick: {
						format: formatMap[localValue.value.config.secondary_interval],
					},
				},
			},
			legend: {
				contents: {
					bindto: legendBindTo,
					template: (title, color) =>
						`<span class="d-flex align-items-center" style="margin-right:1.5rem;">
				<span style="display:inline-block;width:12px;height:12px;border-radius:50%;background-color:${color};margin-right:5px;"></span>
				<span style="color:#333;">${title}</span>
			  </span
			  >`,
				},
				show: !chartOptions.value.hideLegend,
			},
			grid: {
				x: {
					show: true,
					lines: [{ class: "solid-line" }],
				},
				y: {
					show: true,
					lines: [{ class: "solid-line" }],
				},
			},
			zoom: {
				enabled: zoom(),
				type: "drag",
				onzoom: (domain) => {
					if (!isProgrammaticZoom.value && chartGroupId.value) {
						globalEventBus.emit("chart-zoom-change", {
							groupId: chartGroupId.value,
							domain,
							sourceChartId: chartId.value,
						})
					}
					isProgrammaticZoom.value = false
				},
				resetButton: {
					onclick: () => {
						if (!isProgrammaticUnzoom.value && chartGroupId.value) {
							globalEventBus.emit("chart-unzoom", {
								groupId: chartGroupId.value,
								sourceChartId: chartId.value,
							})
						}
						isProgrammaticUnzoom.value = false
					},
				},
			},

			bindto: chartElement,
			tooltip: {
				format: {
					title: (d) => {
						return (
							new Intl.DateTimeFormat("en-US", {
								hour: "2-digit",
								minute: "2-digit",
							}).format(new Date(d)) +
							" " +
							DateTime.local().setZone(timezone.value).toFormat("ZZZZ")
						)
					},
					value: (value) => formatSecondsToDurationString(value),
					label: {
						format: (value, id) => `${getChartLabel(id)}: ${value}`,
					},
				},
				linked: true,
			},
			color: {
				pattern: [
					"#000596", // blue 3

					"#d5d6ff", // blue 9
					"#e3e4e6", // gray 3
					"#0008db", // blue 5
					"#54b4d3", // info
				],
			},
		})
	} catch (error) {
		console.error("Error generating chart:", error)
	} finally {
		isLoading.value = false
	}
}

/**
 * sets the index of the tab to the closest date
 */
const findClosestTabIndex = () => {
	if (!localValue.value?.data) return 0

	const now = DateTime.now().setZone(timezone.value)
	let closestIndex = 0
	let smallestDiff = Infinity

	localValue.value.data.forEach((series, index) => {
		const tabDate = DateTime.fromFormat(
			series.timestamp,
			primaryInterval.value === "hour" ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd",
			{ zone: timezone.value },
		)

		const diff = Math.abs(now.diff(tabDate).as("milliseconds"))
		if (diff < smallestDiff) {
			smallestDiff = diff
			closestIndex = index
		}
	})

	return closestIndex
}

// Watchers
watch(
	() => localValue.value,
	(newValue) => {
		if (newValue?.data?.length > 0) {
			if (currentIndex.value === 0) {
				currentIndex.value = findClosestTabIndex()
				setTimeout(() => {
					scrollToActiveTab(false) // Immediate scroll on load
				}, 200)
			}
			nextTick(() => {
				generateChart()
			})
		}
	},
)

// Lifecycle hooks
onMounted(() => {
	if (localValue.value?.data?.length > 0) {
		nextTick(() => {
			setTimeout(() => {
				generateChart()
			}, 100)
		})
	}

	if (chartGroupId.value) {
		globalEventBus.on("chart-tab-change", ({ groupId, index }) => {
			if (groupId === chartGroupId.value && index !== currentIndex.value) {
				currentIndex.value = index
				nextTick(() => {
					generateChart()
					scrollToActiveTab()
				})
			}
		})

		globalEventBus.on(
			"chart-zoom-change",
			({ groupId, domain, sourceChartId }) => {
				if (
					groupId === chartGroupId.value &&
					sourceChartId !== chartId.value &&
					bbChart.value
				) {
					// Set flag before applying zoom
					isProgrammaticZoom.value = true
					bbChart.value.zoom(domain)
				}
			},
		)

		globalEventBus.on("chart-unzoom", ({ groupId, sourceChartId }) => {
			if (
				groupId === chartGroupId.value &&
				sourceChartId !== chartId.value &&
				bbChart.value
			) {
				isProgrammaticUnzoom.value = true
				bbChart.value.unzoom()
			}
		})
	}
})

onBeforeUnmount(() => {
	bbChart.value?.destroy()
	// Clean up event listener
	if (chartGroupId.value) {
		globalEventBus.off("chart-tab-change")
		globalEventBus.off("chart-zoom-change")
		globalEventBus.off("chart-unzoom")
	}
})

const formatTabTimestamp = (timestamp) => {
	if (!timestamp) return null

	switch (primaryInterval.value) {
		case "day":
		case "week":
			const parsed = DateTime.fromFormat(timestamp, "yyyy-MM-dd", {
				zone: timezone.value,
			})
			return parsed.toLocal().toFormat("M/d/yyyy")
		case "hour":
			return formatTimestamp(timestamp, "M/d/yyyy HH:mm")
		default:
			return timestamp
	}
}
</script>

<style lang="scss">
@import "../../scss/analytics/billboardTheme.scss";
</style>

<style lang="scss" scoped>
.total-value {
	text-align: center;
	min-width: 100px;
	max-width: 300px;
	border: 1px solid transparent;
	transition: border 0.3s;
}

.solid-line line {
	stroke-dasharray: none !important;
}

.bb-xgrid,
.bb-ygrid {
	stroke-dasharray: none !important;
}

.time-series-chart {
	overflow: hidden;
}

.chart-label {
	flex: 0 0 40px;
}

.nav-tabs-header {
	flex: 0 0 40px;
	overflow-x: auto;
	scroll-behavior: smooth;
	white-space: nowrap;
	-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
	scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.nav-tabs-header::-webkit-scrollbar {
	display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.nav-item {
	display: inline-block; /* Keep tabs in a single line */
}

.chart-content {
	flex: 1;
}

.legend-container {
	flex: 0 0 40px;
}

.pane-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.chart-container {
	flex-grow: 1;
	overflow: auto;
}

.total-value {
	font-size: 1.5rem;
	text-align: center;
}

.total-value:hover {
	border: 1px solid black;
}

.total-label {
	color: #6c757d;
}

.total-amount .total-value {
	font-size: clamp(1rem, 5vw + 1rem, 30px);
}

.total-value.w-100 {
	min-width: 0;
	max-width: none;
}

.nav-arrow {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	color: #007bff;
}

.nav-arrow:disabled {
	color: #6c757d;
	cursor: not-allowed;
}

/* Bootstrap tab transitions */
.tab-pane {
	transition: opacity 0.15s linear;
}

.tab-pane:not(.show) {
	opacity: 0;
}

.tab-pane.show {
	opacity: 1;
}

.w-45 {
	width: 45% !important;
	max-width: none;
}
</style>
