<template>
	<MDBAutocomplete
		:id="inputId"
		:model-value="localValue"
		:label="props.field.label || props.label"
		:multiple="props.field.multiple || props.multiple || false"
		:required="props.field.required || props.required || false"
		variant="solo-filled"
		:readonly="props.field.readonly || props.readonly || false"
		:class="props.field.classList || props.classList || []"
		:error-messages="errorMessages"
		:filter="autocompleteFilter"
		:form-outline="false"
		wrapper-class="form-floating"
		@update:model-value="onInput"
	/>
</template>

<script setup>
import { computed, defineModel, defineEmits, onMounted, shallowRef } from "vue"
import { MDBAutocomplete } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const emit = defineEmits(["update:modelValue"])

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = shallowRef(null)
const autocompleteFilter = (input, d2) => {
	let initialOptions = props.field.options || props.options
	return initialOptions.filter((item) => {
		return (
			item.title.toLowerCase().includes(input.toLowerCase()) ||
			item.value.toString().toLowerCase().includes(input.toLowerCase())
		)
	})
}
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}
const onInput = (value) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, value, true)
	}
	localValue.value = value
	if (fieldValue.value !== undefined) {
		fieldValue.value = localValue.value
	}
	emit("update:modelValue", value)
}
onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>
