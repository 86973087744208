<template>
	<div style="position: relative">
		<img
			v-if="computedLocalValue"
			ref="mapImage"
			:class="props.field.classList || props.classList || ['mw-100']"
			:height="props.field.height || props.height || 'auto'"
			:src="computedLocalValue"
			@load="imageLoaded()"
		/>
		<template v-if="showMarkers && markerList && markerList.length > 0">
			<template v-for="(marker, i) in markerList" :key="i">
				<template
					v-if="
						marker.type == 'initial-circle' &&
						marker.xCoordinate &&
						marker.yCoordinate
					"
				>
					<div
						class="circle-marker"
						:style="{
							left: marker.xCoordinate,
							top: marker.yCoordinate,
						}"
					>
						<span class="circle-marker-initial">{{
							marker.title[0] || "AP"
						}}</span>
					</div>
				</template>
			</template>
		</template>
	</div>
</template>

<script setup>
import { computed, defineModel, onMounted, shallowRef, ref, watch } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { Link } from "@inertiajs/vue3"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	base64: {
		type: Boolean,
		default: false,
	},
	height: {
		type: String,
		default: "auto",
	},
	classList: {
		type: Array,
		default: () => ["mw-100"],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

const fieldValue = defineModel({
	type: String,
	default: undefined,
})

const mapImage = ref(null)
const showMarkers = ref(false)
const localValue = shallowRef(null)
const imageLoaded = () => {
	if (
		props.field.markerListKey &&
		props.dataStore &&
		props.field.markerListKey
	) {
		showMarkers.value = true
		markerList.value = computeMarkerList()
	}
}
const markerList = ref([])
const computeMarkerList = () => {
	if (
		props.field.markerListKey &&
		props.dataStore &&
		props.field.markerListKey &&
		showMarkers.value
	) {
		if (props.field.markerPlot) {
			let mPlot = props.field.markerPlot(
				props.dataStore.getNestedValue(
					dataModel.value,
					props.field.markerListKey,
				),
			)
			return mPlot
				? mPlot.map((marker) => {
						return {
							type: marker.type,
							xCoordinate: computeLeft(marker.xCoordinate),
							yCoordinate: computeTop(marker.yCoordinate),
							title: marker.title,
						}
					})
				: []
		} else {
			return props.dataStore.getNestedValue(
				dataModel.value,
				props.field.markerListKey,
			)
		}
	}
	return []
}
const computedLocalValue = computed(() => computeFieldValue())
const computeFieldValue = () => {
	let urlContent = fieldValue.value || props.field.content || props.content
	let getVal =
		props.dataStore &&
		props.dataStore.getModelValue({
			column: "dashboard_assets",
			name: urlContent,
			disableDotNotation: true,
		})
	if (getVal) {
		return getVal
	} else {
		if (props.field.name && props.dataStore) {
			let retValue = props.dataStore.getModelValue({
				column: props.field.column || null,
				name: props.field.name || null,
				disableDotNotation: true,
			})
			if (props.base64) {
				return "data:" + retValue.mime + ";base64," + retValue.base64
			} else {
				return retValue
			}
		} else {
			return urlContent
		}
	}
}
const computeLeft = (left) => {
	if (!left) {
		return "0px"
	}
	let xRatio = mapImage.value.clientWidth / mapImage.value.naturalWidth
	let xOffset = Math.floor(left * xRatio)
	return `${xOffset}px`
}
const computeTop = (top) => {
	if (!top) {
		return "0px"
	}
	let yRatio = mapImage.value.clientHeight / mapImage.value.naturalHeight
	let yOffset = Math.floor(top * yRatio)
	return `${yOffset}px`
}
onMounted(() => {
	// localValue.value = computeFieldValue()
})

window.addEventListener("resize", () => {
	markerList.value = computeMarkerList()
})
</script>
<style scoped>
.circle-marker {
	position: absolute;
	width: 50px;
	height: 50px;
	text-align: center;
	font-size: 28px;
	color: #fff;
	background-color: #000;
	border-radius: 50%;
	margin-left: -30px;
	margin-top: -30px;
}
</style>
