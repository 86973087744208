import { ref, computed } from "vue"
import { getNestedProperty } from "@/Utils/dotNotationHelpers.js"
export function useFormModel(props) {
	/**
	 * Builds a model based on a field list provided in the configurations
	 * @param {*} baseModel the base laravel model
	 * @param {*} configurations the configurations for the model
	 * @param {*} parentProps
	 * @returns
	 */
	function buildModel(baseModel, configurations) {
		let model = JSON.parse(JSON.stringify(baseModel))
		if (configurations?.fieldsList) {
			configurations.fieldsList.forEach((field) => {
				if (field.name) {
					model[field.name] =
						baseModel[field.name] ||
						field.default ||
						(field.type === "boolean" ? false : "")
				}
			})
		}
		return model
	}

	return {
		buildModel,
	}
}
