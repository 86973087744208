<template>
	<div
		v-if="checkFeaturesAndPermissions(fieldConfig) && showField"
		:class="getColClasses()"
	>
		<component
			:is="renderComponent"
			v-if="renderComponent"
			v-model="fieldValue"
			v-bind="propertyBindings"
			:data-store="props.dataStore"
			@update:model-value="onInput"
		/>
		<template v-if="errorMessages.length > 0">
			<div class="text-danger">
				{{ errorMessages }}
			</div>
		</template>
	</div>
</template>
<script setup>
import {
	computed,
	defineEmits,
	defineProps,
	ref,
	shallowRef,
	onMounted,
	watch,
} from "vue"
import { checkConditions } from "@/Composables/useConditionParams"

// Import FormFields
import FormModal from "@/Components/Mod/FormModal.vue"
import RangeField from "@/Components/Mod/FormFields/RangeField.vue"
import QrDisplay from "@/Components/Mod/FormFields/QrDisplay.vue"
import LiveHtml from "@/Components/Mod/FormFields/LiveHtml.vue"
import IconSwitch from "@/Components/Mod/FormFields/IconSwitch.vue"
import FormBuilder from "@/Components/Mod/FormFields/FormBuilder.vue"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import BarcodeReader from "@/Components/Mod/FormFields/BarcodeReader.vue"
import TextareaField from "@/Components/Mod/FormFields/TextareaField.vue"
import TypeDisplay from "@/Components/Mod/FormFields/TypeDisplay.vue"
import BannerDisplay from "@/Components/Mod/FormFields/BannerDisplay.vue"
import SelectField from "@/Components/Mod/FormFields/SelectField.vue"
import SwitchField from "@/Components/Mod/FormFields/SwitchField.vue"
import CheckboxField from "@/Components/Mod/FormFields/CheckboxField.vue"
import ColorPickerField from "@/Components/Mod/FormFields/ColorPickerField.vue"
import ClipboardDisplay from "@/Components/Mod/FormFields/ClipboardDisplay.vue"
import ButtonField from "@/Components/Mod/FormFields/ButtonField.vue"
import ImageDisplay from "@/Components/Mod/FormFields/ImageDisplay.vue"
import VideoPlayer from "@/Components/Mod/FormFields/VideoPlayer.vue"
import IconDisplay from "@/Components/Mod/FormFields/IconDisplay.vue"
import IconSelector from "@/Components/Mod/FormFields/IconSelector.vue"
import UploadField from "@/Components/Mod/FormFields/UploadField.vue"
import TimestampDisplay from "@/Components/Mod/FormFields/TimestampDisplay.vue"
import AutocompleteField from "@/Components/Mod/FormFields/AutocompleteField.vue"
import CodeEditorField from "@/Components/Mod/FormFields/CodeEditorField.vue"
import HtmlEditorField from "@/Components/Mod/FormFields/HtmlEditorField.vue"
import PagePluginSelector from "@/Components/Selectors/PagePluginSelector.vue"
import PagePluginVersionSelector from "@/Components/Selectors/PagePluginVersionSelector.vue"
import ProjectPlanSelector from "@/Components/Selectors/ProjectPlanSelector.vue"
import HtmlDisplay from "@/Components/Mod/FormFields/HtmlDisplay.vue"
import ProjectAssetSelector from "@/Components/Selectors/ProjectAssetSelector.vue"
import DateTimePickerField from "@/Components/Mod/FormFields/DateTimePickerField.vue"
import TagListField from "@/Components/Mod/FormFields/TagListField.vue"
import AsyncCardList from "@/Components/Mod/FormFields/AsyncCardList.vue"
import MapCoordinates from "@/Components/Mod/FormFields/MapCoordinates.vue"
import RadioGroupField from "@/Components/Mod/FormFields/RadioGroupField.vue"
import HiddenField from "@/Components/Mod/FormFields/HiddenField.vue"
import AdvancedSearch from "@/Components/AdvancedSearch.vue"
import AsyncSelector from "@/Components/Mod/AsyncSelector.vue"
import { router, usePage, Link } from "@inertiajs/vue3"
import DataTable from "@/Components/Mod/DataTable.vue"
import IntegrationConnector from "@/Components/Mod/IntegrationConnector.vue"
import { storeToRefs } from "pinia"
import DownloadButtonField from "@/Components/Mod/FormFields/DownloadButtonField.vue"
import ProgressBarField from "@/Components/Mod/FormFields/ProgressBarField.vue"
import IframeField from "@/Components/Mod/FormFields/IframeField.vue"
import DonutChart from "@/Components/ChartDonut.vue"
import BarChart from "@/Components/ChartBar.vue"
import ChartTimeSeries from "@/Components/ChartTimeSeries.vue"
import { checkFeaturesAndPermissions } from "@/Composables/useModVisibilityChecker"
import RenderMarkdown from "@/Components/Mod/FormFields/RenderMarkdown.vue"
import DynamicOptions from "@/Components/Mod/FormFields/DynamicOptions.vue"
import SpriteDisplay from "@/Components/Mod/FormFields/SpriteDisplay.vue"
import PhoneNumberField from "@/Components/Mod/FormFields/PhoneNumberField.vue"
import FilterDialog from "@/Components/Mod/FilterDialog.vue"
import PresetSystem from "@/Components/Mod/FormFields/PresetSystem.vue"
import TimezonePicker from "@/Components/Mod/FormFields/TimezonePicker.vue"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	condition: {
		type: Function,
		default: null,
	},
	type: {
		type: String,
		default: "text",
	},
	name: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	default: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: null,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
	columnClass: {
		type: String,
		default: null,
	},
	previewMode: {
		type: Boolean,
		default: false,
	},
})
const fieldValue = defineModel({
	type: [String, Number, Boolean, Array, Object],
	default: null,
})

const page = usePage()
// const inputId = computed(() => {
// 	return props.field.id || props.field.name
// })
const fieldConfig = computed(() => props.field)
const { dataModel } = storeToRefs(props.dataStore)

const renderComponent = shallowRef(false)

let fieldType = props.field.type || props.type

let propList = []
const setProps = (propListAssigment) => {
	propList = propListAssigment
}

switch (fieldType) {
	case "form-modal":
		setProps(["form-modal", "table-style"])
		renderComponent.value = FormModal
		break
	case "range":
		setProps(["errorMessages"])
		renderComponent.value = RangeField
		break
	case "qr_code":
		setProps(["label", "name", "default"])
		renderComponent.value = QrDisplay
		break
	case "live-html":
		setProps(["label", "name", "default", "formModel"])
		renderComponent.value = LiveHtml
		break
	case "banner":
		setProps(["label", "name", "default"])
		renderComponent.value = BannerDisplay
		break
	case "heading-large":
	case "heading":
	case "small-heading":
	case "heading-small":
	case "blockquote":
	case "description":
		setProps(["type"])
		renderComponent.value = TypeDisplay
		break
	case "external_link":
	case "button":
		setProps(["label", "name", "default", "type"])
		renderComponent.value = ButtonField
		break
	case "image":
		setProps(["label", "name", "default", "base64"])
		renderComponent.value = ImageDisplay
		break
	case "sprite":
		setProps(["label", "name", "default", "fill", "height", "width"])
		renderComponent.value = SpriteDisplay
		break
	case "video":
		setProps(["label", "name", "default"])
		renderComponent.value = VideoPlayer
		break
	case "icon":
		setProps(["label", "name", "default"])
		renderComponent.value = IconDisplay
		break
	case "icon-selector":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = IconSelector
		break
	case "clipboard":
		setProps(["label", "name", "default"])
		renderComponent.value = ClipboardDisplay
		break
	case "timestamp":
		setProps(["label", "format"])
		renderComponent.value = TimestampDisplay
		break
	case "text":
	case "number":
	case "email":
	case "password":
		setProps(["label", "name", "default", "errorMessages", "type"])
		renderComponent.value = TextField
		break
	case "textarea":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = TextareaField
		break
	case "barcode":
		setProps(["label", "name", "default", "errorMessages", "type"])
		renderComponent.value = BarcodeReader
		break
	case "select":
		setProps(["label", "name", "default", "type"])
		renderComponent.value = SelectField
		break
	case "pill_select":
	case "multi_select":
		setProps(["label", "name", "default", "type", "multiple", "pills"])
		renderComponent.value = SelectField
		break
	case "checkbox":
		setProps(["label", "name", "errorMessages"])
		renderComponent.value = CheckboxField
		break
	case "autocomplete":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = AutocompleteField
		break
	case "radio":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = RadioGroupField
		break
	case "switch":
	case "boolean":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = SwitchField
		break
	case "boolean_icon":
		setProps([
			"id",
			"tag",
			"label",
			"name",
			"default",
			"wrapperClass",
			"labelClass",
			"inputClass",
		])
		renderComponent.value = IconSwitch
		break
	case "file":
		setProps(["label", "name", "default", "errorMessages", "base64"])
		renderComponent.value = UploadField
		break
	case "selectAsset":
		setProps(["label", "name", "default", "previewMode"])
		renderComponent.value = ProjectAssetSelector
		break
	case "codeEditor":
		setProps(["label", "name", "default"])
		renderComponent.value = CodeEditorField
		break
	case "htmlEditor":
		setProps(["label", "name", "default"])
		renderComponent.value = HtmlEditorField
		break
	case "colorPicker":
		setProps(["label", "name", "default", "errorMessages"])
		renderComponent.value = ColorPickerField
		break
	case "datetime":
	case "date":
	case "time":
		setProps(["label", "name", "default", "type"])
		renderComponent.value = DateTimePickerField
		break
	case "map_coordinates":
		setProps(["label", "name", "mapName", "content", "additionalMarkers"])
		renderComponent.value = MapCoordinates
		break
	case "tag_list":
		setProps(["label", "name", "default", "button-text"])
		renderComponent.value = TagListField
		break
	case "asyncSelect":
		setProps(["label", "name", "default"])
		renderComponent.value = AsyncSelector
		break
	case "selectPagePlugin":
		setProps(["label", "name", "default"])
		renderComponent.value = PagePluginSelector
		break
	case "selectPagePluginVersion":
		setProps(["label", "name", "default"])
		renderComponent.value = PagePluginVersionSelector
		break
	case "selectProjectPlan":
		setProps(["label", "name", "default"])
		renderComponent.value = ProjectPlanSelector
		break
	case "form_builder":
		setProps(["label", "name", "default"])
		renderComponent.value = FormBuilder
		break
	case "html":
	case "html-block":
		setProps(["label", "name", "default"])
		renderComponent.value = HtmlDisplay
		break
	case "data_table":
		setProps(["data_table"])
		renderComponent.value = DataTable
		break
	case "integration_connector":
		setProps(["errorMessages"])
		renderComponent.value = IntegrationConnector
		break
	case "hidden":
		setProps(["label", "name", "default"])
		renderComponent.value = HiddenField
		break
	case "async_card_list":
		setProps(["label", "name", "default"])
		renderComponent.value = AsyncCardList
		break
	case "download_button":
		renderComponent.value = DownloadButtonField
		break
	case "progress_bar":
		renderComponent.value = ProgressBarField
		break
	case "iframe":
		renderComponent.value = IframeField
		break
	case "donut_chart":
		renderComponent.value = DonutChart
		break
	case "bar_chart":
		renderComponent.value = BarChart
		break
	case "time_series_chart":
		renderComponent.value = ChartTimeSeries
		break
	case "render_markdown":
		renderComponent.value = RenderMarkdown
		break
	case "dynamic_options":
		renderComponent.value = DynamicOptions
		break
	case "phone_number":
		setProps(["label", "name", "default", "errorMessages", "type"])
		renderComponent.value = PhoneNumberField
		break
	case "filter_dialog":
		setProps(["filterDefinition", "options"])
		renderComponent.value = FilterDialog
		break
	case "preset_system":
		setProps(["field"])
		renderComponent.value = PresetSystem
		break
	case "timezone_picker":
		setProps(["field"])
		renderComponent.value = TimezonePicker
		break
}

const propertyBindings = computed(() => {
	if (propList.includes("form-modal")) {
		return {
			buttonClass: props.field.buttonClass ?? null,
			buttonTextClass: props.field.buttonTextClass ?? null,
			buttonText: props.field.buttonText ?? null,
			buttonIcon: props.field.buttonIcon ?? null,
			buttonIconSize: props.field.buttonIconSize ?? null,
			buttonSize: props.field.buttonSize ?? null,
			size: props.field.size || null,
			tooltipLabel: props.field.tooltipLabel ?? null,
			tabsList: props.field.tabsList ?? null,
			tabItem: props.field.tabItem ?? null,
			tab: props.field.tab ?? null,
			options: props.field.options ?? null,
			forceUpdateStore: props.field.forceUpdateStore ?? false,
		}
	}
	if (propList.includes("data_table")) {
		return {
			tableTitle: props.field.tableTitle,
			headers: props.field.headers,
			options: props.field.options || {},
			createConfig: props.field.createConfig,
		}
	}

	let returnBindings = {
		field: props.field,
		classList: props.field.classList || undefined,
	}

	if (propList.includes("label")) {
		returnBindings["label"] = props.field.label || ""
	}
	if (propList.includes("name")) {
		returnBindings["name"] = props.field.name || props.name
	}
	if (propList.includes("default")) {
		returnBindings["default"] = props.field.default || props.default
	}
	if (propList.includes("formModel")) {
		returnBindings["formModel"] = dataModel
	}
	if (propList.includes("table-style")) {
		returnBindings["table-style"] = props.field.tableStyle || "card"
	}
	if (propList.includes("errorMessages")) {
		returnBindings["errorMessages"] = props.errorMessages
	}
	if (propList.includes("type")) {
		returnBindings["type"] = fieldType
	}
	if (propList.includes("multiple")) {
		returnBindings["multiple"] = true
	}
	if (propList.includes("previewMode")) {
		returnBindings["previewMode"] = props.previewMode || false
	}
	if (propList.includes("button-text")) {
		returnBindings["button-text"] = props.field.buttonText
	}
	if (propList.includes("base64")) {
		returnBindings["base64"] = props.field.base64 || false
	}
	if (propList.includes("wrapperClass")) {
		returnBindings["wrapperClass"] = props.field.wrapperClass || "btn-floating"
	}
	if (propList.includes("labelClass")) {
		returnBindings["labelClass"] = props.field.labelClass || ""
	}
	if (propList.includes("inputClass")) {
		returnBindings["inputClass"] = props.field.inputClass || ""
	}
	if (propList.includes("tag")) {
		returnBindings["tag"] = props.field.tag || "button"
	}
	if (propList.includes("id")) {
		returnBindings["id"] = props.field.id || null
	}
	if (propList.includes("mapName")) {
		returnBindings["mapName"] = props.field.mapName || ""
	}
	if (propList.includes("additionalMarkers")) {
		returnBindings["additionalMarkers"] = props.field.additionalMarkers || []
	}
	if (propList.includes("pills")) {
		returnBindings["pills"] = props.field.pills || false
	}
	if (propList.includes("fill") && props.field.fill) {
		returnBindings["fill"] = props.field.fill
	}
	if (propList.includes("height") && props.field.height) {
		returnBindings["height"] = props.field.height
	}
	if (propList.includes("width") && props.field.width) {
		returnBindings["width"] = props.field.width
	}
	if (propList.includes("filterDefinition")) {
		returnBindings["filterDefinition"] = props.field.filterDefinition || {}
	}
	if (propList.includes("options")) {
		returnBindings["options"] = props.field.options || {}
	}
	if (propList.includes("filterDefinition")) {
		returnBindings["filterDefinition"] = props.field.filterDefinition || {}
	}
	if (propList.includes("options")) {
		returnBindings["options"] = props.field.options || {}
	}
	return returnBindings
})

const showField = computed(() => {
	if (props.field.condition || props.field.conditionParams?.length) {
		return checkConditions(
			props.field,
			dataModel?.value ?? fieldValue?.value ?? {},
		)
	}
	return true
})

const emit = defineEmits(["updateValue"])

const getColClasses = () => {
	let fieldCols = props.field.cols || 12
	let fieldColsSm = props.field.colsSm || fieldCols
	let fieldColsMd = props.field.colsMd || fieldColsSm
	let fieldColsLg = props.field.colsLg || fieldColsMd
	let fieldColsXl = props.field.colsXl || fieldColsLg
	let propClass = props.columnClass || props.field.columnClass || "pb-3"
	return [
		propClass,
		"col-" + fieldCols,
		"col-sm-" + fieldColsSm,
		"col-md-" + fieldColsMd,
		"col-lg-" + fieldColsLg,
		"col-xl-" + fieldColsXl,
	]
}

const onInput = (value) => {
	let parsedValue = value
	if (props.field.query_parameter) {
		// add query paremter to the url
		let url = new URL(window.location.href)
		url.searchParams.set(props.field.query_parameter, value)
		router.visit(url.toString(), {
			preserveState: true,
			preserveScroll: true,
		})
	}
	if (props.field.navigate) {
		if (typeof props.field.navigate == "function") {
			props.field.navigate(router, value)
		} else {
			router.visit(props.field.navigate.route, {
				preserveState: true,
				preserveScroll: true,
			})
		}
	}
	emit("updateValue", {
		name: props.field.name || props.name,
		column: props.field.column || null,
		value: parsedValue,
	})
	if (props.field.refresh && props.field.refresh.only) {
		let data = {}
		data[props.field.refresh.key] = parsedValue
		router.get(page.url, data, {
			preserveState: true,
			preserveScroll: true,
			only: [props.field.refresh.only],
		})
	}
}
</script>

<style>
.form-control-textarea {
	min-height: 100px !important;
}
.color-picker-input.input-group-text,
.input-group-prepend.input-group-text,
.input-group-append.input-group-text {
	height: unset;
}
.color-picker-input .vc-color-wrap {
	margin-right: 0;
	height: 100%;
	aspect-ratio: 1;
	border-radius: 5px;
	border: 1px solid #ced4da;
	width: unset;
}
</style>
