<template>
	<!-- MDB5 Modal -->
	<Teleport :to="containerEl">
		<MDBModal
			:model-value="modelValue"
			centered
			class="delete-dialog-modal"
			@update:model-value="handleModelChange"
		>
			<MDBModalBody test-id="confirm-delete-modal-body">
				<div class="position-absolute top-0 end-0 p-3">
					<button
						class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary shadow-0"
						type="button"
						@click.prevent="handleCancel"
					>
						<FontAwesomeIcon :icon="lookupIcon('xmark', 'fat')" size="lg" />
					</button>
				</div>
				<h4 class="text-center mt-2 fw-bold mb-3">
					{{ title }}
				</h4>
				<p class="delete-prompt" v-html="deleteMessageText"></p>

				<!-- A radio group with options 'selected' or 'all'. :value =deleteAllOrSelected, @update:deleteAllOrSelected -->
				<RadioGroup
					v-if="
						allowDeleteAll &&
						requiresInputConfirmation &&
						props.selectedCount < props.deleteAllCount
					"
					:model-value="deleteAllOrSelected"
					:label="'Delete ' + deleteAllOrSelected + ' items'"
					:options="deleteAllOptions"
					name="deleteAllOrSelected"
					@update:model-value="emit('update:deleteAllOrSelected', $event)"
				/>
				<MDBInputZ
					v-if="showDeleteConfirmationInput"
					:model-value="confirmInput"
					type="text"
					:label="
						'Type ' +
						confirmationText +
						' to confirm ' +
						props.confirmDeletionText
					"
					:placeholder="confirmationText"
					test-id="confirm-delete-input"
					autocomplete="off"
					required="true"
					:form-outline="false"
					wrapper-class="form-floating"
					@update:model-value="emit('update:confirmInput', $event)"
				/>

				<div class="error-messages text-danger">
					<div class="bulk-delete-errors">
						{{ Object.values(bulkDeleteForm.errors).join("\n") }}
					</div>
					<div class="single-delete-errors">
						{{ Object.values(deleteSingleForm.errors).join("\n") }}
					</div>
				</div>
			</MDBModalBody>
			<MDBModalFooter class="border-0 justify-content-center pt-2">
				<button
					class="btn btn-outline-primary rounded-pill"
					:disabled="deleteInProgress"
					name="cancel"
					@click="emit('update:modelValue', false)"
				>
					Cancel
				</button>
				<button
					:class="[
						'btn-' + (props.confirmButtonColor || 'outline-danger'),
						props.confirmBtnClassList,
					]"
					class="btn rounded-pill"
					title="Confirm Delete"
					:disabled="
						bulkDeleteForm.processing ||
						deleteSingleForm.processing ||
						(showDeleteConfirmationInput && !isConfirmTextMatched)
					"
					test-id="confirm-delete-btn"
					@click="confirmDelete"
				>
					<MDBSpinner v-if="deleteInProgress" size="sm" />
					<span v-else>{{ buttonText }}</span>
				</button>
			</MDBModalFooter>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue"
import { router, useForm, usePage } from "@inertiajs/vue3"
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBSpinner,
} from "mdb-vue-ui-kit"
import RadioGroup from "@/Components/RadioGroup.vue"
import { useToast } from "@/Composables/useToast"
import MDBInputZ from "@/Components/Mod/MDBOverride/MDBInputZ.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
		default: false,
	},
	title: {
		type: String,
		required: false,
		default: "Confirmation",
	},
	route: {
		type: [String, null],
		required: true,
	},
	requiresInputConfirmation: {
		type: Boolean,
		required: false,
		default: false,
	},
	confirmInput: {
		type: String,
		required: false,
		default: "",
	},
	confirmText: {
		type: String,
		required: false,
		default: "",
	},
	deleteAllOrSelected: {
		type: String,
		required: false,
		default: "selected",
	},
	deleteAllCount: {
		type: Number,
		required: false,
		default: 0,
	},
	selectedCount: {
		type: Number,
		required: false,
		default: 0,
	},
	bulkDeleteRoute: {
		type: String,
		required: false,
		default: null,
	},
	selectedItems: {
		type: Array,
		required: false,
		default: () => [],
	},
	allowDeleteAll: {
		type: Boolean,
		required: false,
		default: false,
	},
	bodyText: {
		type: String,
		required: false,
		default: "",
	},
	successMessage: {
		type: String,
		required: false,
		default: "",
	},
	errorMessage: {
		type: String,
		required: false,
		default: "",
	},
	buttonText: {
		type: String,
		required: false,
		default: "Delete",
	},
	confirmDeletionText: {
		type: String,
		required: false,
		default: "deletion",
	},
	deleteRequestMethod: {
		type: String,
		required: false,
		default: "delete",
	},
	confirmButtonColor: {
		type: String,
		required: false,
		default: "outline-danger",
	},
	requestBody: {
		type: Object,
		required: false,
		default: null,
	},

	bulkDeleteSuccess: {
		type: [Function, undefined, null],
		required: false,
		default: null,
	},
})

const errors = usePage().props

const dialog = ref(false)
const deleteInProgress = ref(false)
const toast = useToast()

const bulkDeleteForm = useForm({
	ids: [],
})
const deleteSingleForm = useForm({})

const containerEl = computed(() => {
	return document.querySelector("body")
})
// setup the v-model functionality to update the dialog
// when the parent component changes the modelValue
const emit = defineEmits([
	"update:modelValue",
	"update:confirmInput",
	"update:deleteAllOrSelected",
	"success",
])
const confirmationText = computed(() => {
	if (typeof props.confirmText === "function") {
		return props.confirmText(props.selectedItems)
	} else if (props.confirmText.length === 0) {
		return "DELETE " + props.selectedItems.length
	}
	return props.confirmText
})
const confirmDelete = () => {
	if (props.selectedItems?.length > 0 && props.bulkDeleteRoute) {
		bulkDelete()
	} else {
		deleteItem()
	}
}

/**
 * Standard delete
 */
const deleteItem = () => {
	const requestMethod = props.deleteRequestMethod
		? props.deleteRequestMethod
		: "delete"

	deleteSingleForm.submit(requestMethod, props.route, {
		method: requestMethod,
		preserveScroll: true,
		preserveState: true,
		onSuccess: () => {
			// success();
			toast.success(
				props.successMessage
					? props.successMessage
					: "Successfully deleted item",
			)
			closeModal()
			emit("success")
		},
		onError: (err) => {
			console.log(err)
			// emit("error", err);
			toast.error(
				props.errorMessage ? props.errorMessage : "Error deleting item",
			)
			console.error(err)
		},
		onFinish: () => {},
	})
}

/**
 * Bulk delete
 */
const bulkDelete = () => {
	console.log("bulkDelete")
	bulkDeleteForm.ids = [...props.selectedItems]
	const requestMethod = props.deleteRequestMethod
		? props.deleteRequestMethod
		: "delete"
	bulkDeleteForm
		.transform((item) => {
			if (props.transformBody && typeof props.transformBody === "function") {
				return props.transformBody(item)
			} else if (props.requestBody) {
				return {
					...item,
					data: props.requestBody,
				}
			}
			return item
		})
		.submit(requestMethod, props.bulkDeleteRoute, {
			method: requestMethod,
			preserveScroll: true,
			preserveState: true,
			onSuccess: () => {
				// success();

				if (typeof props.bulkDeleteSuccess === "function") {
					props.bulkDeleteSuccess()
				} else {
					toast(props.successMessage || "Successfully deleted items", {
						...(props.successToastOptions || {}),
					})
				}
				closeModal()

				emit("success")
			},
			onError: (err) => {
				console.log(err)
				toast.error(
					props.errorMessage ? props.errorMessage : "Error deleting items",
				)
			},
			onFinish: () => {},
		})
}

const deleteAllOptions = [
	{
		title: "Selected",
		option: "selected",
	},
	{
		title: "All",
		option: "all",
	},
]

const showDeleteConfirmationInput = computed(() => {
	return props.requiresInputConfirmation
})

const deleteMessageText = computed(() => {
	if (props.bodyText) {
		return props.bodyText
	} else if (props.selectedCount === 0) {
		return "Are you sure you want to delete this item?"
	} else if (props.selectedCount > 0 && !props.requiresInputConfirmation) {
		return `Are you sure you want to delete the selected ${props.selectedCount} items?`
	} else if (props.selectedCount > 0 && props.requiresInputConfirmation) {
		const deleteType = props.deleteAllOrSelected === "all" ? "all" : "selected"
		if (deleteType === "all") {
			return `Are you sure you want to delete all ${props.deleteAllCount} items?`
		} else if (deleteType === "selected") {
			return `Are you sure you want to delete the ${props.selectedCount} selected items?`
		}
	}
	return ""
})

const handleModelChange = (value) => {
	// reset both forms
	bulkDeleteForm.reset()
	deleteSingleForm.reset()
	if (value == false) {
		deleteSingleForm.clearErrors()
		bulkDeleteForm.clearErrors()
	}
	emit("update:confirmInput", "")
	emit("update:modelValue", value)
}

const closeModal = () => {
	bulkDeleteForm.reset()
	deleteSingleForm.reset()
	emit("update:confirmInput", "")
	emit("update:modelValue", false)
}

const handleCancel = () => {
	closeModal()
}

const isConfirmTextMatched = computed(() => {
	return props.confirmInput === confirmationText.value
})
</script>
<style>
.delete-dialog-modal {
	z-index: 2000 !important;
}
</style>
