import { ref, reactive, computed } from "vue"
import { router } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import route from "ziggy-js"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"

/**
 * `useDataTableDialogs` is a Vue composable that provides functionality for handling dialog states and actions in a DataTable component.
 * It manages the state of create and delete dialogs, handles the deletion of items, and emits a form submission event.
 *
 * @param {Object} props - The props object from the parent component. It should contain a `getDeleteRoute` function that returns the route for deleting an item.
 * @param {Function} emit - The emit function from the parent component. It's used to emit the 'form-submitted' event.
 *
 * @returns {Object} An object containing:

 */

export function useDataTableActions({
	props,
	emit,
	tableRows,
	toggleAllEnabled,
	listDataFiltered,
	paginatedResponseComputed,
	selectedItems,
	fetchData,
	dataModel,
}) {
	const { injectTeamParam } = useTeamRouteParams()

	const toast = useToast()
	const confirmDeleteModalOpen = ref(false)
	const toDeleteId = ref(null)
	const toDeleteObject = ref(null)

	const selectionActionInProgress = ref({})

	const initialDropdowns = {}
	props.createConfig?.dropdowns?.forEach((dropdown, index) => {
		initialDropdowns[index] = false
	})

	const showDropdowns = ref(Object.assign({}, initialDropdowns))
	const dropdownsProcessing = ref(Object.assign({}, initialDropdowns))

	const confirmText = computed(() => {
		// check if props.options.delete.confirmText is a function

		if (
			props.options?.delete?.confirmText &&
			typeof props.options.delete.confirmText === "function"
		) {
			if (toDeleteObject.value) {
				// Single item deleted
				return props.options.delete?.confirmText(toDeleteObject.value)
			} else {
				// multiple items deleted
				return props.options.delete?.confirmText(selectedItems.value)
			}
		} else if (props.options?.delete?.confirmText) {
			return props.options.delete.confirmText
		} else if (selectedItems.value.length > 0) {
			return "DELETE " + selectedItems.value.length + " ITEMS"
		} else {
			return "DELETE ME"
		}
	})
	const userConfirmTextInput = ref("")
	const deleteAllOrSelected = ref("selected")
	const bulkActionInProgress = ref(false)
	const deleteConfirmInputCorrect = computed(() => {
		return userConfirmTextInput.value === confirmText.value
	})
	const deleteRoute = computed(() => {
		// check if toDeleteId.value && props.options.delete.route is a function
		// if so, call the function and return the result
		// otherwise, return the route

		if (toDeleteId.value && props.options.delete.route) {
			if (typeof props.options.delete.route === "function") {
				const foundObject = tableRows.value.find((item) => {
					let item_id = item.slug || item.id
					return item_id === toDeleteId.value
				})
				if (!foundObject) return null
				return props.options.delete.route(foundObject)
			}
			let params = getParams(props.options.delete.params)
			params = injectTeamParam(props.options.delete.route, params)
			return route(props.options.delete.route, {
				[props.options.delete.key]: toDeleteId.value,
				...params,
			})
		}
		return null
	})
	const getParams = (params) => {
		let routeParams = {}
		if (Array.isArray(params)) {
			if (dataModel.value) {
				params.forEach((param) => {
					if (param.key) {
						let paramValue = getNestedProperty(dataModel.value, param.key)
						routeParams[param.name] = paramValue
					} else if (param.value) {
						routeParams[param.name] = param.value
					}
				})
			}
		} else {
			routeParams = params
		}

		return routeParams
	}
	const bulkDeleteRoute = computed(() => {
		if (props.options.bulkDelete?.route) {
			let params = getParams(props.options.bulkDelete.params)
			params = injectTeamParam(props.options.bulkDelete.route, params)
			return route(props.options.bulkDelete.route, {
				...params,
			})
		}
		return null
	})
	const navigationRoute = (item) => {
		let linkHref = null
		let item_id = item.slug || item.id
		if (typeof props.options.edit.route === "function") {
			linkHref = props.options.edit.route(item)
		} else {
			let params = injectTeamParam(
				props.options.edit.route,
				props.options.edit.params,
			)

			linkHref = route(props.options.edit.route, {
				[props.options.edit.key]: item_id,
				...params,
			})
		}
		return linkHref
	}

	const navigate = (item) => {
		let linkHref = null
		let item_id = item.slug || item.id
		if (typeof props.options.edit.route === "function") {
			linkHref = props.options.edit.route(item)
		} else {
			let params = injectTeamParam(
				props.options.edit.route,
				props.options.edit.params,
			)

			linkHref = route(props.options.edit.route, {
				[props.options.edit.key]: item_id,
				...params,
			})
		}

		router.visit(linkHref, {
			method: "get",
			preserveState: true,
			preserveScroll: true,
		})
	}

	const mainDataTable = ref(null)

	const handleEditClicked = (index) => {
		navigate(index)
	}

	const handleDeleteClicked = (item) => {
		const type = props.options.delete.key_type
		const id = item[type] ?? item.slug ?? item.id
		toDeleteId.value = id
		toDeleteObject.value = item
		confirmDeleteModalOpen.value = true
	}

	const handleDeleteAllClicked = () => {
		toDeleteId.value = null
		toDeleteObject.value = null
		userConfirmTextInput.value = ""
		confirmDeleteModalOpen.value = true
	}

	const deleteAllCount = computed(() => {
		if (props.listData && props.listData.length) {
			return listDataFiltered.value.length
		} else if (paginatedResponseComputed.value) {
			return paginatedResponseComputed.value.total
		} else {
			return 0
		}
	})

	const handleBulkActionClicked = () => {
		let params = getParams(props.options.bulkAction.params)
		params = injectTeamParam(props.options.bulkAction.route, params)
		const activeRoute = route(props.options.bulkAction.route, {
			...params,
		})
		router.post(
			activeRoute,
			{
				ids: selectedItems.value,
			},
			{
				preserveState: props.options.bulkAction.preserveState || true,
				preserveScroll: props.options.bulkAction.preserveScroll || true,
				onSuccess: () => {
					if (props.options.bulkAction.onSuccess) {
						props.options.bulkAction.onSuccess({ fetchData })
					} else {
						fetchData()
					}
					selectedItems.value = []
					toggleAllEnabled.value = false
				},
				onError: () => {
					if (props.options.bulkAction.onError) {
						props.options.bulkAction.onError()
					}
				},
			},
		)
	}

	const handleSelectionActionClicked = (action, index) => {
		let params = getParams(action.params)
		const activeRoute = route(action.route, {
			...params,
		})

		selectionActionInProgress.value = Object.assign(
			{},
			selectionActionInProgress.value,
			{
				[index]: true,
			},
		)

		let payload = {}
		if (typeof action.body === "function") {
			payload = action.body(selectedItems.value, action)
		} else {
			payload = { ...action.body, ids: selectedItems.value }
		}

		router.post(activeRoute, payload, {
			preserveState: action.preserveState ?? true,
			preserveScroll: action.preserveScroll ?? true,
			onSuccess: () => {
				if (action.onSuccess) {
					action.onSuccess({ fetchData })
				} else {
					fetchData()
				}
				selectedItems.value = []
				toggleAllEnabled.value = false
				selectionActionInProgress.value = Object.assign(
					{},
					selectionActionInProgress.value,
					{
						[index]: false,
					},
				)
			},
			onError: () => {
				if (action.onError) {
					action.onError()
				}
				selectionActionInProgress.value = Object.assign(
					{},
					selectionActionInProgress.value,
					{
						[index]: false,
					},
				)
			},
		})
	}

	/**
	 * This method is called when a user clicks the links that are
	 * added next to the create button. You can omit the create routes object
	 * and use just links if you want.
	 * @param any link
	 */
	const handleTableLinkClicked = (link) => {
		const activeRoute = route(link.route, {
			...injectTeamParam(link.route, link.params),
		})
		router.visit(activeRoute, {
			method: link.method || "get",
			preserveState: link.preserveState || true,
			preserveScroll: link.preserveScroll || true,
			onSuccess: () => {
				if (link.onSuccess) {
					link.onSuccess({ fetchData })
				}
			},
		})
	}

	const handleDropdownItemClicked = (link, dropdownIndex) => {
		if (!link.route) {
			console.log("no route")
			return
		}
		let activeRoute =
			typeof link.route == "function"
				? link.route(link)
				: route(link.route, {
						...injectTeamParam(link.route, link.params),
					})

		dropdownsProcessing.value[dropdownIndex] = true
		try {
			router.visit(activeRoute, {
				method: link.method || "get",
				preserveState: link.preserveState || true,
				preserveScroll: link.preserveScroll || true,
				onSuccess: () => {
					if (link.onSuccess) {
						link.onSuccess({ fetchData })
					}
					dropdownsProcessing.value[dropdownIndex] = false
				},
				onError: () => {
					if (link.onError) {
						link.onError()
					}
					dropdownsProcessing.value[dropdownIndex] = false
				},
			})
		} catch (error) {
			dropdownsProcessing.value[dropdownIndex] = false
		}
	}

	const handleAdditionalActionClicked = (action, item, event) => {
		if (action.type === "external_link") {
			if (typeof action.url === "function")
				window.open(action.url(item), action.target)
			else window.open(action.url, action.target)
		}
		if (action) {
			const tragetRoute = action.route(item)
			const transformed = action.transform(item)

			router.visit(tragetRoute, {
				method: action.requestType || "get",
				data: transformed || {},
				preserveScroll: true,
				preserveState: true,
				onSuccess: (event) => {
					if (action.onSuccess) {
						action.onSuccess({ event, item, action })
					}
				},
				onError: (errors) => {
					toast.error(Object.values(errors).join("\n"))
				},
			})
		}
	}

	return {
		confirmDeleteModalOpen,
		toDeleteId,
		deleteRoute,
		navigate,
		navigationRoute,
		handleDeleteClicked,
		confirmText,
		deleteConfirmInputCorrect,
		userConfirmTextInput,
		handleDeleteAllClicked,
		deleteAllOrSelected,
		deleteAllCount,
		bulkDeleteRoute,
		handleBulkActionClicked,
		bulkActionInProgress,
		handleTableLinkClicked,
		handleAdditionalActionClicked,
		showDropdowns,
		dropdownsProcessing,
		handleDropdownItemClicked,
		handleSelectionActionClicked,
		selectionActionInProgress,
	}
}
