<template>
	<div class="d-flex flex-column gap-2">
		<div
			v-for="(option, index) in orderedLocalValue"
			:key="option.key"
			class="p-3 card shadow-0 border gap-3"
		>
			<div class="d-flex align-items-center">
				<template v-if="fieldOptions.orderable">
					<MDBBtn
						type="button"
						class="shadow-0 me-1 border border-primary rounded-circle p-1 btn-outline-primary d-flex justify-content-center align-items-center aspect-ratio-1 m-0 flex-shrink-0"
						:disabled="index === 0"
						@click="changeOrder(option.key, -1)"
					>
						<IconBadge
							size="xs"
							icon="far.chevron-up"
							:options="{ classList: [] }"
						/>
					</MDBBtn>
					<MDBBtn
						type="button"
						class="shadow-0 me-1 border border-primary rounded-circle p-1 btn-outline-primary d-flex justify-content-center align-items-center aspect-ratio-1 m-0 flex-shrink-0"
						:disabled="index === orderedLocalValue.length - 1"
						@click="changeOrder(option.key, 1)"
					>
						<IconBadge
							size="xs"
							icon="far.chevron-down"
							:options="{ classList: [] }"
						/>
					</MDBBtn>
				</template>
				<FormField
					:field="{
						...getLabelField(localValue[option.key]),
						classList: [
							'larger',
							'fw-bold',
							'ms-2',
							'mb-0',
							'text-overflow-ellipsis',
							'white-space-nowrap',
							'overflow-hidden',
						],
						columnClass: [
							'flex-shrink-1',
							'flex-grow-1',
							'min-w-0',
							'overflow-hidden',
						],
						type: 'heading',
					}"
					:data-store="props.dataStore"
				/>
				<template v-if="editingFieldKey === option.key">
					<MDBTooltip
						v-if="props.field?.aiBuilder"
						v-model="aiBuilderTooltip"
						:options="{ strategy: 'fixed' }"
					>
						<template #reference>
							<IconBadge
								icon="fas.sparkles"
								class="text-primary ms-auto cursor-pointer flex-shrink-0"
								@click="editWithAIClicked(option.key)"
							/>
						</template>
						<template #tip>
							{{ props.field?.aiBuilder?.tooltipText || "Edit with AI" }}
						</template>
					</MDBTooltip>
					<IconBadge
						icon="far.trash"
						class="text-danger ms-auto cursor-pointer flex-shrink-0"
						@click="removeOption(option.key)"
					/>
					<IconBadge
						icon="check"
						class="bg-success text-white p-2 rounded cursor-pointer flex-shrink-0"
						@click="editingFieldKey = null"
					/>
				</template>
				<IconBadge
					v-else
					icon="far.edit"
					class="ms-auto cursor-pointer flex-shrink-0"
					@click="editingFieldKey = option.key"
				/>
			</div>
			<template v-if="editingFieldKey === option.key">
				<FormField
					v-for="optionField in currentEditFields"
					:key="optionField"
					:field="{
						...optionField,
						column: getColumn(optionField),
						columnClass: [],
					}"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				/>
			</template>
		</div>
	</div>
	<div class="d-flex gap-2 align-items-center mt-3">
		<MDBBtn
			class="btn-outline-primary btn-sm m-0 d-flex align-items-center white-space-nowrap"
			type="button"
			title="Add Option"
			:rounded="true"
			:disabled="orderedLocalValue.length >= fieldOptions.max"
			@click="addOption"
		>
			<IconBadge :options="{ classList: ['me-1'] }" icon="far.plus-circle" />
			Add Option
		</MDBBtn>
		<i v-if="orderedLocalValue.length >= fieldOptions.max" class="smaller"
			>You've reached the maximum number of prompts.</i
		>
	</div>

	<AiFormFieldBuilder
		v-if="props.field?.aiBuilder"
		v-model:is-open="showAiBuilderModal"
		:system-prompt="props.field.aiBuilder?.systemPrompt"
		:field="props.field"
		:current-value="getActiveValues"
		:output-format="getActiveFieldLabelsAndTypes"
		:unique-input-id="editingFieldKey"
		@save="handleAiBuilderSave"
	/>
</template>

<script setup>
import { onMounted, ref, computed } from "vue"
import { MDBBtn, MDBTooltip } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"
import IconBadge from "@/Components/IconBadge.vue"
import FormField from "@/Components/Mod/FormField.vue"
import AiFormFieldBuilder from "./AiFormFieldBuilder.vue"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: null,
	},
})
const fieldOptions = computed(() => props.field.options)

const editingFieldKey = ref("")
const currentEditFields = computed(() =>
	editingFieldKey.value
		? Object.entries(localValue.value[editingFieldKey.value])
				.map((keyValuePair) => keyValuePair[1])
				.filter((value) => value.type)
		: [],
)
const localValue = computed(() => {
	const optionOrder = props.dataStore?.getModelValue({
		column: props.field.column ?? null,
		name: `${props.field.name}_order`,
	})
	if (!optionOrder) return null
	let obj = {}
	for (const [key, order] of Object.entries(optionOrder)) {
		obj[key] = {
			...fieldOptions.value.optionConfig.map((option) => ({
				...option,
				name: `${key}.${option.name}`,
			})),
			order,
		}
	}
	return obj
})

const aiBuilderTooltip = ref(false)

const orderedLocalValue = computed(() =>
	Object.entries(localValue.value ?? {})
		.filter(([key, value]) => value?.order !== null)
		.map(([key, value]) => ({ key, value }))
		.sort((a, b) => a.value.order - b.value.order),
)

onMounted(() => {
	// Initialize localValue since the components are stored in various places in the data model
})
const addOption = () => {
	const uuid = crypto.randomUUID()
	const order = Object.keys(localValue.value ?? {}).length
	if (props.dataStore) {
		props.dataStore.setModelValue(
			{
				name: `${props.field.name}_order.${uuid}`,
				column: props.field.column ?? null,
			},
			order,
			true,
		)
		fieldOptions.value.optionConfig.forEach((option) => {
			props.dataStore.setModelValue(
				{
					column: `${option.column}.${props.field.name}`,
					name: `${uuid}.${option.name}`,
				},
				null,
				true,
			)
		})
	} else {
		// Not supported
	}
	editingFieldKey.value = uuid
}
const removeOption = (key) => {
	editingFieldKey.value = null
	updateDataStoreOrder(`${props.field.name}_order.${key}`, null)
	delete localValue.value[key]
	if (props.dataStore) {
		fieldOptions.value.optionConfig.forEach((option) => {
			props.dataStore.setModelValue(
				{
					column: `${option.column}.${props.field.name}`,
					name: `${key}.${option.name}`,
				},
				null,
				true,
			)
		})
	}
}
const getColumn = (value) => {
	let result = [value.column]
	if (props.field.name) result.push(props.field.name)
	return result.join(".")
}
const getLabelField = (value) => {
	const entries = Object.entries(value).filter((entry) => entry[1].type)
	if (entries) {
		for (let i = 0; i < entries.length; i++) {
			if (entries[i][1].name?.split(".")[1] === "label")
				return {
					...entries[i][1],
					column: `${entries[i][1].column}.${props.field.name}`,
				}
		}
	}
}
const changeOrder = (key, change) => {
	const targetOrder = localValue.value[key].order + change
	for (const [key, value] of Object.entries(localValue.value)) {
		if (value.order === targetOrder) {
			value.order -= change
			if (props.dataStore)
				updateDataStoreOrder(`${props.field.name}_order.${key}`, value.order)
			break
		}
	}
	localValue.value[key].order += change
	if (props.dataStore)
		updateDataStoreOrder(
			`${props.field.name}_order.${key}`,
			localValue.value[key].order,
		)
}
const updateDataStoreOrder = (name, value) => {
	props.dataStore.setModelValue(
		{
			name,
			column: props.field.column ?? null,
		},
		value,
		true,
	)
}

const showAiBuilderModal = ref(false)

const handleAiBuilderSave = (messageText) => {
	try {
		let receivedJson = JSON.parse(messageText)

		// Only process if we have an active edit item
		if (!activeEditItem.value) return

		// Get all fields with type and label
		const fields = currentEditFields.value
		// Update each matching field in the data store
		fields.forEach((item) => {
			const lowercaseLabel = item.label.toLowerCase()
			if (receivedJson[lowercaseLabel] !== undefined) {
				const maxLength = item.options?.maxLength
				let tempVal = receivedJson[lowercaseLabel]
				if (maxLength && tempVal.length > maxLength) {
					tempVal = tempVal.slice(0, maxLength)
				}
				const column = getColumn(item)
				props.dataStore.setModelValue(
					{
						column,
						name: item.name,
					},
					tempVal,
					true,
				)
			}
		})
	} catch (e) {
		console.error("Error parsing JSON", e)
	}
}

const editWithAIClicked = (key) => {
	editingFieldKey.value = key
	showAiBuilderModal.value = true
}

const activeEditItem = computed(() =>
	editingFieldKey.value ? localValue.value[editingFieldKey.value] : null,
)

const getActiveFieldLabelsAndTypes = computed(() => {
	if (!activeEditItem.value) return {}
	if (!activeEditItem.value) return {}

	return Object.entries(activeEditItem.value)
		.filter(([key, value]) => value.type && value.label) // Only get items with type and label
		.reduce((acc, [_, value]) => {
			acc[value.label.toLowerCase()] = `{${value.label}}${
				value?.options?.maxLength
					? `: (max length ${value.options.maxLength} characters)`
					: ""
			}`
			return acc
		}, {})
})

const getActiveValues = computed(() => {
	if (!activeEditItem.value) return {}
	if (!activeEditItem.value) return {}

	return Object.entries(activeEditItem.value)
		.filter(([key, value]) => value.type && value.label) // Only get items with type and label
		.reduce((acc, [_, value]) => {
			const column = getColumn(value)
			acc[value.label.toLowerCase()] = props.dataStore.getModelValue({
				column,
				name: value.name,
			})
			return acc
		}, {})
})
</script>
