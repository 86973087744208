<template>
	<MDBSwitchZ
		:id="inputId"
		:model-value="displayValue"
		:label="labelComputed"
		:required="props.field.required || props.required"
		:wrapper-class="[
			props.field.align == 'right'
				? 'form-check-reverse'
				: props.align == 'right'
					? 'form-check-reverse'
					: '',
			...(props.field.wrapperClass ?? []),
		]"
		variant="solo-filled"
		:disabled="
			typeof props.field.readonly == 'function'
				? props.field.readonly(props.field, dataModel)
				: props.field.readonly || props.readonly || false
		"
		:class="props.field.classList || props.classList || []"
		color="primary"
		:error-messages="errorMessages"
		@update:model-value="onInput"
	/>

	<ConfirmationDialog
		v-model="confirmDialogOpen"
		v-bind="dialogConfig"
		:processing="updateInProgress"
		:size="props.field.inlineUpdate?.size || 'md'"
		@confirm="handleUpdate"
		@cancel="handleCancelUpdate"
	/>
</template>

<script setup>
import { computed, defineModel, defineEmits, onMounted, shallowRef } from "vue"
import MDBSwitchZ from "@/Components/Mod/MDBOverride/MDBSwitchZ.vue"
import { storeToRefs } from "pinia"
import ConfirmationDialog from "@/Components/Mod/ConfirmationDialog.vue"
import { useAutosaveConfirmation } from "@/Composables/useAutosaveConfirmation"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	label: {
		type: [String, Function],
		default: "",
	},
	default: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	align: {
		type: String,
		default: "left",
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})
const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

const emit = defineEmits(["update:modelValue"])

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: Boolean,
	default: undefined,
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value || props.field.default || props.default
})

/**
 * Inverts the value of the field if the invert flag is set
 */
const displayValue = computed(() => {
	let curVal = localValue.value

	return props.field.invert ? !curVal : curVal
})

const {
	confirmDialogOpen,
	updateInProgress,
	dialogConfig,
	handleUpdate,
	handleCancelUpdate,
	previousValue,
	openConfirmationDialog,
} = useAutosaveConfirmation(props)

const onInput = (value) => {
	let incomingValue = props.field.invert ? !value : value

	previousValue.value = localValue.value

	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			incomingValue,
			!props.field.preventUpdate ?? true,
		)
	}
	fieldValue.value = incomingValue
	if (
		props.field.inlineUpdate &&
		props.field.inlineUpdate.requiresConfirmation
	) {
		openConfirmationDialog()
	} else if (props.field.inlineUpdate) {
		handleUpdate()
	}
}

const labelComputed = computed(() => {
	if (typeof props.field.label === "function") {
		return props.field.label(dataModel?.value)
	} else if (typeof props.label === "function") {
		return props.label(dataModel?.value)
	}

	return props.field.label || props.label
})
</script>
