<template>
	<!-- MDB5 Modal -->
	<Teleport :to="containerEl">
		<MDBModal
			:model-value="modelValue"
			centered
			class="delete-dialog-modal"
			@update:model-value="handleModelChange"
		>
			<MDBModalHeader>
				<MDBModalTitle>{{ $t(title) }}</MDBModalTitle>
			</MDBModalHeader>
			<MDBModalBody test-id="confirm-delete-modal-body">
				{{ bodyText }}

				<ul class="list-group">
					<li
						v-for="(option, index) in options"
						:key="index"
						class="list-group-item"
						@click="handleOptionSelect(option)"
					>
						{{ option }}
					</li>
				</ul>
			</MDBModalBody>
			<MDBModalFooter>
				<MDBBtn
					color="outline-secondary"
					name="cancel"
					@click="emit('update:modelValue', false)"
				>
					Cancel
				</MDBBtn>
			</MDBModalFooter>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue"
import { usePage } from "@inertiajs/vue3"
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
} from "mdb-vue-ui-kit"

const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
		default: false,
	},
	title: {
		type: String,
		required: false,
		default: "Choose an Option",
	},

	bodyText: {
		type: String,
		required: false,
		default: "",
	},
	options: {
		type: Array,
		required: true,
	},
})

const errors = usePage().props

const dialog = ref(false)
const deleteInProgress = ref(false)

const containerEl = computed(() => {
	return document.querySelector("body")
})
// setup the v-model functionality to update the dialog
// when the parent component changes the modelValue
const emit = defineEmits(["selected", "update:modelValue"])

const handleOptionSelect = (value) => {
	// reset both forms
	emit("selected", value)
	closeModal()
}
const handleModelChange = (value) => {
	emit("update:modelValue", value)
}
const closeModal = () => {
	emit("update:modelValue", false)
}
</script>
<style>
.delete-dialog-modal {
	z-index: 2000 !important;
}
</style>
