<template>
	<label v-if="label" :class="labelClassName" :for="uid">{{ label }}</label>
	<component :is="tag" :class="wrapperClassName">
		<input
			:id="uid"
			ref="rangeRef"
			type="range"
			:class="inputClassName"
			:value="inputValue"
			:min="minValue"
			:max="maxValue"
			v-bind="$attrs"
			@input="handleInput"
			@mousedown="toggleThumb(true)"
			@touchstart="toggleThumb(true)"
			@mouseup="toggleThumb(false)"
			@touchend="toggleThumb(false)"
		/>
		<div class="thumbWrapper">
			<span
				v-if="thumb"
				ref="thumbRef"
				:class="thumbClassName"
				:style="{ left: thumbLeftPosition }"
			>
				<span class="thumb-value">{{
					parseFloat(parseFloat(inputValue).toPrecision(3))
				}}</span>
			</span>
		</div>
	</component>
</template>

<script lang="ts">
export default {
	name: "MDBRange",
	inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from "vue"
import { getUID } from "./getUID"

const props = defineProps({
	id: { type: String, default: "" },
	inputClass: { type: String, default: "" },
	label: { type: String, default: "" },
	labelClass: { type: String, default: "" },
	max: {
		type: Number,
		default: 100,
	},
	min: {
		type: Number,
		default: 0,
	},
	modelValue: {
		type: Number,
		default: 50,
	},
	tag: {
		type: String,
		default: "div",
	},
	thumb: {
		type: Boolean,
		default: true,
	},
	thumbClass: { type: String, default: "" },
	wrapperClass: { type: String, default: "" },
})

const emit = defineEmits(["update:modelValue"])

const thumbRef = ref<any>(null)
const rangeRef = ref<any>(null)

const inputValue = ref<any>(props.modelValue)
const minValue = ref(props.min)
const maxValue = ref(props.max)
const uid = props.id || getUID("MDBRange-")
const isThumbActive = ref(false)

const wrapperClassName = computed(() => {
	return ["range", props.wrapperClass]
})
const inputClassName = computed(() => {
	return ["form-range", props.inputClass]
})
const labelClassName = computed(() => {
	return ["form-label", props.labelClass]
})
const thumbClassName = computed(() => {
	return ["thumb", isThumbActive.value && "thumb-active", props.thumbClass]
})
const thumbLeftPosition = ref<any>(0)

const handleInput = (e: Event) => {
	const target = e.target as HTMLInputElement
	inputValue.value = parseFloat(target.value)
	emit("update:modelValue", inputValue.value)

	setThumbPosition()
}

const toggleThumb = (isActive: boolean) => {
	isThumbActive.value = isActive
}

const setThumbPosition = () => {
	const inputVal =
		typeof inputValue.value === "string"
			? parseFloat(inputValue.value)
			: inputValue.value
	const left =
		((inputVal - minValue.value) * 100) / (maxValue.value - minValue.value)
	thumbLeftPosition.value = `${left}%`
}

const thumbWidth = computed(() => thumbRef.value.getBoundingClientRect().width)
const rangeWidth = computed(() => rangeRef.value.getBoundingClientRect().width)

const gradientPosition = computed(() => {
	const calculatedLeft = parseFloat(thumbLeftPosition.value)
	const val =
		thumbWidth.value > 0 && rangeWidth.value > 0
			? calculatedLeft +
				(thumbWidth.value * (50 - calculatedLeft)) / rangeWidth.value
			: calculatedLeft
	return `${val}%`
})

nextTick(() => {
	setThumbPosition()
})

watch(
	() => props.modelValue,
	(value) => {
		inputValue.value = value
		setThumbPosition()
	},
)
</script>

<style scoped>
label + .range {
	margin-top: calc(-0.5 * var(--mdb-range-thumb-top));
}
input[type="range"]::-webkit-slider-runnable-track {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#000596),
		color-stop(#000596),
		color-stop(#e3e4e6),
		to(#e3e4e6)
	);
	background: linear-gradient(
		to right,
		#000596 0%,
		#000596 v-bind(gradientPosition),
		#e3e4e6 v-bind(gradientPosition),
		#e3e4e6 100%
	);
}
input[type="range"]::-moz-range-track {
	background: linear-gradient(
		to right,
		#000596 0%,
		#000596 v-bind(gradientPosition),
		#e3e4e6 v-bind(gradientPosition),
		#e3e4e6 100%
	);
}
.thumbWrapper {
	width: calc(100% - 1.5rem);
	position: relative;
	left: 0.75rem;
}
input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb {
	background-color: #000596;
	border: 4px solid white;
	-webkit-transition: none;
	-moz-transition: none;
	transition: none;
}
input[type="range"]:focus ::-moz-range-thumb,
input[type="range"]:active ::-moz-range-thumb,
input[type="range"]::-moz-range-thumb:focus,
input[type="range"]::-moz-range-thumb:active {
	box-shadow: 0 0 0 4px #6b6fea60;
}
</style>
