<template>
	<div
		class="mb-2"
		:class="colsClass"
		:role="props.card.cardClickAction ? 'button' : null"
		@click="handleCardClick"
	>
		<div
			:class="[
				{
					card: true,
					border: true,
					'shadow-0': true,
				},
				backgroundColor,
				'hover-border-primary',
				...customClasses,
			]"
		>
			<div
				:class="[
					`${props.card.lessPadding ? 'p-1' : 'p-3'}`,
					'text-start',
					{
						'card-body': props.card.showCard,
					},
					...(props.card.bodyClassList ?? []),
				]"
			>
				<div
					v-if="
						(!hideIcon && (props.card.icon || dataModel.icon)) ||
						(cardAction === 'button' && actionButtonPosition === 'top-right') ||
						(cardAction === 'clickable_card' &&
							props.card.cardClickAction &&
							props.card.cardClickAction.icon) ||
						headerImageURL.length > 0 ||
						$slots['card-select']
					"
					class="info-card-header rounded mb-2"
					:class="[
						{
							'header-image': headerImageURL.length > 0,
							'ratio ratio-1x1 header-position-center':
								!props.card.headerRatio && headerImageURL.length > 0,
							'header-custom-ratio header-position-top':
								props.card.headerRatio && headerImageURL.length > 0,
						},
						...customHeaderClasses,
					]"
					:style="{
						backgroundImage: headerImageURL,
					}"
				>
					<IconBadge
						v-if="!hideIcon && (props.card.icon || dataModel.icon)"
						:size="props.card.headerIconSize"
						:icon="props.card.icon || dataModel.icon"
						:color="iconColor"
						:badge-outline="
							props.card.badgeOutline !== undefined
								? props.card.badgeOutline
								: true
						"
						:options="{
							badge: true,
							classList: ['float-start'],
						}"
					/>
					<button
						v-if="
							cardAction === 'button' && actionButtonPosition === 'top-right'
						"
						class="btn btn-primary btn-sm rounded-pill float-end btn-filled"
						type="button"
						@click.prevent="onSelect"
					>
						{{ props.buttonText }}
						<IconBadge v-if="props.buttonIcon" :icon="props.buttonIcon" />
					</button>
					<FontAwesomeIcon
						v-else-if="
							cardAction === 'clickable_card' &&
							props.card.cardClickAction &&
							props.card.cardClickAction.icon
						"
						class="float-end"
						:size="props.card.cardClickAction.iconSize || 'lg'"
						:class="'text-' + (props.card.cardClickAction.color || 'primary')"
						:icon="lookupIcon(props.card.cardClickAction.icon, 'fas')"
					/>
					<slot name="card-select" />
					<div class="clearfix"></div>
				</div>

				<div
					v-if="
						props.card.title ||
						dataModel.title ||
						props.card.description ||
						dataModel.description
					"
					class="headingText"
					:class="{
						hasFeatures:
							dataModel.features &&
							Array.isArray(dataModel.features) &&
							dataModel.features.length > 0,
						collapsed: !collapseRef,
					}"
				>
					<h5 v-if="props.card.title" class="larger">
						{{ props.card.title }}
					</h5>
					<h5
						v-else-if="dataModel.title"
						class="larger d-flex align-items-start"
					>
						{{ dataModel.title }}
						<span
							v-if="dataModel.titleBadge"
							class="badge rounded-pill float-end"
							:class="dataModel.titleBadgeClassList"
						>
							{{ dataModel.titleBadge }}
						</span>
					</h5>
					<p v-if="props.card.description" class="description">
						{{ props.card.description }}
					</p>
					<p
						v-else-if="dataModel.description"
						ref="featureDescription"
						class="small"
						:class="{
							'feature-description': dataModel.features,
							collapsed: !collapseRef,
						}"
					>
						{{ dataModel.description }}
					</p>
				</div>

				<template
					v-if="
						dataModel.features &&
						Array.isArray(dataModel.features) &&
						dataModel.features.length > 0
					"
				>
					<MDBCollapse
						id="collapseContent"
						v-model="collapseRef"
						:duration="600"
					>
						<div v-for="(feature, index) in dataModel.features" :key="index">
							<hr class="text-secondary-7" />
							<h5 class="larger">{{ feature.title }}</h5>
							<p v-if="feature.description">{{ feature.description }}</p>
							<ul v-if="feature.details">
								<li
									v-for="(detail, detailsIndex) in feature.details"
									:key="detailsIndex"
									class="small"
								>
									{{ detail }}
								</li>
							</ul>
						</div>
					</MDBCollapse>
					<MDBBtn
						class="w-100 bg-secondary-2 text-secondary-10 rounded-pill py-1 shadow-0 d-flex justify-content-center align-items-center gap-2 mt-auto mb-2 collapse-btn"
						aria-controls="collapseContent"
						:aria-expanded="collapseRef"
						@click="collapseRef = !collapseRef"
					>
						{{ collapseRef ? "See Less" : "See More" }}
						<IconBadge :icon="collapseRef ? 'caret-up' : 'caret-down'" />
					</MDBBtn>
				</template>
				<template v-if="props.card.fieldsList">
					<FormField
						v-for="(field, k) in props.card.fieldsList"
						:key="k"
						:field="field"
						:data-store="infoDataStore"
						@update-value="inlineUpdate(dataModel, $event)"
					/>
				</template>
				<template v-if="props.card.loopKey">
					<template
						v-for="(field, k) in dataModel[props.card.loopKey]"
						:key="k"
					>
						<FormField
							v-for="(loopField, loopKey) in props.card.loopList"
							:key="loopKey"
							v-model="field[loopField.name]"
							:field="loopField"
						/>
					</template>
				</template>
				<div :class="props.card.actionClasses">
					<MDBTooltip
						v-for="(action, key) in props.card.additionalActions || []"
						:key="key"
						v-model="tooltipActions[key]"
						:disabled="menuActions[key] || action.tooltip === undefined"
						offset="0,5"
						direction="left"
					>
						<template #reference>
							<FormField
								v-if="
									action.type == 'boolean_icon' || action.type == 'form-modal'
								"
								:field="action"
								:data-store="infoDataStore"
								@update-value="inlineUpdate(dataModel, $event)"
							/>
							<button
								v-else
								class="shadow-0 btn btn-sm btn-floating btn-primary"
								test-id="dt-actions-additional-btn"
								type="button"
								@click.prevent="
									emit('additional-action-clicked', action, dataModel, $event)
								"
							>
								<div class="d-flex align-items-center h-100 w-100">
									<IconBadge
										v-if="action.icon"
										class="m-auto"
										:icon="action.icon"
									/>
								</div>
							</button>
						</template>
						<template #tip>
							{{ action.tooltip ?? "" }}
						</template>
					</MDBTooltip>
					<FormModal
						v-if="
							props.options?.expandable?.cards?.length > 0 &&
							!props.card?.disableExpandable
						"
						:tab="props.options.expandable"
						:data-store="infoDataStore"
						:options="props.options"
						table-style="card"
						size="sm"
						class="dt-expandable-form-modal"
						@success="emit('expandable-success', $event)"
					/>
					<Link
						v-if="props.options.edit && !props.options.edit?.hideOnCard"
						:class="
							props.options.edit.color
								? 'btn-' + props.options.edit.color
								: 'btn-secondary'
						"
						class="btn btn-sm btn-floating shadow-0"
						:href="navigationRoute(dataModel)"
						test-id="dt-actions-edit-btn"
					>
						<MDBTooltip
							v-model="tooltipEdit"
							:disabled="menuEdit"
							offset="0,5"
							direction="left"
							style="display: inline"
						>
							<template #reference>
								<!-- pencil icon -->
								<div class="d-flex align-items-center h-100 w-100">
									<IconBadge v-if="editIcon" :icon="editIcon" class="m-auto" />
								</div>
							</template>
							<template #tip> Edit </template>
						</MDBTooltip>
					</Link>
					<MDBTooltip
						v-if="
							props.options.delete &&
							(props.options.delete.condition
								? props.options.delete.condition(dataModel)
								: true) &&
							!props.options.delete?.hideOnCard
						"
						v-model="tooltipDelete"
						:disabled="menuDelete"
						offset="0,5"
						direction="left"
					>
						<template #reference>
							<button
								class="shadow-0 btn btn-floating btn-sm btn-outline-danger"
								test-id="dt-actions-delete-btn"
								type="button"
								@click.prevent="handleDeleteClicked()"
							>
								<!-- trash icon -->
								<div class="d-flex align-items-center h-100 w-100">
									<FontAwesomeIcon
										:icon="lookupIcon('trash', 'far')"
										class="m-auto"
									/>
								</div>
							</button>
						</template>
						<template #tip> Delete </template>
					</MDBTooltip>
				</div>
				<div
					v-if="
						cardAction === 'button' && actionButtonPosition === 'bottom-right'
					"
					class="row"
					:class="`${props.card.lessPadding ? 'g-0' : ''}`"
				>
					<div class="col-12 rounded">
						<button
							class="btn btn-primary btn-sm rounded-pill float-end btn-filled"
							type="button"
							@click.prevent="onSelect"
						>
							{{ props.buttonText }}
							<IconBadge v-if="props.buttonIcon" :icon="props.buttonIcon" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { formatDollar } from "@/Utils/formatDollar"
import { MDBCollapse, MDBTooltip, MDBBtn } from "mdb-vue-ui-kit"
import {
	reactive,
	computed,
	watch,
	defineEmits,
	defineProps,
	ref,
	onMounted,
	nextTick,
} from "vue"
import IconBadge from "@/Components/IconBadge.vue"
import FormField from "@/Components/Mod/FormField.vue"
import { Link, router } from "@inertiajs/vue3"
import route from "ziggy-js"
import { useProjectStore } from "@/Store/projectStore"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import { useToast } from "@/Composables/useToast"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import FormModal from "@/Components/Mod/FormModal.vue"
import { useDataTableActions } from "@/Composables/useDataTableActions"
import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"

const { injectTeamParam } = useTeamRouteParams()

const toast = useToast()
const props = defineProps({
	card: {
		type: Object,
		required: false,
		default: () => ({}),
	},
	cardItem: {
		type: Object,
		required: true,
	},
	selected: {
		type: Boolean,
		default: false,
	},
	background: {
		type: String,
		default: null,
	},
	icon: {
		type: String,
		default: null,
	},
	buttonText: {
		type: String,
		default: "Select",
	},
	buttonIcon: {
		type: String,
		default: null,
	},
	dataStore: {
		type: Object,
		default: null,
	},
	options: {
		type: Object,
		default: () => ({}),
	},
})
let setInitialStateToObject = false
let infoDataStore = props.dataStore
if (props.dataStore === null) {
	// Called from datatable row
	infoDataStore = useDataModelStore(
		"update_" + (props.options?.id || "info_card") + "_" + props.cardItem.id,
	)
	setInitialStateToObject = true
}
const { dataModel, updatedFields } = storeToRefs(infoDataStore)

watch(
	() => props.cardItem,
	() => {
		infoDataStore.initializeDataModel(
			props.cardItem,
			[{ cards: [props.card] }],
			true,
		)
	},
	{ deep: true },
)
onMounted(() => {
	if (setInitialStateToObject) {
		infoDataStore.initializeDataModel(
			props.cardItem,
			[{ cards: [props.card] }],
			true,
		)
	}
})
const tooltipActions = ref(
	props.card.additionalActions
		? Array(props.card.additionalActions.length).fill(false)
		: [],
)
const featureDescription = ref(null)
const collapseRef = ref(false)
const tooltipEdit = ref(false)
const tooltipDelete = ref(false)
const menuDelete = ref(false)
const menuActions = ref(
	props.options.additionalActions
		? Array(props.options.additionalActions.length).fill(false)
		: [],
)
const hideIcon = computed(() => {
	return props.card.headerOnly ? props.card.headerOnly : false
})
const iconColor = computed(() => {
	if (props.card.iconColor && typeof props.card.iconColor == "function") {
		return props.card.iconColor(dataModel.value)
	} else if (props.card.iconColor) {
		return props.card.iconColor
	} else {
		return "primary"
	}
})
const menuEdit = ref(false)
const editIcon = computed(() => {
	if (props.options.edit?.icon) {
		return props.options.edit.icon
	} else {
		return "pencil"
	}
})
const backgroundColor = computed(() => {
	return props.background
		? props.background.toString().toLowerCase() == "true"
			? ""
			: "bg-" + props.background
		: "bg-transparent"
})
const customClasses = computed(() => {
	return parseClasses(props.card.classList)
})
const customHeaderClasses = computed(() => {
	if (typeof props.card.headerClassList === "function") {
		return parseClasses(props.card.headerClassList(dataModel.value))
	} else {
		return parseClasses(props.card.headerClassList)
	}
})
const actionButtonPosition = computed(() => {
	return props.card.buttonPosition ? props.card.buttonPosition : "top-right"
})

const parseClasses = (incomingClassList) => {
	let classList = []
	if (incomingClassList) {
		if (incomingClassList instanceof Function) {
			let mergeList = incomingClassList(dataModel.value)
			if (Array.isArray(mergeList)) {
				classList = classList.concat(mergeList)
			} else {
				classList.push(mergeList)
			}
		} else if (Array.isArray(incomingClassList)) {
			classList = incomingClassList
		} else {
			classList = [incomingClassList]
		}
	}
	return classList
}

const cardAction = computed(() => {
	return props.card.action ? props.card.action : "button"
})
const headerImageURL = computed(() => {
	let headerImageValue
	if (typeof props.card.headerImage === "function") {
		headerImageValue = props.card.headerImage(dataModel.value)
	} else {
		headerImageValue = infoDataStore.getModelValue({
			name: props.card.headerImage,
		})
	}
	return props.card.headerImage && headerImageValue
		? "url('" + headerImageValue + "')"
		: props.card.defaultHeaderImage
			? "url('" + props.card.defaultHeaderImage + "')"
			: ""
})
const headerCustomRatio = computed(() => {
	return props.card.headerRatio ? props.card.headerRatio : "1"
})
const colsClass = computed(() => {
	let cols = props.card.cols ? "col-" + props.card.cols : "col-12"
	let mdCols = props.card.colsMd ? "col-md-" + props.card.colsMd : "col-md-12"
	let lgCols = props.card.colsLg ? "col-lg-" + props.card.colsLg : "col-lg-12"
	let xlCols = props.card.colsXl ? "col-xl-" + props.card.colsXl : ""
	let xxlCols = props.card.colsXxl ? "col-xxl-" + props.card.colsXxl : ""

	return [cols, mdCols, lgCols, xlCols, xxlCols]
})
const colsMdClass = computed(() => {
	return props.card.colsMd ? "col-md-" + props.card.colsMd : "col-md-6"
})

const inlineUpdate = (item, updated) => {
	if (!props.options.update) {
		return
	}
	const data = {}
	if (updated.column && updated.column.length > 0) {
		data[updated.column] = {}
		data[updated.column][updated.name] = updated.value
	} else {
		data[updated.name] = updated.value
	}

	let params = props.options.update.params
	if (props.options.update.key) {
		params = {
			[props.options.update.key]: item.id,
			...props.options.update.params,
		}
	}

	params = injectTeamParam(props.options.update.route, params)

	const updateRouteValue = props.options.update.route
	let updateRoute = ""
	if (props.options.update.projectPlugin) {
		let projectStore = useProjectStore()

		params["project"] = projectStore.getActiveProjectId
		Object.keys(props.options.update.params).forEach((key) => {
			let paramValue = getNestedProperty(item, props.options.update.params[key])
			params[key] = paramValue
		})
		updateRoute = route(props.options.update.route, params)
	} else {
		updateRoute = route(props.options.update.route, params)
	}
	router.patch(updateRoute, data, {
		preserveScroll: true,
		preserveState: true,
		onSuccess: (event) => {
			toast.success("Successfully updated data")
		},
		onError: (errors) => {
			toast.error(Object.values(errors).join("\n"))
		},
	})
}

const navigationRoute = (item) => {
	let linkHref = null
	let item_id = item.slug || item.id
	if (!props.options.edit || !item_id) {
		return
	}
	if (typeof props.options.edit.route === "function") {
		linkHref = props.options.edit.route(item)
	} else {
		let params = injectTeamParam(
			props.options.edit.route,
			props.options.edit.params,
		)

		linkHref = route(props.options.edit.route, {
			[props.options.edit.key]: item_id,
			...params,
		})
	}
	return linkHref
}

const emit = defineEmits([
	"selected",
	"delete-clicked",
	"expandable-success",
	"additional-action-clicked",
])

const onSelect = () => {
	emit("selected", props.cardItem.value)
}
const handleDeleteClicked = () => {
	emit("delete-clicked", props.cardItem)
}

const cardClickInProgress = ref(false)
const handleCardClick = () => {
	if (props.card.cardClickAction) {
		if (cardClickInProgress.value) {
			return
		}
		cardClickInProgress.value = true
		let targetRoute = ""
		const clickActionInfo = props.card.cardClickAction

		if (typeof clickActionInfo.route == "function") {
			targetRoute = clickActionInfo.route(dataModel.value)
		} else {
			let params = clickActionInfo.params
			params = injectTeamParam(clickActionInfo.route, params)
			targetRoute = route(clickActionInfo.route, {
				[clickActionInfo.key]: dataModel.value.id,
				...params,
			})
		}
		if (!targetRoute) {
			console.error("No route found for card click action")
			return
		}
		router.visit(targetRoute, {
			onSuccess: (event) => {
				cardClickInProgress.value = false
				if (typeof clickActionInfo.onSuccess == "function") {
					clickActionInfo.onSuccess(event)
				}
			},
			onError: (errors) => {
				cardClickInProgress.value = false
				if (typeof clickActionInfo.onError == "function") {
					clickActionInfo.onError(errors)
				}
			},
		})
	}
}

watch(
	collapseRef,
	async (newVal, oldVal) => {
		await nextTick()
		const description = featureDescription.value
		if (!description) return
		if (newVal === false) {
			setTimeout(() => {
				description.style["line-clamp"] = 3
				description.style["-webkit-line-clamp"] = 3
				description.style.display = "-webkit-box"
				description.style["-webkit-box-orient"] = "vertical"
			}, 300)
		} else if (newVal === true) {
			description.style["line-clamp"] = "revert"
			description.style["-webkit-line-clamp"] = "revert"
			description.style.display = "revert"
			description.style["-webkit-box-orient"] = "revert"
		}
	},
	{ immediate: true },
)
</script>

<style scoped lang="scss">
.header-image {
	background-size: contain;
	background-repeat: no-repeat;
}
.header-position-center {
	background-position: center;
}
.header-position-top {
	background-position: top;
}
.header-custom-ratio {
	aspect-ratio: v-bind(headerCustomRatio);
}
.feature-description,
.headingText {
	transition: 300ms ease-in-out;
	text-overflow: ellipsis;
	max-height: 500px;
	height: 100%;
	overflow: hidden;
}
.headingText.hasFeatures {
	min-height: 96px;
}
.headingText.hasFeatures.collapsed {
	max-height: 96px;
}
.feature-description.collapsed {
	max-height: 67px;
}
.collapse-btn:hover {
	background-color: #d4d6db !important;
}
</style>
