<template>
	<Teleport to="body">
		<MDBModal
			id="confirmDialog"
			:model-value="modelValue"
			centered
			:size="props.size"
			@update:model-value="handleModelChange"
			@hidden="handleCancel"
		>
			<MDBModalBody class="px-3">
				<div class="position-absolute top-0 end-0 p-3">
					<button
						class="float-end btn btn-outline-secondary btn-floating btn-sm border border-secondary shadow-0"
						type="button"
						@click.prevent="handleCancel"
					>
						<FontAwesomeIcon :icon="lookupIcon('xmark', 'fat')" size="lg" />
					</button>
				</div>
				<div class="text-center mt-2">
					<h4 class="text-center mt-2 fw-bold mb-3">
						{{ title || "Confirm Action?" }}
					</h4>
					<p class="text-center" v-html="bodyText || defaultBodyText"></p>
				</div>
				<MDBModalFooter class="border-0 justify-content-center pt-2">
					<button
						class="btn-outline-primary bg-transparent rounded-pill btn"
						:disabled="processing"
						type="button"
						@click="handleCancel"
					>
						{{ cancelButtonText || "Cancel" }}
					</button>
					<button
						class="btn rounded-pill btn-primary"
						:class="props.confirmButtonClassList || []"
						:disabled="processing"
						type="button"
						@click="handleConfirm"
					>
						<span class="d-inline-flex align-items-center">
							<MDBSpinner v-if="processing" size="sm" class="spinner-button" />
							<span v-else>{{ confirmButtonText || "Continue" }}</span>
						</span>
					</button>
				</MDBModalFooter>
			</MDBModalBody>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBSpinner,
} from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		default: "",
	},
	bodyText: {
		type: String,
		default: "",
	},
	cancelButtonText: {
		type: String,
		default: "",
	},
	confirmButtonText: {
		type: String,
		default: "",
	},
	confirmButtonColor: {
		type: String,
		default: "",
	},
	processing: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String,
		default: "md",
	},
})

const defaultBodyText = "Are you sure you want to continue with this action?"
const emit = defineEmits(["update:modelValue", "cancel", "confirm"])

const handleModelChange = (value) => {
	emit("update:modelValue", value)
}

const handleCancel = () => {
	emit("cancel")
	emit("update:modelValue", false)
}

const handleConfirm = () => {
	emit("confirm")
}
</script>

<style scoped>
#confirmDialog {
	z-index: 9999;
}

:deep(.centered-title) {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

:deep(.modal-header) {
	position: relative;
}

#confirmDialog .modal-header {
	display: flex;
	justify-content: center;
	position: relative;
}

#confirmDialog .modal-title {
	flex: 0 1 auto;
}

#confirmDialog .btn-close {
	position: absolute;
	right: 1rem;
}

.spinner-button {
	width: 1em !important;
	height: 1em !important;
}

:deep(.spinner-border) {
	vertical-align: middle;
}
</style>
