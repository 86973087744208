<template>
	<button
		:class="props.field.classList || props.classList || []"
		type="button"
		:disabled="props.field.disabled || props.disabled"
		@click="handleDownload"
	>
		<FontAwesomeIcon
			v-if="props.field.icon || props.icon"
			:icon="lookupIcon(props.field.icon || props.icon, 'fas')"
		/>
		<FontAwesomeIcon
			v-if="props.field.iconLeft || props.iconLeft"
			:icon="lookupIcon(props.field.iconLeft || props.iconLeft, 'fas')"
		/>
		{{ props.field.text || props.text }}
		<FontAwesomeIcon
			v-if="props.field.iconRight || props.iconRight"
			:icon="lookupIcon(props.field.iconRight || props.iconRight, 'fas')"
		/>
	</button>
</template>

<script setup>
import { computed, defineProps } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { usePage } from "@inertiajs/vue3"

import http from "@/Services/http"
import { downloadFile } from "@/Composables/useDownloadFile"
const emit = defineEmits(["downloaded"])

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	text: {
		type: String,
		default: "Download",
	},
	icon: {
		type: String,
		default: "download",
	},
	iconLeft: {
		type: String,
		default: "",
	},
	iconRight: {
		type: String,
		default: "",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const downloadUrl = computed(() => {
	if (props.field.route) {
		return typeof props.field.route === "function"
			? props.field.route(props.dataStore)
			: props.field.route
	}
	return null
})

const handleDownload = async () => {
	try {
		await downloadFile(downloadUrl.value, props.field.filename)
		emit("downloaded", true)
	} catch (error) {
		console.error("Download failed:", error)
	}
}
</script>
