export const useLegacyEFConnection = (props) => {
	const fieldsList = [
		{
			name: "access_token",
			label: "API Key",
			type: "text",
		},
		// name
		{
			name: "name",
			label: "Name",
			type: "text",
		},
		// description
		{
			name: "description",
			label: "Description",
			type: "text",
		},
	]

	return {
		fieldsList,
	}
}
