<template>
	<div
		class="row fields-list"
		:class="props.card?.fieldsListClassList || []"
		:style="customGutters"
	>
		<h5 v-if="props.title.length > 0">
			{{ props.title }}
		</h5>
		<FormField
			v-for="(field, k) in fieldsList"
			:key="k"
			:field="field"
			:data-store="props.dataStore"
			@update-value="inputUpdate"
		/>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue"
import FormField from "@/Components/Mod/FormField.vue"

const props = defineProps({
	title: {
		type: String,
		default: "",
	},
	fieldsList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
	card: {
		type: Object,
		default: () => ({}),
	},
})

const emit = defineEmits(["updateValue"])
const inputUpdate = (field) => {
	emit("updateValue", field)
}

const customGutters = computed(() => {
	let style = {}
	if (props.fieldsList?.gutterY)
		style["--mdb-gutter-y"] = props.fieldsList.gutterY
	if (props.fieldsList?.gutterX)
		style["--mdb-gutter-x"] = props.fieldsList.gutterX
	return style
})
</script>

<style scoped></style>
