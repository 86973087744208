<template>
	<label v-if="computedLabel" :for="inputId" class="form-label">
		{{ computedLabel }}
	</label>
	<div class="input-group">
		<!-- maxlength needs to be at least 9 to allow for backgrounds with an alpha value other than 1. Ex: #00000000 -->
		<MDBInput
			:id="inputId"
			v-model="localValue"
			:required="props.field.required || props.required || false"
			type="text"
			:maxlength="9"
			:readonly="props.field.readonly || props.readonly || false"
			class="border-end-0"
			:class="props.field.classList || props.classList || []"
			:error-messages="errorMessages"
			:form-outline="false"
			wrapper-class="form-floating border-right-0"
			@update:model-value="onInput"
		/>
		<span class="input-group-text color-picker-input p-1 bg-secondary-1">
			<ColorPicker
				advanced
				:disable-history="true"
				:pure-color="localValue"
				format="hex8"
				lang="en-US"
				@update:pure-color="onInput"
			/>
		</span>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
	nextTick,
} from "vue"
import { MDBInput } from "mdb-vue-ui-kit"
import { ColorPicker } from "vue3-colorpicker"

import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	align: {
		type: String,
		default: "left",
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const computedLabel = computed(() => props.field.label ?? props.label)

const emit = defineEmits(["update:modelValue"])

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = shallowRef(null)
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

watch(
	computeFieldValue,
	(newValue) => {
		if (newValue !== localValue.value) {
			localValue.value = newValue
		}
	},
	{ immediate: true },
)

const onInput = (value) => {
	if (!value.startsWith("#")) {
		value = "#" + value
	}
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, value, true)
	}
	nextTick(() => {
		localValue.value = value
		if (fieldValue.value !== undefined) {
			fieldValue.value = localValue.value
		}
		emit("update:modelValue", value)
	})
}
</script>
<style scoped>
.color-picker-input {
	margin-top: auto;
}
</style>
