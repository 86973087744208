<template>
	<div class="banner" :class="bannerClassList">
		<div class="banner-content rounded-3 bg-primary-10 p-3">
			<h5 v-if="title || title.length > 0" class="text-primary-3 mb-1 fw-bold">
				{{ title }}
			</h5>
			<p v-if="props.field.allowHtml" v-html="content"></p>
			<p v-else class="m-0">{{ content }}</p>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, defineModel, defineEmits, onMounted } from "vue"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: [Array, String],
		default: () => [],
	},
	title: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})

const fieldValue = defineModel({
	type: String,
	default: "",
})
const title = computed(() => {
	return props.field.title || props.title
})
const content = computed(() => {
	return props.content.length > 0
		? props.content
		: props.field.content
			? props.field.content
			: props.field.name
				? fieldValue
				: ""
})
const bannerClassList = computed(() => {
	if (typeof props.classList === "string" && props.classList.length > 0) {
		return props.classList.split(" ") || []
	} else if (Array.isArray(props.classList) && props.classList.length > 0) {
		return props.classList || []
	} else {
		return props.field.classList || []
	}
})
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}
onMounted(() => {
	if (props.dataStore) {
		fieldValue.value = computeFieldValue()
	}
})
</script>
