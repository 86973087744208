<template>
	<input
		:id="inputId"
		type="hidden"
		:name="props.field.name || props.name"
		:value="fieldValue"
	/>
</template>

<script setup>
import { computed, defineModel, onMounted, shallowRef } from "vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: Boolean,
	default: undefined,
})
const localValue = shallowRef(null)

const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}

onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	} else {
		localValue.value = fieldValue.value
	}
})
</script>
