<template>
	<div
		class="iframe-container"
		:style="props.field.style"
		:class="props.field.classList"
	>
		<iframe ref="iframeRef" class="w-100 h-100" :[srcTag]="currentSrc"></iframe>
	</div>
</template>

<script setup>
import { ref, computed, defineProps, defineModel } from "vue"
import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"
import { watch } from "vue"
import { debounce } from "lodash"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: [Array, String],
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})
// const updateStore = useDataModelStore("update_" + props.data.id)
// const { updateDataModel } = storeToRefs(updateStore)
// const pageStore = useDataModelStore()
const { dataModel, updatedFields } = storeToRefs(props.dataStore)
const fieldValue = defineModel({
	type: String,
	default: "",
})

const needsRefresh = ref(false)
const iframeRef = ref(null)
const refreshKey = ref(0)

if (props.field.refreshOnSave) {
	watch(
		() => updatedFields.value,
		() => {
			if (updatedFields.value.length > 0) {
				needsRefresh.value = false
			} else {
				needsRefresh.value = true
				refreshIframe()
			}
		},
		{ deep: true },
	)
}

const iframeRoute = computed(() => {
	if (props.field.href) {
		return props.field.href
	}
	const targetRoute = props.field.route
	const params = props.field.params || {}

	if (typeof targetRoute === "function") {
		return targetRoute(params)
	} else {
		return route(targetRoute, params)
	}
})

const srcTag = computed(() => {
	if (props.field.name && props.dataStore) {
		return "srcdoc"
	} else {
		return "src"
	}
})

const currentSrc = computed(() => {
	if (props.field.name && props.dataStore) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
			disableDotNotation: true,
		})

		return retValue
	}

	const param = `refresh=${refreshKey.value}`

	if (!iframeRoute.value) {
		return null
	}
	return (
		`${iframeRoute.value}` +
		(iframeRoute.value.includes("?") ? "&" : "?") +
		param
	)
})

const refreshIframe = debounce(() => {
	refreshKey.value++
	needsRefresh.value = false
}, 1000)
</script>

<style scoped>
.iframe-container {
	width: 100%;
	height: 100%;
}

.refresh-button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;
	padding: 5px 10px;
	cursor: pointer;
}
</style>
