<template>
	<MDBTabs v-model="currentTab">
		<!-- Tabs navs -->
		<div class="row" :style="customGutters">
			<div :class="navClasses">
				<div
					v-if="props.card.title"
					:class="[...(props.card.titleClassList || [])]"
				>
					<h5
						:class="
							props.card.titleTextClassList
								? props.card.titleTextClassList
								: ['text-center', 'larger']
						"
					>
						{{ props.card.title }}
					</h5>
				</div>
				<MDBTabNav :tabs-classes="tabClasses">
					<MDBTabItem
						v-for="tab in props.card.tabsList"
						:key="tab.tabId"
						class="rounded"
						:class="[
							{
								'use-image border-0': tab.imageAsset,
							},
							...(props.card.tabItemClassList || ['p-0']),
						]"
						:tab-id="tab.tabId"
						:href="tab.tabId"
					>
						<div v-if="!tab.imageAsset">
							{{ tab.title }}
						</div>
						<div v-if="tab.imageAsset" class="image-asset-tab text-center">
							<div
								class="smaller mb-1"
								:class="{
									'text-primary fw-bold': currentTab === tab.tabId,
									'fw-light': currentTab !== tab.tabId,
								}"
							>
								{{ tab.title }}
							</div>
							<div>
								<img
									v-if="
										props.dataStore.getModelValue({
											name: 'dashboard_assets.' + tab.imageAsset,
										})
									"
									:src="
										props.dataStore.getModelValue({
											name: 'dashboard_assets.' + tab.imageAsset,
										})
									"
									class="img-fluid img-thumbnail shadow-0"
									:class="{
										'border-primary': currentTab === tab.tabId,
									}"
								/>
							</div>
						</div>
					</MDBTabItem>
				</MDBTabNav>
			</div>
			<!-- Tabs navs -->

			<!-- Tabs content -->
			<div :class="contentClasses">
				<MDBTabContent>
					<MDBTabPane
						v-for="tab in props.card.tabsList"
						:key="tab.tabId"
						:tab-id="tab.tabId"
					>
						<MDBRow :style="customGutters" class="test">
							<FormCard
								v-for="(c, j) in tab.cards"
								:key="j"
								:card="c"
								:card-item="props.formModel"
								:data-store="props.dataStore"
								@update-value="inputUpdate"
							>
								<template #title>
									<MDBCardTitle class="d-flex justify-content-between">
										<div class="card-title-text">{{ c.title }}</div>
										<MDBBtn
											v-if="tab.delete"
											floating
											class="float-right"
											@click.stop="deleteClicked(c)"
										>
											<FontAwesomeIcon :icon="lookupIcon('trash', 'fas')" />
										</MDBBtn>
									</MDBCardTitle>
								</template>
							</FormCard>
						</MDBRow>
					</MDBTabPane>
				</MDBTabContent>
			</div>
			<!-- Tabs content -->
		</div>
	</MDBTabs>
</template>

<!-- Rest of the component remains the same -->

<script setup>
import {
	ref,
	reactive,
	defineProps,
	defineEmits,
	computed,
	watch,
	onMounted,
} from "vue"
import FormCard from "@/Components/Mod/FormCard.vue"
import {
	MDBBtn,
	MDBRow,
	MDBTabContent,
	MDBTabItem,
	MDBTabNav,
	MDBTabPane,
	MDBTabs,
	MDBCardTitle,
} from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { storeToRefs } from "pinia"

const props = defineProps({
	card: {
		type: Object,
		default: () => ({}),
	},
	formModel: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})
const currentTab = ref(props.card.tabsList[0].tabId)

const emit = defineEmits(["updateValue"])
const { dataModel } = storeToRefs(props.dataStore)
const correctTab = computed(
	() => dataModel.value.tabIds?.[props.card.tabId] ?? currentTab.value,
)

const navClasses = computed(() => {
	let navClass
	if (props.card.hideNav) {
		navClass = ["d-none"]
	} else if (props.card.horizontalNav) {
		navClass = ["col-12"]
		if (props.card.navOnTop) {
			navClass.push("order-0")
		} else {
			navClass.push("order-1")
		}
	} else {
		navClass = [...(props.card.navClassList || []), "col-md-2", "col-12"]
	}
	return navClass
})
const tabClasses = computed(() => {
	const baseClasses = [...(props.card.tabClassList || [])]
	if (props.card.horizontalNav) {
		baseClasses.push("gap-3", "horizontalNav")
		if (props.card.navClassList) {
			baseClasses.push(...props.card.navClassList)
		}
		return baseClasses.join(" ")
	} else {
		baseClasses.push("flex-column")
		return baseClasses.join(" ")
	}
})
const contentClasses = computed(() => {
	if (props.card.hideNav || props.card.horizontalNav) return ["col-12"]
	else return ["col-md-10", "col-12"]
})

watch(currentTab, (newVal) => {
	if (props.card.tabId) {
		props.dataStore.setNestedValue(`tabIds.${props.card.tabId}`, newVal)
	}
})
watch(correctTab, (newVal) => {
	currentTab.value = newVal
})

onMounted(() => {
	if (props.card.tabId) {
		props.dataStore.setNestedValue(
			`tabIds.${props.card.tabId}`,
			props.card.tabsList[0].tabId,
		)
	}
})

const inputUpdate = (field) => {
	emit("updateValue", field)
}

const customGutters = computed(() => {
	let style = {}
	if (props.card?.gutterY) style["--mdb-gutter-y"] = props.card.gutterY
	if (props.card?.gutterX) style["--mdb-gutter-x"] = props.card.gutterX
	return style
})
</script>

<style scoped>
.use-image img {
	width: 100px;
}
.nav-item:hover .use-image img {
	border-width: 2px;
	border-color: var(--mdb-primary);
}
.nav-item .active.use-image img {
	border-width: 3px;
}
</style>
