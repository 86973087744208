<template>
	<tr :class="getClass(item)" :data-item="JSON.stringify(item)" class="dt-tr">
		<!-- drag handle -->
		<td
			v-for="(column, index) in computedHeaders"
			:key="index"
			class="dt-td"
			style="vertical-align: middle"
			:style="column.key !== '_select' && index === 0 ? { paddingLeft: 0 } : {}"
			:class="{
				'fixed-cell-end table-light': column.key == 'actions',
				'fixed-cell table-light': column.key == '_select',
				[column.class]: column.class,
			}"
		>
			<slot :column="column" :name="columnName(column)">
				<div
					:class="getAlignment(column)"
					class="dt-td-content d-flex align-items-center"
				>
					<template v-if="column.key == '_drag'">
						<FontAwesomeIcon
							class="drag-handle"
							:icon="lookupIcon('bars', 'fas')"
						/>
					</template>
					<template v-if="column.key == 'actions'">
						<MDBDropdown
							v-model="menuMore"
							dropstart
							class="showMore"
							offset="-5,0"
							:popper-config="{ strategy: 'fixed' }"
						>
							<MDBDropdownToggle
								tag="a"
								class="d-flex align-items-center rounded-pill text-white text-decoration-none"
								title="Actions"
								@click.prevent="
									((menuMore = !menuMore), (tooltipMore = !tooltipMore))
								"
							>
								<MDBBtn
									size="xl"
									floating
									color="primary"
									class="shadow-0 d-flex align-items-center justify-content-center"
									test-id="dt-actions-more-btn"
								>
									<IconBadge
										class="m-auto"
										icon="ellipsis-vertical"
										:options="{ classList: [] }"
									/>
								</MDBBtn>
							</MDBDropdownToggle>

							<MDBDropdownMenu
								class="border-secondary border"
								aria-labelledby="dropdownMenuButton"
							>
								<MDBDropdownItem
									v-if="
										props.tableOptions?.expandable?.cards?.length > 0 &&
										checkFeaturesAndPermissions(props.tableOptions.expandable)
									"
								>
									<FormModal
										:tab="props.tableOptions.expandable"
										:tab-item="item"
										:data-store="updateStore"
										button-size="md"
										:button-class="[
											'btn',
											'btn-floating',
											'm-0',
											'h-100',
											'w-100',
											'border',
											'btn-outline-primary',
										]"
										@success="emit('expandable-success', $event)"
									/>
								</MDBDropdownItem>
								<MDBDropdownItem
									v-for="(action, key) in props.tableOptions
										.additionalActions || []"
									v-show="checkFeaturesAndPermissions(action)"
									:key="key"
								>
									<IconBadge
										size="xl"
										class="m-auto"
										:icon="action.icon"
										:options="{
											classList: [
												'btn',
												'btn-primary',
												'rounded-circle',
												'p-2',
												...(action.classList ?? []),
											],
										}"
										@click="
											emit('additional-action-clicked', action, item, $event)
										"
									/>
								</MDBDropdownItem>
								<MDBDropdownItem
									v-if="
										props.tableOptions.edit &&
										checkFeaturesAndPermissions(props.tableOptions.edit)
									"
								>
									<Link
										class="h-100 d-block shadow-0 rounded-circle"
										:class="
											props.tableOptions.edit.color
												? 'btn-' + props.tableOptions.edit.color
												: 'btn-secondary'
										"
										:href="props.navigationRoute(item)"
										test-id="dt-actions-edit-btn d-flex align-items-center justify-content-center"
									>
										<IconBadge
											size="xl"
											:icon="props.tableOptions.edit.icon || 'pencil'"
											class="m-auto"
											:options="{
												classList: [
													'btn',
													'btn-outline-primary',
													'rounded-circle',
													'p-2',
												],
											}"
										/>
									</Link>
								</MDBDropdownItem>
								<MDBDropdownItem
									v-if="
										props.tableOptions.delete &&
										props.tableOptions.delete.disabled !== true &&
										(props.tableOptions.delete.condition
											? props.tableOptions.delete.condition(item)
											: true) &&
										checkFeaturesAndPermissions(props.tableOptions.delete)
									"
								>
									<IconBadge
										size="xl"
										icon="far.trash"
										class="m-auto"
										:options="{
											classList: [
												'btn',
												'btn-outline-danger',
												'rounded-circle',
												'p-2',
												'text-danger',
											],
										}"
										@click="handleDeleteClicked(item)"
									/>
								</MDBDropdownItem>
							</MDBDropdownMenu>
						</MDBDropdown>
						<FormModal
							v-if="
								props.tableOptions?.expandable?.cards?.length > 0 &&
								checkFeaturesAndPermissions(props.tableOptions?.expandable)
							"
							:tab="props.tableOptions.expandable"
							:tab-item="item"
							:data-store="updateStore"
							button-size="sm"
							table-style="table"
							tooltip-label="Details"
							:button-class="[
								'btn-floating',
								'btn-outline-primary',
								'd-flex',
								'justify-content-center',
								'align-items-center',
							]"
							@success="emit('expandable-success', $event)"
						/>
						<MDBTooltip
							v-for="(action, key) in props.tableOptions.additionalActions ||
							[]"
							:key="key"
							v-model="tooltipActions[key]"
							:disabled="menuActions[key] || action.tooltip === undefined"
							offset="0,5"
							direction="left"
							:options="{ strategy: 'fixed' }"
						>
							<template #reference>
								<FormField
									v-if="action.type == 'boolean_icon'"
									:field="action"
									:data-store="updateStore"
									@update-value="inlineUpdate(item, $event)"
								/>
								<MDBBtn
									v-else
									size="sm"
									floating
									color="primary"
									class="shadow-0 d-flex align-items-center justify-content-center"
									:class="action.wrapperClass ?? []"
									test-id="dt-actions-additional-btn"
									@click="
										emit('additional-action-clicked', action, item, $event)
									"
								>
									<IconBadge
										class="m-auto"
										:icon="action.icon"
										:options="{ classList: action.classList ?? [] }"
									/>
								</MDBBtn>
							</template>
							<template #tip>
								{{ action.tooltip ?? "" }}
							</template>
						</MDBTooltip>
						<Link
							v-if="
								props.tableOptions.edit &&
								checkFeaturesAndPermissions(props.tableOptions?.edit)
							"
							:class="
								props.tableOptions.edit.color
									? 'btn-' + props.tableOptions.edit.color
									: 'btn-secondary'
							"
							class="btn btn-sm btn-floating shadow-0 d-flex align-items-center justify-content-center"
							:href="props.navigationRoute(item)"
							test-id="dt-actions-edit-btn"
						>
							<MDBTooltip
								v-model="tooltipEdit"
								:disabled="menuEdit"
								offset="0,5"
								direction="left"
								style="display: inline"
								:options="{ strategy: 'fixed' }"
							>
								<template #reference>
									<IconBadge
										:icon="props.tableOptions.edit.icon || 'pencil'"
										class="m-auto"
										:options="{ classList: [] }"
									/>
								</template>
								<template #tip>
									{{ props.tableOptions.edit.tooltip || "Edit" }}
								</template>
							</MDBTooltip>
						</Link>
						<MDBTooltip
							v-if="
								props.tableOptions.delete &&
								props.tableOptions.delete.disabled !== true &&
								(props.tableOptions.delete.condition
									? props.tableOptions.delete.condition(item)
									: true) &&
								checkFeaturesAndPermissions(props.tableOptions?.delete)
							"
							v-model="tooltipDelete"
							:disabled="menuDelete"
							offset="0,5"
							direction="left"
							:options="{ strategy: 'fixed' }"
						>
							<template #reference>
								<MDBBtn
									size="sm"
									class="shadow-0 d-flex align-items-center justify-content-center text-danger btn-outline-danger"
									floating
									test-id="dt-actions-delete-btn"
									@click="handleDeleteClicked(item)"
								>
									<IconBadge
										icon="far.trash"
										class="m-auto"
										:options="{ classList: [] }"
									/>
								</MDBBtn>
							</template>
							<template #tip> Delete </template>
						</MDBTooltip>
					</template>
					<template v-if="column.key == 'update_action'">
						<FormField
							v-model="dataModel[column.field.name]"
							:field="{ ...column.field, readonly: inlineUpdateInProgress }"
							:data-store="updateStore"
							@update-value="inlineUpdate(item, $event)"
						/>
					</template>

					<template v-else-if="column.type == 'boolean'">
						<div class="d-flex align-items-center h-100 w-100">
							<FontAwesomeIcon
								v-if="item[column.key]"
								class="text-success m-auto"
								size="xl"
								:icon="lookupIcon('circle', 'fas')"
							/>
							<FontAwesomeIcon
								v-else
								class="text-danger m-auto"
								size="xl"
								:icon="lookupIcon('circle', 'fas')"
							/>
						</div>
					</template>
					<template v-else-if="column.type == 'status_circle'">
						<div class="d-flex align-items-center h-100 w-100">
							<MDBTooltip
								v-model="statusTooltip[column.key]"
								offset="0,5"
								direction="left"
								class="m-auto"
								style="display: inline"
								:options="{ strategy: 'fixed' }"
							>
								<template #reference>
									<FontAwesomeIcon
										:class="column.classList(item)"
										class="m-auto"
										size="xl"
										:icon="lookupIcon('circle', 'fas')"
									/>
								</template>
								<template #tip> {{ column.statusText(item) }} </template>
							</MDBTooltip>
						</div>
					</template>
					<template v-else-if="column.type == 'translation'">
						{{ column.prepend || "" }}
						{{
							translator.lookupOnObject(
								item,
								column.translationLocation,
								column.key,
							)
						}}
						{{ column.append || "" }}
					</template>
					<template v-else-if="column.type == 'image'">
						<div
							v-if="
								column.sprite &&
								(typeof column.sprite == 'function'
									? column.sprite(item)
									: column.sprite)
							"
							style="
								width: 100%;
								max-height: 80px;
								text-align: center;
								aspect-ratio: 1;
							"
						>
							<SpriteDisplay
								:content="
									column.url && typeof column.url == 'function'
										? column.url(item, column)
										: item[column.key]
								"
								:field="column"
								fill="fixed"
								:height="80"
								:width="120"
							/>
						</div>
						<img
							v-else-if="item[column.key] && item[column.key].length > 0"
							:src="
								column.url && typeof column.url == 'function'
									? column.url(item, column)
									: item[column.key]
							"
							:alt="item[column.key]"
							style="
								width: auto;
								height: 100%;
								max-height: 80px;
								margin: auto;
								aspect-ratio: 1;
								object-fit: cover;
							"
						/>
						<div v-if="column.captionField" class="ms-3">
							<template v-if="column.captionFieldType == 'timestamp'">
								{{
									formatTimestamp(getNestedProperty(item, column.captionField))
								}}
							</template>
							<template v-else>
								{{ item[column.captionField] }}
							</template>
						</div>
					</template>
					<template v-else-if="column.type == 'time_since'">
						{{ timeSince(item[column.key]) }}
					</template>
					<template v-else-if="column.type == 'duration'">
						{{ formatSecondsToDurationString(item[column.key]) }}
					</template>
					<template v-else-if="column.type == 'laravel-timestamp'">
						{{ formatLaravelTimestamp(item[column.key], "F ZZZZ") }}
					</template>
					<template
						v-else-if="
							column.type == 'timestamp' ||
							column.key == 'created_at' ||
							column.key == 'updated_at'
						"
					>
						{{ formatTimestamp(getNestedProperty(item, column.key)) }}
					</template>
					<template v-else-if="column.type == 'export_button'">
						<button
							:target="column.target || ''"
							:class="column.classList(item) || []"
							@click="exportClicked({ ...item, ...column.options } || item)"
						>
							<div class="d-flex align-items-center h-100 w-100">
								<FontAwesomeIcon
									v-if="column.icon"
									:icon="lookupIcon(column.icon, 'fas')"
									class="m-auto"
								/>
								<span v-if="column.label" class="ms-1">
									{{ column.label(item) }}
								</span>
							</div>
						</button>
					</template>
					<template v-else-if="column.type == 'external_link'">
						<a :target="column.target || ''" :href="column.route(item)">
							<div class="d-flex align-items-center h-100 w-100">
								<FontAwesomeIcon
									v-if="column.icon"
									:icon="lookupIcon(column.icon, 'fas')"
									class="m-auto"
								/>
								<span v-if="column.label" class="ms-1">
									{{ column.label(item) }}
								</span>
							</div>
						</a>
					</template>
					<template v-else-if="column.type == 'download_button'">
						<button
							:target="column.target || ''"
							:class="column.classList(item) || []"
							@click="handleDownload(column)"
						>
							<div class="d-flex align-items-center h-100 w-100">
								<FontAwesomeIcon
									v-if="column.icon"
									:icon="lookupIcon(column.icon, 'fas')"
									class="m-auto"
								/>
								<span v-if="column.label" class="ms-1">
									{{ column.label(item) }}
								</span>
							</div>
						</button>
					</template>
					<template v-else-if="column.type == 'internal_link'">
						<Link
							:href="column.route(item)"
							:class="column.classList(item) || []"
						>
							<div class="d-flex align-items-center h-100 w-100">
								<FontAwesomeIcon
									v-if="column.icon"
									:icon="lookupIcon(column.icon, 'fas')"
									class="m-auto"
								/>
								<span v-if="column.label" class="ms-1">
									{{ column.label(item) }}
								</span>
							</div>
						</Link>
					</template>

					<!-- tags -->
					<template v-else-if="column.type == 'tags'">
						<div class="chip-group d-flex">
							<MDBChip
								v-for="(tag, chipIndex) in item[column.key]"
								:key="chipIndex"
								class="d-flex align-items-center justify-content-center"
							>
								{{ column.transform ? column.transform(tag) : tag }}
							</MDBChip>
						</div>
					</template>
					<template v-else-if="column.type == 'badge'">
						<MDBBadge
							:color="
								typeof column.color == 'function'
									? column.color(item, column)
									: column.color || 'primary'
							"
						>
							{{
								column.format
									? column.format(getNestedProperty(item, column.key), item)
									: getNestedProperty(item, column.key)
							}}
						</MDBBadge>
					</template>
					<template v-else-if="column.type == 'markdown'">
						<div
							class="markdown-cell"
							v-html="formatMarkdown(getNestedProperty(item, column.key))"
						></div>
					</template>
					<template v-else>
						<IconBadge
							v-if="column.icon"
							:icon="
								typeof column.icon === 'function'
									? column.icon(item)
									: column.icon
							"
							:options="column.iconOptions || {}"
						></IconBadge>

						{{ column.prepend || "" }}
						<span
							v-if="column.formatHtml"
							v-html="
								column.formatHtml
									? column.formatHtml(getNestedProperty(item, column.key), item)
									: getNestedProperty(item, column.key)
							"
						></span>
						<span v-else>
							{{
								column.format
									? column.format(getNestedProperty(item, column.key), item)
									: getNestedProperty(item, column.key)
							}}
						</span>
						{{ column.append || "" }}
					</template>
				</div>
			</slot>
		</td>
	</tr>
</template>

<script setup>
import {
	MDBBadge,
	MDBBtn,
	MDBCheckbox,
	MDBChip,
	MDBIcon,
	MDBTooltip,
	MDBDropdown,
	MDBDropdownMenu,
	MDBDropdownToggle,
	MDBDropdownItem,
} from "mdb-vue-ui-kit"
import { defineProps, computed, ref, watch, onMounted } from "vue"
import { getNestedProperty } from "@/Utils/dotNotationHelpers"
import {
	formatTimestamp,
	formatLaravelTimestamp,
	timeSince,
} from "@/Utils/dateHelpers"
import FormField from "@/Components/Mod/FormField.vue"
import FormModal from "@/Components/Mod/FormModal.vue"
import { Link, router } from "@inertiajs/vue3"
import { useProjectStore } from "@/Store/projectStore"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import IconBadge from "@/Components/IconBadge.vue"
import { useToast } from "@/Composables/useToast"
import http from "@/Services/http"
import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"
const { injectTeamParam } = useTeamRouteParams()
import SpriteDisplay from "@/Components/Mod/FormFields/SpriteDisplay.vue"
import { formatMarkdown } from "@/Utils/aiChatMessageHelpers.js"
import { formatSecondsToDurationString } from "@/Utils/dateHelpers"

const toast = useToast()

const emit = defineEmits([
	"export-clicked",
	"update:selectedItems",
	"update:modelValue",
	"navigate",
	"delete-clicked",
	"expandable-success",
	"additional-action-clicked",
])

// #region ---------  Props ----------
const props = defineProps({
	itemClass: {
		type: [String, Function],
		default: "",
	},
	item: {
		type: Object,
		default: () => {
			return {}
		},
	},
	headers: {
		type: Array,
		default: () => {
			return []
		},
	},
	tableOptions: {
		type: Object,
		default: () => {
			return {}
		},
	},
	selectedItems: {
		type: Array,
		default: () => {
			return []
		},
	},
	navigationRoute: {
		type: Function,
		default: () => {
			return () => {}
		},
	},
})

const computedHeaders = computed(() => {
	if (props.headers[0].key !== "_drag") return props.headers
	const filteredHeaders = props.headers.slice(1)
	filteredHeaders[0].icon = "grip-vertical"
	filteredHeaders[0].iconOptions = {
		classList: ["text-secondary-6", "me-2", "cursor-grab"],
	}
	return filteredHeaders
})

const updateStore = useDataModelStore(
	"update_" + (props.tableOptions?.id || "expandable") + "_" + props.item.id,
)
const { dataModel, updatedFields } = storeToRefs(updateStore)
const tooltipMore = ref(false)
const menuMore = ref(false)

import { checkFeaturesAndPermissions } from "@/Composables/useModVisibilityChecker"
import { indexOf } from "lodash"

const tooltipDelete = ref(false)
const menuDelete = ref(false)

const tooltipEdit = ref(false)
const menuEdit = ref(false)

const tooltipActions = ref(
	props.tableOptions.additionalActions
		? Array(props.tableOptions.additionalActions.length).fill(false)
		: [],
)
const menuActions = ref(
	props.tableOptions.additionalActions
		? Array(props.tableOptions.additionalActions.length).fill(false)
		: [],
)
const statusTooltip = ref(
	Object.assign(
		{},
		...computedHeaders.value.map((header) => ({ [header.key]: false })),
	),
)

const getClass = (item) => {
	if (props.itemClass && typeof props.itemClass === "function") {
		return props.itemClass(item)
	} else {
		return props.itemClass
	}
}

const columnName = (header) => {
	return `item.${header.key}`
}

const anyName = (header) => {
	return `item.any`
}
const alignmentOptions = {
	start: "left",
	end: "right",
	center: "center",
}

const getAlignment = (header) => {
	let alignmentStyle = alignmentOptions[header.align]
		? alignmentOptions[header.align]
		: header.key === "actions"
			? "end"
			: "start"
	if (alignmentStyle === "right") alignmentStyle = "end"
	else if (alignmentStyle === "left") alignmentStyle = "start"
	return `justify-content-${alignmentStyle}`
}

/**
 * takes in a header containing title and key
 * and finds the data from item.
 * if key uses dot notation, dig into the object
 * @param {object} item
 * @param {object} header
 */
const getNonSlotValue = (item, header) => {
	const key = header.key
	if (key.includes(".")) {
		const keys = key.split(".")
		let value = item
		keys.forEach((k) => {
			value = value[k]
		})
		return value
	} else {
		return item[key]
	}
}
const inlineUpdateInProgress = ref(false)

watch(
	() => props.item,
	() => {
		updateStore.initializeDataModel(
			props.item,
			[...computedHeaders.value, props.tableOptions?.expandable || {}],
			true,
		)
	},
	{ deep: true },
)

const inlineUpdate = (item, updated) => {
	if (!props.tableOptions.update) {
		return
	}
	const data = {}
	if (updated.column && updated.column.length > 0) {
		data[updated.column] = {}
		data[updated.column][updated.name] = updated.value
	} else {
		data[updated.name] = updated.value
	}

	let params = props.tableOptions.update.params
	if (props.tableOptions.update.key) {
		params = {
			[props.tableOptions.update.key]: item.id,
			...props.tableOptions.update.params,
		}
	}
	params = injectTeamParam(props.tableOptions.update.route, params)

	const updateRouteValue = props.tableOptions.update.route
	let updateRoute = ""
	if (props.tableOptions.update.projectPlugin) {
		let projectStore = useProjectStore()

		params["project"] = projectStore.getActiveProjectId
		Object.keys(props.tableOptions.update.params).forEach((key) => {
			let paramValue = getNestedProperty(
				item,
				props.tableOptions.update.params[key],
			)
			params[key] = paramValue
		})
		updateRoute = route(props.tableOptions.update.route, params)
	} else {
		updateRoute = route(props.tableOptions.update.route, params)
	}
	inlineUpdateInProgress.value = true

	try {
		http
			.patch(updateRoute, data)
			.then(() => {
				toast.success("Successfully updated data")
				inlineUpdateInProgress.value = false
				router.reload({
					replace: true,
				})
			})
			.catch((e) => {
				if (e.response && e.response.data) {
					toast.error(e.response.data.message)
				} else {
					toast.error(e)
				}
				inlineUpdateInProgress.value = false
			})
	} catch (e) {
		toast.error(e)
		inlineUpdateInProgress.value = false
	}
}
const exportClicked = (options) => {
	console.log("export clicked", options)
	emit("export-clicked", options)
}
const handleDeleteClicked = (item) => {
	emit("delete-clicked", item)
}
onMounted(() => {
	updateStore.initializeDataModel(
		props.item,
		[...computedHeaders.value, props.tableOptions?.expandable || {}],
		true,
	)
})

const handleDownload = async (column) => {
	const downloadUrl = column.route(props.item)
	if (!downloadUrl) {
		console.error("Download URL is not defined")
		return
	}

	try {
		// Get the signed URL and Content-Disposition from your server
		const response = await http.get(downloadUrl)

		if (!response.data || !response.data.url) {
			throw new Error("Invalid response from server")
		}

		const signedUrl = response.data.url

		// Extract filename from Content-Disposition header
		const contentDisposition = response.headers["content-disposition"]
		let filename = "download"
		if (contentDisposition) {
			const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/i)
			if (filenameMatch) {
				filename = filenameMatch[1]
			}
		}

		// Create a hidden anchor element to trigger the download
		const link = document.createElement("a")
		link.href = signedUrl
		link.setAttribute("download", filename)
		link.setAttribute("target", "_blank") // Open in a new tab
		document.body.appendChild(link)
		link.click()
		link.remove()
	} catch (error) {
		console.error("Download failed:", error)
	}
}
</script>

<style>
th[data-key="_drag"] {
	display: none;
}
th[data-key="_drag"] + th {
	padding-left: 0;
}
@media (min-width: 769px) {
	.showMore {
		display: none !important;
	}
}
@media (max-width: 767px) {
	.showMore ~ *,
	.dropdown-toggle::before {
		display: none !important;
	}
	.dropdown-toggle button {
		width: 30px;
		height: 30px;
	}
	.showMore .dropdown-menu {
		min-width: unset;
		display: flex;
		padding: 5px;
		gap: 5px;
		align-items: center;
	}
	.showMore .dropdown-menu li {
		border: none !important;
		cursor: pointer;
		height: 32px;
		flex: 1 0 0;
		aspect-ratio: 1;
	}
	.showMore .dropdown-menu svg {
		aspect-ratio: 1;
	}
}
</style>

<style scoped>
.dt-td {
	max-width: 200px;
	font-weight: 300;
}
.dt-td span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}
.chip-group {
	flex-wrap: wrap;
	min-width: 250px;
}
.fixed-cell {
	position: sticky;
	left: 0;
	background-clip: padding-box;
	border-collapse: separate;
	z-index: 1;
}
.fixed-cell-end {
	position: sticky;
	right: 0;
	background-clip: padding-box;
	border-collapse: separate;
	z-index: 2;
	overflow: hidden;
}

.markdown-cell {
	max-width: 100%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}

.markdown-cell :deep(p) {
	margin-bottom: 0;
}

.markdown-cell :deep(img) {
	max-width: 100%;
	height: auto;
}
</style>
