<template>
	<label v-if="computedLabel" :for="inputId" class="form-label">
		{{ computedLabel }}
	</label>
	<div
		:class="{
			'input-group': true,
		}"
	>
		<MDBInput
			:id="inputId"
			:model-value="localValue"
			:required="props.field.required || props.required"
			type="text"
			autocomplete="off"
			:readonly="props.readonly || props.readonly || false"
			:disabled="props.field.readonly || props.readonly || false"
			:class="props.field.classList || props.classList || []"
			:error-messages="errorMessages"
			:form-outline="false"
			wrapper-class="form-floating"
			@update:model-value="onInput"
		/>
		<MDBBtn class="btn btn-outline-primary" :ripple="false">
			<FontAwesomeIcon
				v-if="localValue"
				size="2x"
				:icon="lookupIcon(localValue, props.field.iconStyle || props.iconStyle)"
			/>
		</MDBBtn>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
} from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { MDBInput, MDBBtn } from "mdb-vue-ui-kit"
import { storeToRefs } from "pinia"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	iconStyle: {
		type: String,
		default: "fas",
	},
	label: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
})

const emit = defineEmits(["update:modelValue"])

const computedLabel = computed(() => props.field.label ?? props.label)

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const localValue = shallowRef(null)
const computeFieldValue = () => {
	if (props.field.name) {
		let retValue = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
		if (props.field.stringify) {
			try {
				return JSON.stringify(retValue, null, 4)
			} catch (err) {
				return retValue
			}
		} else {
			return retValue
		}
	} else {
		return props.field.content
	}
}
const onInput = (value) => {
	if (props.dataStore) {
		props.dataStore.setModelValue(props.field, value, true)
	}
	localValue.value = value
	if (fieldValue.value !== undefined) {
		fieldValue.value = localValue.value
	}
	emit("update:modelValue", value)
}
onMounted(() => {
	if (props.dataStore) {
		localValue.value = computeFieldValue()
	}
})
</script>
<style scoped>
.btn.btn-outline-primary {
	margin-top: auto;
	pointer-events: none;
}
</style>
