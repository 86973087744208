<template>
	<div class="radio-group">
		<label class="radio-group-label">{{ label }}</label>
		<div
			v-for="option in options"
			:key="option.option"
			:class="`mb-1 radio-group-option ${option.icon ? 'radio-icon' : 'form-check'}`"
		>
			<label class="form-check-label" :for="option.option + '-radio'">
				<input
					:id="option.option + '-radio'"
					v-model="fieldValue"
					class="form-check-input"
					type="radio"
					:name="name"
					:value="option.option"
					@input="onInput($event)"
				/>
				<IconBadge
					v-if="option.icon"
					:icon="option.icon"
					:class="`border border-dark rounded p-2 ${fieldValue === option.option ? 'text-white bg-primary' : 'text-primary '}`"
				/>
				<span v-else>
					{{ option.title }}
				</span>
			</label>
		</div>

		<div v-if="errorMessages.length > 0">{{ errorMessages }}</div>
	</div>
</template>

<script setup>
import { ref, watch } from "vue"
import { MDBRadio } from "mdb-vue-ui-kit" // Assuming you're importing the MDBRadio component
import IconBadge from "@/Components/IconBadge.vue"

const emit = defineEmits(["update:modelValue"])
// Props
const props = defineProps({
	modelValue: {
		type: [String, Number],
		required: true,
	},
	label: {
		type: String,
		required: true,
	},
	options: {
		type: Array,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	initialValue: {
		type: [String, Number],
		default: null,
	},
	errorMessages: {
		type: String,
		default: "",
	},
})

const fieldValue = ref(props.modelValue)

/**
 * watches for changes to the modelValue and updates fieldValue
 */
watch(
	() => props.modelValue,
	(newValue) => {
		fieldValue.value = newValue
	},
	{ immediate: true },
)

const onInput = (event) => {
	emit("update:modelValue", event.target.value)
}
</script>

<style scoped>
.radio-group-label {
	display: block;
}
.radio-icon {
	display: inline-block;
}
.radio-icon input {
	display: none;
}
.radio-icon svg {
	margin: 0 !important;
	width: 32px;
	height: 32px;
}
</style>
