/**
 * Fetches all the URL parameters and returns them as an object.
 *
 * @returns {object} An object representing URL parameters as key-value pairs.
 */
export default function getUrlParams() {
	const params = {}
	const url = new URL(window.location.href)
	const existingParams = new URLSearchParams(url.search)
	for (const [key, value] of existingParams.entries()) {
		params[key] = value
	}
	return params
}

/**
 * Merges the provided parameters with the existing URL parameters.
 * If a parameter already exists, its value will be replaced by the new value.
 *
 * @param {object} params - An object representing the parameters to be merged.
 * @returns {object} An object representing the merged URL parameters.
 */
export const mergeUrlParams = (params) => {
	const queryParams = Object.assign({}, getUrlParams(), params)
	return queryParams
}

/**
 * Retrieves the value of a specific parameter from the URL query string.
 *
 * @param {string} paramName - The name of the parameter to retrieve.
 * @returns {string|null} - The value of the parameter or null if the parameter does not exist.
 */
export const getParam = (paramName) => {
	const params = getUrlParams()
	return Object.prototype.hasOwnProperty.call(params, paramName)
		? params[paramName]
		: null
}
