import { usePage } from "@inertiajs/vue3"
import { onMounted, ref, computed, watch } from "vue"
import { router } from "@inertiajs/vue3"
import CreateDialog from "@/Components/Mod/CreateDialog.vue"
import { useDataTableStore } from "@/Store/dataTableStore"
import { useToast } from "@/Composables/useToast"

/**
 * This composable is used to show the create modal if the create param is set in the url.
 * It defines both the modal state as well as the helper function to show the modal.
 * See AdminTable.vue for an example of how to use this.
 * @param {*} props
 * @returns
 */
export const useDataTablePagination = ({
	props,
	makeInternalRequest,
	fetchData,
	paginatedResponseComputed,
	tableStyle,
}) => {
	const toast = useToast()
	const dataTableStore = useDataTableStore()
	const navigationInProgress = ref(false)

	const navigatePage = (page, perPage) => {
		if (props.listData) return null
		if (makeInternalRequest.value) {
			fetchData()
			return
		}
		navigationInProgress.value = true
		let url = props.paginatedItems.links.find((link) => link.label == page).url

		if (perPage) {
			url = url + "&per_page=" + perPage
		}
		if (window.location.hash.substr(1)) {
			url = url + "#" + window.location.hash.substr(1)
		}

		// if perPage, add perPage to the url

		try {
			router.visit(url, {
				preserveState: true,
				preserveScroll: true,
				onSuccess: () => {
					navigationInProgress.value = false
				},
				onError: (errors) => {
					toast.error(Object.values(errors).join("\n"))

					navigationInProgress.value = false
				},
			})
		} catch (err) {
			navigationInProgress.value = false
			toast.error(err.message)
		}
	}

	/**
	 * Pagination
	 */
	const page = ref(props.paginatedItems?.current_page || 1)

	const pageCount = computed(() => {
		// when provided all data in listData mode, we need to calculate the page count
		if (props.listData) {
			return Math.ceil(props.listData.length / itemsPerPage.value)
		}

		return paginatedResponseComputed.value?.last_page || 1
	})
	const perPageOptions = computed(() => {
		if (tableStyle.value === "card_view")
			return [
				{ text: "12", value: 12 },
				{ text: "24", value: 24 },
				{ text: "48", value: 48 },
				{ text: "96", value: 96 },
			]
		else
			return [
				{ text: "10", value: 10 },
				{ text: "25", value: 25 },
				{ text: "50", value: 50 },
				{ text: "100", value: 100 },
			]
	})
	const itemsPerPage = ref(
		paginatedResponseComputed.value?.per_page ||
			props.options?.defaultItemsPerPage ||
			perPageOptions.value[0].value,
	)

	return {
		page,
		pageCount,
		itemsPerPage,
		perPageOptions,
		navigatePage,
		navigationInProgress,
	}
}
