<template>
	<h6 v-if="props.field.label || props.label">
		{{ props.field.label || props.label }}
	</h6>
	<p
		v-if="props.field.instructions || props.instructions"
		v-html="props.field.instructions || props.instructions"
	></p>
	<vue-monaco-editor
		v-model:value="computedValue"
		class="code-editor"
		theme="vs-dark"
		:language="props.field.language || ''"
		:options="MONACO_EDITOR_OPTIONS"
		@change="onInput"
		@mount="handleMount"
	/>
</template>

<script setup>
import {
	computed,
	defineModel,
	defineEmits,
	onMounted,
	shallowRef,
	watch,
} from "vue"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	default: {
		type: [String, Array, Object],
		default: "",
	},
	autoFormat: {
		type: Boolean,
		default: false,
	},
	language: {
		type: String,
		default: "json",
	},
	label: {
		type: String,
		default: "",
	},
	instructions: {
		type: String,
		default: null,
	},
	height: {
		type: String,
		default: "75vh",
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	autoLayout: {
		type: Boolean,
		default: true,
	},
})

const editorRef = shallowRef(null)

const computedHeight = computed(
	() => props.field.height || props.height || "60vh",
)

const MONACO_EDITOR_OPTIONS = computed(() => {
	return {
		automaticLayout:
			props.field.autoLayout !== undefined
				? props.field.autoLayout
				: props.autoLayout,
		formatOnType:
			props.field.autoFormat !== undefined
				? props.field.autoFormat
				: props.autoFormat,
		formatOnPaste:
			props.field.autoFormat !== undefined
				? props.field.autoFormat
				: props.autoFormat,
		readOnly:
			props.field.readonly !== undefined
				? props.field.readonly
				: props.readonly || false,
	}
})
const handleMount = (editor) => {
	editorRef.value = editor
}

const fieldValue = defineModel({
	type: [String, Object, Array],
	default: undefined,
})
const localValue = shallowRef(null)
const computedValue = computed(() => {
	if (props.field.stringify) {
		try {
			return JSON.stringify(localValue.value, null, 4)
		} catch (err) {
			return localValue.value
		}
	} else {
		return localValue.value
	}
})
const computeFieldValue = () => {
	let retVal
	if (props.field.name) {
		retVal = props.dataStore.getModelValue({
			column: props.field.column || null,
			name: props.field.name || null,
		})
	} else {
		retVal = props.field.content
	}
	localValue.value = retVal || props.field.default || props.default
}
if (props.dataStore) {
	watch(
		() =>
			props.dataStore.getModelValue({
				column: props.field.column || null,
				name: props.field.name,
			}),
		(newValue) => {
			localValue.value = newValue
		},
	)
}
const onInput = (value) => {
	let parsedValue = null
	if (props.field.stringify) {
		try {
			parsedValue = JSON.parse(value)
			setDataModel(props.field, parsedValue)
			fieldValue.value = parsedValue.value
		} catch (e) {
			let invalid = true
		}
	} else {
		setDataModel(props.field, value)
		fieldValue.value = value
	}
}
onMounted(() => {
	if (props.dataStore) {
		computeFieldValue()
	} else {
		localValue.value = fieldValue.value
	}
})
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (props.dataStore) {
		if (setModelTimeout) {
			clearTimeout(setModelTimeout)
		}
		setModelTimeout = setTimeout(() => {
			props.dataStore.setModelValue(field, value, true)
		}, 250)
	}
}
</script>

<style scoped>
.code-editor {
	height: v-bind(computedHeight) !important;
}
@media (max-width: 767px) or (max-height: 965px) {
	.code-editor {
		max-height: 50vh;
	}
}
</style>
