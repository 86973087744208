/**
 * converts a number into US dollars
 */
export function formatDollar(num, int = false) {
	if (num === null || num === undefined) {
		return int ? "$0" : "$0.00"
	}
	// if num is a string, make it a number
	if (typeof num === "string") {
		num = Number(num)
	}
	return `$${num.toFixed(int ? 0 : 2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
}
