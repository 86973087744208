<template>
	<div :style="outerDivStyle">
		<div
			class="sprite-display"
			:class="{ 'sprite-always-on': spriteAlwaysOn }"
			:style="spritStyle"
		></div>
	</div>
</template>
ç
<script setup>
import { computed, defineModel, onMounted, shallowRef, ref, watch } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { Link } from "@inertiajs/vue3"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	base64: {
		type: Boolean,
		default: false,
	},
	fill: {
		type: String,
		default: "width",
	},
	height: {
		type: [String, Number],
		default: 270,
	},
	width: {
		type: [String, Number],
		default: 480,
	},
	classList: {
		type: Array,
		default: () => ["mw-100"],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	spriteColumns: {
		type: Number,
		default: 25,
	},
	spriteRows: {
		type: Number,
		default: 1,
	},
	spriteSpeed: {
		type: Number,
		default: 1,
	},
	aspectRatio: {
		type: Number,
		default: 1,
	},
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const outerDivStyle = ref({})
const spriteHeight = props.field.height || props.height || 270
const spriteWidth = props.field.width || props.width || 480
const spriteAlwaysOn = ref(props.field.spriteAlwaysOn || false)
const spritStyle = ref({})
const computeFieldValue = computed(() => {
	let urlContent = fieldValue.value || props.field.content || props.content
	let getVal =
		props.dataStore &&
		props.dataStore.getModelValue({
			column: "dashboard_assets",
			name: urlContent,
			disableDotNotation: true,
		})
	if (getVal) {
		return getVal
	} else {
		if (props.field.name && props.dataStore) {
			let retValue = props.dataStore.getModelValue({
				column: props.field.column || null,
				name: props.field.name || null,
				disableDotNotation: true,
			})
			if (props.base64) {
				return "data:" + retValue.mime + ";base64," + retValue.base64
			} else {
				return retValue
			}
		} else {
			return urlContent
		}
	}
})

onMounted(() => {
	// Set CSS variables for sprite columns and rows
	document.documentElement.style.setProperty(
		"--sprite-columns",
		(props.spriteColumns - 1).toString(),
	)
	document.documentElement.style.setProperty(
		"--sprite-rows",
		props.spriteRows.toString(),
	)
	if (props.fill == "height") {
		outerDivStyle.value = {
			height: "100%",
			maxHeight: "100%",
			width: "auto",
			display: "flex",
			aspectRatio: props.aspectRatio,
		}
	} else if (props.fill == "width") {
		outerDivStyle.value = {
			width: "100%",
			maxWidth: "100%",
			height: "auto",
			display: "flex",
			aspectRatio: props.aspectRatio,
		}
	} else {
		outerDivStyle.value = {
			width: "100%",
			maxWidth: "100%",
			height: "100%",
			maxHeight: "100%",
			display: "flex",
		}
	}
	let img = new Image()
	img.src = computeFieldValue.value
	img.onload = () => {
		let localSpriteWidth = img.width / props.spriteColumns
		let localSpriteHeight = img.height / props.spriteRows
		let aspectRatio = localSpriteWidth / localSpriteHeight
		let localSpriteWidthPercent =
			(aspectRatio * 100) / props.spriteColumns + "%"
		let localSpriteHeightPercent =
			((1 / aspectRatio) * 100) / props.spriteRows + "%"
		// If the sprite is too tall, scale it down to the height
		if (localSpriteHeight > props.height) {
			localSpriteHeight = props.height
			localSpriteWidth = localSpriteHeight * aspectRatio
			localSpriteWidthPercent = (aspectRatio * 100) / props.spriteColumns + "%"
		}
		// If the sprite is too wide, scale it down to the width
		if (localSpriteWidth > props.width) {
			localSpriteWidth = props.width
			localSpriteHeight = localSpriteWidth / aspectRatio
			localSpriteHeightPercent =
				((1 / aspectRatio) * 100) / props.spriteRows + "%"
		}

		spritStyle.value = {
			width:
				props.fill == "width"
					? "auto"
					: props.fill == "height"
						? localSpriteWidthPercent
						: localSpriteWidth + "px",
			height:
				props.fill == "height"
					? "auto"
					: props.fill == "width"
						? localSpriteHeightPercent
						: localSpriteHeight + "px",
			backgroundImage: "url(" + computeFieldValue.value + ")",
			backgroundSize: "auto 100%",
			aspectRatio: aspectRatio,
			maxWidth: "100%",
			maxHeight: "100%",
			animationDuration: `${props.spriteSpeed}s, ${props.spriteSpeed * props.spriteColumns}s`,
		}
	}

	// spritStyle.value = {
	// 	width: props.fill == "width" ? "100%" : spriteWidth,
	// 	height: spriteHeight,
	// 	backgroundImage: "url(" + computeFieldValue.value + ")",
	// 	backgroundSize: "auto 100%",
	// 	animationDuration: `${props.spriteSpeed}s, ${props.spriteSpeed * props.spriteColumns}s`,
	// }
})

watch(
	[() => props.spriteColumns, () => props.spriteRows],
	([newColumns, newRows]) => {
		// Update CSS variables when spriteColumns or spriteRows change
		document.documentElement.style.setProperty(
			"--sprite-columns",
			(newColumns - 1).toString(),
		)
		document.documentElement.style.setProperty(
			"--sprite-rows",
			newRows.toString(),
		)
	},
)
// watch(computeFieldValue, (newVal) => {
// 	let img = new Image()
// 	img.src = newVal
// 	img.onload = () => {
// 		console.log(img.width, img.height)
// 		spriteWidth.value = img.width
// 		spriteHeight.value = img.height
// 	}
// })
</script>
<style>
.sprite-display {
	display: block;
	margin: auto;
}
.sprite-display:hover {
	animation-name: playh, playv;
	animation-iteration-count: infinite;
	animation-timing-function: steps(var(--sprite-columns)),
		steps(var(--sprite-rows));
}
.sprite-always-on {
	animation-name: playh, playv;
	animation-iteration-count: infinite;
	animation-timing-function: steps(var(--sprite-columns)),
		steps(var(--sprite-rows));
}
@keyframes playv {
	0% {
		background-position-y: 0px;
	}
	100% {
		background-position-y: 100%;
	}
}

@keyframes playh {
	0% {
		background-position-x: 0px;
	}
	100% {
		background-position-x: 100%;
	}
}
</style>
