<template>
	<component :is="htmlContent" :class="props.classList" />
</template>
<script setup>
import {
	reactive,
	computed,
	watch,
	defineEmits,
	defineProps,
	ref,
	onMounted,
	nextTick,
	h,
} from "vue"
import { DateTime } from "luxon"

// import { MDBColorPicker } from "mdb-vue-color-picker"
import { useDataModelStore } from "@/Store/dataModelStore"
import { Link } from "@inertiajs/vue3"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import FormField from "@/Components/Mod/FormField.vue"

import { useProjectStore } from "@/Store/projectStore"
import { TemplateParsingService } from "@/Services/templateParsingService"
const projectStore = useProjectStore()

// const pageStore = useDataModelStore()

const allowedTags = [
	"div",
	"span",
	"img",
	"svg",
	"ul",
	"ol",
	"li",
	"link",
	"a",
	"h1",
	"h2",
	"h3",
	"h4",
	"h5",
	"h6",
	"p",
	"strong",
	"em",
	"b",
	"i",
	"u",
	"strike",
	"small",
	"sub",
	"sup",
	"pre",
	"code",
	"br",
	"hr",
	"blockquote",
]
const allowedClasses = [
	"px-0",
	"px-1",
	"px-2",
	"px-3",
	"px-4",
	"px-5",
	"px-auto",
	"py-0",
	"py-1",
	"py-2",
	"py-3",
	"py-4",
	"py-5",
	"py-auto",
	"pt-0",
	"pe-0",
	"pb-0",
	"ps-0",
	"pt-1",
	"pe-1",
	"pb-1",
	"ps-1",
	"pt-2",
	"pe-2",
	"pb-2",
	"ps-2",
	"pt-3",
	"pe-3",
	"pb-3",
	"ps-3",
	"pt-4",
	"pe-4",
	"pb-4",
	"ps-4",
	"pt-5",
	"pe-5",
	"pb-5",
	"ps-5",
	"p-0",
	"p-1",
	"p-2",
	"p-3",
	"p-4",
	"p-5",
	"p-auto",
	"m-0",
	"m-1",
	"m-2",
	"m-3",
	"m-4",
	"m-5",
	"m-auto",
	"mt-0",
	"me-0",
	"mb-0",
	"ms-0",
	"mt-1",
	"me-1",
	"mb-1",
	"ms-1",
	"mt-2",
	"me-2",
	"mb-2",
	"ms-2",
	"mt-3",
	"me-3",
	"mb-3",
	"ms-3",
	"mt-4",
	"me-4",
	"mb-4",
	"ms-4",
	"mt-5",
	"me-5",
	"mb-5",
	"ms-5",
	"rounded-pill",
	"rounded",
	"rounded-top",
	"rounded-end",
	"rounded-bottom",
	"rounded-start",
	"rounded-0",
	"rounded-1",
	"rounded-2",
	"rounded-3",
	"rounded-circle",
	"rounded-4",
	"rounded-5",
	"text-start",
	"text-end",
	"text-center",
	"btn",
	"btn-primary",
	"btn-secondary",
	"btn-success",
	"btn-danger",
	"btn-warning",
	"btn-info",
	"btn-light",
	"btn-dark",
	"btn-link",
	"btn-sm",
	"btn-lg",
	"btn-block",
	"btn-outline-primary",
	"btn-outline-secondary",
	"btn-outline-success",
	"btn-outline-danger",
	"btn-outline-warning",
	"btn-outline-info",
	"btn-outline-light",
	"btn-outline-dark",
	"btn-outline-link",
	"btn-outline-sm",
	"btn-outline-lg",
	"btn-outline-block",
	"btn-group",
	"btn-group-vertical",
	"btn-toolbar",
	"container",
	"col",
	"col-1",
	"col-2",
	"col-3",
	"col-4",
	"col-5",
	"col-6",
	"col-7",
	"col-8",
	"col-9",
	"col-10",
	"col-11",
	"col-12",
	"col-sm",
	"col-md",
	"col-lg",
	"col-xl",
	"col-xxl",
	"col-auto",
	"col-sm-auto",
	"col-md-auto",
	"col-lg-auto",
	"col-xl-auto",
	"col-xxl-auto",
	"col-sm-1",
	"col-sm-2",
	"col-sm-3",
	"col-sm-4",
	"col-sm-5",
	"col-sm-6",
	"col-sm-7",
	"col-sm-8",
	"col-sm-9",
	"col-sm-10",
	"col-sm-11",
	"col-sm-12",
	"col-md-1",
	"col-md-2",
	"col-md-3",
	"col-md-4",
	"col-md-5",
	"col-md-6",
	"col-md-7",
	"col-md-8",
	"col-md-9",
	"col-md-10",
	"col-md-11",
	"col-md-12",
	"col-lg-1",
	"col-lg-2",
	"col-lg-3",
	"col-lg-4",
	"col-lg-5",
	"col-lg-6",
	"col-lg-7",
	"col-lg-8",
	"col-lg-9",
	"col-lg-10",
	"col-lg-11",
	"col-lg-12",
	"col-xl-1",
	"col-xl-2",
	"col-xl-3",
	"col-xl-4",
	"col-xl-5",
	"col-xl-6",
	"col-xl-7",
	"col-xl-8",
	"col-xl-9",
	"col-xl-10",
	"col-xl-11",
	"col-xl-12",
	"col-xxl-1",
	"col-xxl-2",
	"col-xxl-3",
	"col-xxl-4",
	"col-xxl-5",
	"col-xxl-6",
	"col-xxl-7",
	"col-xxl-8",
	"col-xxl-9",
	"col-xxl-10",
	"col-xxl-11",
	"col-xxl-12",
	"col-1",
	"col-2",
	"col-3",
	"col-4",
	"col-5",
	"col-6",
	"col-7",
	"col-8",
	"col-9",
	"col-10",
	"col-11",
	"col-12",
	"row",
	"h1",
	"h2",
	"h3",
	"h4",
	"h5",
	"h6",
	"card",
	"card-body",
	"border",
	"border-0",
	"border-1",
	"shadow-0",
	"shadow-1",
	"d-flex",
	"flex-column",
	"h-100",
	"smaller",
	"small",
	"larger",
	"fw-bold",
	"hover-border-primary",
]

const templateParser = new TemplateParsingService()

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	formModel: {
		type: Object,
		default: () => ({}),
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
})
const htmlContent = computed(() => {
	let content = []
	for (const item of props.field.content) {
		// if type in [div, span, image, svg]
		// if style is set
		// if content is set
		let sanitizedNode = sanitizeNode(item)
		content.push(sanitizedNode)
	}
	return h("div", {}, content)
})

const sanitizeNode = (node, localModel = null) => {
	// if type in [div, span, image, svg]
	// if style is set
	// if content is set
	//check if childnode has forEach first, may need to loop here
	// set node as variable, remove foreach, render foreach here and call sanitizeNode within loop so that each item can be rendered/sanitized
	if (node.forEach) {
		let loopContent = []
		//get foreach object
		if (node.forEach.type == "calculate") {
			let calcValue = 1
			let equation = "add"

			//remove foreach from loopedNode
			if (node.forEach.multiply && node.forEach.multiply == true) {
				equation = "multiply"
			}
			if (node.forEach.fields) {
				node.forEach.fields.forEach((field) => {
					let value = 0
					if (field instanceof Object) {
						value = getDataModel(field.column, field.name, field.options)
					} else {
						value = field
					}
					if (equation == "add") {
						calcValue += parseInt(value)
					} else {
						calcValue = calcValue * parseInt(value)
					}
				})
			}
			if (node.forEach.subtract) {
				calcValue -= parseInt(node.forEach.subtract)
			}
			let loopedNode = Object.assign({}, node)
			delete loopedNode.forEach
			for (let i = 0; i < calcValue; i++) {
				let sanitizedNode = sanitizeNode(loopedNode, { index: i })
				loopContent.push(sanitizedNode)
			}
		} else {
			let forEachObject = []
			if (node.forEach instanceof Object) {
				let name = node.forEach.name
				if (node.forEach.type == "local-model") {
					if (localModel && localModel[name]) {
						forEachObject = localModel[name]
					}
				} else if (node.forEach.repeatableField) {
					// I want to reference at least one field for each item in a repatable fields list
					// Retrieve the order that the values should be in
					forEachObject = getDataModel(
						node.forEach.column,
						node.forEach.name + "_order",
						node.forEach.options,
					)
					let fields = {}
					// Retrieves one data model per column in optionConfig
					// All custom_settings fields for a repeatable field will be included if custom_settings is a provided column
					for (const column of Object.keys(node.forEach.optionConfig)) {
						fields[column] = getDataModel(column, node.forEach.name)
					}
					let array = []
					// Using the current order of keys...
					for (const [key, order] of Object.entries(forEachObject)) {
						// Create an ordered array of objects with each name in the config
						array[order] = {}
						for (const [column, names] of Object.entries(
							node.forEach.optionConfig,
						)) {
							names.forEach((name) => {
								if (!fields[column][key]) return
								array[order][name] = fields[column][key][name]
							})
						}
					}
					forEachObject = array
				} else {
					forEachObject = getDataModel(
						node.forEach.column,
						node.forEach.name,
						node.forEach.options,
					)
				}
			}
			let loopedNode = Object.assign({}, node)
			//remove foreach from loopedNode
			delete loopedNode.forEach
			//else if foreach is an int, loop that many times
			if (forEachObject && Array.isArray(forEachObject)) {
				forEachObject.forEach((item, i) => {
					item["loopIndex"] = i
					let sanitizedNode = sanitizeNode(loopedNode, item)
					loopContent.push(sanitizedNode)
				})
			}
		}
		return loopContent
	} else {
		let canRender = true
		if (node.conditionParams) {
			if (node.localCondition) {
				canRender = conditionParamCheck(node.conditionParams, localModel)
			} else {
				canRender = conditionParamCheck(node.conditionParams)
			}
		}
		if (canRender && allowedTags.includes(node.type)) {
			let options = {}
			let nodeType = node.type || "span"
			if (node.classList) {
				const classList = Array.isArray(node.classList)
					? node.classList
					: typeof node.classList === "string"
						? node.classList.split(" ")
						: null
				options.class = []
				classList.forEach((item) => {
					if (!item) return
					if (typeof item === "string") options.class.push(item)
					else if (typeof item === "object") {
						options.class.push(
							getDataModel(item.column, item.name, item.options),
						)
					}
				})
			}
			if (node.style) {
				// sanitize style
				options.style = sanitizeStyle(node.style, localModel)
				// style = sanitizeStyle(node.style)
			}
			if ((node.type == "a" || node.type == "link") && node.url) {
				let url = ""
				if (node.url instanceof Object) {
					if (node.url.type === "local-model") {
						// used in loop
						if (localModel && localModel[node.url.name]) {
							url = localModel[node.url.name]
						} else {
							url = ""
						}
					} else {
						url = getDataModel(node.url.column, node.url.name, node.url.options)
					}
				} else {
					url = node.url
				}
				if (node.internal) {
					nodeType = Link
					options.href = "/projects/" + projectStore.getActiveProject.slug + url
				} else {
					options.href = url
					options.target = node.target || "_self"
				}
			}
			let content = null
			if (node.type == "img") {
				let url = ""
				if (node.url instanceof Object) {
					if (node.url.type === "local-model") {
						// used in loop
						if (localModel && localModel[node.url.name]) {
							url = localModel[node.url.name]
						} else {
							url = ""
						}
					} else {
						url = getDataModel(
							node.url.column,
							node.url.name,
							node.url.options,
							true,
						)
					}
				} else {
					url = node.url
				}
				options.src = url
				return h(node.type, options)
			}
			if (node.type == "i") {
				let iconStyle = node.iconStyle || "fas"
				let icon = node.icon || "arrow-right"
				options.icon = lookupIcon(icon, iconStyle)
				options.size = node.size || "sm"
				return h(FontAwesomeIcon, options)
			}
			if (node.content) {
				// sanitize content
				if (node.content instanceof Array) {
					content = node.content.map((childNode) => {
						let options = {}
						let childNodeType = childNode.type || "span"
						if (typeof childNode === "string") {
							options.textContent = sanitizeString(childNode)
							return h(childNodeType, options)
						} else {
							let sanitizedChild = sanitizeNode(childNode, localModel)
							if (typeof sanitizedChild === "string") {
								if (
									childNodeType === "model" ||
									childNodeType === "local-model"
								)
									return sanitizedChild // Return as a string so that any styling on the parent element gets applied directly
								if (childNode.renderHtml) {
									options.innerHTML = sanitizedChild
								} else {
									options.textContent = sanitizedChild
								}
								return h(childNodeType, options)
							} else {
								return sanitizedChild
							}
						}
					})
					return h(nodeType, options, content)
				} else if (typeof node.content === "string") {
					options.innerHTML = sanitizeString(node.content)
					return h(nodeType, options)
				}
			}
		} else if (node.type === "model") {
			return getDataModel(node.column, node.name, node.options)
		} else if (node.type === "local-model") {
			if (localModel && localModel[node.name]) {
				if (node?.options?.timestamp_format) {
					let timestamp = localModel[node.name]
					try {
						const dateTime = DateTime.fromISO(timestamp, {
							zone: "utc",
						}).setZone(DateTime.local().zoneName)
						if (
							typeof DateTime[node.options.timestamp_format] !== "undefined"
						) {
							return dateTime.toLocaleString(
								DateTime[node.options.timestamp_format],
							)
						} else {
							return dateTime.toFormat(node.options.timestamp_format)
						}
					} catch (e) {
						return timestamp
					}
				}
				return localModel[node.name]
			} else {
				return ""
			}
		} else if (node.type === "form-field") {
			return h(FormField, {
				field: node.field,
				dataStore: props.dataStore,
			})
		}
	}
}

// sanitize style
const sanitizeStyle = (style, localModel) => {
	let sanitizedStyle = {}
	for (const [key, value] of Object.entries(style)) {
		let valueDefinition = value
		if (Array.isArray(value)) {
			let valueLoop = valueDefinition
			valueLoop.some((styleDefinition) => {
				valueDefinition = styleDefinition
				if (
					styleDefinition.conditionParams &&
					conditionParamCheck(styleDefinition.conditionParams)
				) {
					valueDefinition.conditionChecked = true
					return true
				}
				valueDefinition.conditionChecked = false
				return false
			})
		}
		if (typeof valueDefinition === "object") {
			let canStyle = true
			if (
				valueDefinition.conditionParams &&
				valueDefinition.conditionChecked !== true
			) {
				canStyle = conditionParamCheck(valueDefinition.conditionParams)
			}
			if (canStyle) {
				if (valueDefinition.type == "template") {
					const dm = props.dataStore.dataModel
					let processed = templateParser.processTemplate(
						valueDefinition.value,
						dm,
					)
					processed = templateParser.cleanWhitespace(processed)
					sanitizedStyle[key] = processed
				} else if (valueDefinition.value) {
					sanitizedStyle[key] = valueDefinition.value
				} else if (valueDefinition.type === "local-model") {
					let modelValue = ""
					if (localModel && localModel[valueDefinition.name]) {
						modelValue = localModel[valueDefinition.name]
					}
					if (
						(modelValue === null || modelValue === "") &&
						valueDefinition.options?.defaultValue
					) {
						modelValue = valueDefinition.options?.defaultValue
					}
					if (valueDefinition.options && valueDefinition.options.prepend) {
						modelValue = valueDefinition.options.prepend + modelValue.toString()
					}
					if (valueDefinition.options && valueDefinition.options.append) {
						modelValue = modelValue.toString() + valueDefinition.options.append
					}
					sanitizedStyle[key] = modelValue
				} else {
					sanitizedStyle[key] = getDataModel(
						valueDefinition.column,
						valueDefinition.name,
						valueDefinition.options,
						true,
					)
				}
			}
		} else if (typeof value === "string") {
			sanitizedStyle[key] = sanitizeString(value)
		}
	}
	return sanitizedStyle
}

// sanitize string
const sanitizeString = (string) => {
	return string
}

const getDataModel = (column, name, options, disableDotNotation = false) => {
	let tileId = null
	if (props.formModel && props.formModel.tileId) {
		// we're rendering a grid tile, need to look at grid model
		tileId = props.formModel.tileId
	}
	if (props.field.tileId) {
		tileId = props.field.tileId
	}
	let modelValue =
		props.dataStore.getModelValue({
			column: column,
			tileId: tileId,
			name: name,
			options: options,
			disableDotNotation: disableDotNotation,
		}) || ""
	if ((modelValue === null || modelValue === "") && options?.defaultValue) {
		modelValue = options?.defaultValue
	}
	if (options && options.prepend) {
		modelValue = options.prepend + modelValue.toString()
	}
	if (options && options.append) {
		modelValue = modelValue.toString() + options.append
	}
	return modelValue
}
const conditionParamCheck = (conditionParams, localModel = false) => {
	let conditionMet = false
	// {
	// 	"name": "display_include_prompt",
	// 	"logic": "==",
	// 	"value": "yes",
	// 	"column": "custom_settings"
	// }
	conditionParams.every((element) => {
		let compareValue
		if (localModel) {
			let name = element.name
			if (element.column) {
				name = element.column + "." + name
			}
			compareValue = props.dataStore.getNestedValue(localModel, name)
		} else {
			compareValue = getDataModel(element.column, element.name, {})
		}

		let comparisonValue
		if (element.value && typeof element.value === "object") {
			if (localModel) {
				let comparisonName = element.value.name
				if (element.value.column) {
					comparisonName = element.value.column + "." + element.value.name
				}
				comparisonValue = props.dataStore.getNestedValue(
					localModel,
					comparisonName,
				)
			} else {
				comparisonValue = getDataModel(
					element.value.column,
					element.value.name,
					{},
				)
			}
		} else {
			comparisonValue = element.value ?? ""
		}

		switch (element.logic) {
			case "==":
				conditionMet = compareValue == comparisonValue
				break
			case "!=":
				conditionMet = compareValue != comparisonValue
				break
			case ">":
				conditionMet = compareValue > comparisonValue
				break
			case "<":
				conditionMet = compareValue < comparisonValue
				break
			case ">=":
				conditionMet = compareValue >= comparisonValue
				break
			case "<=":
				conditionMet = compareValue <= comparisonValue
				break
			case "g0length":
				conditionMet = compareValue.length > 0
				break
			case "n0length":
				conditionMet = compareValue.length == 0
				break
		}
		// array.every stops when false returned
		return conditionMet
	})
	return conditionMet
}
// {
// 	"type": "live-html",
// 	"content": [
// 		{
// 			"type": "div",
// 			"style": {
// 				"width": "400px",
// 				"height": "400px",
// 				"background-color": {
// 					"name": "holding_background_color",
// 					"column": "custom-settings"
// 				}
// 			},
// 			"content": [
// 				{
// 					"name": "start_line_two",
// 					"type": "model",
// 					"column": "language_overrides"
// 				},
// 				"this will be in its own span"
// 			]
// 		}
// 	]
// },
</script>
