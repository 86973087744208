<template>
	<div class="row">
		<div class="col-6 text-start">
			<SwitchField v-model="previewMode" label="Preview Mode" />
		</div>
		<div class="col-6 text-end">
			<button
				v-if="!previewMode"
				type="button"
				class="btn btn-success btn-sm btn-rounded mb-2"
				@click.prevent="updateForm"
			>
				Update Model with Form
			</button>
		</div>
	</div>
	<div v-if="!previewMode" class="card mb-2">
		<div class="card-header">
			<h5 class="card-title">Tab Settings</h5>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-6 col-md-4 text-end">
					<MDBSelectZ
						ref="selectCurrent"
						v-model:selected="currentTab"
						v-model:options="currentTabOptions"
						label="Current Tab"
						:preselect="false"
						:required="true"
						class="form-floating"
						@close="onSelect"
					>
					</MDBSelectZ>
					<!-- <button type="button" class="btn btn-primary btn-sm btn-rounded">Move Tab Up</button>
					<button type="button" class="btn btn-primary btn-sm btn-rounded">Move Tab Down</button> -->
					<button
						type="button"
						class="btn btn-primary btn-sm btn-rounded mt-2"
						@click.prevent="addNewTab"
					>
						New Tab
					</button>
				</div>
				<div v-if="currentTab !== 'settings'" class="col-6 col-md-4">
					<MDBInput
						v-model="currentTabFields.title"
						label="Tab Title"
						:required="true"
						type="text"
						:readonly="currentTab === 'settings'"
						autocomplete="off"
						:maxlength="75"
						class="mb-2"
						:form-outline="false"
						wrapper-class="form-floating"
					/>
				</div>
				<div class="col-6 col-md-4 text-end">
					<div
						:class="{
							'input-group': true,
						}"
					>
						<MDBInput
							v-model="currentTabFields.icon"
							label="Tab Icon (Optional)"
							:required="true"
							type="text"
							autocomplete="off"
							:maxlength="75"
							:form-outline="false"
							wrapper-class="form-floating"
						/>
						<button type="button" class="btn btn-outline-primary">
							<FontAwesomeIcon
								v-if="currentTabFields.icon"
								size="2x"
								:icon="lookupIcon(currentTabFields.icon, 'far')"
							/>
						</button>
					</div>
					<!--
					<input type="text" placeholder="Previous Tab Text" />
					<input type="text" placeholder="Next Tab Text" />
					<input type="text" placeholder="Tab conditionParams" /> -->
					<button
						type="button"
						class="btn btn-primary btn-sm btn-rounded mt-2"
						@click.prevent="updateTab"
					>
						Update Tab
					</button>
				</div>
			</div>
		</div>
	</div>
	<div v-if="!previewMode" class="card mb-2">
		<div class="card-header">
			<h5 class="card-title">Card Settings</h5>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-6 col-md-4 text-end">
					<MDBSelectZ
						ref="selectCurrentCard"
						v-model:selected="currentCard"
						v-model:options="currentCardOptions"
						label="Card Details"
						:preselect="false"
						:required="true"
						class="form-floating"
						@close="onSelect"
					>
					</MDBSelectZ>
					<button
						type="button"
						class="btn btn-primary btn-sm btn-rounded mt-2"
						@click.prevent="addNewCard"
					>
						New Card
					</button>
				</div>
				<div class="col-6 col-md-8 text-end">
					<div class="row">
						<div class="col-12 col-md-6">
							<MDBSelectZ
								ref="selectCurrentCardType"
								v-model:selected="currentCardType"
								v-model:options="cardTypes"
								label="Card Type"
								:preselect="false"
								:required="true"
								class="form-floating mb-2"
								@close="onSelect"
							>
							</MDBSelectZ>
							<MDBInput
								v-model="currentCardFields.title"
								label="Card Title (Optional)"
								:required="true"
								type="text"
								autocomplete="off"
								:maxlength="75"
								:form-outline="false"
								wrapper-class="form-floating"
							/>
						</div>
						<div class="col-12 col-md-6">
							<MDBInput
								v-model="currentCardFields.cols"
								label="Cols"
								:required="true"
								type="text"
								autocomplete="off"
								:default="12"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.colsSm"
								label="Cols Sm"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.colsMd"
								label="Cols Md"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.colsLg"
								label="Cols Lg"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.colsXl"
								label="Cols Xl"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
						</div>
						<div class="col-12">
							<button
								type="button"
								class="btn btn-primary btn-sm btn-rounded"
								@click.prevent="updateCard"
							>
								Update Card
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="!previewMode" class="card mb-2">
		<div class="card-header">
			<h5 class="card-title">Current Card</h5>
		</div>
		<div
			v-if="
				formModel.additionalTabs &&
				formModel.additionalTabs[currentTab]?.cards &&
				formModel.additionalTabs[currentTab]?.cards[currentCard]?.type ==
					'fields_list'
			"
			class="card-body"
		>
			<div class="row">
				<div class="col-6 col-md-4 text-end">
					<MDBSelectZ
						ref="selectCurrentField"
						v-model:selected="currentField"
						v-model:options="currentFieldOptions"
						label="Current Field"
						:preselect="false"
						:required="true"
						class="form-floating"
						:option-height="60"
						@close="onSelect"
					>
					</MDBSelectZ>
					<button
						type="button"
						class="btn btn-primary btn-sm btn-rounded mt-2"
						@click.prevent="addNewField"
					>
						New Field
					</button>
				</div>
				<div class="col-6 col-md-8 text-end">
					<div class="row">
						<div class="col-12 col-md-6">
							<MDBSelectZ
								ref="selectCurrentFieldType"
								v-model:selected="currentFieldType"
								v-model:options="fieldTypes"
								label="Field Type"
								:preselect="false"
								:required="true"
								class="form-floating mb-2"
								:option-height="60"
								@close="onSelect"
							>
							</MDBSelectZ>
							<MDBInput
								v-model="currentFieldSettings.cols"
								label="Cols"
								:required="true"
								type="text"
								autocomplete="off"
								:default="12"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentFieldSettings.colsSm"
								label="Cols Sm"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentFieldSettings.colsMd"
								label="Cols Md"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentFieldSettings.colsLg"
								label="Cols Lg"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentFieldSettings.colsXl"
								label="Cols Xl"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.columnClass"
								label="Additional Classes on Column"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
							<MDBInput
								v-model="currentCardFields.classList"
								label="Additional Classes on Component"
								type="text"
								autocomplete="off"
								:maxlength="2"
								:form-outline="false"
								wrapper-class="form-floating mb-2"
							/>
						</div>
						<div class="col-12 col-md-6">
							<template v-if="fieldTypeSettings[currentFieldSettings.type]">
								<template
									v-for="(cardField, cardIndex) in fieldTypeSettings[
										currentFieldSettings.type
									]"
									:key="cardIndex"
								>
									<MDBInput
										v-if="cardField.type == 'text'"
										v-model="currentFieldSettings[cardField.name]"
										:label="cardField.label"
										:required="cardField.required"
										type="text"
										autocomplete="off"
										:maxlength="75"
										:form-outline="false"
										wrapper-class="form-floating mb-2"
									/>
									<MDBInput
										v-if="cardField.type == 'number'"
										v-model="currentFieldSettings[cardField.name]"
										:label="cardField.label"
										:required="cardField.required"
										type="number"
										autocomplete="off"
										:maxlength="75"
										:form-outline="false"
										wrapper-class="form-floating mb-2"
									/>
									<MDBTextarea
										v-if="cardField.type == 'textarea'"
										v-model="currentFieldSettings[cardField.name]"
										class="form-control-textarea"
										:label="cardField.label"
										type="textarea"
										:required="cardField.required"
										variant="solo-filled"
										:rows="cardField.rows || 8"
										:form-outline="false"
										wrapper-class="form-floating"
									/>
									<MDBSwitch
										v-if="cardField.type == 'boolean'"
										v-model="currentFieldSettings[cardField.name]"
										:label="cardField.label"
										:required="cardField.required"
										:false-value="false"
										:true-value="true"
										:wrapper-class="
											cardField.align == 'right' ? 'form-check-reverse' : ''
										"
										variant="solo-filled"
										color="primary"
									/>
									<template v-else-if="cardField.type == 'codeEditor'">
										<vue-monaco-editor
											v-model:value="currentFieldSettings[cardField.name]"
											theme="vs-dark"
											:language="cardField.language || ''"
											:height="cardField.height || '80vh'"
											:options="MONACO_EDITOR_OPTIONS"
										/>
									</template>
								</template>
							</template>
						</div>
						<div class="col-12">
							<button
								type="button"
								class="btn btn-primary btn-sm btn-rounded"
								@click.prevent="updateField"
							>
								Update Field
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<ul class="nav nav-pills">
				<li
					v-for="(tab, index) in currentTabOptions"
					:key="index"
					class="nav-item"
				>
					<a
						:class="{ active: tab.value == currentTab }"
						class="nav-link"
						aria-current="page"
						href="#"
						@click.prevent="setCurrentTab(index)"
						>{{ tab.text }}</a
					>
				</li>
				<li v-if="!previewMode" class="">
					<a
						class="nav-link btn btn-sm bg-primary text-white"
						href="#"
						@click.prevent="addNewTab"
						>New Tab</a
					>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<div
				v-if="
					currentTab !== 'settings' &&
					formModel.additionalTabs &&
					formModel.additionalTabs[currentTab]
				"
				class="row"
			>
				<div
					v-for="(card, index) in formModel.additionalTabs[currentTab].cards"
					:key="index"
					:class="getCardClasses(card, index)"
					@click="!previewMode && setCurrentCard(index)"
				>
					<h5 v-if="card.title">{{ card.title }}</h5>
					<small>{{ card.type }}</small>
					<template v-if="card.type == 'fields_list'">
						<template
							v-for="(fieldDisplay, fieldIndex) in card.fieldsList"
							:key="fieldIndex"
						>
							<div @click="setCurrentField(index, fieldIndex)">
								<FormField
									:type="fieldDisplay.type"
									:field="fieldDisplay"
									:preview-mode="true"
								/>
							</div>
						</template>
					</template>
				</div>
				<div v-if="!previewMode" class="col-12 text-end">
					<a
						class="btn btn-sm bg-secondary text-white"
						href="#"
						@click.prevent="addNewCard"
						>Add New Card</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	computed,
	defineProps,
	defineEmits,
	watch,
	onMounted,
	nextTick,
} from "vue"

import TypeDisplay from "@/Components/Mod/FormFields/TypeDisplay.vue"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import TextareaField from "./TextareaField.vue"
import SelectField from "@/Components/Mod/FormFields/SelectField.vue"
import SwitchField from "@/Components/Mod/FormFields/SwitchField.vue"
import ColorPickerField from "@/Components/Mod/FormFields/ColorPickerField.vue"
import ProjectAssetSelector from "@/Components/Selectors/ProjectAssetSelector.vue"
import FormField from "@/Components/Mod/FormField.vue"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"
import { MDBInput, MDBTextarea, MDBSwitch } from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { storeToRefs } from "pinia"
const MONACO_EDITOR_OPTIONS = {
	automaticLayout: true,
	formatOnType: true,
	formatOnPaste: true,
	readOnly: false,
}
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	label: {
		type: String,
		default: "",
	},
	default: {
		type: [String, Number, Array, Object],
		default: "",
	},
	classList: {
		type: Array,
		default: () => [],
	},
})
const { dataModel } = storeToRefs(props.dataStore)

const fieldValue = defineModel({
	type: [Array, Object],
	default: () => ({}),
})

const previewMode = ref(false)

const formModel = ref({})

const cardTypes = ref([
	{
		value: "data_table",
		text: "Data Table",
	},
	{
		value: "fields_list",
		text: "Fields List",
	},
	{
		value: "tabs_list",
		text: "Tabs List",
	},
	{
		value: "info_card",
		text: "Info Card",
	},
	{
		value: "render_list",
		text: "Render List",
	},
	{
		value: "card_list",
		text: "Card List",
	},
	{
		value: "carousel",
		text: "Carousel",
	},
	{
		value: "grid_view",
		text: "Grid View",
	},
	{
		value: "component",
		text: "Custom Component",
	},
	{
		value: "data_display",
		text: "Data Display",
	},
	{
		value: "button_group",
		text: "Button Group",
	},
])
const fieldTypeSettings = ref({
	heading: [
		{
			label: "Heading Text",
			name: "content",
			type: "text",
		},
	],
	"heading-large": [
		{
			label: "Heading Text",
			name: "content",
			type: "text",
		},
	],
	"small-heading": [
		{
			label: "Heading Text",
			name: "content",
			type: "text",
		},
	],
	description: [
		{
			label: "Description Text",
			name: "content",
			type: "textarea",
		},
	],
	blockquote: [
		{
			label: "Blockquote Text",
			name: "content",
			type: "textarea",
		},
	],
	text: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
		{
			label: "Max Length",
			name: "maxlength",
			type: "number",
		},
	],
	number: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
		{
			label: "Step",
			name: "step",
			type: "number",
		},
	],
	textarea: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Rows",
			name: "rows",
			type: "number",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
		{
			label: "Max Length",
			name: "maxlength",
			type: "number",
		},
	],
	select: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
	],
	colorPicker: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
	],
	boolean: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
		{
			label: "Read Only",
			name: "readonly",
			type: "boolean",
		},
		{
			label: "Default Value",
			name: "default",
			type: "text",
		},
	],
	selectAsset: [
		{
			label: "Field Label",
			name: "label",
			type: "text",
		},
		{
			label: "Field Name",
			name: "name",
			type: "text",
		},
		{
			label: "Field Column",
			name: "column",
			type: "text",
		},
		{
			label: "Required",
			name: "required",
			type: "boolean",
		},
	],
	"live-html": [
		{
			label: "Content",
			name: "content",
			type: "codeEditor",
			language: "json",
		},
	],
})
const fieldTypes = ref([
	{
		value: "text",
		text: "Text",
	},
	{
		value: "textarea",
		text: "Textarea",
	},
	{
		value: "number",
		text: "Number",
	},
	{
		value: "select",
		text: "Select",
	},
	{
		value: "multi_select",
		text: "Multi Select",
	},
	{
		value: "pill_select",
		text: "Pill Select",
	},
	{
		value: "checkbox",
		text: "Checkbox",
	},
	{
		value: "radio",
		text: "Radio",
	},
	{
		value: "date",
		text: "Date",
	},
	{
		value: "time",
		text: "Time",
	},
	{
		value: "datetime",
		text: "Datetime",
	},
	{
		value: "live-html",
		text: "Live HTML",
	},
	{
		value: "html",
		text: "Static HTML",
	},
	{
		value: "hidden",
		text: "Hidden",
	},
	{
		value: "banner",
		text: "Banner",
	},
	{
		value: "button",
		text: "Button",
	},
	{
		value: "external-link",
		text: "External Link",
	},
	{
		value: "heading",
		text: "Heading",
	},
	{
		value: "small-heading",
		text: "Small Heading",
	},
	{
		value: "heading-large",
		text: "Heading Large",
	},
	{
		value: "description",
		text: "Description",
	},
	{
		value: "blockquote",
		text: "Blockquote",
	},
	{
		value: "image",
		text: "Image Display",
	},
	{
		value: "video",
		text: "Video Player",
	},
	{
		value: "icon",
		text: "Icon",
	},
	{
		value: "icon-selector",
		text: "Icon Selector",
	},
	{
		value: "clipboard",
		text: "Clipboard",
	},
	{
		value: "timestamp",
		text: "Formatted Timestamp",
	},
	{
		value: "autocomplete",
		text: "Autocomplete",
	},
	{
		value: "boolean",
		text: "Boolean",
	},
	{
		value: "boolean_icon",
		text: "Boolean Icon",
	},
	{
		value: "file",
		text: "File",
	},
	{
		value: "selectAsset",
		text: "Asset Selector",
	},
	{
		value: "asyncSelect",
		text: "Experience Plugin Selector",
	},
	{
		value: "codeEditor",
		text: "Code Editor",
	},
	{
		value: "htmlEditor",
		text: "HTML Editor",
	},
	{
		value: "data_table",
		text: "Data Table",
	},
])
const currentCardType = ref(null)

const selectCurrent = ref(null)
const selectCurrentCard = ref(null)
const selectCurrentCardType = ref(null)
const selectCurrentField = ref(null)
const selectCurrentFieldType = ref(null)

const currentTab = ref(0)
const currentCard = ref(0)
const currentField = ref(0)
const currentFieldType = ref("text")

const currentTabOptions = ref([])
const currentCardOptions = ref([])
const currentFieldOptions = ref([])

const updateCurrentTabOptions = () => {
	if (!formModel.value.additionalTabs) {
		formModel.value.additionalTabs = []
	}
	let currentTabs = formModel.value.additionalTabs.map((tab, index) => {
		let title = tab.title
		let order = tab.order
		return {
			sortOrder: order,
			text: title,
			value: index,
		}
	})
	currentTabs.push({
		text: "Advanced Settings",
		value: "settings",
		sortOrder: 9999,
	})
	currentTabOptions.value = currentTabs
}
const updateCurrentCardOptions = () => {
	let currentCards
	if (currentTab.value === "settings") {
		currentCards = formModel.value.settings.map((card, index) => {
			let title = card.title || "Card " + (parseInt(index) + 1)
			let cardId = index
			return {
				text: title,
				value: cardId,
			}
		})
	} else {
		if (
			!formModel.value.additionalTabs[currentTab.value] ||
			!formModel.value.additionalTabs[currentTab.value].cards
		) {
			return []
		}
		currentCards = formModel.value.additionalTabs[currentTab.value].cards.map(
			(card, index) => {
				let title = card.title || "Card " + (parseInt(index) + 1)
				let cardId = index
				return {
					text: title,
					value: cardId,
				}
			},
		)
	}
	currentCardOptions.value = currentCards
}

const updateCurrentFieldOptions = () => {
	if (
		!formModel.value.additionalTabs[currentTab.value].cards[currentCard.value]
	) {
		formModel.value.additionalTabs[currentTab.value].cards[currentCard.value] =
			{ fieldsList: [] }
	}
	let currentFields = formModel.value.additionalTabs[currentTab.value].cards[
		currentCard.value
	].fieldsList.map((field, index) => {
		let title
		if (field.type === "live-html") {
			title = "Live HTML"
		} else {
			title = field.label || field.name || field.content.substr(0, 15)
		}
		let secondary = field.type
		let fieldId = index
		return {
			text: title,
			secondaryText: secondary,
			value: fieldId,
		}
	})
	currentFieldOptions.value = currentFields
}

let cardTemplate = {
	title: "",
	id: "",
	type: "",
}
const currentCardFields = ref(cardTemplate)
let tabTemplate = {
	title: "",
	id: "",
	icon: "",
	order: 0,
	previousTabText: "",
	nextTabText: "",
	conditionParams: "",
}
const currentTabFields = ref(tabTemplate)
let fieldTemplate = {
	type: "",
	name: "",
	column: "custom_settings",
}
const currentFieldSettings = ref(fieldTemplate)

// watch(currentTab, async (newVal) => {
// 	if (newVal === "settings") {
// 		currentTabFields.value = formModel.value.settings || tabTemplate
// 	} else {
// 		if (!formModel.value.additionalTabs) {
// 			formModel.value.additionalTabs = {}
// 		}
// 		if (!formModel.value.additionalTabs[newVal]) {
// 			formModel.value.additionalTabs[newVal] = tabTemplate
// 		}
// 		currentTabFields.value =
// 			formModel.value.additionalTabs[newVal] || tabTemplate
// 	}
// 	currentCard.value = 0
// 	updateCurrentCardOptions()
// })
// watch(currentCard, async (newVal) => {
// 	if (currentTab.value === "settings") {
// 		currentCardFields.value =
// 			formModel.value.settings[currentCard.value] || cardTemplate
// 	} else {
// 		if (!formModel.value.additionalTabs[currentTab.value].cards) {
// 			formModel.value.additionalTabs[currentTab.value].cards = []
// 		}
// 		currentCardFields.value =
// 			formModel.value.additionalTabs[currentTab.value]?.cards[
// 				currentCard.value
// 			] || cardTemplate
// 	}
// 	currentCardType.value = currentCardFields.value.type
// 	currentField.value = 0
// 	if (currentCardFields.value.type === "fields_list") {
// 		updateCurrentFieldOptions()
// 	}
// })
// watch(currentField, async (newVal) => {
// 	if (currentTab.value === "settings") {
// 		currentFieldSettings.value =
// 			formModel.value.settings[currentCard.value].fieldsList[
// 				currentField.value
// 			] || fieldTemplate
// 	} else {
// 		if (!formModel.value.additionalTabs[currentTab.value].cards) {
// 			formModel.value.additionalTabs[currentTab.value].cards = []
// 		}
// 		if (
// 			!formModel.value.additionalTabs[currentTab.value].cards[currentCard.value]
// 		) {
// 			formModel.value.additionalTabs[currentTab.value].cards[
// 				currentCard.value
// 			] = { fieldsList: [] }
// 		}
// 		currentFieldSettings.value =
// 			formModel.value.additionalTabs[currentTab.value].cards[currentCard.value]
// 				.fieldsList[currentField.value] || fieldTemplate
// 	}
// 	if (currentFieldSettings.value.type === "live-html") {
// 		currentFieldSettings.value.content = JSON.stringify(
// 			currentFieldSettings.value.content,
// 			null,
// 			2,
// 		)
// 	}
// 	currentFieldType.value = currentFieldSettings.value.type
// 	let currentSelection = currentFieldType.value
// 	nextTick(() => {
// 		if (selectCurrentFieldType.value) {
// 			selectCurrentFieldType.value.setValue(currentSelection)
// 		}
// 	})
// })

// watch(currentCardType, async (newVal) => {
// 	currentCardFields.value.type = newVal
// 	if (
// 		currentCardFields.value.type === "fields_list" &&
// 		!currentCardFields.value.fieldsList
// 	) {
// 		currentCardFields.value.fieldsList = []
// 	}
// })
// watch(currentFieldType, async (newVal) => {
// 	currentFieldSettings.value.type = newVal
// 	if (fieldTypeSettings.value[newVal]) {
// 		fieldTypeSettings.value[newVal].forEach((field) => {
// 			if (!currentFieldSettings.value[field.name]) {
// 				currentFieldSettings.value[field.name] = field.default || ""
// 			}
// 		})
// 	}
// })
// watch(currentTabOptions, async (newVal) => {
// 	let currentSelection = currentTab.value
// 	nextTick(() => {
// 		if (selectCurrent.value) {
// 			selectCurrent.value.setValue(currentSelection)
// 		}
// 	})
// })
// watch(currentCardOptions, async (newVal) => {
// 	let currentSelection = currentCard.value

// 	nextTick(() => {
// 		if (selectCurrentCard.value) {
// 			selectCurrentCard.value.setValue(currentSelection)
// 		}
// 	})
// })
// watch(currentFieldOptions, async (newVal) => {
// 	let currentSelection = currentField.value
// 	nextTick(() => {
// 		if (selectCurrentField.value) {
// 			selectCurrentField.value.setValue(currentSelection)
// 		}
// 	})
// })

// watch(currentCardFields, async (newVal) => {
// 	let currentSelection = currentCardType.value
// 	nextTick(() => {
// 		if (selectCurrentCardType.value) {
// 			selectCurrentCardType.value.setValue(currentSelection)
// 		}
// 	})
// })

const addNewTab = () => {
	let currentLength = formModel.value.additionalTabs.length
	formModel.value.additionalTabs[currentLength] = {
		title: "New Tab",
		id: "new-tab" + currentLength,
		icon: "",
		order: currentLength,
		previousTabText: "",
		nextTabText: "",
		conditionParams: "",
		cards: [],
	}
	currentTab.value = currentLength
	updateCurrentTabOptions()
}
const updateTab = () => {
	currentTabFields.value.id = currentTabFields.value.title
		.replace(/\s+/g, "-")
		.toLowerCase()
	if (currentTab.value === "settings") {
		formModel.value.settings = currentTabFields.value
	} else {
		formModel.value.additionalTabs[currentTab.value] = currentTabFields.value
	}
	updateCurrentTabOptions()
}
const onSelect = async () => {}
const setCurrentCard = (index) => {
	currentCard.value = index
	updateCurrentFieldOptions()
}
const setCurrentTab = (index) => {
	currentTab.value = index
	updateCurrentCardOptions()
}
const setCurrentField = (cardIndex, index) => {
	setCurrentCard(cardIndex)
	nextTick(() => {
		currentField.value = index
		nextTick(() => {
			if (selectCurrentField.value) {
				selectCurrentField.value.setValue(index)
			}
		})
	})
}
const getCardClasses = (card, index) => {
	let cardCols = card.cols || 12
	let cardColsSm = card.colsSm || cardCols
	let cardColsMd = card.colsMd || cardColsSm
	let cardColsLg = card.colsLg || cardColsMd
	let cardColsXl = card.colsXl || cardColsLg
	let activeCard =
		currentCard.value === index && !previewMode.value
			? "border border-primary"
			: ""
	return [
		"col-" + cardCols,
		"col-sm-" + cardColsSm,
		"col-md-" + cardColsMd,
		"col-lg-" + cardColsLg,
		"col-xl-" + cardColsXl,
		activeCard,
	]
}
const getFieldClasses = (field, cardIndex, fieldIndex) => {
	let fieldCols = field.cols || 12
	let fieldColsSm = field.colsSm || fieldCols
	let fieldColsMd = field.colsMd || fieldColsSm
	let fieldColsLg = field.colsLg || fieldColsMd
	let fieldColsXl = field.colsXl || fieldColsLg
	let activeField =
		currentField.value === fieldIndex &&
		currentCard.value == cardIndex &&
		!previewMode.value
			? "border border-danger"
			: ""
	return [
		"col-" + fieldCols,
		"col-sm-" + fieldColsSm,
		"col-md-" + fieldColsMd,
		"col-lg-" + fieldColsLg,
		"col-xl-" + fieldColsXl,
		activeField,
	]
}

const addNewCard = () => {
	let selectedTab = formModel.value.additionalTabs[currentTab.value]
	if (!selectedTab.cards) {
		selectedTab.cards = []
	}
	let currentLength = selectedTab.cards.length
	selectedTab.cards.push({
		title: "New Card",
		id: "new-card" + currentLength,
		type: "fields_list",
		fieldsList: [],
	})
	currentCard.value = currentLength
	updateCurrentCardOptions()
}
const updateCard = () => {
	if (currentTab.value === "settings") {
		formModel.value.settings[currentCard.value] = currentCardFields.value
	} else {
		if (!formModel.value.additionalTabs[currentTab.value].cards) {
			formModel.value.additionalTabs[currentTab.value].cards = []
		}
		formModel.value.additionalTabs[currentTab.value].cards[currentCard.value] =
			currentCardFields.value
	}
	updateCurrentCardOptions()
}

const addNewField = () => {
	let selectedCard =
		formModel.value.additionalTabs[currentTab.value].cards[currentCard.value]
	if (!selectedCard.fieldsList) {
		selectedCard.fieldsList = []
	}
	let currentLength = selectedCard.fieldsList.length
	selectedCard.fieldsList.push({
		type: "text",
		label: "Field Label",
		name: "field_name",
	})
	currentField.value = currentLength
	updateCurrentFieldOptions()
}
const updateField = () => {}

const updateForm = () => {
	props.dataStore.setModelValue(props.field, formModel.value, true)
}

onMounted(async () => {
	formModel.value = props.dataStore.getModelValue(props.field)
	if (!formModel.value.additionalTabs) {
		formModel.value.additionalTabs = []
	}
	if (!formModel.value.settings) {
		formModel.value.settings = []
	}
	updateCurrentTabOptions()
	currentTab.value = 0
	selectCurrent.value?.setValue(0)
})
</script>

<style scoped></style>
