import { getNestedProperty } from "../Utils/dotNotationHelpers"

export class TemplateParsingService {
	constructor() {
		this.maxRecursionDepth = 10
		this.templateCache = new Map()
		// FNV-1a constants
		this.FNV_PRIME = 0x01000193
		this.FNV_OFFSET_BASIS = 0x811c9dc5
	}

	fnv1a(str) {
		let hash = this.FNV_OFFSET_BASIS
		for (let i = 0; i < str.length; i++) {
			hash ^= str.charCodeAt(i)
			hash = Math.imul(hash, this.FNV_PRIME)
		}
		return hash >>> 0 // Convert to unsigned 32-bit integer
	}

	compileTemplate(content) {
		const hash = this.fnv1a(content)
		if (this.templateCache.has(hash)) {
			return this.templateCache.get(hash)
		}

		const tokens = this.tokenize(content)
		const compiled = this.compileTokens(tokens)
		this.templateCache.set(hash, compiled)
		return compiled
	}

	compileTokens(tokens) {
		const parts = []
		const stack = [{ parts, currentParts: parts }]

		for (const token of tokens) {
			try {
				if (token.match(/\{% if (.+?) %\}/)) {
					const condition = token.match(/\{% if (.+?) %\}/)[1]
					const newBlock = {
						type: "condition",
						test: this.parseCondition(condition),
						parts: [],
						elseParts: [],
					}
					stack[stack.length - 1].currentParts.push(newBlock)
					stack.push({
						block: newBlock,
						currentParts: newBlock.parts,
					})
				} else if (token === "{% else %}") {
					if (stack.length > 1) {
						const current = stack[stack.length - 1]
						current.currentParts = current.block.elseParts
					}
				} else if (token === "{% endif %}") {
					if (stack.length > 1) {
						stack.pop()
					}
				} else {
					const valueMatch = token.match(/\{\{\s*(.+?)\s*\}\}/)
					const current = stack[stack.length - 1]

					if (valueMatch) {
						current.currentParts.push({
							type: "variable",
							path: valueMatch[1].trim(),
						})
					} else if (token !== "") {
						// Keep all non-empty text tokens
						current.currentParts.push({
							type: "text",
							content: token,
						})
					}
				}
			} catch (error) {
				console.error("Error processing token:", token, error)
				continue
			}
		}
		return parts
	}

	parseCondition(condition) {
		if (condition.includes(" is not empty")) {
			const key = condition.replace(" is not empty", "").trim()
			return { type: "notEmpty", key }
		}
		if (condition.includes("==")) {
			const [left, right] = condition.split("==").map((s) => s.trim())
			const isQuoted = right.startsWith('"') || right.startsWith("'")
			return {
				type: "equality",
				left,
				right: isQuoted ? right.slice(1, -1) : right,
				rightIsLiteral: isQuoted,
			}
		}
		if (condition.includes(">=")) {
			const [left, right] = condition.split(">=").map((s) => s.trim())
			return { type: "gte", left, right }
		}
		if (condition.includes(">")) {
			const [left, right] = condition.split(">").map((s) => s.trim())
			return { type: "gt", left, right }
		}
		if (condition.includes("<")) {
			const [left, right] = condition.split("<").map((s) => s.trim())
			return { type: "lt", left, right }
		}
		return { type: "truthy", key: condition }
	}

	processTemplate(content, referenceData, depth = 0, originalContent = null) {
		if (depth > this.maxRecursionDepth) {
			console.error("Max recursion depth reached while processing template")
			return ""
		}

		if (depth === 0) {
			originalContent = content
		}

		const compiled = this.compileTemplate(content)
		const result = this.renderCompiled(compiled, referenceData, depth)

		// Check for infinite recursion
		if (result === content && depth > 0) {
			return ""
		}

		// Check if more processing is needed
		if (
			result.includes("{{") &&
			result.includes("}}") &&
			depth < this.maxRecursionDepth
		) {
			return this.processTemplate(
				result,
				referenceData,
				depth + 1,
				originalContent,
			)
		}

		return result
	}

	renderCompiled(compiled, referenceData, depth) {
		return compiled
			.map((part) => {
				switch (part.type) {
					case "text":
						return part.content
					case "variable":
						return this.getValue(part.path, referenceData) ?? ""
					case "condition":
						const result = this.evaluateCompiledCondition(
							part.test,
							referenceData,
						)
						const targetParts = result ? part.parts : part.elseParts
						return this.renderCompiled(targetParts, referenceData, depth)
					default:
						return ""
				}
			})
			.join("")
	}

	evaluateCompiledCondition(test, referenceData) {
		try {
			switch (test.type) {
				case "notEmpty": {
					const value = this.getValue(test.key, referenceData)
					return value !== "" && value != null
				}
				case "equality": {
					const leftValue = this.getValue(test.left, referenceData)
					const rightValue = test.rightIsLiteral
						? test.right
						: this.getValue(test.right, referenceData)
					return String(leftValue) === String(rightValue)
				}
				case "gte": {
					const left = parseFloat(this.getValue(test.left, referenceData))
					const right = parseFloat(test.right)
					return !isNaN(left) && !isNaN(right) && left >= right
				}
				case "gt": {
					const left = parseFloat(this.getValue(test.left, referenceData))
					const right = parseFloat(test.right)
					return !isNaN(left) && !isNaN(right) && left > right
				}
				case "lt": {
					const left = parseFloat(this.getValue(test.left, referenceData))
					const right = parseFloat(test.right)
					return !isNaN(left) && !isNaN(right) && left < right
				}
				case "truthy": {
					const value = this.getValue(test.key, referenceData)
					return value !== false && value != null && value !== ""
				}
				default:
					return false
			}
		} catch (error) {
			console.error("Error evaluating condition:", test, error)
			return false
		}
	}

	tokenize(content) {
		const regex =
			/\{%\s*if\s+(.+?)\s*%\}|\{%\s*else\s*%\}|\{%\s*endif\s*%\}|\{\{\s*(.+?)\s*\}\}|.+?(?=\{\{|\{%|$)/gs
		return Array.from(content.matchAll(regex)).map((match) => match[0])
	}

	getValue(key, referenceData) {
		try {
			const value = getNestedProperty(referenceData, key)
			return value ?? "" // Return empty string instead of space
		} catch (error) {
			console.error(`Error resolving value for key: ${key}`, error)
			return "" // Return empty string instead of space
		}
	}

	cleanWhitespace(content) {
		return content
			.replace(/\s+/g, " ")
			.split("\n")
			.map((line) => line.trim())
			.filter((line) => line)
			.join("\n")
			.trim()
	}

	clearCache() {
		this.templateCache.clear()
	}
}
