export const useSwoogoConnection = (props) => {
	const fieldsList = [
		{
			name: "client_id",
			label: "API Key",
			type: "text",
		},
		{
			name: "client_secret",
			label: "API Secret",
			type: "text",
		},
		// name
		{
			name: "name",
			label: "Name",
			type: "text",
		},
		// description
		{
			name: "description",
			label: "Description",
			type: "text",
		},
	]

	return {
		fieldsList,
	}
}
