import { usePage } from "@inertiajs/vue3"
import { onMounted, ref, computed } from "vue"
import { router } from "@inertiajs/vue3"
import CreateDialog from "@/Components/Mod/CreateDialog.vue"

/**
 * This composable is used to show the create modal if the create param is set in the url.
 * It defines both the modal state as well as the helper function to show the modal.
 * See AdminTable.vue for an example of how to use this.
 * @param {*} props
 * @returns
 */
export const useDataTableCreateModal = (props) => {
	/**
	 * Defines the current dialog to use when creating an item
	 * You can define a custom component in the createConfig prop
	 * by importing the component and passing it in the createConfig
	 */
	const activeCreateDialog = computed(() => {
		if (
			!tableCreate.value?.create ||
			Object.keys(tableCreate.value).length === 0
		)
			return null
		if (tableCreate.value.component) {
			return tableCreate.value.component
		}
		return CreateDialog
	})

	/**
	 * The create config is passed in from the parent component
	 */
	const tableCreate = computed(() => props.createConfig)

	return {
		activeCreateDialog,
	}
}
