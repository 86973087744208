<template>
	<canvas
		ref="canvas"
		class="qr_canvas"
		:class="props.field.classList ?? props.classList ?? []"
		:height="props.field.height ?? props.height ?? 'auto'"
	>
	</canvas>
</template>

<script setup>
import { computed, defineModel, watch, onMounted, ref } from "vue"
import QRCode from "qrcode"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	height: {
		type: String,
		default: "auto",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})

const canvas = ref(null)

const fieldValue = defineModel({
	type: String,
	default: null,
})
const localValue = computed(() => {
	let fieldName = props.field.name ?? props.name ?? null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column ?? null,
				name: fieldName,
			})
		: (fieldValue.value ??
				props.field.content ??
				props.field.default ??
				props.default ??
				"")
})

const setCanvas = (value) => {
	if (props.field.options) {
		value = (props.field.options.prepend ?? "") + value
		value += props.field.options.append ?? ""
	}
	QRCode.toCanvas(canvas.value, value, (error) => {
		if (error) console.error(error)
	})
}

onMounted(() => {
	setCanvas(localValue.value)
})

watch(localValue, (value) => {
	setCanvas(value)
})
</script>
