<template>
	<label v-if="props.field.label" :for="inputId" class="form-label">
		{{ props.field.label }}
	</label>
	<MDBSelectZ
		ref="selectInput"
		v-model:selected="selectValue"
		v-model:options="options"
		:input-id="inputId"
		:filter="true"
		:preselect="false"
		:multiple="false"
		:required="props.field.required"
		:disabled="props.field.readonly || false"
		class=""
		:class="props.field.classList || []"
		:option-height="60"
		@update:selected="onSelect"
		@closed="onInput"
	/>
</template>

<script setup>
import {
	ref,
	computed,
	watch,
	defineModel,
	onMounted,
	nextTick,
	onBeforeMount,
} from "vue"
import MDBSelectZ from "@/Components/Mod/MDBOverride/MDBSelectZ.vue"
import { storeToRefs } from "pinia"
import { DateTime, IANAZone } from "luxon"
import { router } from "@inertiajs/vue3"
import { usePage } from "@inertiajs/vue3"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: null,
	},
})

const { dataModel } = storeToRefs(props.dataStore)

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const fieldValue = defineModel({
	type: String,
	default: "",
})

const selectInput = ref(null)
const options = ref([])
const selectValue = ref(null)

// Generate timezone options using Luxon
const generateTimezoneOptions = () => {
	const commonAmericanTimezones = [
		"America/New_York",
		"America/Chicago",
		"America/Denver",
		"America/Los_Angeles",
	]

	return Intl.supportedValuesOf("timeZone")
		.map((zoneName) => {
			const zone = DateTime.local().setZone(zoneName)
			const offset = zone.toFormat("ZZ")
			const abbr = zone.toFormat("ZZZZ")

			return {
				value: zoneName,
				text: abbr + " - " + zoneName,
				secondaryText: `UTC${offset}`,
				isCommon: commonAmericanTimezones.includes(zoneName),
			}
		})
		.sort((a, b) => {
			if (a.isCommon && !b.isCommon) return -1
			if (!a.isCommon && b.isCommon) return 1
			return a.text.localeCompare(b.text)
		})
}

// Initialize options on mount
const initialized = ref(false)
onMounted(() => {
	options.value = generateTimezoneOptions()
})

const computeFieldValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: props.field.content || fieldValue.value || props.field.default
})

watch(
	computeFieldValue,
	(newValue) => {
		if (newValue !== selectValue.value) {
			selectValue.value = newValue
			nextTick(() => {
				selectInput.value?.setValue(newValue)
			})
		}
	},
	{ immediate: true },
)

const onSelect = (value) => {
	selectValue.value = value
}
const onInput = () => {
	let value = selectValue.value
	let fieldName = props.field.name || props.name || null
	if (props.dataStore && fieldName) {
		props.dataStore.setModelValue(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
			true,
		)
	}
}
</script>
