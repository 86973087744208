<template>
	<label class="asset-label">{{ props.field.label || props.label }}</label>
	<small v-if="props.field.infoText" class="asset-label-small">{{
		props.field.infoText
	}}</small>
	<div
		class="asset-selector text-center p-2 mb-3"
		:style="{
			height: height + 'px',
			position: 'relative',
			borderStyle: 'dashed',
			borderWidth: '1px',
			borderColor: 'rgb(79, 79, 79)',
			borderRadius: '4px',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			backgroundImage: backgroundUrl,
		}"
	>
		<div
			v-if="loading"
			class="spinner-border text-primary float-start"
			role="status"
		>
			<span class="visually-hidden">Loading...</span>
		</div>
		<div
			style="
				display: flex;
				flex-direction: column;
				margin-top: auto;
				margin-bottom: auto;
				height: 100%;
			"
			class="text-center mx-2"
		>
			<button
				class="btn btn-outline-primary rounded-pill selectBtn"
				type="button"
				style="margin: auto; display: block"
				:disabled="props.previewMode"
				@click.prevent="showModal = true"
			>
				Select
			</button>
		</div>
		<button
			v-if="selectValue !== null && selectValue !== ''"
			class="border-0 bg-transparent text-danger position-absolute top-100 end-0"
			style="font-size: 0.75rem"
			type="button"
			@click.prevent="removeSelection()"
		>
			<FontAwesomeIcon size="xs" :icon="lookupIcon('trash', 'far')" />
			Remove
		</button>
	</div>

	<MDBModal
		v-model="showModal"
		tabindex="-1"
		size="lg"
		dialog-classes="modal-dialog-scrollable"
		labelledby="assetModal"
	>
		<MDBModalBody>
			<button
				class="float-end btn border border-1 text-black shadow-0 btn-floating btn-sm"
				type="button"
				@click.prevent="showModal = false"
			>
				<IconBadge icon="fat.xmark" size="lg" :options="{ classList: [] }" />
			</button>
			<h4 class="text-center mt-3 fw-bold">{{ modalTitle }}</h4>
			<h6 class="text-center fw-light mb-4">
				{{ modalSubtitle }}
			</h6>
			<div
				v-if="loading"
				class="progress"
				:style="{ opacity: loading ? 1 : 0 }"
			>
				<div
					class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					style="width: 100%"
				></div>
			</div>
			<div
				v-if="!loading"
				class="d-flex flex-column gap-4"
				style="max-width: 650px; margin: auto"
			>
				<AssetUploaderDropzone
					:options="fieldOptions"
					:file-types="acceptedFileTypes"
					@uploaded="refreshList"
				/>
				<div class="row">
					<div
						v-for="option in options"
						:key="option.id"
						class="col-sm-4 col-md-3 col-lg-2"
						@click="selectInput = option.id"
					>
						<div
							class="file-man-box border border-secondary-10"
							:class="[{ active: selectInput == option.id }]"
						>
							<IconBadge
								icon="far.trash"
								:options="{
									classList: [
										'cursor-pointer',
										'text-danger',
										'deleteAsset',
										'p-1',
										'rounded-circle',
									],
								}"
								@click.stop="setItemToDelete(option)"
							/>
							<div class="file-img-box">
								<img
									v-if="isImage(option.asset_url)"
									:src="option.asset_url"
									alt="icon"
								/>
								<img
									v-else
									:src="getGenericFileImage(option.asset_url)"
									alt="icon"
								/>
							</div>
						</div>
						<div class="mb-0 text-center text-overflow smaller mt-1">
							{{ option.text }}
						</div>
					</div>
				</div>
			</div>
			<MDBBtn
				:disabled="!selectInput"
				color="primary"
				class="rounded-pill m-auto d-block py-1 px-3 mt-4 mb-2"
				@click="selectAsset()"
			>
				Select
			</MDBBtn>
		</MDBModalBody>
	</MDBModal>
	<ConfirmDelete
		v-model="deleteModalOpen"
		:route="deleteRoute"
		title="Delete Experience Asset"
		:body-text="`Are you sure you want to delete this experience asset?<br><img class='w-50 mt-3 m-auto d-block' src=${itemToDelete?.asset_url} /><br><div class='text-center'>${itemToDelete?.text}</div>`"
		@success="removeDeletedItem"
	/>
</template>

<script setup>
import {
	ref,
	onMounted,
	defineProps,
	defineEmits,
	computed,
	nextTick,
	watch,
} from "vue"
import ConfirmDelete from "@/Components/Mod/ConfirmDelete.vue"
import http from "@/Services/http"
import { useTranslator } from "@/Composables/useTranslator"
import { useProjectStore } from "@/Store/projectStore"
import { useCommonPageProps } from "@/Composables/useCommonPageProps"
import { usePage } from "@inertiajs/vue3"
import {
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBBtn,
} from "mdb-vue-ui-kit"
import AssetUploaderDropzone from "@/Components/AssetUploaderDropzone.vue"
import IconBadge from "@/Components/IconBadge.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { useDataModelStore } from "@/Store/dataModelStore"
import { storeToRefs } from "pinia"
import { useAssetStore } from "@/Store/assetStore"
import { defaultsDeep, remove } from "lodash"
import Underline from "@tiptap/extension-underline"

// const pageStore = useDataModelStore()
// const { dataStore } = storeToRefs(pageStore)

const assetStore = useAssetStore()
const { assetList } = storeToRefs(assetStore)

const page = usePage()

const props = defineProps({
	field: {
		type: Object,
		required: true,
		default: () => ({ label: "", required: false, name: "" }),
	},
	name: {
		type: String,
		required: false,
		default: "",
	},
	default: {
		type: String,
		required: false,
		default: "",
	},
	label: {
		type: String,
		required: true,
	},
	classList: {
		type: [Array, String],
		default: () => [],
	},
	errorMessages: {
		type: Array,
		required: false,
		default: () => [],
	},
	dataStore: {
		type: Object,
		required: true,
		default: null,
	},
	previewMode: {
		type: Boolean,
		default: false,
	},
})

const fieldOptions = computed(() => props.field.options ?? {})
const modalTitle = computed(() => {
	if (
		fieldOptions.value.acceptedFileTypes?.length ||
		fieldOptions.value.fileTypes?.length > 1 ||
		fieldOptions.value.fileTypes?.includes("*")
	)
		return "Select or Upload an Asset"
	if (!fieldOptions.value.fileTypes && !fieldOptions.value.acceptedFileTypes)
		return "Select or Upload an Image"
	const assetType =
		fieldOptions.value.fileTypes?.[0][0].toUpperCase() +
		fieldOptions.value.fileTypes?.[0].slice(1)
	if (/^[AEIOU]/.test(assetType[0])) {
		return `Select or Upload an ${assetType}`
	} else {
		return `Select or Upload a ${assetType}`
	}
})
const modalSubtitle = computed(() => {
	if (
		fieldOptions.value.fileTypes?.length > 1 ||
		fieldOptions.value.fileTypes?.includes("*")
	)
		return "Upload a new asset, or select from previously uploaded assets in your library."
	if (!fieldOptions.value.fileTypes && !fieldOptions.value.acceptedFileTypes)
		return "Upload a new image file, or select from previously uploaded image files in your asset library."
	const assetType = fieldOptions.value.fileTypes?.[0]
	return `Upload a new ${assetType} file, or select from previously uploaded ${assetType} files in your asset library.`
})
const acceptedFileTypes = computed(() => {
	if (fieldOptions.value.acceptedFileTypes) {
		return fieldOptions.value.acceptedFileTypes
	}

	const fileTypeExtensions = {
		font: [".ttf", ".otf", ".woff", ".woff2", ".eot", ".svg"],
		video: [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm", ".m4v"],
		image: [
			".jpg",
			".jpeg",
			".png",
			".gif",
			".bmp",
			".tiff",
			".svg",
			".webp",
			".ico",
		],
		audio: [".mp3", ".wav", ".flac", ".aac", ".m4a", ".ogg", ".wma", ".alac"],
		table: [".json", ".csv", ".xlsx", ".xls"],
	}

	const types = fieldOptions.value.fileTypes ?? ["image"]

	return types.reduce((acc, type) => {
		if (fileTypeExtensions[type]) {
			acc.push(...fileTypeExtensions[type])
		}
		return acc
	}, [])
})
const isImage = (url) => {
	const extension = url.split("?")[0].split(".").at(-1)
	const imageExtensions = [
		".jpg",
		".jpeg",
		".png",
		".gif",
		".bmp",
		".tiff",
		".svg",
		".webp",
		".ico",
	]
	return imageExtensions.includes(`.${extension.toLowerCase()}`)
}

const getGenericFileImage = (url) => {
	const extension = url.split("?")[0].split(".").at(-1)
	const fileTypeExtensions = {
		font: [".ttf", ".otf", ".woff", ".woff2", ".eot", ".svg"],
		video: [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm", ".m4v"],
		audio: [".mp3", ".wav", ".flac", ".aac", ".m4a", ".ogg", ".wma", ".alac"],
		table: [".json", ".csv", ".xlsx", ".xls"],
	}
	for (const [key, value] of Object.entries(fileTypeExtensions)) {
		if (value.includes(`.${extension.toLowerCase()}`)) {
			if (key === "video" || key === "audio")
				return "/images/playable_asset.png"
			if (key === "font") return "/images/font_asset.png"
			if (key === "table") return "/images/table_asset.png"
		}
	}
}

const fieldValue = defineModel({
	type: String,
	default: "",
})

const selectValue = ref(null)
const selectInput = ref(null)

const itemToDelete = ref(null)
const deleteModalOpen = ref(false)
const deleteRoute = computed(() =>
	route("dashboard.project.assets.destroy", {
		project: page.props.project.slug,
		asset: itemToDelete.value?.id ?? 0,
		team: usePage().props.currentTeam.slug,
	}),
)

const emit = defineEmits(["update:modelValue"])
const loading = ref(false)
const backgroundUrl = ref("none")

const options = computed(() => {
	if (acceptedFileTypes.value.includes("*")) return assetList.value
	return assetList.value.filter((asset) => {
		const extension = asset.asset_url.split("?")[0].split(".").at(-1)
		return acceptedFileTypes.value.includes(`.${extension.toLowerCase()}`)
	})
})

const previewColumn = computed(() => {
	const column = props.field.column
	if (!column || column === "asset_list") return "asset_preview"

	const dotIndex = column.indexOf(".")
	if (dotIndex === -1) return column

	return "asset_preview" + column.slice(dotIndex)
})

onMounted(assetBoot)
const height = ref(60)

const assetColumn =
	props.field.column !== undefined ? props.field.column : "asset_list"
let preview = null
let showModal = ref(false)
function assetBoot() {
	if (props.previewMode) {
		return
	}
	fetchOptions()
	if (props.field.settings) {
		if (props.field.settings.preview) {
			preview = true
		}
		if (props.field.settings.height) {
			height.value = props.field.settings.height
		}
	}
}
const refreshList = () => {
	fetchOptions()
}
const selectAsset = () => {
	selectValue.value = selectInput.value
	if (props.previewMode) {
		return
	}
	options.value.forEach((option) => {
		if (option.id == selectValue.value) {
			backgroundUrl.value = `url(${isImage(option.asset_url) ? option.asset_url : getGenericFileImage(option.asset_url)})`
			if (props.dataStore) {
				props.dataStore.setModelValue(
					{ column: previewColumn.value, name: props.field.name },
					option.asset_url,
				)
				props.dataStore.setModelValue(
					{ column: assetColumn, name: props.field.name },
					option.id,
					true,
				)
			}
		}
	})
	showModal.value = false
	emit("update:modelValue", selectValue.value)
}
const removeSelection = () => {
	if (props.previewMode) {
		return
	}
	selectValue.value = null
	if (props.dataStore) {
		props.dataStore.setModelValue(
			{ column: previewColumn.value, name: props.field.name },
			"",
		)
		props.dataStore.setModelValue(
			{ column: assetColumn, name: props.field.name },
			null,
			true,
		)
	}
	backgroundUrl.value = "none"
	emit("update:modelValue", null)
}

async function fetchOptions() {
	if (props.dataStore) {
		selectValue.value = props.dataStore.getModelValue({
			name: props.field.name,
			column: assetColumn,
		})
	}
	options.value.forEach((option) => {
		if (option?.asset_url && option.id == selectValue.value) {
			backgroundUrl.value = `url(${isImage(option.asset_url) ? option.asset_url : getGenericFileImage(option.asset_url)})`
		}
	})
}

watch(
	() => assetList.value,
	(value) => {
		if (value.length) {
			const selectedOption = options.value.find(
				(option) => option.id == selectValue.value,
			)
			if (selectedOption?.asset_url)
				backgroundUrl.value = `url(${isImage(selectedOption.asset_url) ? selectedOption.asset_url : getGenericFileImage(selectedOption.asset_url)})`
		}
	},
)
watch(selectValue, (value) => (selectInput.value = value))

const setItemToDelete = (item) => {
	itemToDelete.value = {
		...item,
		asset_url: isImage(item.asset_url)
			? item.asset_url
			: getGenericFileImage(item.asset_url),
	}
	deleteModalOpen.value = true
}
const removeDeletedItem = () => {
	console.log("test")
	fetchOptions()
}
</script>

<style scoped>
.asset-label,
.asset-label-small {
	display: block;
}
.asset-label-small {
	font-size: 12px;
}
@keyframes progressBarAnimation {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
.selectBtn {
	opacity: 0.8;
	background-color: white;
}
.progress-bar {
	animation: progressBarAnimation 2s linear infinite;
	background-size: 200% 100%;
}

.progress {
	height: 1px;
	transition: opacity 0.2s ease-in-out;
	z-index: 100;
	margin-top: -1px;
	width: calc(100% - 10px);
	margin-left: 5px;
	margin-right: 5px;
}
.card-box {
	padding: 20px;
	border-radius: 3px;
	margin-bottom: 30px;
	background-color: #fff;
}

.file-man-box {
	aspect-ratio: 1;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.file-man-box:hover,
.file-man-box.active {
	border-color: var(--mdb-primary) !important;
}
.file-man-box:hover {
	border-width: 2px !important;
}
.file-man-box.active {
	border-width: 3px !important;
}

.file-img-box {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
		linear-gradient(135deg, #ccc 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, #ccc 75%),
		linear-gradient(135deg, transparent 75%, #ccc 75%);
	background-size: 25px 25px;
	background-position:
		0 0,
		12.5px 0,
		12.5px -12.5px,
		0px 12.5px;
}
.file-man-box:hover .file-img-box {
	left: -1px;
	top: -1px;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
}
.file-man-box.active .file-img-box {
	left: -2px;
	top: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
}
.file-img-box img {
	position: absolute;
	left: 5px;
	top: 5px;
	width: calc(100% - 10px);
	aspect-ratio: 1;
	border-radius: 3px;
	object-fit: cover;
}
.deleteAsset {
	position: absolute;
	background: rgba(254, 74, 103, 0.3);
	aspect-ratio: 1;
	display: none;
	z-index: 1;
}
.file-man-box:hover .deleteAsset {
	top: 4px;
	right: 4px;
	display: block;
}
.file-man-box.active:hover .deleteAsset {
	top: 3px;
	right: 3px;
}
.text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	width: 100%;
	overflow: hidden;
}
</style>
