// Utilities
import { defineStore } from "pinia"

export const useDataTableStore = defineStore("dataTable", {
	state: () => ({
		dataPaginated: {},
	}),
	getters: {
		paginatedData: (state) => state.dataPaginated,
	},

	actions: {
		setPaginatedData(value) {
			this.dataPaginated = value
		},
	},
})
