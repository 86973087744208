/**
 * useCheckboxGroup - A Vue 3 composable for handling checkbox groups with a "select all" feature.
 *
 * This composable provides a way to manage a list of checkboxes, including a "toggle all" checkbox.
 * The state of individual checkboxes, as well as the state of the "toggle all" checkbox (including an
 * indeterminate state), are all managed internally by the composable.
 *
 * @param {Ref<Array>} items - An array of objects that you want to represent with checkboxes. Each object should have a unique 'id' property.
 *
 * @returns {Object}
 * @property {Ref<Array>} selectedItems - A ref to an array of selected item IDs.
 * @property {Ref<Boolean>} toggleAllEnabled - A ref to a boolean indicating whether the "toggle all" checkbox is checked.
 * @property {Computed<Boolean>} isIndeterminate - A computed property that indicates whether the "toggle all" checkbox should be in an indeterminate state.
 *
 * Usage:
 * ```javascript
 * import { ref } from 'vue';
 * import useCheckboxGroup from './useCheckboxGroup';
 *
 * const items = ref([
 *   { id: 1, name: "Item 1" },
 *   { id: 2, name: "Item 2" },
 *   { id: 3, name: "Item 3" }
 * ]);
 *
 * const { selectedItems, toggleAllEnabled, isIndeterminate } = useCheckboxGroup(items);
 * ```
 *
 * In the template, bind `selectedItems` to the v-model of individual checkboxes, `toggleAllEnabled` to the v-model
 * of the "toggle all" checkbox, and use `isIndeterminate` to set the indeterminate state of the "toggle all" checkbox.
 */
import { ref, watch, computed } from "vue"

export const useCheckboxGroup = (items, props, emit) => {
	let curVal = []

	if (props.selected && Array.isArray(props.selected)) {
		curVal = props.selected
	} else if (props.selected) {
		curVal = [props.selected]
	}
	const internalselectedItems = ref(curVal)
	const isSingleSelect = props.options?.singleSelect || false
	const selectedItems = computed({
		get: () => internalselectedItems.value,
		set: (newVal) => {
			if (isSingleSelect) {
				// For single select, ensure only one item can be selected at a time
				newVal = newVal.slice(-1)
				// internalselectedItems.value.pop()
				internalselectedItems.value = newVal
				// pop first
			} else {
				internalselectedItems.value = newVal
			}
		},
	})
	const toggleAllEnabled = ref(false)

	const isIndeterminate = computed(() => {
		return (
			selectedItems.value.length > 0 &&
			selectedItems.value.length < items.value.length
		)
	})

	watch(toggleAllEnabled, (newVal) => {
		if (newVal) {
			selectedItems.value = items.value.map((item) => item.id)
		} else {
			selectedItems.value = []
		}
	})
	watch(selectedItems, (newVal) => {
		emit("update:selected", newVal)
	})

	return {
		selectedItems,
		toggleAllEnabled,
		isIndeterminate,
	}
}
