<template>
	<div class="position-relative input-group mb-2">
		<MDBInput
			:model-value="localValue"
			:label="label"
			:required="props.field.required || props.required"
			:type="props.type"
			autocomplete="off"
			:maxlength="
				props.field.maxLength
					? props.field.maxLength
					: props.maxLength
						? props.maxLength
						: 0
			"
			:readonly="props.field.readonly || props.readonly || false"
			:class="props.field.classList || props.classList || []"
			:error-messages="errorMessages"
			:form-outline="false"
			wrapper-class="form-floating"
			@update:model-value="onInput"
		/>
		<MDBTooltip
			v-model="tooltip"
			offset="0,5"
			tabindex="-1"
			direction="left"
			tag="button"
			class="input-group-prepend input-group-text"
			type="button"
			@click.prevent="toggleSelect()"
		>
			<template #reference>
				<FontAwesomeIcon
					v-if="!selectMode"
					:icon="lookupIcon('location-crosshairs', 'fas')"
				/>
				<FontAwesomeIcon
					v-else
					:icon="lookupIcon('location-crosshairs-slash', 'fas')"
				/>
			</template>
			<template #tip>
				{{
					selectMode
						? "Cancel coordinate selection"
						: "Click to select coordinates on the map above"
				}}
			</template>
		</MDBTooltip>
	</div>
	<div
		class="p-2"
		style="position: relative"
		:class="{
			border: true,
			'border-success': selectMode,
			'border-3': selectMode,
			'select-mode': selectMode,
		}"
	>
		<img
			ref="mapImage"
			:class="props.field.mapClassList || props.mapClassList || []"
			:height="props.field.mapHeight || props.mapHeight || 'auto'"
			:src="mapValue"
			@load="onLoad()"
			@mousemove="(event) => selectMode && calculateCoordinates(event)"
			@mousedown="selectMode && setCoordinates()"
		/>
		<div v-if="selectMode" class="position-tooltip">
			Click to Set <br />
			{{ xPosition }}, {{ yPosition }}
		</div>
		<div v-else-if="localValue" class="coordinate-marker"></div>
		<template v-if="!selectMode && additionalMarkers.length > 0">
			<div
				v-for="(marker, i) in additionalMarkers"
				:key="i"
				:alt="marker.name"
				class="additional-marker"
				:style="{
					background: marker.color,
					width: marker.width,
					height: marker.width,
					marginTop: marker.offset,
					marginLeft: marker.offset,
					borderRadius: marker.half,
					top: marker.yPosition,
					left: marker.xPosition,
				}"
			></div>
		</template>
	</div>
</template>

<script setup>
import {
	computed,
	defineModel,
	nextTick,
	onMounted,
	ref,
	shallowRef,
	watch,
} from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { MDBInput, MDBTooltip } from "mdb-vue-ui-kit"

import { Link } from "@inertiajs/vue3"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { storeToRefs } from "pinia"

const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	name: {
		type: String,
		default: "",
	},
	mapName: {
		type: String,
		default: "",
	},
	content: {
		type: String,
		default: "",
	},
	base64: {
		type: Boolean,
		default: false,
	},
	height: {
		type: String,
		default: "auto",
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
})
const selectMode = shallowRef(false)
const tooltip = ref(false)
const fieldValue = defineModel({
	type: String,
	default: undefined,
})
const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}

const mapImage = ref(null)
const xPosition = shallowRef(0)
const yPosition = shallowRef(0)
const xPositionPx = shallowRef("0px")
const yPositionPx = shallowRef("0px")
const selectionColor =
	props.field.selectionColor || props.selectionColor || "#0ea2fd"
const selectionSize = computed(() => {
	let width = props.field.selectionWidth || props.selectionWidth || 100
	let half = Math.floor(width / 2)
	let offset = half - 5

	return {
		width: width + "px",
		half: half + "px",
		offset: "-" + offset + "px",
	}
})
const additionalMarkers = ref([])
const computeAdditionalMarkers = () => {
	if (props.field?.additionalMarkers) {
		if (typeof props.field.additionalMarkers === "function") {
			let markers = props.field.additionalMarkers(dataModel.value)
			if (markers.length > 0) {
				return markers
					.map((marker) => {
						let width = marker.selectionWidth || "100"
						let half = Math.floor(width / 2)
						let offset = half - 5
						let postionX = marker.coordinates
							? marker.coordinates.split(",")[0]
							: 0
						let postionY = marker.coordinates
							? marker.coordinates.split(",")[1]
							: 0
						let xRatio =
							mapImage.value.clientWidth / mapImage.value.naturalWidth
						let yRatio =
							mapImage.value.clientHeight / mapImage.value.naturalHeight

						return {
							name: marker.name || "additional-marker",
							color: marker.selectionColor || "#0ea2fd",
							width: width + "px",
							half: half + "px",
							offset: "-" + offset + "px",
							xPosition: postionX * xRatio + "px",
							yPosition: postionY * yRatio + "px",
						}
					})
					.filter((marker) => marker.xPosition && marker.yPosition)
			}
		} else {
			let markers = props.field.additionalMarkers
			if (markers.length > 0) {
				return markers
					.map((marker) => {
						let width = marker.selectionWidth || "100"
						let half = Math.floor(width / 2)
						let offset = half - 5
						let postionX = marker.coordinates
							? marker.coordinates.split(",")[0]
							: 0
						let postionY = marker.coordinates
							? marker.coordinates.split(",")[1]
							: 0
						return {
							name: marker.name || "additional-marker",
							color: marker.selectionColor || "#0ea2fd",
							width: width + "px",
							half: half + "px",
							offset: "-" + offset + "px",
							xPosition: postionX + "px",
							yPosition: postionY + "px",
						}
					})
					.filter((marker) => marker.xPosition && marker.yPosition)
			}
		}
	}
	return []
}

const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				props.field.default ||
				props.field.content ||
				props.default ||
				""
})
let label = computed(() => {
	if (typeof props.field.label === "function") {
		return props.field.label(props.field, dataModel.value)
	} else if (typeof props.label === "function") {
		return props.label(props.field, dataModel.value)
	} else {
		return props.field.label || props.label
	}
})

const onInput = (value) => {
	let fieldName = props.field.name || props.name || null
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
	if (mapLoaded.value) {
		xMarkerPx.value = computeMarkerX(value)
		yMarkerPx.value = computeMarkerY(value)
	}
}
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 100)
}

const mapValue = shallowRef(null)
const computeMapValue = () => {
	let urlContent = fieldValue.value || props.field.content || props.content
	let getVal =
		props.dataStore &&
		props.dataStore.getModelValue({
			column: "dashboard_assets",
			name: urlContent,
			disableDotNotation: true,
		})
	if (getVal) {
		return getVal
	} else {
		if (props.field.name && props.dataStore) {
			let retValue = props.dataStore.getModelValue({
				column: props.field.mapColumn || null,
				name: props.field.mapName || null,
			})
			if (props.base64) {
				return "data:" + retValue.mime + ";base64," + retValue.base64
			} else {
				return retValue
			}
		} else {
			return urlContent
		}
	}
}
const toggleSelect = () => {
	selectMode.value = !selectMode.value
}
const calculateCoordinates = (event) => {
	let xRatio = event.target.naturalWidth / event.target.clientWidth
	let yRatio = event.target.naturalHeight / event.target.clientHeight

	xPosition.value = Math.floor(
		(event.layerX - event.target.offsetLeft) * xRatio,
	)
	yPosition.value = Math.floor((event.layerY - event.target.offsetTop) * yRatio)
	xPositionPx.value =
		event.target.offsetLeft + (event.layerX - event.target.offsetLeft) + "px"
	yPositionPx.value =
		event.target.offsetTop + (event.layerY - event.target.offsetTop) + "px"
}
const setCoordinates = () => {
	localValue.value = `${xPosition.value},${yPosition.value}`
	onInput(`${xPosition.value},${yPosition.value}`)
	selectMode.value = false
}

const computeMarkerX = (useSuppliedValue = false) => {
	let xRatio = mapImage.value.clientWidth / mapImage.value.naturalWidth
	let xOffset = useSuppliedValue
		? useSuppliedValue.split(",")[0]
		: localValue.value
			? localValue.value.split(",")[0]
			: 0
	xOffset = xOffset * xRatio
	return mapValue.value ? `${xOffset}px` : "0px"
}
const xMarkerPx = ref("0px")
const computeMarkerY = (useSuppliedValue = false) => {
	let yRatio = mapImage.value.clientHeight / mapImage.value.naturalHeight
	let yOffset = useSuppliedValue
		? useSuppliedValue.split(",")[1]
		: localValue.value
			? localValue.value.split(",")[1]
			: 0
	yOffset = yOffset * yRatio
	return mapValue.value ? `${yOffset}px` : "0px"
}
const yMarkerPx = ref("0px")
const mapLoaded = ref(false)
const onLoad = () => {
	mapLoaded.value = true
	nextTick(() => {
		initializeMarkers()
	})
}
const initializeMarkers = () => {
	if (mapLoaded.value) {
		xMarkerPx.value = computeMarkerX()
		yMarkerPx.value = computeMarkerY()
		additionalMarkers.value = computeAdditionalMarkers()
	}
}
window.addEventListener("resize", initializeMarkers())
onMounted(() => {
	mapValue.value = computeMapValue()
})
</script>
<style scoped>
.pulsate {
	animation: Pulsate 2s infinite;
}
.select-mode img {
	cursor: crosshair;
}
.position-tooltip {
	top: v-bind(yPositionPx);
	left: v-bind(xPositionPx);
	position: absolute; /* must have this */
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	font: normal 11px/15px sans-serif;
	padding: 3px 10px;
	border-radius: 3px;
	white-space: nowrap;
}
.coordinate-marker {
	top: v-bind(yMarkerPx);
	left: v-bind(xMarkerPx);
	position: absolute;
	width: v-bind(selectionSize.width);
	height: v-bind(selectionSize.width);
	margin-top: v-bind(selectionSize.offset);
	margin-left: v-bind(selectionSize.offset);
	border-radius: v-bind(selectionSize.half);
	background: v-bind(selectionColor);
	opacity: 0.5;
}
.additional-marker {
	position: absolute;
	opacity: 0.5;
}
@keyframes Pulsate {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}
</style>
