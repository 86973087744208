// ... existing formatDollar function ...

/**
 * Formats a number with thousand separators
 * @param {number|string} num - Number to format
 * @param {number} decimals - Number of decimal places (default: 0)
 * @returns {string} Formatted number with commas
 */
export function formatNumber(num, decimals = 0) {
	if (num === null || num === undefined) {
		return "0"
	}
	// if num is a string, make it a number
	if (typeof num === "string") {
		num = Number(num)
	}

	// Round using a more precise method
	const factor = Math.pow(10, decimals)
	num = Math.round((num + Number.EPSILON) * factor) / factor

	return num.toLocaleString("en-US", {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
}
