<template>
	<div
		v-if="
			checkFeaturesAndPermissions(props.card) &&
			(showCard ||
				(props.card.type === 'render_list' &&
					props.card.listKey &&
					dataModel[props.card.listKey] &&
					dataModel[props.card.listKey].length > 0))
		"
		:class="getCardClasses()"
	>
		<div
			:class="[
				{
					card: props.card.showCard,
					'col-12': !props.card.showCard,
					border: props.card.border || props.card.showCard,
					'shadow-0': true,
					collapsed: !showingCollapsed,
					collapseSmooth: props.card.collapse?.smooth,
				},
				props.card.collapse ? 'collapsible' : '',
				...(props.card.classList || []),
			]"
		>
			<div
				:class="[
					...(props.card.bodyClassList || ['pb-2']),
					{
						'card-body': props.card.showCard,
					},
				]"
				class="show-card"
				:style="customSpacers"
			>
				<!-- <h5 v-if="props.card.title">
					{{ props.card.title }}
				</h5> -->
				<div
					v-if="props.card.collapse"
					class="collapse-toggle d-flex justify-content-between"
				>
					<h5 class="collapse-heading">{{ props.card.collapse.heading }}</h5>
					<MDBSwitchZ
						v-model="showingCollapsed"
						:label="props.card.collapse.label"
						:false-value="false"
					/>
				</div>
				<DataTable
					v-if="props.card.type === 'data_table'"
					:table-title="props.card.tableTitle"
					:headers="props.card.headers"
					:options="props.card.options || {}"
					:data-store="props.dataStore"
					:list-data="
						props.card.listData
							? props.card.listData
							: props.card.options?.paginated
								? null
								: getNestedListProperty()
					"
					:paginated-items="
						props.card.options?.paginated ? getNestedListProperty() : null
					"
					:create-config="props.card.createConfig"
				/>
				<FieldsList
					v-else-if="
						props.card.type === 'fields_list' || props.card.type == undefined
					"
					:title="props.card.title || ''"
					:fields-list="props.card.fieldsList || []"
					:card="props.card"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				/>
				<FieldsList
					v-else-if="props.card.type === 'render_list'"
					:title="props.card.title || ''"
					:fields-list="getNestedListProperty()"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				/>
				<FormBuilder
					v-else-if="props.card.type === 'form_builder'"
					:card="props.card"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				/>
				<TabsList
					v-else-if="props.card.type === 'tabs_list'"
					:card="props.card"
					:form-model="dataModel"
					:data-store="props.dataStore"
					@update-value="inputUpdate"
				/>
				<InfoCard
					v-else-if="props.card.type === 'info_card'"
					:card="props.card"
					:card-item="dataModel"
					:data-store="props.dataStore"
					:options="props.card.options || {}"
				/>
				<FormPanel
					v-else-if="props.card.type === 'card_list'"
					:tab="props.card"
					:tab-item="dataModel"
					:tab-id="props.id"
					:data-store="props.dataStore"
					:class="props.card.wrapperClassList"
				/>
				<CarouselSpinner
					v-else-if="props.card.type === 'carousel'"
					:label="props.card.label"
					:item-list="props.card.itemList"
				/>
				<GridView
					v-else-if="props.card.type === 'grid_view'"
					:tile-list="props.card.tileList"
					:grid-data="props.card.gridData"
					:data-store="props.dataStore"
					:card="props.card"
				/>
				<component
					:is="props.card.component"
					v-else-if="props.card.type === 'component'"
					v-bind="props.card.props"
					:card-item="dataModel.value"
				/>
				<FormDataDisplay
					v-else-if="props.card.type === 'data_display'"
					:fields-list="props.card.fieldsList"
					:title="props.card.title || ''"
					:data-store="props.dataStore"
				/>
				<FormButtonGroup
					v-else-if="props.card.type === 'button_group'"
					:buttons="props.card.buttons"
					:grouped="props.card.grouped"
					:class-list="props.card.classList || []"
				/>
			</div>
			<Link
				v-if="props.card.footerButton"
				as="a"
				:href="props.card.footerButton.route"
				:class="
					props.card.footerButton.classList || ['card-footer', 'text-center']
				"
			>
				<IconBadge
					v-if="props.card.footerButton.icon"
					:icon="props.card.footerButton.icon"
				/>
				{{ props.card.footerButton.text }}
			</Link>

			<div
				v-if="dragHandleClass"
				class="d-flex justify-content-center align-items-center"
			>
				<span :class="dragHandleClass">
					<FontAwesomeIcon :icon="lookupIcon('grip-horizontal', 'fas')" />
				</span>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from "vue"
import { Link } from "@inertiajs/vue3"
import MDBSwitchZ from "@/Components/Mod/MDBOverride/MDBSwitchZ.vue"
import FormField from "@/Components/Mod/FormField.vue"
import InfoCard from "@/Components/Mod/InfoCard.vue"
import DataTable from "@/Components/Mod/DataTable.vue"
import CarouselSpinner from "@/Components/Mod/CarouselSpinner.vue"
import GridView from "@/Components/Mod/GridView.vue"
import FormDataDisplay from "@/Components/Mod/FormDataDisplay.vue"
import FormPanel from "@/Components/Mod/FormPanel.vue"
import TabsList from "@/Components/Mod/TabsList.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import IconBadge from "@/Components/IconBadge.vue"
import FieldsList from "@/Components/Mod/FormCards/FieldsList.vue"
import FormBuilder from "@/Components/Mod/FormCards/FormBuilder.vue"

import { storeToRefs } from "pinia"

import { MDBSwitch } from "mdb-vue-ui-kit"
import FormButtonGroup from "./FormButtonGroup.vue"

import { checkConditions } from "@/Composables/useConditionParams"
import { checkFeaturesAndPermissions } from "@/Composables/useModVisibilityChecker"

const props = defineProps({
	card: {
		type: Object,
		default: () => ({}),
	},
	dataStore: {
		type: Object,
		default: () => ({}),
	},
	dragHandleClass: {
		type: String,
		default: "",
	},
})
const { dataModel } = storeToRefs(props.dataStore)

const showCard = computed(() => {
	if (props.card.condition || props.card.conditionParams?.length) {
		return checkConditions(props.card, dataModel?.value ?? {})
	}
	return true
})

const showingCollapsed = ref(props.card.collapse?.showingByDefault ?? true)

const emit = defineEmits(["updateValue"])

const inputUpdate = (field) => {
	emit("updateValue", field)
}

const getNestedListProperty = () => {
	if (props.dataStore) {
		return props.card.listKey
			? props.dataStore.getModelValue({ name: props.card.listKey })
			: []
	} else {
		return ""
	}
}

const getCardClasses = () => {
	let cardCols = props.card.cols || 12
	let cardColsSm = props.card.colsSm || cardCols
	let cardColsMd = props.card.colsMd || cardColsSm
	let cardColsLg = props.card.colsLg || cardColsMd
	let cardColsXl = props.card.colsXl || cardColsLg
	let propClass = props.card.marginBottom
		? "mb-" + props.card.marginBottom
		: "mb-4"
	let wrapperClassList = props.card.cardWrapperClassList || ""

	wrapperClassList = Array.isArray(wrapperClassList)
		? wrapperClassList.join(" ")
		: wrapperClassList

	return [
		propClass,
		wrapperClassList,
		"col-" + cardCols,
		"col-sm-" + cardColsSm,
		"col-md-" + cardColsMd,
		"col-lg-" + cardColsLg,
		"col-xl-" + cardColsXl,
	]
}

const customSpacers = computed(() => {
	let style = {}
	if (props.card?.spacerY) style["--mdb-card-spacer-y"] = props.card.spacerY
	if (props.card?.spacerX) style["--mdb-card-spacer-x"] = props.card.spacerX
	return style
})
</script>

<style scoped>
.collapsible {
	overflow: hidden;
}
.collapsed {
	height: 75px;
}
.collapsed .collapse-toggle ~ * {
	display: none;
}
.collapseSmooth {
	max-height: 100%;
	transition: 0.5s ease-in-out max-height;
}
.home-dashboard-card .show-card,
.home-dashboard-card .show-card .row.py-3,
.home-dashboard-card .show-card .row.py-3 .col-md-12.py-2 {
	padding: 0 !important;
	margin: 0 !important;
}
</style>
